export interface ChannelSubscription {
  channelName: string;
}

enum SubscriptionStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  UNAUTHORIZED = 'unauthorized',
}

export interface SubscriptionResponse {
  status: SubscriptionStatus;
  channel: string;
  message?: string;
}

export interface CallVerificationResponse {
  event: string;
  data: {
    callSid: string;
    phoneNumber: string;
  };
}

// Socket Channel Constants
// We currently only subscribe to the all-event-channels and phone-number channels
export const SOCKET_CHANNELS = {
  ALL_ELIGIBLE_CHANNELS: 'all-event-channels',
  PHONE_NUMBER_CHANNEL: 'phone-number:',
  ORG_ROLE_FORMAT: 'org:{orgId}:{role}',
  SUPER_ADMIN: 'superadmin',
};
