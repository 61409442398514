import { SerializedError } from '@reduxjs/toolkit';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SKIP_AUTH_HEADER } from '../constants';
import { getAuth0ClientInstance } from '../context/CustomAuth0Provider';
import { setConnectionStatus } from '../redux/reducers';
import { AppDispatch, RootState } from '../redux/store';
import { ApiPostError, ApiTagTypes, ImpersonateUserParams } from '../types';
import { isSerializedError } from '../utils';

const authBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL, // Set the base URL for all queries.
  prepareHeaders: async (headers) => {
    // Skip authentication for requests with 'Skip-Auth' header.
    if (headers.get(SKIP_AUTH_HEADER) === 'true') {
      // Remove custom header before sending the request
      headers.delete(SKIP_AUTH_HEADER);
      return headers;
    }

    const auth0Client = getAuth0ClientInstance();
    const token = await auth0Client.getAccessTokenSilently();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

/**
 * Maps a FetchBaseQueryError or SerializedError to an ApiPostError.
 */
const mapFetchBaseQueryError = (error: FetchBaseQueryError | SerializedError): ApiPostError => {
  if (isSerializedError(error)) {
    return error;
  }

  // Ensure the message exists in the error response or provide a fallback.
  const message =
    typeof error.data === 'object' && error.data && 'message' in error.data && typeof error.data.message === 'string'
      ? error.data.message
      : 'An unknown error occurred';

  return {
    data: { message },
    status: error.status,
  };
};

const modifyArgsForImpersonation = (args: string | FetchArgs, impersonateParams?: ImpersonateUserParams) => {
  if (!impersonateParams) return args;

  // If the args are a string, return a new object with the url and params.
  if (typeof args === 'string') {
    return {
      url: args,
      params: impersonateParams,
    };
  }

  // If the args are an object and have a disableImpersonation param, return the args as is.
  if (args.params && args.params.disableImpersonation) return args;

  // Otherwise, return a new object with the existing args and the impersonation params merged.
  return {
    ...args,
    params: {
      ...impersonateParams,
      ...(args.params || {}), // Merge existing params if any
    },
  };
};

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, ApiPostError> = async (args, api, extraOptions) => {
  const { dispatch, getState } = api as { dispatch: AppDispatch; getState: () => RootState };

  if (navigator.onLine === false) {
    // If there's no connection, dispatch an action to update the state
    dispatch(setConnectionStatus(false));
  }

  const { organization, user, isImpersonating } = getState().auth;
  const orgId = organization?.id;
  const userId = user?.id;
  const impersonateParams = isImpersonating && orgId && userId ? { orgId, impersonateUserId: userId } : undefined;
  const modifiedArgs = modifyArgsForImpersonation(args, impersonateParams);

  const result = await authBaseQuery(modifiedArgs, api, extraOptions);
  if (result.error) {
    // Map the error to an ApiPostError.
    return {
      error: mapFetchBaseQueryError(result.error),
    };
  }

  return result;
};

// Setup RTK Query API service with base configurations.
// Note: When adding new APIs, ensure that tags are provided.
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  tagTypes: Object.values(ApiTagTypes),
  endpoints: () => ({}),
});
