import { PaginationState } from '@tanstack/react-table';
import { DatePickerRange } from '../components';
import { SimulationsFilterKeys } from './filters.types';
import { PagePagination } from './pagination.types';
import { CustomSortingState } from './sorting.types';
import { Tag } from './tags.types';

export enum ProspectCategory {
  PRACTICE = 'PRACTICE',
  INTERVIEW = 'INTERVIEW',
  QUIZ = 'QUIZ',
}

export enum ProspectType {
  PROSPECTING = 'PROSPECTING',
  DISCOVERY = 'DISCOVERY',
}

export enum ProspectSortingFilters {
  FIRST_NAME = 'firstName',
  UPDATED_AT = 'updatedAt',
}

/** Represents the base data for a practice prospect. */
export type BasePracticeProspect = {
  accountName: string;
  firstName: string;
  lastName: string;
  orgId: string;
  personaId: string;
  type: ProspectType;
  category: ProspectCategory;
  associatedPhoneNumber?: string;
  jobTitle?: string;
};

export enum ProspectStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

type ListPracticeProspectWithoutTags = BasePracticeProspect & {
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  isUsed: boolean;
  status: ProspectStatus;
  isFavorited: boolean;
  isSubscribed: boolean;
  cooldownPeriod?: number;
  lastCall?: {
    endTime: Date;
  };
  lastModifiedBy: string;
  managerNotes?: string;
  notes?: string;
  scorecardTemplateId?: string;
  timeLimit?: number;
  successResult?: ProspectSuccessResult;
  allowedCallers?: {
    id: string;
    email: string;
    name: string;
  }[];
  allowedTeams?: {
    id: string;
    name: string;
    color?: string;
  }[];
  isLocked: boolean;
};

/**
 * Represents the fields returned for each prospect in the
 * practice prospects list query after transformation.
 */
export type PracticeProspect = ListPracticeProspectWithoutTags & { tags: Tag[] };

/**
 * Represents the fields returned for each prospect in the
 * practice prospects list query before transformation.
 */
export type ApiListPracticeProspect = ListPracticeProspectWithoutTags & { tags: { tag: Tag }[] };

/**
 * Represents the data returned from the API for practice prospects
 * after transformation.
 */
export type PracticeProspectListResponseData = {
  prospects: PracticeProspect[];
  pagination: PagePagination;
};

/**
 * Represents the response returned from the API for practice prospects list
 * before transformation.
 */
export type PracticeProspectListResponse = {
  data: {
    prospects: ApiListPracticeProspect[];
    pagination: PagePagination;
  };
};

type PracticeProspectDetailsWithoutTags = ListPracticeProspectWithoutTags & {
  orgId: string;

  // Personal
  age?: number;
  pronouns?: Pronouns;
  talkativenessLevel: number;
  figurativeThinkingLevel: number;
  empathyLevel: number;
  improvisationLevel: number;
  personaVoiceId: string;
  language: Language;

  // Lead
  tenure?: number;
  roleType?: ProspectRoleType;
  department?: string;
  numOfDirectReports?: number;

  // Account
  industry?: string;
  hqLocation?: string;
  remoteType?: RemoteType;
  isMultiSite?: boolean;
  employeesFrom?: number;
  employeesTo?: number;
  annualRevenueFrom?: number;
  annualRevenueTo?: number;
  fundingRaisedType?: FundingRaised;

  // Scenario
  priorities: string[];
  isDynamicPriorities: boolean;
  numOfDynamicPriorities?: number;
  objections: string[];
  isDynamicObjections: boolean;
  numOfDynamicObjections?: number;
  questions: string[];
  isDynamicQuestions: boolean;
  numOfDynamicQuestions?: number;
  lastTouchpoint?: ProspectLastTouchpoint;
  hiddenContexts: string[];
  successDifficultyLevel?: number;

  // Configuration
  isHidden: boolean;
  isRestricted: boolean;
  firstMessage: string;
  breathingRoom?: number | null;
  numOfInitialShortResponses?: number;
  objectionRate?: SeverityLevel;
  questionRate?: SeverityLevel;
  numOfRequiredUncoveredPriorities?: number;
  isHangupFeatureEnabled: boolean;
  hangupPrompt?: string;
  ttsModelId: string;
  sttModel: string;
  sttModelId: string;
  llmModel: string;
  llmModelId: string;
  llmTemperature: number;
  llmMaxTokens: number;
  llmFrequencyPenalty?: number | null;
  llmPresencePenalty?: number | null;
  promptTemplateId?: string;

  personaPrompt: string;
  isPromptEdited: boolean;
  updatedAt: Date;
  isHotlineProspect?: boolean;
};

/**
 * Represents all fields returned for a prospect by the
 * prospect by id query after transformation.
 */
export type PracticeProspectDetails = PracticeProspectDetailsWithoutTags & { tags: Tag[] };

/**
 * Represents the fields returned for each prospect in the
 * practice prospects list query before transformation.
 */
export type ApiPracticeProspectDetails = PracticeProspectDetailsWithoutTags & { tags: { tag: Tag }[] };

export type HotlinePracticeProspectResponse = {
  data: {
    prospect: ApiListPracticeProspect;
  };
};

export type PracticeProspectsSelectResponse = {
  data: {
    prospects: BasePracticeProspect[];
  };
};

/** Represents data related to a selected prospect. */
export type GetPracticeProspectsForSelectParams = {
  categories?: ProspectCategory[];
  includeJobTitle?: boolean;
  includeAssociatedPhoneNumber?: boolean;
  orgId?: string;
};

// -- PROSPECT PAGE --

/** Represents the mode of the prospect page. */
export enum ProspectPageMode {
  CREATE = 'Create',
  EDIT = 'Edit',
}

/** Represents the tabs of the prospect page. */
export enum ProspectPageTabs {
  PERSONAL = 'personal',
  LEAD = 'lead',
  ACCOUNT = 'account',
  SCENARIO = 'scenario',
  CONFIGURATION = 'configuration',
  PROMPT = 'prompt',
}

// -- PERSONAL --

/** Represents the pronoun options for a prospect. */
export enum Pronouns {
  HE_HIM_HIS = 'HE_HIM_HIS',
  SHE_HER_HERS = 'SHE_HER_HERS',
  THEY_THEM_THEIRS = 'THEY_THEM_THEIRS',
}

export enum Language {
  ENGLISH = 'ENGLISH',
  DUTCH = 'DUTCH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  GREEK = 'GREEK',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  POLISH = 'POLISH',
  SPANISH = 'SPANISH',
  ITALIAN = 'ITALIAN',
  PORTUGUESE = 'PORTUGUESE',
  RUSSIAN = 'RUSSIAN',
}

// -- LEAD --

/** Represents the role type options for a prospect. */
export enum ProspectRoleType {
  CHAMPION = 'CHAMPION',
  GATEKEEPER = 'GATEKEEPER',
  DECISION_MAKER = 'DECISION_MAKER',
}

// -- ACCOUNT --

/**
 * Represents the range type for a prospect.
 * Undefined second value means the range is from the first value to infinity.
 */
export type RangeType = [number, number | undefined];

/** Represents the work options for a prospect. */
export enum RemoteType {
  REMOTE = 'REMOTE',
  HYBRID = 'HYBRID',
  IN_PERSON = 'IN_PERSON',
}

/** Represents the funding raised options for a prospect. */
export enum FundingRaised {
  SMB = 'SMB',
  SEED = 'SEED',
  SERIES_A = 'SERIES_A',
  SERIES_B = 'SERIES_B',
  SERIES_C = 'SERIES_C',
  SERIES_D_PLUS = 'SERIES_D_PLUS',
  PRIVATE_EQUITY = 'PRIVATE_EQUITY',
  PUBLIC_SMALL_CAP = 'PUBLIC_SMALL_CAP',
  PUBLIC_MID_CAP = 'PUBLIC_MID_CAP',
  PUBLIC_LARGE_CAP = 'PUBLIC_LARGE_CAP',
  NON_PROFIT = 'NON_PROFIT',
  GOVERNMENT_FEDERAL = 'GOVERNMENT_FEDERAL',
  GOVERNMENT_STATE = 'GOVERNMENT_STATE',
  GOVERNMENT_LOCAL = 'GOVERNMENT_LOCAL',
  GOVERNMENT_OTHER = 'GOVERNMENT_OTHER',
  EDUCATION_K_12 = 'EDUCATION_K_12',
  EDUCATION_HIGHER_EDUCATION = 'EDUCATION_HIGHER_EDUCATION',
  EDUCATION_OTHER = 'EDUCATION_OTHER',
  OTHER = 'OTHER',
}

// -- SCENARIO --

export enum ProspectLastTouchpoint {
  NONE = 'NONE',
  EMAIL = 'EMAIL',
  VOICEMAIL = 'VOICEMAIL',
  DEMO_REQUEST = 'DEMO_REQUEST',
  WEBINAR_ATTENDEE = 'WEBINAR_ATTENDEE',
  IN_PERSON_EVENT = 'IN_PERSON_EVENT',
}

/** Represents the successful result options for a call with a prospect. */
export enum ProspectSuccessResult {
  BOOKED_A_MEETING = 'BOOKED_A_MEETING',
  QUALIFY_PROSPECT = 'QUALIFY_PROSPECT',
  GET_REFERRAL = 'GET_REFERRAL',
}

// -- CONFIGURATION --

export enum SeverityLevel {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
}

/** Represents the LLM provider options for a prospect. */
export enum LLMProviderID {
  GPT = 'gpt',
  ANTHROPIC = 'anthropic',
  LLAMA = 'llama',
  GEMINI = 'gemini',
  OPENROUTER = 'openrouter',
}

export interface PracticeProspectsFilters {
  [SimulationsFilterKeys.PROSPECT_ACCOUNT]?: string[];
  [SimulationsFilterKeys.PROSPECT]?: string[];
  [SimulationsFilterKeys.DATE_CREATED]?: DatePickerRange[];
  [SimulationsFilterKeys.SEARCH]?: string;
  [SimulationsFilterKeys.TAGS]?: string[];
  // Specifies the categories of prospects to include/exclude in the filters.
  [SimulationsFilterKeys.CATEGORY]?: ProspectCategory[];
  // Indicates whether to exclude the specified categories from the filters.
  excludeCategories?: boolean;
  // Indicates whether to return only prospects that are favorited.
  isFavorited?: boolean;
}

export interface PracticeProspectsParams {
  pagination?: PaginationState;
  sorting?: CustomSortingState;
}

interface BasePracticeProspectPayload {
  buyNewPhoneNumber?: boolean;
  orgId?: string;

  // Personal Tab
  firstName?: string;
  lastName?: string;
  age?: number | null;
  pronouns?: Pronouns | null;

  // Personality
  talkativenessLevel?: number;
  figurativeThinkingLevel?: number;
  empathyLevel?: number;
  improvisationLevel?: number;

  // Voice
  personaVoiceId?: string;
  language?: Language;
  personaVoiceProvider?: string;
  ttsModelId?: string;

  // Lead Tab
  jobTitle?: string;
  tenure?: number | null;
  roleType?: ProspectRoleType;
  department?: string;
  numOfDirectReports?: number | null;

  // Account Tab
  accountName?: string;
  industry?: string;
  hqLocation?: string;
  remoteType?: RemoteType | null;
  isMultiSite?: boolean;
  employeesFrom?: number | null;
  employeesTo?: number | null;
  annualRevenueFrom?: number | null;
  annualRevenueTo?: number | null;
  fundingRaisedType?: FundingRaised | null;

  // Scenario Tab
  priorities?: string[];
  isDynamicPriorities?: boolean;
  numOfDynamicPriorities?: number | null;
  objections?: string[];
  isDynamicObjections?: boolean;
  numOfDynamicObjections?: number | null;
  questions?: string[];
  isDynamicQuestions?: boolean;
  numOfDynamicQuestions?: number | null;
  lastTouchpoint?: ProspectLastTouchpoint | null;
  successResult?: ProspectSuccessResult;
  hiddenContexts?: string[];
  successDifficultyLevel?: number;

  // Configuration Tab
  type?: ProspectType;
  category?: ProspectCategory;
  isHidden?: boolean;
  isRestricted?: boolean;

  // Beginning of the Call
  firstMessage?: string;
  numOfInitialShortResponses?: number | null;

  // Conversation Tuning
  breathingRoom?: number | null;
  objectionRate?: SeverityLevel | null;
  questionRate?: SeverityLevel | null;
  numOfRequiredUncoveredPriorities?: number | null;

  // Ending the Call
  isHangupFeatureEnabled?: boolean;
  hangupPrompt?: string | null;

  // Technical Configs
  sttModel?: string;
  sttModelId?: string;
  llmModel?: string;
  llmModelId?: string;
  llmTemperature?: number;
  llmMaxTokens?: number;
  llmFrequencyPenalty?: number | null;
  llmPresencePenalty?: number | null;
  promptTemplateId?: string;

  // Other Fields
  notes?: string;
  isHotlineProspect?: boolean;
  scorecardTemplateId?: string | null;
  managerNotes?: string | null;
  cooldownPeriod?: number | null;
  timeLimit?: number | null;
  allowedCallers?: string[];
  allowedTeams?: string[];
}

/**
 * Represents the payload for creating a prospect.
 * Specifies fields that are required for the creation process.
 */
export interface CreatePracticeProspectPayload extends BasePracticeProspectPayload {
  orgId: string;

  // Personal Tab
  firstName: string;
  lastName: string;

  // Personality
  talkativenessLevel: number;
  figurativeThinkingLevel: number;
  empathyLevel: number;
  improvisationLevel: number;

  // Voice
  personaVoiceId: string;

  // Lead Tab
  jobTitle: string;

  // Account Tab
  accountName: string;
  industry: string;

  // Scenario Tab
  priorities: string[];
  objections: string[];
  questions: string[];
  successResult: ProspectSuccessResult;

  // Configuration Tab
  type: ProspectType;
  category: ProspectCategory;
  isHidden: boolean;
  firstMessage: string;

  // Other Fields
  tags?: string[];
}

/** Represents the payload for editing a prospect. */
export interface EditPracticeProspectPayload extends BasePracticeProspectPayload {
  id: string;
  personaPrompt?: string;
}

export interface UpdatedPracticeProspectData {
  prospect: PracticeProspectDetails;
}

export interface ProspectSubscriptionPayload {
  personaId: string;
}
