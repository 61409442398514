import { useCallback, useMemo, useState } from 'react';
import { ROLE_OPTIONS, SUPER_ADMIN_EMAIL_DOMAIN } from '../../../../../constants';
import { useAppSelector, useHandleApiResponse, useToast, useUpdateOrgSeats } from '../../../../../hooks';
import { useInviteUserMutation } from '../../../../../services';
import { OrganizationStatus, Permissions, Roles } from '../../../../../types';
import { AlertType, ButtonColor, Select, TextButton, TextInput } from '../../../../shared';

const ERROR_MSG = 'Failed to invite user.';
const ROLE_OPTIONS_WITHOUT_SUPER_ADMIN = ROLE_OPTIONS.filter((role) => role.value !== Roles.SUPER_ADMIN);
const VALIDATION_ERROR_MSG = 'Validation error';

const AddUser = () => {
  // State
  const [emailInput, setEmailInput] = useState('');
  const [selectedRole, setSelectedRole] = useState<Roles>(Roles.SALES_REP);
  const [error, setError] = useState('');

  // Redux
  const { user: currUser, organization } = useAppSelector((state) => state.auth);
  const permissions = currUser?.permissions || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);

  const isTrialOrg = organization?.status === OrganizationStatus.TRIAL;

  // Custom hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();
  const updateOrgSeats = useUpdateOrgSeats();

  // Mutations
  const [inviteUser, { isLoading }] = useInviteUserMutation();

  // Only show super admin role if the user has super-admin access.
  const roleOptions = useMemo(() => (isSuperAdmin ? ROLE_OPTIONS : ROLE_OPTIONS_WITHOUT_SUPER_ADMIN), [isSuperAdmin]);

  const handleInvite = useCallback(async () => {
    if (error.length) return;

    if (!emailInput.length) {
      setError('Field cannot be empty.');
      return;
    }

    if (selectedRole === Roles.SUPER_ADMIN && !emailInput?.endsWith(SUPER_ADMIN_EMAIL_DOMAIN)) {
      setError(`Email must end with "${SUPER_ADMIN_EMAIL_DOMAIN}" for super admin role.`);
      return;
    }

    try {
      const response = await inviteUser({ role: selectedRole, email: emailInput });

      const onError = (e: { message: string; status?: number | string }) => {
        if (e.status === 403) {
          setError('Insufficient privileges. Please contact your administrator.');
        } else if (e.message.includes(VALIDATION_ERROR_MSG)) {
          setError('Invalid email. Please enter a valid email address.');
        } else {
          setError(e.message);
        }
      };

      const onSuccess = () => {
        setEmailInput('');
        // Update organization seat counts
        updateOrgSeats(undefined, selectedRole);
      };

      handleApiResponse({
        response,
        errorMsg: ERROR_MSG,
        onError,
        onSuccess,
      });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [handleApiResponse, updateOrgSeats, selectedRole, isLoading, emailInput, error]);

  const resetError = () => {
    if (error.length) {
      setError('');
    }
  };

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
    resetError();
  };

  const handleSelectRoleChange = (newRole: Roles) => {
    setSelectedRole(newRole);
    resetError();
  };

  const isInputDisabled = isLoading || isTrialOrg;

  return (
    <div className="flex items-center gap-2">
      <TextInput
        placeholder="Add user by email"
        width="70%"
        onChange={handleEmailInputChange}
        value={emailInput}
        error={error}
        disabled={isInputDisabled}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleInvite();
          }
        }}
      />
      <div className="mb-4 flex gap-2">
        <Select
          options={roleOptions}
          selected={selectedRole}
          onChange={(newRole) => handleSelectRoleChange(newRole as Roles)}
          error={!!error}
          disabled={isInputDisabled}
        />
        <TextButton
          text="Invite"
          onClick={handleInvite}
          loading={isLoading}
          color={error ? ButtonColor.DESTRUCTIVE : undefined}
          disabled={isTrialOrg}
        />
      </div>
    </div>
  );
};

export default AddUser;
