import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { startNextWebCall } from '../../../../redux/reducers';
import { PracticeProspect, TextColor } from '../../../../types';
import { ButtonColor, ProspectDetails, TextButton, Typography, TypographySize } from '../../../shared';

interface UpNextProps {
  currPersonaId: string;
  exitCall: () => void;
  nextProspect?: PracticeProspect;
}

const UpNext = ({ currPersonaId, exitCall, nextProspect }: UpNextProps) => {
  const dispatch = useAppDispatch();
  const prospects = useAppSelector((state) => state.simulations.prospects);

  const renderExitButton = () => <TextButton text="Exit" color={ButtonColor.SECONDARY} fullWidth onClick={exitCall} />;

  if (!nextProspect) {
    return renderExitButton();
  }

  const { firstName, lastName, accountName, jobTitle } = nextProspect;
  const isLastProspect =
    prospects.findIndex((prospect) => prospect.personaId === currPersonaId) === prospects.length - 1;

  return (
    <div className="relative flex items-center justify-between gap-4 rounded-[4px] border border-primary p-4">
      <span className="left-1/5 absolute -top-2 transform bg-white px-2">
        <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION}>
          {isLastProspect ? 'From the top' : 'Up next'}
        </Typography>
      </span>
      <ProspectDetails firstName={firstName} lastName={lastName} accountName={accountName} jobTitle={jobTitle} />
      <div className="flex flex-col gap-2">
        <TextButton
          text="Start call"
          onClick={() => {
            dispatch(startNextWebCall());
          }}
        />
        {renderExitButton()}
      </div>
    </div>
  );
};

export default UpNext;
