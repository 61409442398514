import { Dispatch, SetStateAction, useCallback } from 'react';
import { PUBLIC_PROSPECT_CATEGORY_OPTIONS } from '../../../constants';
import { ProspectCategory } from '../../../types';
import { Select } from '../../shared';

interface SimulationCategorySelectorProps {
  filterProspectCategories: (category: ProspectCategory) => boolean;
  onChange: () => void;
  setNewCategory: Dispatch<SetStateAction<ProspectCategory | undefined>>;
  newCategory?: ProspectCategory;
}

const SimulationCategorySelector = ({
  filterProspectCategories,
  onChange,
  setNewCategory,
  newCategory,
}: SimulationCategorySelectorProps) => {
  const options = PUBLIC_PROSPECT_CATEGORY_OPTIONS.filter(({ value }) => filterProspectCategories(value));

  const onCategoryChange = useCallback(
    (newValue?: string) => {
      setNewCategory(newValue as ProspectCategory);
      onChange();
    },
    [setNewCategory, onChange]
  );

  return (
    <Select
      clearable
      placeholder="Select category"
      options={options}
      selected={newCategory}
      onChange={onCategoryChange}
    />
  );
};

export default SimulationCategorySelector;
