import { useCallback, useState } from 'react';
import { useHandleApiResponse } from '../../../hooks';
import { useFavoriteProspectMutation, useUnfavoriteProspectMutation } from '../../../services';
import { ComponentSize, StatusColor } from '../../../types';
import { Icon, Icons } from '../Icons';

const FAVORITE_ERROR_MSG = 'Failed to add to favorites';
const UNFAVORITE_ERROR_MSG = 'Failed to remove from favorites';

interface FavoriteButtonProps {
  personaId: string;
  isFavorited: boolean;
  onSuccess: (newIsFavorited: boolean) => void;
  size?: ComponentSize.SMALL | ComponentSize.LARGE;
}

const FavoriteButton = ({ personaId, isFavorited, onSuccess, size = ComponentSize.SMALL }: FavoriteButtonProps) => {
  const [isStarHovered, setIsStarHovered] = useState(false);

  const [favoriteProspect, { isLoading: isFavoriteLoading }] = useFavoriteProspectMutation();
  const [unfavoriteProspect, { isLoading: isUnfavoriteLoading }] = useUnfavoriteProspectMutation();

  const handleApiResponse = useHandleApiResponse();

  const getFillColor = () => {
    if (isStarHovered) {
      return isFavorited ? undefined : StatusColor.WARNING;
    } else {
      return isFavorited ? StatusColor.WARNING : undefined;
    }
  };

  const runFavorite = useCallback(async () => {
    try {
      const response = await favoriteProspect(personaId);
      handleApiResponse({
        response,
        errorMsg: FAVORITE_ERROR_MSG,
        onSuccess: () => onSuccess(true),
      });
    } catch (error) {
      console.error(FAVORITE_ERROR_MSG, error);
    }
  }, [personaId, favoriteProspect, handleApiResponse, onSuccess]);

  const runUnfavorite = useCallback(async () => {
    try {
      const response = await unfavoriteProspect(personaId);
      handleApiResponse({
        response,
        errorMsg: UNFAVORITE_ERROR_MSG,
        onSuccess: () => onSuccess(false),
      });
    } catch (error) {
      console.error(UNFAVORITE_ERROR_MSG, error);
    }
  }, [personaId, unfavoriteProspect, handleApiResponse, onSuccess]);

  const onClick = useCallback(async () => {
    // Prevent multiple clicks.
    if (isFavoriteLoading || isUnfavoriteLoading) return;
    // Run the appropriate mutation based on the current favorite status.
    if (isFavorited) {
      runUnfavorite();
    } else {
      runFavorite();
    }
  }, [isFavorited, isFavoriteLoading, isUnfavoriteLoading, runFavorite, runUnfavorite]);

  return (
    <Icons
      icon={Icon.STAR}
      color={StatusColor.WARNING}
      fillColor={getFillColor()}
      size={size}
      tooltip={isFavorited ? 'Remove from favorites' : 'Add to favorites'}
      onClick={onClick}
      onHover={(hover) => setIsStarHovered(hover)}
    />
  );
};

export default FavoriteButton;
