import { useCallback, useEffect, useState } from 'react';
import { US_COUNTRY_CODE } from '../../../../../../constants';
import { useAppSelector, useHandleApiResponse, useToast } from '../../../../../../hooks';
import { useCreateCandidateMutation, useGetPracticeProspectsForSelectQuery } from '../../../../../../services';
import { ComponentSize, ProspectCategory, TextColor } from '../../../../../../types';
import { isValidEmail, isValidPhoneNumber } from '../../../../../../utils';
import { AlertType, Checkbox, Divider, Spinner, TextButton, Typography, TypographySize } from '../../../../../shared';
import CandidateInputs from './CandidateInputs';
import InvitationMessage from './InvitationMessage';
import ProspectSelector from './ProspectsSelector';

const ERROR_MSG = 'Failed to add candidate';

interface CandidateFormProps {
  onBack: () => void;
}

const CandidateForm = ({ onBack }: CandidateFormProps) => {
  // Input states
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [emailContent, setEmailContent] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState(US_COUNTRY_CODE);

  const [selectedProspect, setSelectedProspect] = useState<string | undefined>(undefined);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [isEmailCopyChecked, setIsEmailCopyChecked] = useState(false);

  // Hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  // Redux
  const { user } = useAppSelector((state) => state.auth);

  if (!user) return null;

  // API Hooks
  const [createCandidate, { isLoading: isCreateCandidateLoading }] = useCreateCandidateMutation();

  const { data: prospects, isLoading: isProspectsLoading } = useGetPracticeProspectsForSelectQuery({
    categories: [ProspectCategory.INTERVIEW],
    includeJobTitle: true,
    includeAssociatedPhoneNumber: true,
  });

  // Set the first prospect as default when prospects are loaded
  useEffect(() => {
    if (prospects && prospects.length > 0 && !selectedProspect) {
      setSelectedProspect(prospects[0].personaId);
    }
  }, [prospects, selectedProspect]);

  // Get selected prospect data
  const selectedProspectData = prospects?.find((p) => p.personaId === selectedProspect);

  // Reset errors
  const resetError = () => {
    setErrors({});
    setErrorMsg('');
  };

  // Success handler
  const onSuccess = useCallback(() => {
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
    setEmail('');
    setEmailContent('');
    setCountryCode(US_COUNTRY_CODE);
    setSelectedProspect(undefined);
    resetError();
    onBack();
  }, [
    setFirstName,
    setLastName,
    setPhoneNumber,
    setEmail,
    setEmailContent,
    setSelectedProspect,
    setCountryCode,
    resetError,
    onBack,
  ]);

  // Validate inputs
  const validateInputs = useCallback(() => {
    if (errorMsg.length) return false;

    const newErrors = {
      firstName: !firstName.trim().length,
      lastName: !lastName.trim().length,
      phoneNumber: !phoneNumber.trim().length,
      email: !email.trim().length,
      emailContent: !emailContent.trim().length,
      prospect: !selectedProspect || !selectedProspectData,
    };

    // Validate required fields
    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      setErrorMsg('Required fields cannot be empty.');
      return false;
    }

    const fullPhoneNumber = countryCode + phoneNumber;

    // Validate phone number
    if (fullPhoneNumber && !isValidPhoneNumber(fullPhoneNumber)) {
      setErrors({ ...errors, phoneNumber: true });
      setErrorMsg('Please enter a valid phone number.');
      return false;
    }

    // Validate email
    if (email && !isValidEmail(email)) {
      setErrors({ ...errors, email: true });
      setErrorMsg('Please enter a valid email address.');
      return false;
    }

    return true;
  }, [
    errorMsg,
    firstName,
    lastName,
    phoneNumber,
    email,
    emailContent,
    selectedProspect,
    selectedProspectData,
    countryCode,
    errors,
    setErrorMsg,
    setErrors,
    isValidPhoneNumber,
    isValidEmail,
    countryCode,
    phoneNumber,
    email,
    emailContent,
    selectedProspectData,
  ]);

  // Create candidate handler
  const handleCreateCandidate = useCallback(async () => {
    // If any input is invalid, return
    if (!validateInputs() || !selectedProspectData || !user.email) return;

    const fullPhoneNumber = countryCode + phoneNumber;

    const payload = {
      candidateData: {
        firstName,
        lastName,
        email,
        phoneNumber: fullPhoneNumber,
      },
      emailData: {
        content: emailContent,
        prospectId: selectedProspectData.personaId,
        bccInviter: isEmailCopyChecked,
      },
    };

    try {
      const response = await createCandidate(payload);
      handleApiResponse({
        response,
        errorMsg: ERROR_MSG,
        onError: (e) => setErrorMsg(e.message),
        onSuccess,
      });
    } catch (e) {
      console.error(`${ERROR_MSG}: `, e);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [
    validateInputs,
    firstName,
    lastName,
    email,
    emailContent,
    phoneNumber,
    countryCode,
    selectedProspectData,
    createCandidate,
    isEmailCopyChecked,
    user,
    handleApiResponse,
    onSuccess,
    setErrorMsg,
    showToast,
  ]);

  // To avoid error state from flashing while loading prospects
  if (isProspectsLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.SMALL} />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col">
      <div className="display-scrollbar-lg flex-grow overflow-y-auto">
        <div className="flex flex-col gap-4">
          <CandidateInputs
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            errors={errors}
            resetError={resetError}
            onBack={onBack}
            handleCreateCandidate={handleCreateCandidate}
          />
          <ProspectSelector
            isProspectsLoading={isProspectsLoading}
            error={!!errors.prospect}
            prospects={prospects || []}
            setSelectedProspect={setSelectedProspect}
            resetError={resetError}
            selectedProspect={selectedProspect}
            setErrorMsg={setErrorMsg}
          />
          <div className="flex h-full flex-col">
            <InvitationMessage
              emailContent={emailContent}
              setEmailContent={setEmailContent}
              error={!!errors.emailContent}
              resetError={resetError}
              firstName={firstName}
              lastName={lastName}
              email={email}
              phoneNumber={phoneNumber}
              countryCode={countryCode}
              prospects={prospects || []}
              selectedProspectData={selectedProspectData}
            />
          </div>
        </div>
        <div className="align-center min-h-[1.5em] px-1">
          <Typography size={TypographySize.CAPTION} color={TextColor.DESTRUCTIVE}>
            {errorMsg}
          </Typography>
        </div>
      </div>

      <div className="sticky bottom-0 flex flex-col gap-4">
        <Divider />

        <div className="flex justify-end gap-4">
          <div className="flex items-center gap-2">
            <Checkbox checked={isEmailCopyChecked} setChecked={setIsEmailCopyChecked} size={ComponentSize.X_SMALL} />
            <Typography>Email me a copy</Typography>
          </div>

          <TextButton
            text="Send invitation"
            onClick={handleCreateCandidate}
            disabled={!prospects?.length}
            loading={isCreateCandidateLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidateForm;
