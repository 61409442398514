import {
  ApiSuccess,
  ApiTagTypes,
  AppUser,
  ImpersonatedUserData,
  ImpersonatedUserResponse,
  ImpersonateUserParams,
  OrgUsersResponse,
  PhoneNumbersResponse,
  Team,
  TeamsData,
  UsersForSelectParams,
  UsersForSelectResponse,
  UsersForSelectResponseData,
  UsersParams,
} from '../../types';
import { getParsedAuth0User } from '../../utils';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getImpersonatedUser: builder.mutation<ApiSuccess<ImpersonatedUserData>, ImpersonateUserParams>({
      query: (params) => ({
        url: '/users/impersonate',
        params: {
          ...params,
          // Prevents user impersonation from being applied to this request.
          // This endpoint is only accessible to super admins and we don't allow super-admins to impersonate super-admins,
          // so we don't need to worry about impersonation here.
          disableImpersonation: true,
        },
      }),
      transformResponse: (response: ImpersonatedUserResponse) => {
        return {
          ...response,
          data: {
            ...response.data.decodedToken,
            ...getParsedAuth0User({ ...response.data.decodedToken, roles: [response.data.decodedToken.role] }),
          },
        };
      },
    }),
    getUsers: builder.query<AppUser[], UsersParams>({
      query: (params) => ({
        url: '/users',
        params,
      }),
      transformResponse: (response: OrgUsersResponse) => response.data.users,
      providesTags: [ApiTagTypes.USERS],
    }),
    getUsersForSelect: builder.query<UsersForSelectResponseData, UsersForSelectParams>({
      query: (params) => ({
        url: '/users/select',
        params: { includeDisabledUsers: false, ...params },
      }),
      transformResponse: (response: UsersForSelectResponse) => {
        const users = response.data.users;
        const phoneNumbers = response.data.phoneNumbers;
        return { users, phoneNumbers };
      },
      providesTags: [ApiTagTypes.USERS_FOR_SELECT],
    }),
    getPhoneNumbers: builder.query<string[], void>({
      query: () => '/users/me/phone-numbers',
      transformResponse: (response: PhoneNumbersResponse) => response.data.phoneNumbers,
      providesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    getUserTeams: builder.query<Team[], string>({
      query: (id) => `/users/${id}/teams`,
      transformResponse: (response: ApiSuccess<TeamsData>) => response.data.teams,
      providesTags: (_, __, id) => [{ type: ApiTagTypes.TEAMS, id }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetPhoneNumbersQuery,
  useGetImpersonatedUserMutation,
  useGetUsersForSelectQuery,
  useGetUserTeamsQuery,
} = enhancedApiSlice;
