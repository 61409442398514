import { useCallback, useState } from 'react';
import { ButtonColor, getSelectOptions, Icon, IconButton, MultiSelect } from '../../../../components';
import { CALL_METRIC_OPTIONS } from '../../../../constants';
import { useGetScorecardTemplatesForSelectQuery } from '../../../../services';
import { ComponentSize } from '../../../../types';

const DROPDOWN_SIZE = 32;

interface HeatMapFieldsDropdownProps {
  x: number;
  y: number;
  tickIndex: number;
  selectedFields: string[];
  isFieldsDropdownOpen: boolean;
  handleUpdateDefaultMetrics: () => void;
  setSelectedFields: (fields: string[]) => void;
  setIsFieldsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeatMapFieldsDropdown = ({
  x,
  y,
  tickIndex,
  selectedFields,
  setSelectedFields,
  isFieldsDropdownOpen,
  setIsFieldsDropdownOpen,
  handleUpdateDefaultMetrics,
}: HeatMapFieldsDropdownProps) => {
  const [searchValue, setSearchValue] = useState('');

  const { data: scorecardTemplates = [] } = useGetScorecardTemplatesForSelectQuery(
    {
      status: undefined,
    },
    {
      skip: tickIndex !== 0,
    }
  );

  const scorecardTemplateOptions = getSelectOptions(scorecardTemplates);

  // Combine the call metrics and the AI scorecard templates  to get the options for the dropdown
  const metricsAndAIScorecardTemplatesOptions = [...CALL_METRIC_OPTIONS, ...scorecardTemplateOptions];

  const handleDropdownOpenChange = useCallback(() => {
    setIsFieldsDropdownOpen((prev) => {
      if (prev) handleUpdateDefaultMetrics();
      return !prev;
    });
  }, [handleUpdateDefaultMetrics]);

  // Only show the dropdown for the first tick
  if (tickIndex !== 0) return null;

  return (
    <foreignObject x={x + DROPDOWN_SIZE / 2} y={y - DROPDOWN_SIZE} width={DROPDOWN_SIZE} height={DROPDOWN_SIZE}>
      <MultiSelect
        controlledOpenProps={{
          isOpen: isFieldsDropdownOpen,
          setIsOpen: handleDropdownOpenChange,
        }}
        customButton={<IconButton icon={Icon.PLUS} size={ComponentSize.X_SMALL} color={ButtonColor.SECONDARY} />}
        options={metricsAndAIScorecardTemplatesOptions}
        selected={selectedFields}
        // Selected fields shouldn't be empty
        onChange={(selected) => selected?.length && setSelectedFields(selected)}
        searchProps={{
          searchValue,
          setSearchValue,
        }}
      />
    </foreignObject>
  );
};

export default HeatMapFieldsDropdown;
