import { Dispatch, SetStateAction, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonColor, ButtonGroup, ButtonVariant, Icon, TextButton } from '../../../components';
import { AppRoutes } from '../../../constants';
import { useAppDispatch, useAppSelector, useCheckRequiredProspectFields, useHandleApiResponse } from '../../../hooks';
import {
  randomizeAccount,
  randomizeCallContext,
  randomizeDemographics,
  randomizeHiddenGems,
  randomizeLead,
  randomizeObjections,
  randomizePersonality,
  randomizePriorities,
  randomizeQuestions,
  randomizeVoice,
  setMode,
  setPersonaPrompt,
  setShowConfetti,
} from '../../../redux/reducers';
import { useCreatePracticeProspectMutation } from '../../../services';
import {
  LLMProviderID,
  Permissions,
  ProspectLastTouchpoint,
  ProspectPageMode,
  ProspectPageTabs,
  UpdatedPracticeProspectData,
} from '../../../types';
import { conditionalObject } from '../../../utils';

const CREATE_PROSPECT_ERROR_MSG = 'Failed to create prospect';

interface CreateEndActionsProps {
  activeTab: ProspectPageTabs;
  isSuperAdmin: boolean;
  visitedTabs: Set<ProspectPageTabs>;
  setActiveTab: Dispatch<SetStateAction<ProspectPageTabs>>;
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
}

const CreateEndActions = ({
  activeTab,
  isSuperAdmin,
  visitedTabs,
  setActiveTab,
  setVisitedTabs,
}: CreateEndActionsProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { fields, notes, orgId, type, category, tags } = useAppSelector((state) => state.prospect);
  const user = useAppSelector((state) => state.auth.user);
  const canManageProspect = (user?.permissions || []).includes(Permissions.MANAGE_PROSPECT);

  const [createPracticeProspect, { isLoading: isCreatingProspect }] = useCreatePracticeProspectMutation();

  const areRequiredFieldsFilled = useCheckRequiredProspectFields();
  const handleApiResponse = useHandleApiResponse();

  const activeTabIndex = Object.values(ProspectPageTabs).indexOf(activeTab);
  const nextTab = Object.values(ProspectPageTabs).find((_, index) => index === activeTabIndex + 1);
  // The next tab is disabled if it has not been visited before and all required fields in preceding tabs have not been filled out.
  const isNextTabDisabled = !!nextTab && !visitedTabs.has(nextTab) && !areRequiredFieldsFilled(activeTab);

  const handleNextSection = () => {
    if (!nextTab) return;
    setActiveTab(nextTab);
    setVisitedTabs((prev) => new Set(prev).add(nextTab));
  };

  const onCreateSuccess = useCallback(
    (newData: UpdatedPracticeProspectData) => {
      dispatch(setPersonaPrompt({ value: newData.prospect.personaPrompt }));
      if (isSuperAdmin) {
        // For super-admins, open the prompt tab.
        // Only super-admins have access to the prompt tab.
        setActiveTab(ProspectPageTabs.PROMPT);
        dispatch(setMode(ProspectPageMode.EDIT));
        navigate(`${AppRoutes.PROSPECT}/${newData.prospect.personaId}`, { replace: true });
      } else {
        // For non-super-admins, navigate to the simulations page.
        navigate(AppRoutes.SIMULATIONS);
        dispatch(setShowConfetti(true));
      }
    },
    [isSuperAdmin, dispatch, navigate]
  );

  const handleCreateProspect = useCallback(async () => {
    if (
      !type ||
      !category ||
      !fields.firstName.value ||
      !fields.lastName.value ||
      !fields.personaVoiceId.value ||
      !fields.jobTitle.value ||
      !fields.accountName.value ||
      !fields.industry.value ||
      !fields.successResult.value ||
      !fields.firstMessage.value
    )
      return;

    try {
      const response = await createPracticeProspect({
        orgId,
        type,
        category,
        notes,
        tags: tags.map((tag) => tag.id),

        // Personal
        firstName: fields.firstName.value,
        lastName: fields.lastName.value,
        age: fields.age.value,
        pronouns: fields.pronouns.value,
        talkativenessLevel: fields.talkativenessLevel.value,
        figurativeThinkingLevel: fields.figurativeThinkingLevel.value,
        empathyLevel: fields.empathyLevel.value,
        improvisationLevel: fields.improvisationLevel.value,
        personaVoiceId: fields.personaVoiceId.value,
        firstMessage: fields.firstMessage.value,
        language: fields.language,

        // Lead
        jobTitle: fields.jobTitle.value,
        tenure: fields.tenure.value,
        roleType: fields.roleType.value,
        department: fields.department.value,
        numOfDirectReports: fields.numOfDirectReports.value,

        // Account
        accountName: fields.accountName.value,
        industry: fields.industry.value,
        hqLocation: fields.hqLocation.value,
        remoteType: fields.remoteType.value,
        isMultiSite: fields.isMultiSite.value,
        employeesFrom: fields.employeesFrom.value,
        ...conditionalObject(fields.employeesFrom.value !== undefined, {
          // Only include employeesTo if employeesFrom is filled out.
          employeesTo: fields.employeesTo,
        }),
        annualRevenueFrom: fields.annualRevenueFrom.value,
        ...conditionalObject(fields.annualRevenueFrom.value !== undefined, {
          // Only include annualRevenueTo if annualRevenueFrom is filled out.
          annualRevenueTo: fields.annualRevenueTo,
        }),
        fundingRaisedType: fields.fundingRaisedType.value,

        // Scenario
        priorities: fields.priorities.map((priority) => priority.value),
        isDynamicPriorities: fields.isDynamicPriorities,
        ...conditionalObject(fields.isDynamicPriorities, {
          // Only include numOfDynamicPriorities if isDynamicPriorities is true.
          numOfDynamicPriorities: fields.numOfDynamicPriorities,
        }),
        objections: fields.objections.map((objection) => objection.value),
        isDynamicObjections: fields.isDynamicObjections,
        ...conditionalObject(fields.isDynamicObjections, {
          // Only include numOfDynamicObjections if isDynamicObjections is true.
          numOfDynamicObjections: fields.numOfDynamicObjections,
        }),
        questions: fields.questions.map((question) => question.value),
        isDynamicQuestions: fields.isDynamicQuestions,
        ...conditionalObject(fields.isDynamicQuestions, {
          // Only include numOfDynamicQuestions if isDynamicQuestions is true.
          numOfDynamicQuestions: fields.numOfDynamicQuestions,
        }),
        ...conditionalObject(fields.lastTouchpoint.value !== ProspectLastTouchpoint.NONE, {
          // NONE is not a valid value for lastTouchpoint, so we don't include it in the request.
          lastTouchpoint: fields.lastTouchpoint.value,
        }),
        successResult: fields.successResult.value,
        hiddenContexts: fields.hiddenContexts.map((context) => context.value),
        successDifficultyLevel: fields.successDifficultyLevel.value,

        // Configuration
        isHidden: fields.isHidden,
        scorecardTemplateId: fields.scorecardTemplateId,
        cooldownPeriod: fields.cooldownPeriod,
        timeLimit: fields.timeLimit,
        managerNotes: fields.managerNotes,

        ...conditionalObject(isSuperAdmin, {
          isRestricted: fields.isRestricted,
          breathingRoom: fields.breathingRoom,
          numOfInitialShortResponses: fields.numOfInitialShortResponses,
          objectionRate: fields.objectionRate,
          questionRate: fields.questionRate,
          numOfRequiredUncoveredPriorities: fields.numOfRequiredUncoveredPriorities,
          isHangupFeatureEnabled: fields.isHangupFeatureEnabled,
          ...conditionalObject(fields.isHangupFeatureEnabled, {
            // Only include hangupPrompt if the hangup feature is enabled.
            hangupPrompt: fields.hangupPrompt,
          }),
          buyNewPhoneNumber: fields.buyNewPhoneNumber,
          ttsModelId: fields.ttsModelId,
          sttModel: fields.sttModel,
          sttModelId: fields.sttModelId,
          llmModel: fields.llmModel,
          llmModelId: fields.llmModelId,
          llmTemperature: fields.llmTemperature,
          llmMaxTokens: fields.llmMaxTokens,
          ...conditionalObject(fields.llmModel === LLMProviderID.GPT, {
            llmFrequencyPenalty: fields.llmFrequencyPenalty,
            llmPresencePenalty: fields.llmPresencePenalty,
          }),
          promptTemplateId: fields.promptTemplateId,
        }),
      });
      handleApiResponse({
        response,
        errorMsg: CREATE_PROSPECT_ERROR_MSG,
        onSuccess: onCreateSuccess,
      });
    } catch (error) {
      console.error(CREATE_PROSPECT_ERROR_MSG, error);
    }
  }, [
    fields,
    isSuperAdmin,
    notes,
    orgId,
    type,
    category,
    tags,
    createPracticeProspect,
    handleApiResponse,
    onCreateSuccess,
  ]);

  // Fills the prospect form section with random values.
  const handleFillForMe = () => {
    switch (activeTab) {
      case ProspectPageTabs.PERSONAL:
        dispatch(randomizeDemographics());
        dispatch(randomizePersonality());
        dispatch(randomizeVoice());
        break;
      case ProspectPageTabs.LEAD:
        dispatch(randomizeLead());
        break;
      case ProspectPageTabs.ACCOUNT:
        dispatch(randomizeAccount());
        break;
      case ProspectPageTabs.SCENARIO:
        dispatch(randomizeCallContext());
        dispatch(randomizePriorities());
        dispatch(randomizeObjections());
        dispatch(randomizeQuestions());
        dispatch(randomizeHiddenGems());
        break;
      default:
        break;
    }
  };

  // If user can manage prospects, the create button is shown on the configuration tab.
  // Otherwise, the create button is shown on the scenario tab.
  const showCreateButton = canManageProspect
    ? activeTab === ProspectPageTabs.CONFIGURATION
    : activeTab === ProspectPageTabs.SCENARIO;

  return (
    <ButtonGroup loading={isCreatingProspect}>
      <TextButton
        color={ButtonColor.PRIMARY}
        text={showCreateButton ? 'Create' : 'Next section'}
        onClick={showCreateButton ? handleCreateProspect : handleNextSection}
        disabled={isNextTabDisabled}
        tooltip={isNextTabDisabled ? 'Please make sure all required fields are filled.' : undefined}
      />
      <TextButton
        disabled={activeTab === ProspectPageTabs.CONFIGURATION} // The Configuration tab has no randomization options.
        startIcon={Icon.WAND}
        color={ButtonColor.AI}
        text="Fill for me"
        onClick={handleFillForMe}
        variant={ButtonVariant.OUTLINE}
      />
    </ButtonGroup>
  );
};

export default CreateEndActions;
