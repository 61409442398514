// Query parameter name for specifying the start time of a call in the URL.
export const CALL_START_TIME_QUERY_PARAM = 'startTime';

// Default number of items per page for pagination.
export const PAGINATION_PAGE_SIZE = 25;
export const SINGLE_AUDIO_WAVE_HEIGHT = 30;

// App url
export const APP_URL = 'https://fullyramped.com';

// App privacy policy url
export const PRIVACY_POLICY_URL = `${APP_URL}/resources/privacy-policy`;

export const SUPPORT_EMAIL = 'support@fullyramped.com';

export const SIDEBAR_WIDTH = 256;

// AWS RUM VERSION
export const RUM_APPLICATION_VERSION = '1.0.0';

// HEIGHTS
export const NAVBAR_HEIGHT = 64;
export const BANNER_HEIGHT = 48;

// SUPER ADMIN EMAIL DOMAIN
export const SUPER_ADMIN_EMAIL_DOMAIN = '@fullyramped.com';

// Breakpoints for responsive design based on screen width in pixels.
export const SCREEN_SIZE_BREAKPOINTS = {
  MOBILE: 628, // Maximum width for mobile devices.
  TABLET: 1024, // Maximum width for tablet devices.
};
