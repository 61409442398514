import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHandleApiResponse, useToast } from '../../../../../../hooks';
import {
  useApplyTeamsMutation,
  useCreateTeamMutation,
  useEditTeamMutation,
  useGetTeamsQuery,
  useGetUserTeamsQuery,
} from '../../../../../../services';
import { ComponentSize, TableUser, TagColor } from '../../../../../../types';
import {
  AlertType,
  ButtonColor,
  ButtonGroup,
  Dialog,
  DialogType,
  Icon,
  MultiSelect,
  Spinner,
  TextButton,
} from '../../../../../shared';

const CREATE_TEAM_ERROR_MSG = 'Failed to create team';
const APPLY_TEAMS_ERROR_MSG = 'Failed to apply teams';
const EDIT_TEAM_ERROR_MSG = 'Failed to edit team';

interface ManageTeamsModalProps {
  user: TableUser;
  closeModal: () => void;
}

const ManageTeamsModal = ({ user, closeModal }: ManageTeamsModalProps) => {
  const userId = user.id;

  // Queries
  const { data: orgTeamsData = [], isLoading: isLoadingOrgTeams } = useGetTeamsQuery();
  const { data: userTeamsData = [], isLoading: isLoadingUserTeams } = useGetUserTeamsQuery(userId);

  // Mutations
  const [createTeam] = useCreateTeamMutation();
  const [applyTeams, { isLoading: isApplyingTeams }] = useApplyTeamsMutation();
  const [editTeam, { isLoading: isEditingTeam }] = useEditTeamMutation();

  // Hooks
  const handleApiResponse = useHandleApiResponse();
  const { showToast } = useToast();

  // State
  const [searchValue, setSearchValue] = useState('');
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);

  // Set selected teams to user's current teams when modal opens
  useEffect(() => {
    if (!isLoadingUserTeams) {
      setSelectedTeams(userTeamsData.map((team) => team.id));
    }
  }, [isLoadingUserTeams, userTeamsData]);

  const handleEditTeam = useCallback(
    async (newName: string, teamId: string) => {
      // Find the current team name
      const currentTeam = orgTeamsData.find((team) => team.id === teamId);
      const currentName = currentTeam?.name;

      // If the new name is empty, the same as the current name, or if editing is in progress, don't edit the team
      if (!newName || newName === currentName || isEditingTeam) return;

      try {
        const response = await editTeam({
          id: teamId,
          name: newName,
        });
        handleApiResponse({
          response,
          errorMsg: EDIT_TEAM_ERROR_MSG,
        });
      } catch (error) {
        console.error(error);
        showToast({
          message: EDIT_TEAM_ERROR_MSG,
          type: AlertType.ERROR,
        });
      }
    },
    [editTeam, handleApiResponse, showToast, orgTeamsData]
  );

  const teamOptions = useMemo(() => {
    return orgTeamsData?.map((team) => ({
      value: team.id,
      label: team.name,
      color: team.color,
      onEdit: (newTeamName: string) => handleEditTeam(newTeamName, team.id),
    }));
  }, [orgTeamsData, handleEditTeam]);

  const handleCreateTeam = useCallback(async () => {
    if (!searchValue) return;

    try {
      const randomColor = Object.values(TagColor)[Math.floor(Math.random() * Object.values(TagColor).length)];
      const response = await createTeam({ name: searchValue, color: randomColor });

      handleApiResponse({
        response,
        errorMsg: CREATE_TEAM_ERROR_MSG,
        onSuccess: (data) => {
          setSearchValue('');
          // If team was created successfully, add it to the selected teams
          const newTeamId = data?.team?.id;
          if (newTeamId) {
            setSelectedTeams([...(selectedTeams || []), newTeamId]);
          }
        },
      });
    } catch (error) {
      console.error(error);
      showToast({
        message: CREATE_TEAM_ERROR_MSG,
        type: AlertType.ERROR,
      });
    }
  }, [searchValue, createTeam, handleApiResponse, showToast]);

  const handleApplyTeams = useCallback(async () => {
    try {
      const response = await applyTeams({ id: userId, teamIds: selectedTeams });
      handleApiResponse({
        response,
        errorMsg: APPLY_TEAMS_ERROR_MSG,
        onSuccess: closeModal,
      });
    } catch (error) {
      console.error(error);
      showToast({
        message: APPLY_TEAMS_ERROR_MSG,
        type: AlertType.ERROR,
      });
    }
  }, [userId, applyTeams, handleApiResponse, showToast, selectedTeams]);

  const handleCloseModal = useCallback(() => {
    closeModal();
    setSearchValue('');
  }, [closeModal]);

  const isLoading = isLoadingOrgTeams || isLoadingUserTeams;

  return (
    <Dialog isOpen onClose={handleCloseModal} title="Add or remove teams" type={DialogType.CONFIRM}>
      <div className="flex h-full flex-col">
        {isLoading && <Spinner className="h-full self-center" size={ComponentSize.MEDIUM} />}
        {!isLoading && (
          <div className="flex flex-col gap-4">
            <MultiSelect
              inline
              options={teamOptions}
              selected={selectedTeams}
              onChange={(selected) => setSelectedTeams(selected || [])}
              searchProps={{
                placeholder: teamOptions.length ? 'Type to filter or create' : 'Type to create',
                searchValue,
                setSearchValue,
                createOption: {
                  icon: Icon.PLUS,
                  label: `Create "${searchValue}"`,
                  value: 'create',
                  onClick: handleCreateTeam,
                },
              }}
            />
            <ButtonGroup loading={isApplyingTeams} size={ComponentSize.MEDIUM}>
              <TextButton text="Save changes" onClick={handleApplyTeams} color={ButtonColor.PRIMARY} />
              <TextButton text="Cancel" onClick={handleCloseModal} />
            </ButtonGroup>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ManageTeamsModal;
