import {
  ApiSuccess,
  ApiTagTypes,
  CreateScorecardPayload,
  UpdateScorecardPayload,
  UpdateScorecardResponse,
} from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSliceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createScorecard: builder.mutation<ApiSuccess, CreateScorecardPayload>({
      query: (scorecardData) => ({
        url: '/scorecards',
        method: 'POST',
        body: scorecardData,
      }),
      invalidatesTags: [ApiTagTypes.SCORECARDS, ApiTagTypes.SCORECARD_TEMPLATES],
    }),
    updateScorecard: builder.mutation<ApiSuccess<UpdateScorecardResponse>, UpdateScorecardPayload>({
      query: ({ id, ...scorecardData }) => ({
        url: `/scorecards/${id}`,
        method: 'POST',
        body: scorecardData,
      }),
      invalidatesTags: (_, __, { callSid }) => [
        { type: ApiTagTypes.SCORECARDS, id: callSid },
        ApiTagTypes.SCORECARD_TEMPLATES,
      ],
    }),
    deleteScorecard: builder.mutation<ApiSuccess<UpdateScorecardResponse>, { id: string; callSid: string }>({
      query: ({ id }) => ({
        url: `/scorecards/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { callSid }) => [
        { type: ApiTagTypes.SCORECARDS, id: callSid },
        ApiTagTypes.SCORECARD_TEMPLATES,
      ],
    }),
  }),
});

export const { useCreateScorecardMutation, useUpdateScorecardMutation, useDeleteScorecardMutation } =
  enhancedApiSliceSlice;
