import { useMemo } from 'react';
import { PUBLIC_PROSPECT_CATEGORY_OPTIONS_WITH_COLORS } from '../../constants';
import {
  FilterOptionsAndSelections,
  useAppSelector,
  useFeatureFlag,
  useGetFiltersFromParams,
  useUpdateFilterParams,
} from '../../hooks';
import { useGetTeamsQuery, useGetUsersForSelectQuery, useGetUserTeamsQuery } from '../../services';
import {
  ComponentSize,
  LD_FeatureFlags,
  Permissions,
  ProspectCategory,
  ReviewFilterKeys,
  ReviewFilters as ReviewFiltersType,
  Roles,
} from '../../types';
import { getTeamsOptions, getUserOptions } from '../../utils';
import { SidebarDateFilter, SidebarSelectFilter, Toggle } from '../shared';

interface ReviewFiltersProps {
  prospectOptionsAndSelections: FilterOptionsAndSelections;
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const ReviewFilters = ({ prospectOptionsAndSelections, tagOptionsAndSelections }: ReviewFiltersProps) => {
  const { user } = useAppSelector((state) => state.auth);
  const isSalesRep = user?.role === Roles.SALES_REP;
  const permissions = user?.permissions || [];
  const canViewHiring = permissions.includes(Permissions.VIEW_CANDIDATE);

  const teamsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_TEAMS);

  // Retrieve filters from URL parameters.
  const filters = useGetFiltersFromParams() as ReviewFiltersType;

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  // Queries
  const { data: userData, isLoading: isLoadingUsers } = useGetUsersForSelectQuery({
    includeCurrentUser: true,
    includeDisabledUsers: true,
  });
  const { data: orgTeamsData = [], isLoading: isLoadingOrgTeams } = useGetTeamsQuery(undefined, {
    skip: !teamsFF,
  });
  const { data: userTeamsData = [] } = useGetUserTeamsQuery(user?.id ?? '', { skip: !user?.id || !teamsFF });

  const userOptions = useMemo(() => getUserOptions(userData), [userData]);

  const teamOptions = useMemo(() => getTeamsOptions(orgTeamsData, userTeamsData), [orgTeamsData, userTeamsData]);

  const prospectTypeOptions = useMemo(() => {
    // Filter out candidate options if user doesn't have hiring permissions.
    return PUBLIC_PROSPECT_CATEGORY_OPTIONS_WITH_COLORS.filter(
      (option) => canViewHiring || option.value !== ProspectCategory.INTERVIEW
    );
  }, [canViewHiring]);

  const selectedUsers = [...(filters.incomingPhoneNumber || []), ...(filters.userId || [])];

  // Disable the team and user filters if the other filter has values.
  const isTeamFilterDisabled = !!filters.incomingPhoneNumber || !!filters.userId;
  const isUserFilterDisabled = !!filters.teamIds;

  return (
    <>
      <Toggle
        label="Flagged calls only"
        checked={filters.isFlagged}
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.FLAGGED, newValue)}
        size={ComponentSize.MEDIUM}
      />
      <SidebarDateFilter
        title="Call date"
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.CALL_DATE, newValue)}
        selected={filters.startTime}
      />
      {/* Only show the Team filter if there are team options */}
      {teamsFF && !!teamOptions.length && (
        <SidebarSelectFilter
          options={teamOptions}
          placeholder="Select teams"
          selected={filters.teamIds}
          title="Team"
          onChange={(newValue) => updateFilterParams(ReviewFilterKeys.TEAM, newValue)}
          loading={isLoadingOrgTeams}
          disabled={isTeamFilterDisabled}
          tooltip={isTeamFilterDisabled ? 'Remove user filter to enable' : undefined}
        />
      )}
      {/* Only show the User filter if the user is not a Sales Representative */}
      {!isSalesRep && (
        <SidebarSelectFilter
          options={userOptions}
          placeholder="Select user"
          selected={selectedUsers}
          title="User"
          onChange={(newValue) => {
            const phoneNumbers = newValue?.filter((value) => value.startsWith('+'));
            const userIds = newValue?.filter((value) => !value.startsWith('+'));
            updateFilterParams(ReviewFilterKeys.USER, userIds);
            updateFilterParams(ReviewFilterKeys.INCOMING_PHONE_NUMBER, phoneNumbers);
          }}
          loading={isLoadingUsers}
          disabled={isUserFilterDisabled}
          tooltip={isUserFilterDisabled ? 'Remove team filter to enable' : undefined}
        />
      )}
      <SidebarSelectFilter
        placeholder="Select prospect"
        title="Prospect"
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
      <SidebarSelectFilter
        title="Category"
        placeholder="Select category"
        selected={filters.categories}
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.CATEGORY, newValue)}
        options={prospectTypeOptions}
      />
    </>
  );
};

export default ReviewFilters;
