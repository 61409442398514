import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Typography, TypographySize } from '../../components';
import { APP_URL, SUPPORT_EMAIL } from '../../constants';
import { AccessDeniedPageStep, Alignment, RequestAccessRoles } from '../../types';
import AccessDeniedLayout from './AccessDeniedLayout';
import ProspectExampleStep from './ProspectExampleStep';
import SelectRoleStep from './SelectRoleStep';
import WorkEmailStep from './WorkEmailStep';

const USER_DISABLED_CODE = 'USER_DISABLED';
const ORG_ARCHIVED_CODE = 'ORG_ARCHIVED';

const AccessDeniedPage = () => {
  const [currentStep, setCurrentStep] = useState(AccessDeniedPageStep.SELECT_ROLE);
  const [selectedRole, setSelectedRole] = useState<RequestAccessRoles | undefined>(undefined);
  const [icp, setIcp] = useState('');

  const location = useLocation();
  const { logout } = useAuth0();

  const params = new URLSearchParams(location.search);

  const errorMessage = params.get('error_description');
  const errorMessageDetails = errorMessage?.split(',');

  const isUserDisabled = errorMessageDetails?.[0] === USER_DISABLED_CODE;
  const isOrgArchived = errorMessageDetails?.[0] === ORG_ARCHIVED_CODE;

  const orgName = errorMessageDetails?.[1];

  const onKeepReadingClick = useCallback(() => {
    logout({ logoutParams: { returnTo: APP_URL } });
  }, [logout]);

  if (isUserDisabled || isOrgArchived) {
    return (
      <AccessDeniedLayout
        title="Your account has been disabled"
        description={
          <Typography size={TypographySize.H5} alignment={Alignment.CENTER}>
            {orgName && `Please contact your administrator at ${orgName}.`}
            <br />
            If you think this is a mistake, contact
            <br />
            <a href={`mailto:${SUPPORT_EMAIL}`} className="text-blue-500 underline">
              {SUPPORT_EMAIL}
            </a>
            .
          </Typography>
        }
      />
    );
  }

  switch (currentStep) {
    case AccessDeniedPageStep.PROSPECT_EXAMPLE:
      return (
        <ProspectExampleStep selectedRole={selectedRole} icp={icp} setIcp={setIcp} setCurrentStep={setCurrentStep} />
      );
    case AccessDeniedPageStep.WORK_EMAIL:
      return <WorkEmailStep selectedRole={selectedRole} icp={icp} setCurrentStep={setCurrentStep} />;
    case AccessDeniedPageStep.COMPLETE:
      return (
        <AccessDeniedLayout actionButtonText="Keep reading" onActionButtonClick={onKeepReadingClick}>
          <Typography size={TypographySize.H5}>Our team will be in touch in 1-2 business days!</Typography>
        </AccessDeniedLayout>
      );
    default:
      return (
        <SelectRoleStep selectedRole={selectedRole} setSelectedRole={setSelectedRole} setCurrentStep={setCurrentStep} />
      );
  }
};

export default AccessDeniedPage;
