import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactNode, useCallback } from 'react';
import fullyRampedLogo from '../../assets/logo-light.png';
import {
  ButtonColor,
  ButtonVariant,
  Dialog,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../components';
import { Alignment, ComponentSize } from '../../types';

interface AccessDeniedLayoutProps {
  title?: string;
  description?: string | ReactNode;
  actionButtonText?: string;
  buttonLoading?: boolean;
  children?: ReactNode;
  onActionButtonClick?: () => void;
}

const AccessDeniedLayout: React.FC<AccessDeniedLayoutProps> = ({
  title,
  description,
  children,
  actionButtonText,
  onActionButtonClick,
  buttonLoading,
}) => {
  const { logout } = useAuth0();

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onActionButtonClick?.();
      }
    },
    [onActionButtonClick]
  );

  return (
    <div className="h-screen bg-base-200">
      <Dialog isOpen={true} className="p-12">
        <div className="flex flex-col items-center gap-8">
          {/* Header */}
          <div className="flex flex-col items-center gap-4">
            <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-14 w-14" />
            <Typography size={TypographySize.H2} weight={TypographyWeight.SEMI_BOLD} alignment={Alignment.CENTER}>
              {title}
            </Typography>
            {typeof description === 'string' && <Typography size={TypographySize.H5}>{description}</Typography>}
            {typeof description !== 'string' && description}
          </div>
          {/* Content */}
          {children}
          {/* Action button */}
          {actionButtonText && onActionButtonClick && (
            <TextButton
              text={actionButtonText}
              onClick={onActionButtonClick}
              fullWidth
              size={ComponentSize.LARGE}
              loading={buttonLoading}
              onKeyDown={handleKeyDown}
            />
          )}
          <TextButton
            text="Log into another account"
            onClick={() => logout()}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default AccessDeniedLayout;
