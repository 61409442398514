import { Typography, TypographySize, TypographyWeight, IconComponent } from '../../../../shared';

interface OrgTabSectionTitleProps {
  title: string;
  icon?: IconComponent;
}

const OrgTabSectionTitle = ({ title, icon }: OrgTabSectionTitleProps) => {
  return (
    <div className="flex justify-between">
      <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
        {title}
      </Typography>
      {icon}
    </div>
  );
};

export default OrgTabSectionTitle;
