import {
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  TextArea,
  Typography,
  TypographySize,
} from '../../../../components';
import { TextColor } from '../../../../types';

const MIN_SCENARIO_ITEM_ROWS = 1;
const MAX_SCENARIO_ITEM_ROWS = 5;

interface ScenarioListItemProps {
  index: number;
  canDelete: boolean;
  value: string;
  deleteItem: () => void;
  updateItem: (value: string) => void;
  isRequired?: boolean;
}

const ScenarioListItem = ({ index, canDelete, value, deleteItem, updateItem, isRequired }: ScenarioListItemProps) => {
  return (
    <div key={index} className="flex items-center gap-2">
      <Typography mono color={TextColor.SECONDARY} size={TypographySize.CAPTION} className="select-none">
        {index + 1}
        {isRequired ? '*' : '\u00A0'}
      </Typography>
      <TextArea
        fitContent
        maxRows={MAX_SCENARIO_ITEM_ROWS}
        rows={MIN_SCENARIO_ITEM_ROWS}
        value={value}
        onChange={(e) => updateItem(e.target.value)}
      />
      <IconButton
        color={ButtonColor.DESTRUCTIVE}
        disabled={!canDelete}
        icon={Icon.TRASH}
        variant={ButtonVariant.GHOST}
        onClick={deleteItem}
      />
    </div>
  );
};

export default ScenarioListItem;
