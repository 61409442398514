import { Icon, Icons, Typography, TypographySize, TypographyWeight } from '../../../components';
import { TextColor } from '../../../types';

interface CallInfoCardProps {
  title: string;
  value: string;
  description: string;
  icon?: Icon;
  iconColor?: TextColor;
}

const CallInfoCard = ({ title, value, description, icon, iconColor }: CallInfoCardProps) => {
  return (
    <div className="flex w-full flex-col gap-1 rounded-lg border border-base-200 p-4">
      <Typography>{title}</Typography>
      <div className="flex items-center gap-2">
        {icon && <Icons icon={icon} color={iconColor} />}
        <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
          {value}
        </Typography>
      </div>
      <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION} noWrap>
        {description}
      </Typography>
    </div>
  );
};

export default CallInfoCard;
