import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlertType, Spinner, Typography } from '../../../../components';
import { useAppSelector, useToast } from '../../../../hooks';
import { useCreateScorecardMutation, useGetOrganizationSettingsQuery } from '../../../../services';
import {
  CallProcessingStatus,
  ComponentSize,
  QuestionType,
  Roles,
  Scorecard,
  ScorecardAnswer,
  ScorecardTemplate,
  TextColor,
} from '../../../../types';
import ScorecardContent from './ScorecardContent';
import ScorecardEmptyState from './ScorecardEmptyState';
import ScorecardHeader from './ScorecardHeader';

interface CallScorecardProps {
  isScorecardLoading: boolean;
  existingScorecard?: Scorecard;
  scoringStatus?: CallProcessingStatus;
}

const CallScorecard = ({ isScorecardLoading, existingScorecard, scoringStatus }: CallScorecardProps) => {
  const { callSid } = useParams();
  const { showToast } = useToast();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isScoring, setIsScoring] = useState(false);

  const [answers, setAnswers] = useState<ScorecardAnswer[] | undefined>(undefined);
  const [selectedScorecardTemplate, setSelectedScorecardTemplate] = useState<ScorecardTemplate | undefined>(undefined);

  const { user } = useAppSelector((state) => state.auth);
  const isSalesRep = user?.role === Roles.SALES_REP;

  const {
    scorecardTemplate: existingScorecardTemplate,
    isScoredByAI,
    user: existingScorecardUser,
    id: existingScorecardId,
  } = existingScorecard || {};

  const { name: existingScorecardTemplateName, sections: existingScorecardTemplateSections } =
    existingScorecardTemplate || {};

  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const repCanScoreCalls = orgConfigs?.repCanScoreCalls ?? false;

  const [createScorecard] = useCreateScorecardMutation();

  const isScored = !!existingScorecardId;

  // Memoized sections to render
  const sections = useMemo(() => {
    // If the scorecard is scored, then we show the sections from existing scorecard
    if (isScored) {
      return existingScorecardTemplateSections;
    }
    // If the scorecard is not scored, then we show the sections from selected scorecard template
    return selectedScorecardTemplate?.sections;
  }, [existingScorecardTemplateSections, selectedScorecardTemplate, isScored]);

  // Clear answers and selected scorecard template
  const handleClearAnswers = useCallback(() => {
    setAnswers(undefined);
    setSelectedScorecardTemplate(undefined);
    setIsEditMode(false);
  }, []);

  // Clear answers and selected scorecard template when the component mounts
  useEffect(() => {
    handleClearAnswers();
  }, [handleClearAnswers, callSid]);

  // Initialize answers with existing scorecard data if it exists
  useEffect(() => {
    // Flatten the sections and map to answers
    const initialAnswers = existingScorecardTemplateSections?.flatMap((section) =>
      section.questions
        .map((question) => ({
          id: question?.answers?.[0]?.id,
          questionId: question?.id,
          response: question?.answers?.[0]?.response,
        }))
        // Filter out undefined responses
        .filter((answer) => answer.response !== undefined)
    );
    setAnswers(initialAnswers || []);
    // Re-initialize answers when the callSid changes
  }, [existingScorecardTemplateSections, callSid]);

  /** Handles the scoring of a new scorecard with AI */
  const handleScoreWithAI = useCallback(async () => {
    if (!callSid || !selectedScorecardTemplate?.id) return;
    try {
      setIsScoring(true);
      const response = await createScorecard({
        callSid,
        scorecardTemplateId: selectedScorecardTemplate.id,
        answers: [],
        isScoredByAI: true,
      });

      // We want to show an error toast only if the scoring failed.
      // We intentionally don't show a toast on success because
      // this api success doesn't mean the scoring was successful.
      if (response.error) {
        setIsScoring(false);
        showToast({ message: 'Failed to add scorecard.', type: AlertType.ERROR });
        console.error('Error scoring scorecard:', response.error);
      }
    } catch (error) {
      setIsScoring(false);
      console.error('Error scoring scorecard:', error);
    }
  }, [callSid, selectedScorecardTemplate, createScorecard, showToast]);

  // Check if the scorecard was scored by the current user
  const isScoredByCurrentUser = existingScorecardUser?.id === user?.id;

  // If the user is a sales rep, then they can score calls if the org setting is enabled
  const canScoreCalls = isSalesRep ? repCanScoreCalls : true;

  // Disable edit mode if the user cannot score calls
  // Or if the scorecard is not scored by AI or the current user
  const canEditScorecard = isScored ? isScoredByAI || isScoredByCurrentUser : true;
  const enableEditMode = canScoreCalls && canEditScorecard;

  // Name of existing scorecard if exists or selected scorecard template
  const scorecardName = existingScorecardTemplateName || selectedScorecardTemplate?.name;

  // Check if template has only YES_NO questions, if so then it's AI scoreable
  const isAiScoreable = useMemo(
    () =>
      sections?.every((section) => section.questions.every((question) => question.type === QuestionType.YES_NO)) ??
      false,
    [sections]
  );

  const isScoringProcessing = scoringStatus === CallProcessingStatus.PROCESSING;
  const isScoringSuccess = scoringStatus === CallProcessingStatus.PROCESSED;
  const isScoringFailed = scoringStatus === CallProcessingStatus.PROCESSING_FAILED;

  // Exit edit mode after a scorecard was successfully scored
  useEffect(() => {
    if (isScoringSuccess) {
      setIsEditMode(false);
    }
  }, [isScoringSuccess]);

  // Stop scoring if it failed or succeeded
  useEffect(() => {
    if (isScoringFailed || isScoringSuccess) {
      setIsScoring(false);
    }
  }, [isScoringFailed, isScoringSuccess]);

  // If scoring is processing and no scorecard template is selected
  if (isScoringProcessing && !selectedScorecardTemplate) {
    return (
      <div className="flex h-full items-center justify-center gap-2">
        <Spinner size={ComponentSize.MEDIUM} color={TextColor.SECONDARY} />
        <Typography>Scoring in progress</Typography>
      </div>
    );
  }

  // Getting scorecard template details
  if (isScorecardLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.SMALL} />
      </div>
    );
  }

  // If no existing scorecard and no selected scorecard template, or if scoring failed, show empty/retry component
  if (!existingScorecardId && (!selectedScorecardTemplate || isScoringFailed)) {
    return (
      <div className="h-full rounded-lg border border-base-200">
        <ScorecardEmptyState
          canScoreCalls={canScoreCalls}
          isScoringFailed={isScoringFailed}
          scorecardTemplate={selectedScorecardTemplate}
          setScorecardTemplate={setSelectedScorecardTemplate}
          setIsEditMode={setIsEditMode}
          handleScoreWithAI={handleScoreWithAI}
        />
      </div>
    );
  }

  return (
    <div className="display-scrollbar-sm flex h-full w-full flex-col gap-4 overflow-auto rounded-lg border border-base-200 p-4">
      <ScorecardHeader
        answers={answers}
        isScoringProcessing={isScoring}
        enableEditMode={enableEditMode}
        existingScorecard={existingScorecard}
        handleClearAnswers={handleClearAnswers}
        handleScoreWithAI={handleScoreWithAI}
        isAiScoreable={isAiScoreable}
        isEditMode={isEditMode}
        scorecardName={scorecardName}
        selectedScorecardTemplateId={selectedScorecardTemplate?.id}
        setIsEditMode={setIsEditMode}
      />
      <ScorecardContent sections={sections} answers={answers} setAnswers={setAnswers} isEditMode={isEditMode} />
    </div>
  );
};

export default CallScorecard;
