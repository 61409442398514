import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openRequestReviewModal } from '../../../redux/reducers';
import { ComponentSize, Permissions, SemanticColor, TextColor } from '../../../types';
import { formatSecondsToDuration } from '../../../utils';
import { ButtonColor, ButtonVariant, Icon, Icons, TextButton, Typography, TypographySize } from '../../shared';

interface WebCallStatusProps {
  callSid: string;
  isCalling: boolean;
  isConnected: boolean;
  isConnectionError: boolean;
  isConnectingToMic: boolean;
  isHangingUp: boolean;
  handleRedial: () => void;
  // Start time of the call in seconds.
  startTime?: number;
}

const WebCallStatus = ({
  callSid,
  isCalling,
  isConnected,
  isConnectionError,
  isConnectingToMic: isRedialing,
  isHangingUp,
  handleRedial,
  startTime: startTimeProp,
}: WebCallStatusProps) => {
  // Current time of the call in seconds.
  const [currentTime, setCurrentTime] = useState(0);

  const dispatch = useAppDispatch();
  const permissions = useAppSelector((state) => state.auth.user?.permissions);

  // Tracks if the call has connected to the WebSocket, persisting across state resets.
  const connectedRef = useRef(false);

  // The user can flag the call if they have sufficient permissions and the call has connected without any errors.
  const canFlag = permissions?.includes(Permissions.FLAG_CALL) && connectedRef.current;

  // Reset the current time and connected flags when a new call starts.
  useEffect(() => {
    if (isCalling) {
      setCurrentTime(0);
      connectedRef.current = false;
    }
  }, [isCalling]);

  // Increment the call duration counter while connected.
  // Updates every second to show elapsed time.
  useEffect(() => {
    if (!isConnected && !isConnectionError) return;

    // Get the start time of the call.
    // If the start time is not provided, use the current time.
    // The start time will not be provided if an error occurs.
    const startTime = startTimeProp ?? new Date().getTime();

    // Set a timer to increment the call duration counter every second.
    const timer = setInterval(() => {
      const elapsedTime = (new Date().getTime() - startTime) / 1000;
      setCurrentTime(elapsedTime);
    }, 1000);

    // Clear the timer if the call is hanging up.
    if (isHangingUp) {
      clearInterval(timer);
    }

    // Clear the timer if the call is not connected.
    return () => {
      clearInterval(timer);
    };
  }, [startTimeProp, isConnected, isConnectionError, isHangingUp]);

  // Update the refs if the call is connected.
  useEffect(() => {
    if (isConnected) {
      connectedRef.current = true;
    }
  }, [isConnected]);

  const renderContent = () => {
    if (isConnected || isConnectionError || isHangingUp) {
      return (
        <>
          <Typography size={TypographySize.H5}>{formatSecondsToDuration(currentTime)}</Typography>
          <div className="flex w-full items-center gap-2">
            <Icons size={ComponentSize.LARGE} icon={Icon.PHONE_OUTGOING} color={SemanticColor.PRIMARY} />
            <Typography size={TypographySize.H5} color="text-primary">
              Connected
            </Typography>
          </div>
        </>
      );
    }

    if (!isCalling || isRedialing) {
      return (
        <>
          <Typography size={TypographySize.H5}>{formatSecondsToDuration(currentTime)}</Typography>
          <div className="flex w-full flex-col gap-2">
            {canFlag && (
              <TextButton
                fullWidth
                size={ComponentSize.X_SMALL}
                startIcon={<Icons fillColor={SemanticColor.PRIMARY} icon={Icon.FLAG} />}
                text="Request review"
                variant={ButtonVariant.OUTLINE}
                onClick={() => {
                  dispatch(openRequestReviewModal({ callSid }));
                }}
              />
            )}
            <TextButton
              color={canFlag ? ButtonColor.SECONDARY : ButtonColor.PRIMARY}
              fullWidth
              loading={isRedialing}
              tooltip={isRedialing ? 'Connecting to mic...' : undefined}
              size={ComponentSize.X_SMALL}
              startIcon={Icon.PHONE}
              text="Redial"
              variant={ButtonVariant.OUTLINE}
              onClick={handleRedial}
            />
          </div>
        </>
      );
    }

    return (
      <div className="flex w-full items-center gap-2">
        <Icons size={ComponentSize.LARGE} color={TextColor.SECONDARY} icon={Icon.PHONE_OUTGOING} />
        <Typography color={TextColor.SECONDARY} size={TypographySize.H5}>
          Ringing...
        </Typography>
      </div>
    );
  };

  return (
    <div className="flex w-full items-center gap-4">
      {renderContent()}
      <Icons
        size={ComponentSize.LARGE}
        icon={Icon.INFO}
        tooltip="We recommend calling from a quiet place or using headphones"
        color={TextColor.SECONDARY}
      />
    </div>
  );
};

export default WebCallStatus;
