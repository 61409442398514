import {
  ApiSuccess,
  ApiTagTypes,
  CreateScorecardTemplatePayload,
  ScorecardTemplateActionResponse,
  UpdateScorecardTemplatePayload,
} from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createScorecardTemplate: builder.mutation<ScorecardTemplateActionResponse, CreateScorecardTemplatePayload>({
      query: (templateData) => ({
        url: '/scorecard-templates',
        method: 'POST',
        body: templateData,
      }),
      invalidatesTags: [ApiTagTypes.SCORECARD_TEMPLATES, ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT],
    }),
    updateScorecardTemplate: builder.mutation<ScorecardTemplateActionResponse, UpdateScorecardTemplatePayload>({
      query: ({ id, ...templateData }) => ({
        url: `/scorecard-templates/${id}`,
        method: 'POST',
        body: templateData,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: ApiTagTypes.SCORECARD_TEMPLATES, id },
        ApiTagTypes.SCORECARD_TEMPLATES,
        ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT,
      ],
    }),
    deleteScorecardTemplate: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/scorecard-templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagTypes.SCORECARD_TEMPLATES, ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT],
    }),
    archiveScorecardTemplate: builder.mutation<ScorecardTemplateActionResponse, string>({
      query: (id) => ({
        url: `/scorecard-templates/${id}/archive`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, id) => [
        { type: ApiTagTypes.SCORECARD_TEMPLATES, id },
        ApiTagTypes.SCORECARD_TEMPLATES,
        ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT,
      ],
    }),
    duplicateScorecardTemplate: builder.mutation<ScorecardTemplateActionResponse, string>({
      query: (id) => ({
        url: `/scorecard-templates/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.SCORECARD_TEMPLATES, ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT],
    }),
  }),
});

export const {
  useCreateScorecardTemplateMutation,
  useUpdateScorecardTemplateMutation,
  useDeleteScorecardTemplateMutation,
  useArchiveScorecardTemplateMutation,
  useDuplicateScorecardTemplateMutation,
} = enhancedApiSlice;
