import { useCallback, useState } from 'react';
import { AlertType, Checkbox, Typography, TypographySize } from '../../../../shared';
import { useUpdateOrganizationSettingsMutation } from '../../../../../services';
import { useToast } from '../../../../../hooks';
import { AccessSettingsPayload } from '../../../../../types';
import OrgTabSectionTitle from './OrgTabSectionTitle';

interface AccessProps {
  repCanScoreCalls: boolean;
  repCanCreateSims: boolean;
}

const Access = ({ repCanScoreCalls, repCanCreateSims }: AccessProps) => {
  const [isRepCanScoreChecked, setIsRepCanScoreChecked] = useState(repCanScoreCalls);
  const [isRepCanCreateSimChecked, setIsRepCanCreateSimChecked] = useState(repCanCreateSims);
  const [error, setError] = useState(false);

  const [updateOrganizationSettings] = useUpdateOrganizationSettingsMutation();

  const { showToast } = useToast();

  const onError = useCallback(() => {
    showToast({ message: 'Failed to update organization settings', type: AlertType.ERROR });
    setError(true);
  }, [showToast]);

  const handleUpdateOrgSetting = useCallback(
    async (accessSettings: AccessSettingsPayload) => {
      try {
        const response = await updateOrganizationSettings(accessSettings);
        if (response.error) {
          onError();
        }
      } catch (error) {
        console.error(error);
        onError();
      }
    },
    [updateOrganizationSettings, onError]
  );

  const handleOnChangeRepCanScore = useCallback(
    (newChecked: boolean) => {
      setError(false);
      setIsRepCanScoreChecked(newChecked);
      handleUpdateOrgSetting({ repCanScoreCalls: newChecked });
    },
    [handleUpdateOrgSetting]
  );

  const handleOnChangeRepCanSim = useCallback(
    (newChecked: boolean) => {
      setError(false);
      setIsRepCanCreateSimChecked(newChecked);
      handleUpdateOrgSetting({ repCanCreateSims: newChecked });
    },
    [handleUpdateOrgSetting]
  );
  return (
    <div className="flex flex-col gap-4">
      <OrgTabSectionTitle title="Access" />
      <div className="flex justify-between">
        <Typography size={TypographySize.H5}>Representatives can score calls</Typography>
        <Checkbox checked={isRepCanScoreChecked} setChecked={handleOnChangeRepCanScore} error={error} />
      </div>
      <div className="flex justify-between">
        <Typography size={TypographySize.H5}>Representatives can create simulations</Typography>
        <Checkbox checked={isRepCanCreateSimChecked} setChecked={handleOnChangeRepCanSim} error={error} />
      </div>
    </div>
  );
};

export default Access;
