import { ButtonColor, Icons, TextButton, Typography, TypographySize } from '..';
import { ComponentSize } from '../../../types';
import { EmptyStateProps } from './EmptyState.types';

const EmptyState = ({ description, ctaButtonProps, icon }: EmptyStateProps) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      {icon && <Icons icon={icon} size={ComponentSize.LARGE} />}
      <Typography size={TypographySize.H5}>{description}</Typography>
      {ctaButtonProps && <TextButton color={ButtonColor.SECONDARY} {...ctaButtonProps} />}
    </div>
  );
};

export default EmptyState;
