import { Dispatch, SetStateAction, useCallback } from 'react';
import { PROSPECT_TYPE_OPTIONS } from '../../../constants';
import { useAppDispatch } from '../../../hooks';
import { setCategory, setOrgId, setType } from '../../../redux/reducers';
import { ComponentSize, ProspectCategory, ProspectType } from '../../../types';
import { ButtonColor, ButtonGroup, Select, TextButton, Typography, TypographySize } from '../../shared';

interface SimulationTypeStepProps {
  goToPrevStep: () => void;
  setNewType: Dispatch<SetStateAction<ProspectType | undefined>>;
  targetOrgId?: string;
  newCategory?: ProspectCategory;
  newType?: ProspectType;
}

const SimulationTypeStep = ({
  goToPrevStep,
  setNewType,
  targetOrgId,
  newCategory,
  newType,
}: SimulationTypeStepProps) => {
  const dispatch = useAppDispatch();

  const disabled = !newType;

  const onNext = useCallback(() => {
    if (!targetOrgId || !newCategory || !newType) return;

    dispatch(setOrgId(targetOrgId));
    dispatch(setCategory(newCategory));
    dispatch(setType(newType));
  }, [targetOrgId, newCategory, newType, dispatch]);

  return (
    <div className="flex flex-col gap-4">
      <Typography size={TypographySize.H5}>What kind of scenario are you simulating?</Typography>
      <Select
        placeholder="Select scenario"
        options={PROSPECT_TYPE_OPTIONS}
        selected={newType}
        onChange={(value) => setNewType(value as ProspectType)}
      />
      <ButtonGroup size={ComponentSize.MEDIUM}>
        <TextButton onClick={onNext} text="Next" color={ButtonColor.PRIMARY} disabled={disabled} />
        <TextButton onClick={goToPrevStep} text="Back" />
      </ButtonGroup>
    </div>
  );
};

export default SimulationTypeStep;
