import { flexRender, Row } from '@tanstack/react-table';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useState } from 'react';
import { Skeleton } from '../Skeleton';
import { TableBody, TableCell, TableRow } from '../Table';
import { DATA_TABLE_DEFAULT_CONTENT_HEIGHT, DATA_TABLE_Y_PADDING, END_ACTIONS_COLUMN_ID } from './DataTable.constants';
import { ExtendedRowData } from './DataTable.types';
import { getSizeStyles } from './DataTable.utils';

interface DataTableBodyProps<TData> {
  activeRowIndices: number[];
  colSpan: number;
  rows: Row<TData & ExtendedRowData>[];
  contentHeight?: number;
  isLoading?: boolean;
  setOpenEndActionsIndex: Dispatch<SetStateAction<number | null>>;
}

const DataTableBody = <TData,>({
  activeRowIndices,
  colSpan,
  rows,
  contentHeight: contentHeightProp,
  isLoading,
  setOpenEndActionsIndex,
}: DataTableBodyProps<TData>) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

  const contentHeight = contentHeightProp ?? DATA_TABLE_DEFAULT_CONTENT_HEIGHT;
  // + 1 for the bottom border.
  const rowHeight = contentHeight + DATA_TABLE_Y_PADDING * 2 + 1;

  if (isLoading) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Skeleton size={contentHeight} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (!rows.length) {
    return (
      <TableBody>
        <TableRow height={rowHeight}>
          <TableCell colSpan={colSpan}>No results.</TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {rows.map((row, index) => {
        const { disabled, tooltip, onClick } = row.original;
        const isActive = activeRowIndices.includes(index);
        const isHovered = hoveredRowIndex === index;
        return (
          <TableRow
            key={row.id}
            data-state={row.getIsSelected() && 'selected'}
            active={isActive}
            disabled={disabled}
            height={rowHeight}
            tooltip={tooltip}
            onClick={onClick}
            onHover={(hover) => setHoveredRowIndex(hover ? index : null)}
            onRightClick={() => setOpenEndActionsIndex(index)}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell
                key={cell.id}
                className={clsx(disabled && cell.column.id !== END_ACTIONS_COLUMN_ID && 'opacity-50')}
                style={getSizeStyles(cell.column.getSize())}
              >
                {flexRender(cell.column.columnDef.cell, { ...cell.getContext(), isActive, isHovered })}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default DataTableBody;
