import { AppRoutes, SIDEBAR_WIDTH } from '../../constants';
import { useFilterOptionsAndSelections } from '../../hooks';
import { getCurrentAppRoute } from '../../utils';
import { Typography, TypographySize, TypographyWeight } from '../shared';
import AnalyticsFilters from './AnalyticsFilters';
import ReviewFilters from './ReviewFilters';
import SidebarFooterActions from './SidebarFooterActions';
import SimulationsFilters from './SimulationsFilters';
import SimulationsFolders from './SimulationsFolders';

const SidebarContent = () => {
  const currRoute = getCurrentAppRoute();
  const { accounts, prospects, tags } = useFilterOptionsAndSelections();

  const renderFolders = () => {
    switch (currRoute) {
      case AppRoutes.SIMULATIONS:
        return <SimulationsFolders />;
      case AppRoutes.REVIEW:
      case AppRoutes.ANALYTICS:
      default:
        return null;
    }
  };

  const renderFilters = () => {
    switch (currRoute) {
      case AppRoutes.SIMULATIONS:
        return (
          <SimulationsFilters
            accountOptionsAndSelections={accounts}
            prospectOptionsAndSelections={prospects}
            tagOptionsAndSelections={tags}
          />
        );
      case AppRoutes.REVIEW:
        return <ReviewFilters prospectOptionsAndSelections={prospects} tagOptionsAndSelections={tags} />;
      case AppRoutes.ANALYTICS:
        return <AnalyticsFilters tagOptionsAndSelections={tags} prospectOptionsAndSelections={prospects} />;
      default:
        return null;
    }
  };

  return (
    <div
      className="flex h-full w-full flex-col gap-4 overflow-hidden border-r border-base-100 p-4"
      style={{ width: SIDEBAR_WIDTH }}
    >
      {renderFolders()}
      <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
        Filters
      </Typography>
      <div className="flex flex-1 flex-col gap-6 overflow-y-auto pb-6">{renderFilters()}</div>
      <SidebarFooterActions />
    </div>
  );
};

export default SidebarContent;
