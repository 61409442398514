import {
  ApiSuccess,
  CallsActivityData,
  CallsActivityPayload,
  ProgressReportCallScoreData,
  ProgressReportCallScorePayload,
  ProgressReportMetricData,
  ProgressReportMetricPayload,
  TeamPerformanceAnalyticsPayload,
  TeamPerformanceAnalyticsResponse,
} from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCallsActivityAnalytics: builder.mutation<CallsActivityData, CallsActivityPayload>({
      transformResponse: (response: ApiSuccess<CallsActivityData>) => response.data,
      query: (filters) => ({
        url: '/analytics/calls/activity',
        method: 'POST',
        body: filters,
      }),
    }),
    getCallScoreAnalytics: builder.mutation<ProgressReportCallScoreData, ProgressReportCallScorePayload>({
      transformResponse: (response: ApiSuccess<{ analytics: ProgressReportCallScoreData }>) => response.data.analytics,
      query: (filters) => ({
        url: '/analytics/calls/progress-report/call-score',
        method: 'POST',
        body: filters,
      }),
    }),
    getCallMetricAnalytics: builder.mutation<ProgressReportMetricData, ProgressReportMetricPayload>({
      transformResponse: (response: ApiSuccess<{ analytics: ProgressReportMetricData }>) => response.data.analytics,
      query: ({ metric, ...filters }) => ({
        url: `/analytics/calls/progress-report/${metric}`,
        method: 'POST',
        body: filters,
      }),
    }),
    getTeamPerformanceAnalytics: builder.mutation<TeamPerformanceAnalyticsResponse, TeamPerformanceAnalyticsPayload>({
      transformResponse: (response: ApiSuccess<TeamPerformanceAnalyticsResponse>) => response.data,
      query: (filters) => ({
        url: '/analytics/calls/performance/teams',
        method: 'POST',
        body: filters,
      }),
    }),
  }),
});

export const {
  useGetCallsActivityAnalyticsMutation,
  useGetCallScoreAnalyticsMutation,
  useGetCallMetricAnalyticsMutation,
  useGetTeamPerformanceAnalyticsMutation,
} = enhancedApiSlice;
