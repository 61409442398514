import { show as openIntercom } from '@intercom/messenger-js-sdk';
import { useState } from 'react';
import { TextColor } from '../../../../types';
import { ButtonColor, Icon, IconButton, Icons, TextButton } from '../../../shared';

interface CallActionsProps {
  isConnectingToMic: boolean;
  isHangingUp: boolean;
  isMuted: boolean;
  endCall: () => void;
  mute: () => void;
  unmute: () => void;
}

const CallActions = ({ isConnectingToMic, isHangingUp, isMuted, endCall, mute, unmute }: CallActionsProps) => {
  const [isHoveringMute, setIsHoveringMute] = useState(false);

  const disabled = isConnectingToMic || isHangingUp;

  const icon = isMuted ? Icon.MIC_OFF : Icon.MIC_ON;
  const iconColor = isHoveringMute ? TextColor.DESTRUCTIVE : undefined;

  const onMuteClick = () => {
    if (isMuted) unmute();
    else mute();
  };

  return (
    <div className="flex items-center justify-end gap-2">
      <IconButton
        disabled={disabled}
        icon={<Icons icon={icon} color={iconColor} />}
        color={ButtonColor.SECONDARY}
        onClick={onMuteClick}
        onHover={(hover) => setIsHoveringMute(hover)}
        active={isMuted}
        tooltip={isMuted ? 'Unmute' : 'Mute'}
      />
      <TextButton
        disabled={disabled}
        text="Report issue"
        startIcon={Icon.REPORT}
        color={ButtonColor.SECONDARY}
        onClick={openIntercom}
        fullWidth
      />
      <TextButton disabled={disabled} text="End call" color={ButtonColor.DESTRUCTIVE} onClick={endCall} fullWidth />
    </div>
  );
};

export default CallActions;
