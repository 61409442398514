import { useMemo } from 'react';
import { Select } from '../../../../components';
import {
  DEFAULT_LANGUAGE,
  LLM_MAX_TOKENS_SLIDER_LIMITS,
  LLM_PENALTY_SLIDER_LIMITS,
  LLM_PROVIDER_ID_MODEL_OPTIONS,
  LLM_PROVIDER_OPTIONS,
  LLM_TEMPERATURE_SLIDER_LIMITS,
  MULTILINGUAL_STT_MODEL_ID_OPTIONS,
  MULTILINGUAL_TTS_MODEL_OPTIONS,
  STT_MODEL_ID_OPTIONS,
  TTS_MODEL_OPTIONS,
} from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  setBuyNewPhoneNumber,
  setLlmFrequencyPenalty,
  setLlmMaxTokens,
  setLlmModel,
  setLlmModelId,
  setLlmPresencePenalty,
  setLlmTemperature,
  setPromptTemplateId,
  setSttModelId,
  setTtsModelId,
} from '../../../../redux/reducers';
import { useGetPromptTemplatesQuery } from '../../../../services';
import { LLMProviderID, PromptTemplatesSelectMode } from '../../../../types';
import ProspectCheckbox from '../ProspectCheckbox';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectPageField from '../ProspectPageField';
import ProspectSlider from '../ProspectSlider';
import TabSection from '../TabSection';

const TECHNICAL_CONFIG_SELECT_WIDTH = 340;

const TechnicalConfiguration = () => {
  const dispatch = useAppDispatch();
  const {
    associatedPhoneNumber,
    category,
    type,
    fields: {
      buyNewPhoneNumber,
      ttsModelId,
      sttModelId,
      llmModel,
      llmModelId,
      llmTemperature,
      llmMaxTokens,
      llmFrequencyPenalty,
      llmPresencePenalty,
      promptTemplateId,
      language,
    },
  } = useAppSelector((state) => state.prospect);

  const { data: promptTemplates = [], isLoading: isPromptTemplatesLoading } = useGetPromptTemplatesQuery({
    selectMode: PromptTemplatesSelectMode.SELECT,
    type,
    category,
  });

  const isMultilingual = language !== DEFAULT_LANGUAGE.value;

  const ttsModelOptions = isMultilingual ? MULTILINGUAL_TTS_MODEL_OPTIONS : TTS_MODEL_OPTIONS;
  const sttModelOptions = isMultilingual ? MULTILINGUAL_STT_MODEL_ID_OPTIONS : STT_MODEL_ID_OPTIONS;

  const promptTemplateOptions = promptTemplates.map(({ name, id }) => ({ label: name, value: id }));

  const llmModelOptions = llmModel ? LLM_PROVIDER_ID_MODEL_OPTIONS[llmModel] : [];

  const speechFields = useMemo(
    () => [
      {
        fullWidth: true,
        label: 'Text to speech model',
        required: true,
        content: (
          <Select
            options={ttsModelOptions}
            selected={ttsModelId}
            onChange={(newValue) => dispatch(setTtsModelId(newValue as string))}
          />
        ),
      },
      {
        fullWidth: true,
        label: 'Speech to text model',
        required: true,
        content: (
          <Select
            options={sttModelOptions}
            selected={sttModelId}
            onChange={(newValue) => dispatch(setSttModelId(newValue as string))}
          />
        ),
      },
    ],
    [ttsModelId, sttModelId, dispatch, sttModelOptions, ttsModelOptions]
  );

  const llmFields = useMemo(
    () => [
      {
        label: 'LLM provider',
        required: true,
        width: TECHNICAL_CONFIG_SELECT_WIDTH,
        content: (
          <Select
            options={LLM_PROVIDER_OPTIONS}
            selected={llmModel}
            onChange={(newValue) => dispatch(setLlmModel(newValue as LLMProviderID))}
          />
        ),
      },
      {
        label: 'LLM',
        required: true,
        width: TECHNICAL_CONFIG_SELECT_WIDTH,
        content: (
          <Select
            disabled={!llmModel}
            options={llmModelOptions}
            selected={llmModelId}
            onChange={(newValue) => dispatch(setLlmModelId(newValue as string))}
          />
        ),
      },
    ],
    [llmModel, llmModelId, llmModelOptions, dispatch]
  );

  const llmConfigFields = useMemo(
    () => [
      {
        label: 'LLM temperature',
        required: true,
        content: (
          <ProspectSlider
            leftLabel={llmTemperature.toFixed(1)}
            rightLabel={LLM_TEMPERATURE_SLIDER_LIMITS.max.toFixed(1)}
            value={llmTemperature}
            onChange={(newValue) => dispatch(setLlmTemperature(newValue))}
            {...LLM_TEMPERATURE_SLIDER_LIMITS}
          />
        ),
      },
      {
        label: 'LLM max tokens',
        required: true,
        content: (
          <ProspectSlider
            leftLabel={llmMaxTokens}
            rightLabel={LLM_MAX_TOKENS_SLIDER_LIMITS.max}
            value={llmMaxTokens}
            onChange={(newValue) => dispatch(setLlmMaxTokens(newValue))}
            {...LLM_MAX_TOKENS_SLIDER_LIMITS}
          />
        ),
      },
    ],
    [llmTemperature, llmMaxTokens, dispatch]
  );

  const gptLlmConfigFields = useMemo(
    () => [
      {
        label: 'LLM frequency penalty',
        required: true,
        content: (
          <ProspectSlider
            leftLabel={(llmFrequencyPenalty || 0).toFixed(1)}
            rightLabel={LLM_PENALTY_SLIDER_LIMITS.max.toFixed(1)}
            value={llmFrequencyPenalty}
            onChange={(newValue) => dispatch(setLlmFrequencyPenalty(newValue))}
            {...LLM_PENALTY_SLIDER_LIMITS}
          />
        ),
      },
      {
        label: 'LLM presence penalty',
        required: true,
        content: (
          <ProspectSlider
            leftLabel={(llmPresencePenalty || 0).toFixed(1)}
            rightLabel={LLM_PENALTY_SLIDER_LIMITS.max.toFixed(1)}
            value={llmPresencePenalty}
            onChange={(newValue) => dispatch(setLlmPresencePenalty(newValue))}
            {...LLM_PENALTY_SLIDER_LIMITS}
          />
        ),
      },
    ],
    [llmFrequencyPenalty, llmPresencePenalty, dispatch]
  );

  return (
    <TabSection title="Technical configuration">
      <ProspectPageField
        label="Phone number"
        content={
          <ProspectCheckbox
            checked={!!buyNewPhoneNumber}
            disabled={!!associatedPhoneNumber} // After a phone number is attached, it cannot be removed.
            label="Add phone number to simulation"
            setChecked={(newChecked) => dispatch(setBuyNewPhoneNumber(newChecked))}
          />
        }
      />
      <ProspectFieldGroup fields={speechFields} />
      <ProspectFieldGroup fields={llmFields} />
      <ProspectFieldGroup fields={llmConfigFields} />
      {llmModel === LLMProviderID.GPT && <ProspectFieldGroup fields={gptLlmConfigFields} />}
      <ProspectPageField
        label="Prompt template"
        required
        width={TECHNICAL_CONFIG_SELECT_WIDTH}
        content={
          <Select
            loading={isPromptTemplatesLoading}
            selected={promptTemplateId}
            options={promptTemplateOptions}
            onChange={(newValue) => dispatch(setPromptTemplateId(newValue as string))}
          />
        }
      />
    </TabSection>
  );
};

export default TechnicalConfiguration;
