import {
  ApiPracticeProspectDetails,
  ApiSuccess,
  ApiTagTypes,
  BasePracticeProspect,
  GetPracticeProspectsForSelectParams,
  HotlinePracticeProspectResponse,
  PracticeProspect,
  PracticeProspectDetails,
  PracticeProspectsSelectResponse,
} from '../../types';
import { parseProspect } from '../../utils';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPracticeProspectsForSelect: builder.query<BasePracticeProspect[], GetPracticeProspectsForSelectParams>({
      query: (params) => ({
        url: '/prospects/select',
        params,
      }),
      transformResponse: (response: PracticeProspectsSelectResponse) => response.data.prospects,
      providesTags: [ApiTagTypes.PROSPECTS_FOR_SELECT],
    }),
    getPracticeProspectById: builder.mutation<PracticeProspectDetails, string>({
      query: (prospectId) => `/prospects/${prospectId}`,
      transformResponse: (response: ApiSuccess<{ prospect: ApiPracticeProspectDetails }>) =>
        parseProspect(response.data.prospect),
    }),
    getHotlinePracticeProspect: builder.query<PracticeProspect, void>({
      query: () => '/prospects/hotline',
      transformResponse: (response: HotlinePracticeProspectResponse) => parseProspect(response.data.prospect),
      providesTags: [ApiTagTypes.HOTLINE_PROSPECT],
    }),
  }),
});

export const {
  useGetPracticeProspectsForSelectQuery,
  useGetPracticeProspectByIdMutation,
  useGetHotlinePracticeProspectQuery,
} = enhancedApiSlice;
