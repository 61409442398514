import {
  ApiSuccess,
  ApiTagTypes,
  ApplyTeamPayload,
  EditUserPhoneNumberPayload,
  EditUserRolePayload,
  IntercomHmacResponseData,
} from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    editUserRole: builder.mutation<ApiSuccess, EditUserRolePayload>({
      query: ({ id, ...editData }) => ({
        url: `/users/${id}/role/edit`,
        method: 'POST',
        body: editData,
      }),
      invalidatesTags: [ApiTagTypes.USERS],
    }),
    disableUser: builder.mutation<ApiSuccess, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/disable`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.USERS],
    }),
    enableUser: builder.mutation<ApiSuccess, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/enable`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.USERS],
    }),
    finishSetup: builder.mutation<ApiSuccess, void>({
      query: () => ({
        url: '/users/me/finish-setup',
        method: 'POST',
        params: {
          // Prevents user impersonation from being applied to this request.
          // This endpoint is only used to check if the current user exists in our DB,
          // so we don't need to worry about impersonation here.
          disableImpersonation: true,
        },
      }),
    }),
    applyTeams: builder.mutation<ApiSuccess, ApplyTeamPayload>({
      query: ({ id, teamIds }) => ({
        url: `/users/${id}/apply-teams`,
        method: 'POST',
        body: { teamIds },
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: ApiTagTypes.USERS, id },
        { type: ApiTagTypes.TEAMS, id },
        ApiTagTypes.USERS,
      ],
    }),
    deleteUserPhoneNumber: builder.mutation<ApiSuccess, EditUserPhoneNumberPayload>({
      query: ({ id, phoneNumber }) => ({
        url: `/users/${id}/phone-numbers/delete`,
        method: 'POST',
        body: { phoneNumber },
      }),
      invalidatesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    generateIntercomHMAC: builder.mutation<IntercomHmacResponseData, string>({
      query: (userId) => ({
        url: `/users/${userId}/generate-intercom-hmac`,
        method: 'POST',
      }),
      transformResponse: (response: ApiSuccess<IntercomHmacResponseData>) => response.data,
    }),
  }),
});

export const {
  useEditUserRoleMutation,
  useDisableUserMutation,
  useEnableUserMutation,
  useFinishSetupMutation,
  useApplyTeamsMutation,
  useDeleteUserPhoneNumberMutation,
  useGenerateIntercomHMACMutation,
} = enhancedApiSlice;
