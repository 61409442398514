import { ApiTagTypes, Comment, CommentsResponseData } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query<Comment[], string>({
      query: (callSid) => `/comments/${callSid}`,
      transformResponse: (response: CommentsResponseData) => response.data.comments,
      providesTags: [ApiTagTypes.COMMENTS],
    }),
  }),
});

export const { useGetCommentsQuery } = enhancedApiSlice;
