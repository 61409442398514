import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { connectSocket, subscribeToChannel } from '../services';
import { SOCKET_CHANNELS } from '../types';

const useSocketConnection = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    const initializeSocket = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        connectSocket(token);
        subscribeToChannel(SOCKET_CHANNELS.ALL_ELIGIBLE_CHANNELS);
      }
    };

    initializeSocket();
  }, [isAuthenticated, getAccessTokenSilently]);
};

export default useSocketConnection;
