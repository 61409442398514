import clsx from 'clsx';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { BUTTON_ID } from '../../../../constants';
import { ComponentSize } from '../../../../types';
import { Icon, IconComponent, Icons } from '../../Icons';
import { Tooltip } from '../../Tooltip';
import { Typography } from '../../Typography';
import { BUTTON_SIZE_CONFIG } from '../Button.constants';
import '../Button.css';
import { ButtonColor, ButtonVariant } from '../Button.types';
import { getButtonClass } from '../Button.utils';
import ButtonContent from '../ButtonContent';
import useButtonHandlers from '../useButtonHandlers';
import { TextButtonProps } from './TextButton.types';

const TextButton = forwardRef<HTMLDivElement, TextButtonProps>(
  (
    {
      className,
      active = false,
      text,
      color = ButtonColor.PRIMARY,
      disabled = false,
      fullWidth = false,
      endIcon,
      startIcon,
      loading,
      size = ComponentSize.SMALL,
      tooltip,
      variant = ButtonVariant.FILLED,
      onClick,
      onHover,
      onKeyDown,
    },
    ref
  ) => {
    const shouldDisable = disabled || loading || !onClick;

    const { hover, handleOnClick, handleOnMouseEnter, handleOnMouseLeave } = useButtonHandlers({
      onClick,
      onHover,
      onKeyDown,
    });

    const { btnSizeClass, iconSize, textSize } = BUTTON_SIZE_CONFIG[size];

    const { bg: btnBgClass, text: textColor } = useMemo(
      () => getButtonClass({ color, isActive: active, isDisabled: shouldDisable, isHovered: hover, variant }),
      [active, color, hover, shouldDisable, variant]
    );

    const renderIcon = useCallback(
      (icon: Icon | IconComponent) =>
        typeof icon === 'string' ? (
          <Icons icon={icon} color={textColor} size={iconSize} />
        ) : (
          React.cloneElement(icon, {
            color: icon.props.color || icon.props.fillColor ? icon.props.color : textColor,
            size: icon.props.size ?? iconSize,
          })
        ),
      [textColor, iconSize]
    );

    return (
      <Tooltip label={tooltip} triggerConfig={{ fullWidth }}>
        <div
          className={clsx('btn relative', fullWidth ? 'btn-block' : 'w-fit', btnSizeClass, btnBgClass, className)}
          ref={ref}
          id={BUTTON_ID}
          onClick={handleOnClick}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <ButtonContent loading={loading} size={size}>
            {startIcon && renderIcon(startIcon)}
            <Typography color={textColor} noWrap size={textSize}>
              {text}
            </Typography>
            {endIcon && renderIcon(endIcon)}
          </ButtonContent>
        </div>
      </Tooltip>
    );
  }
);

TextButton.displayName = 'TextButton';

export default TextButton;
