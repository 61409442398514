import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { MicPermissionState, useCheckMicPermissions } from '../../../hooks';
import { GiveMicAccessModal, MicAccessBlockedModal } from '../../modals';

interface ManageMicAccessProps {
  onCancel: () => void;
  onDenied: () => void;
  setIsMicAccessGranted: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}

/**
 * Wrapper for the WebCallModal that handles mic permission states.
 */
const ManageMicAccess = ({
  onCancel: onCancelProp,
  onDenied,
  setIsMicAccessGranted,
  disabled,
}: ManageMicAccessProps) => {
  // State to manage the visibility of the mic access modals.
  const [isGiveMicAccessModalOpen, setIsGiveMicAccessModalOpen] = useState(false);
  const [isMicAccessBlockedModalOpen, setIsMicAccessBlockedModalOpen] = useState(false);

  const { isLoading: isMicPermissionsLoading, micPermissionState, requestMicAccess } = useCheckMicPermissions();

  const onCancel = useCallback(() => {
    onCancelProp();
    setIsGiveMicAccessModalOpen(false);
    setIsMicAccessBlockedModalOpen(false);
  }, [onCancelProp]);

  // Handles prompting the user to give microphone access and closing the GiveMicAccessModal.
  const onGiveAccess = useCallback(() => {
    requestMicAccess();
    setIsGiveMicAccessModalOpen(false);
  }, [requestMicAccess, setIsGiveMicAccessModalOpen]);

  // Handles trying again to get microphone access.
  const onTryAgain = useCallback(() => {
    // Do nothing if permissions are still denied.
    // Keeps the MicAccessBlockedModal open.
    if (micPermissionState === MicPermissionState.DENIED) return;

    // Otherwise, close the MicAccessBlockedModal and request microphone access if needed.
    setIsMicAccessBlockedModalOpen(false);
    if (micPermissionState === MicPermissionState.PROMPT) requestMicAccess();
  }, [micPermissionState, requestMicAccess, setIsMicAccessBlockedModalOpen]);

  useEffect(() => {
    if (disabled || isMicPermissionsLoading) return;

    // Make sure to close the access modals if the user has granted microphone access.
    // Handles the case where the user grants mic access within the same browser window,
    // which would automatically set the permission state to GRANTED.
    if (micPermissionState === MicPermissionState.GRANTED) {
      setIsMicAccessGranted(true);
      setIsGiveMicAccessModalOpen(false);
      setIsMicAccessBlockedModalOpen(false);
      return;
    }

    setIsMicAccessGranted(false);

    if (micPermissionState === MicPermissionState.PROMPT && !isMicAccessBlockedModalOpen) {
      // Open the GiveMicAccessModal if the user has not been prompted for microphone access yet.
      setIsGiveMicAccessModalOpen(true);
    } else if (micPermissionState === MicPermissionState.DENIED) {
      // Open the MicAccessBlockedModal if the user has denied microphone access.
      setIsMicAccessBlockedModalOpen(true);
      onDenied();
    } else if (micPermissionState === null) {
      // Close the web call modal if an unknown error occurred while checking microphone permissions.
      onCancel();
    }
  }, [
    disabled,
    isMicAccessBlockedModalOpen,
    isMicPermissionsLoading,
    micPermissionState,
    onCancel,
    onDenied,
    setIsMicAccessGranted,
  ]);

  return (
    <>
      {/* Open the GiveMicAccessModal only if microphone permissions are not granted nor denied */}
      <GiveMicAccessModal isOpen={isGiveMicAccessModalOpen} onCancel={onCancel} onGiveAccess={onGiveAccess} />

      {/* Open the MicAccessBlockedModal only if microphone permissions are blocked */}
      <MicAccessBlockedModal isOpen={isMicAccessBlockedModalOpen} onCancel={onCancel} onTryAgain={onTryAgain} />
    </>
  );
};

export default ManageMicAccess;
