import { useEffect, useMemo } from 'react';
import {
  ANALYTICS_ACTIVE_TAB_PARAM,
  AVERAGE_BY_OPTIONS,
  DEFAULT_ANALYTICS_AVERAGE_BY,
  DEFAULT_ANALYTICS_DATE_RANGE,
} from '../../constants';
import {
  FilterOptionsAndSelections,
  useAppSelector,
  useFeatureFlag,
  useGetFiltersFromParams,
  useUpdateFilterParams,
} from '../../hooks';
import { useGetTeamsQuery, useGetUsersForSelectQuery, useGetUserTeamsQuery } from '../../services';
import {
  AnalyticsFilterKeys,
  AnalyticsFilters as AnalyticsFiltersType,
  AnalyticsTab,
  LD_FeatureFlags,
  RoleFilterType,
  Roles,
} from '../../types';
import { getTeamsOptions, getUserOptions } from '../../utils';
import { SidebarDateFilter, SidebarSelectFilter } from '../shared';

interface AnalyticsFiltersProps {
  tagOptionsAndSelections: FilterOptionsAndSelections;
  prospectOptionsAndSelections: FilterOptionsAndSelections;
}

const AnalyticsFilters = ({ tagOptionsAndSelections, prospectOptionsAndSelections }: AnalyticsFiltersProps) => {
  const { user } = useAppSelector((state) => state.auth);

  const searchParams = new URLSearchParams(location.search);

  const analyticsActiveTab = searchParams.get(ANALYTICS_ACTIVE_TAB_PARAM);

  const isProgressReportTab = analyticsActiveTab === AnalyticsTab.PROGRESS_REPORT;
  const isTeamPerformanceTab = analyticsActiveTab === AnalyticsTab.TEAM_PERFORMANCE;

  const filters = useGetFiltersFromParams() as AnalyticsFiltersType;
  const teamsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_TEAMS);

  const updateFilterParams = useUpdateFilterParams();

  // In activity tab we show all org analytics so we include all roles in user select
  // In progress report tab we show lower hierarchy roles
  const { data: userData, isLoading: isLoadingUsers } = useGetUsersForSelectQuery(
    isProgressReportTab
      ? { includeCurrentUser: true, includeDisabledUsers: true }
      : {
          roleFilterType: RoleFilterType.SPECIFY_ROLES,
          roles: [Roles.MANAGER, Roles.ADMIN, Roles.SALES_REP],
          includeDisabledUsers: true,
        }
  );
  const { data: orgTeamsData = [], isLoading: isLoadingOrgTeams } = useGetTeamsQuery(undefined, {
    skip: !teamsFF,
  });
  const { data: userTeamsData = [] } = useGetUserTeamsQuery(user?.id ?? '', { skip: !user?.id || !teamsFF });

  // Memoize user and team options to avoid unnecessary recalculations.
  const userOptions = useMemo(() => getUserOptions({ users: userData?.users || [] }), [userData]);
  const teamOptions = useMemo(() => getTeamsOptions(orgTeamsData, userTeamsData), [orgTeamsData, userTeamsData]);

  useEffect(() => {
    // Set the default date range if no date range is selected.
    if (!filters.dateRange) {
      updateFilterParams(AnalyticsFilterKeys.DATE_RANGE, DEFAULT_ANALYTICS_DATE_RANGE);
    }
    // Reset average by if not on progress report tab.
    if (filters.groupBy && !isProgressReportTab) {
      updateFilterParams(AnalyticsFilterKeys.AVERAGE_BY, undefined);
    }
    // Set default average by if on progress report tab.
    if (!filters.groupBy && isProgressReportTab) {
      updateFilterParams(AnalyticsFilterKeys.AVERAGE_BY, DEFAULT_ANALYTICS_AVERAGE_BY);
    }
  }, [isProgressReportTab, updateFilterParams]);

  // Disable the team and user filters if the other filter has values.
  const isTeamFilterDisabled = !!filters.callers;
  const isUserFilterDisabled = !!filters.teamIds;

  const sharedUserFilterProps = {
    options: userOptions,
    placeholder: 'Select user',
    title: 'User',
    loading: isLoadingUsers,
  };

  const renderDateRangeFilter = () => {
    return (
      <SidebarDateFilter
        title="Date range"
        onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.DATE_RANGE, newValue)}
        selected={filters.dateRange}
        disableClear
      />
    );
  };

  // Render only date range filter for team performance tab.
  if (isTeamPerformanceTab) return renderDateRangeFilter();

  return (
    <>
      {renderDateRangeFilter()}

      {!isProgressReportTab && (
        <>
          {/* Only show the Team filter if there are team options */}
          {!!teamOptions.length && (
            <SidebarSelectFilter
              options={teamOptions}
              placeholder="Select teams"
              selected={filters.teamIds}
              title="Team"
              onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.TEAM, newValue)}
              loading={isLoadingOrgTeams}
              disabled={isTeamFilterDisabled}
              tooltip={isTeamFilterDisabled ? 'Remove user filter to enable' : undefined}
            />
          )}
          <SidebarSelectFilter
            selected={filters.callers}
            onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.CALLERS, newValue)}
            disabled={isUserFilterDisabled}
            tooltip={isUserFilterDisabled ? 'Remove team filter to enable' : undefined}
            {...sharedUserFilterProps}
          />
        </>
      )}
      {isProgressReportTab && (
        <>
          <SidebarSelectFilter
            selected={filters.userId?.[0]}
            error={!filters.userId}
            onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.USER, newValue)}
            multiSelect={false}
            clearable={false}
            {...sharedUserFilterProps}
          />
          <SidebarSelectFilter
            options={AVERAGE_BY_OPTIONS}
            selected={filters.groupBy?.[0]}
            title="Average by"
            onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.AVERAGE_BY, newValue)}
            multiSelect={false}
            clearable={false}
          />
        </>
      )}
      <SidebarSelectFilter
        placeholder="Select prospect"
        title="Prospect"
        onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
    </>
  );
};

export default AnalyticsFilters;
