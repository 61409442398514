import { useMemo } from 'react';
import { findOptionByValue, getSelectOptions } from '../components';
import { useGetScorecardTemplateQuery, useGetScorecardTemplatesForSelectQuery } from '../services';
import { conditionalArray } from '../utils';

interface UseGetScorecardTemplateOptionsProps {
  orgId?: string;
  selectedTemplateId?: string;
}

const useGetScorecardTemplateOptions = ({ orgId, selectedTemplateId }: UseGetScorecardTemplateOptionsProps) => {
  // Get the active scorecard templates for the organization.
  const { data: scorecardTemplates = [], isLoading: isLoadingScorecardTemplates } =
    useGetScorecardTemplatesForSelectQuery({ orgId });

  // Parse the scorecard templates to be used in the select component.
  const options = getSelectOptions(scorecardTemplates);

  // Check if the selected template exists in the options.
  // If it doesn't, it means the selected template was archived so we get it's details to display it.
  const isSelectedArchived = !!selectedTemplateId && !findOptionByValue(options, selectedTemplateId);

  const { data: archivedTemplateData, isLoading: isLoadingArchivedTemplateData } = useGetScorecardTemplateQuery(
    selectedTemplateId || '',
    { skip: !isSelectedArchived }
  );

  // Add the archived template to the top of the options list if it exists.
  const optionsWithArchived = useMemo(
    () => [
      // We check isSelectedArchived rather than if archivedTemplateData exists
      // in case an old selected template was archived and had been fetched already.
      ...conditionalArray(isSelectedArchived, {
        label: `${archivedTemplateData?.name} (Archived)`,
        value: archivedTemplateData?.id || '',
      }),
      ...options,
    ],
    [isSelectedArchived, archivedTemplateData, options]
  );

  return {
    options: optionsWithArchived,
    isLoading: isLoadingScorecardTemplates || isLoadingArchivedTemplateData,
  };
};

export default useGetScorecardTemplateOptions;
