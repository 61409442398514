import CallContext from './CallContext';
import HiddenGems from './HiddenGems';
import Objections from './Objections';
import Priorities from './Priorities';
import Questions from './Questions';

const ScenarioTab = () => {
  return (
    <div className="flex flex-col gap-4">
      <CallContext />
      <Priorities />
      <Objections />
      <Questions />
      <HiddenGems />
    </div>
  );
};

export default ScenarioTab;
