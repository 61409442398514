import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openRequestReviewModal, openUnflagConfirmModal } from '../../../redux/reducers';
import { Call, Permissions, Roles, StatusColor, TextColor } from '../../../types';
import { ButtonColor, Icon, IconButton, Icons, TextButton } from '../../shared';

interface FlagButtonProps {
  call: Call;
  isCompact: boolean;
}

const FlagButton = ({ call, isCompact }: FlagButtonProps) => {
  const dispatch = useAppDispatch();
  const { role, permissions } = useAppSelector((state) => state.auth.user) || {};
  const isFlaggedToUser = !!call.isFlaggedToUser;

  // Check if the user can flag/unflag calls.
  // Checking permissions and not just roles is just an extra security check.
  const canFlag = permissions?.includes(Permissions.FLAG_CALL);
  const canUnflag = role !== Roles.SALES_REP && isFlaggedToUser && permissions?.includes(Permissions.UNFLAG_CALL);

  const buttonColor = ButtonColor.SECONDARY;

  const action = useMemo(() => {
    if (canFlag) {
      return {
        icon: <Icons icon={Icon.FLAG} fillColor={TextColor.PRIMARY} />,
        text: 'Request review',
        onClick: () => {
          dispatch(openRequestReviewModal({ call }));
        },
      };
    } else if (canUnflag) {
      return {
        icon: <Icons icon={Icon.FLAG} fillColor={StatusColor.WARNING} />,
        text: 'Unflag call',
        onClick: () => {
          dispatch(openUnflagConfirmModal(call.callSid));
        },
      };
    }
  }, [canFlag, canUnflag, call, dispatch]);

  if (!action) {
    return null;
  }

  if (isCompact) {
    return <IconButton icon={action.icon} onClick={action.onClick} color={buttonColor} tooltip={action.text} />;
  }

  return <TextButton text={action.text} startIcon={action.icon} onClick={action.onClick} color={buttonColor} />;
};

export default FlagButton;
