import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import fullyRampedLogo from '../../../assets/logo-light.png';
import { VERIFICATION_FAILED_ERROR_MSG } from '../../../constants';
import { useHandleApiResponse } from '../../../hooks';
import { socket, subscribeToChannel, useConfirmUserPhoneNumberMutation } from '../../../services';
import { InitiateCallResponseData, CallVerificationResponse, SOCKET_CHANNELS } from '../../../types';
import {
  ButtonColor,
  ButtonVariant,
  Dialog,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../shared';

// Call verification events
enum VerificationEvent {
  VERIFICATION_SUCCESS = 'call:verification:success',
  VERIFICATION_FAILED = 'call:verification:failed',
  VERIFICATION_ENDED = 'call:verification:ended',
}

interface PhoneVerificationProps {
  // Phone number to verify.
  phoneNumber: string;
  // User ID to verify phone number for.
  userId: string;
  // Callback to close the verification modal.
  closeModal: () => void;
  // Callback to call when verification is successful.
  onSuccess: () => void;
  // Callback to call when user ends the call before verification is successful.
  setCallEnded: Dispatch<SetStateAction<boolean>>;
  // Callback to call when verification is unsuccessful.
  setError: Dispatch<SetStateAction<string | undefined>>;
  // Data for the call that is initiated.
  callData?: InitiateCallResponseData;
  // Controls whether the logo is shown.
  showLogo?: boolean;
}

const PhoneVerificationModal = ({
  userId,
  phoneNumber,
  closeModal,
  onSuccess,
  setError,
  setCallEnded,
  callData,
  showLogo,
}: PhoneVerificationProps) => {
  const handleApiResponse = useHandleApiResponse();

  const [confirmUserPhoneNumber] = useConfirmUserPhoneNumberMutation();

  // Whether the modal is open.
  const isOpen = useMemo(() => !!callData, [callData]);
  const { callSid, verificationCode } = callData ?? {};

  // Handles ending the call by closing the modal and setting the call ended flag.
  const handleEndCall = useCallback(() => {
    closeModal();
    setCallEnded(true);
  }, [closeModal, setCallEnded]);

  // Handles error events by closing the modal and setting an error message.
  const handleError = useCallback(() => {
    closeModal();
    setError(VERIFICATION_FAILED_ERROR_MSG);
  }, [closeModal, setError]);

  // Handles phone verified event by adding the phone number to the user's account.
  const handlePhoneVerified = useCallback(async () => {
    try {
      const response = await confirmUserPhoneNumber({ id: userId, phoneNumber });
      handleApiResponse({ response, errorMsg: VERIFICATION_FAILED_ERROR_MSG, onError: handleError, onSuccess });
    } catch (error) {
      console.error(`${VERIFICATION_FAILED_ERROR_MSG}: `, error);
      handleError();
    }
  }, [confirmUserPhoneNumber, handleApiResponse, onSuccess, userId, phoneNumber, handleError]);

  // Handles events from the socket by checking if the callSid matches and then executing the appropriate handler.
  // This is used to prevent events from other calls from being processed.
  const handleVerificationEvent = useCallback(
    (response: CallVerificationResponse, handler: () => void) => {
      if (response.data.callSid === callSid) {
        handler();
      }
    },
    [callSid]
  );

  useEffect(() => {
    if (!isOpen) return;

    subscribeToChannel(SOCKET_CHANNELS.PHONE_NUMBER_CHANNEL + phoneNumber);

    // Listen for verification events.
    socket.on(VerificationEvent.VERIFICATION_ENDED, (response) => handleVerificationEvent(response, handleEndCall));
    socket.on(VerificationEvent.VERIFICATION_SUCCESS, (response) =>
      handleVerificationEvent(response, handlePhoneVerified)
    );
    socket.on(VerificationEvent.VERIFICATION_FAILED, (response) => handleVerificationEvent(response, handleError));

    // Clean up the event listeners when the component unmounts or dependencies change.
    return () => {
      socket.off(VerificationEvent.VERIFICATION_ENDED, (response) => handleVerificationEvent(response, handleEndCall));
      socket.off(VerificationEvent.VERIFICATION_SUCCESS, (response) =>
        handleVerificationEvent(response, handlePhoneVerified)
      );
      socket.off(VerificationEvent.VERIFICATION_FAILED, (response) => handleVerificationEvent(response, handleError));
      socket.disconnect();
    };
  }, [isOpen, phoneNumber, handleVerificationEvent, handleError, handlePhoneVerified, handleEndCall]);

  return (
    <Dialog isOpen={isOpen} hideCloseButton>
      <div className="flex flex-col items-center gap-8 p-4">
        {showLogo && <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-14 w-14" />}
        <Typography size={TypographySize.H5}>Calling your phone...</Typography>
        <div className="flex flex-col items-center gap-4">
          <Typography size={TypographySize.H5}>Dial the code</Typography>
          <Typography size={TypographySize.H1} weight={TypographyWeight.SEMI_BOLD}>
            {verificationCode}
          </Typography>
          <Typography size={TypographySize.H5}>when prompted.</Typography>
        </div>
        <TextButton
          text="Cancel"
          color={ButtonColor.DESTRUCTIVE}
          onClick={handleEndCall}
          variant={ButtonVariant.OUTLINE}
        />
      </div>
    </Dialog>
  );
};

export default PhoneVerificationModal;
