import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { LineChartKeys } from '../../../components';
import { CALL_METRIC_OPTIONS } from '../../../constants';
import { useGetCallMetricAnalyticsMutation, useGetOrganizationSettingsQuery } from '../../../services';
import { AnalyticsFilters, AverageByPeriod, CallMetric, DateFormat } from '../../../types';
import { formatSecondsToDuration } from '../../../utils';
import { ProgressReportCard } from './ProgressReportCard';

interface CallMetricChartProps {
  filters: AnalyticsFilters;
}

const CallMetricChart = ({ filters }: CallMetricChartProps) => {
  // Set default selected metric to words per minute
  const [selectedMetric, setSelectedMetric] = useState<CallMetric>(CallMetric.TALK_SPEED);

  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const { wordsPerMinute, fillerWordsPerMinute, longestMonologue, talkRatio } = orgConfigs || {};

  // Convert talk ratio to decimal for benchmark because data points are in decimal
  const talkRatioBenchmarkIndecimal = talkRatio ? talkRatio / 100 : undefined;
  const formattedLongestMonologueBenchmark = longestMonologue ? formatSecondsToDuration(longestMonologue) : undefined;

  const [
    getCallMetricAnalytics,
    { data: { stats: callMetricAnalytics = [], overallAverage = null } = {}, isLoading: isLoadingCallMetricAnalytics },
  ] = useGetCallMetricAnalyticsMutation();

  const fetchCallMetricAnalytics = useCallback(() => {
    if (!filters.userId || !filters.groupBy || !filters.dateRange) return;

    getCallMetricAnalytics({
      ...filters,
      dateRange: [filters.dateRange],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      groupBy: filters.groupBy[0] as AverageByPeriod,
      userId: filters.userId[0],
      metric: selectedMetric,
    });
  }, [getCallMetricAnalytics, filters, selectedMetric]);

  // Fetch call metric analytics
  useEffect(() => {
    fetchCallMetricAnalytics();
  }, [fetchCallMetricAnalytics]);

  const getBenchmark = () => {
    let value;
    let label = 'Benchmark: ';

    switch (selectedMetric) {
      case CallMetric.TALK_SPEED:
        value = wordsPerMinute;
        label += value;
        break;
      case CallMetric.FILLER_WORDS:
        value = fillerWordsPerMinute;
        label += value;
        break;
      case CallMetric.LONGEST_MONOLOGUE:
        value = longestMonologue;
        label += formattedLongestMonologueBenchmark;
        break;
      case CallMetric.TALK_RATIO:
        value = talkRatioBenchmarkIndecimal;
        label += value;
        break;
      default:
        return;
    }

    if (!value) return undefined;
    return { value, label };
  };

  // Find selected metric
  const selectedMetricDetails = CALL_METRIC_OPTIONS.find((metric) => metric.value === selectedMetric);
  const { label, unit, formatter } = selectedMetricDetails || {};
  const benchmark = getBenchmark();

  // Transform call metric analytics data
  const callMetricData = callMetricAnalytics
    .filter((item) => item.metricValue !== undefined)
    .map((item) => ({
      [LineChartKeys.DATE]: item.date,
      [LineChartKeys.AVG]: item.metricValue,
    }));

  return (
    <ProgressReportCard
      title={`Average ${label?.toLowerCase()}`}
      valueUnit={unit}
      value={overallAverage ? `${formatter?.(overallAverage)}` : undefined}
      data={callMetricData}
      xAxis={{ formatter: (value) => dayjs(value).format(DateFormat.MONTH_DAY) }}
      yAxis={{
        label,
        tooltipLabel: label,
        formatter,
      }}
      selectOptions={CALL_METRIC_OPTIONS}
      selectedOption={selectedMetric}
      onSelectOption={(value) => setSelectedMetric(value as CallMetric)}
      isLoading={isLoadingCallMetricAnalytics}
      benchmark={benchmark}
    />
  );
};

export default CallMetricChart;
