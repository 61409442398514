import { TooltipProps } from '@nivo/heatmap';
import { TextColor, HeatMapData, HeatMapDataPoint } from '../../../../types';
import { Typography, TypographySize } from '../../../../components';

interface HeatMapTooltipProps extends TooltipProps<HeatMapDataPoint> {
  data?: HeatMapData;
}

const HeatMapTooltip = ({ data, cell }: HeatMapTooltipProps) => {
  const { name, rows } = data ?? {};
  const isTeam = !!rows?.length;

  const { data: pointData } = cell;

  if (pointData.y === undefined) return null;

  return (
    <div className="flex flex-col gap-2 rounded-md border bg-black px-3 py-2">
      <div className="flex items-center gap-2">
        <div className="h-3 w-3 rounded-sm" style={{ backgroundColor: cell.color }} />
        <Typography color={TextColor.WHITE}>{name}</Typography>
      </div>
      <Typography size={TypographySize.CAPTION} color={TextColor.WHITE}>
        {pointData.x}: {pointData.formatter(pointData.y)} {pointData.unit}
      </Typography>

      {!isTeam && (
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
          Click to view progress report
        </Typography>
      )}
    </div>
  );
};

export default HeatMapTooltip;
