import { useCallback, useEffect, useMemo, useState } from 'react';
import { TypographySize, Typography, TypographyWeight, Divider, EmptyState, Icon, Spinner } from '../../../components';
import {
  useGetTeamPerformanceAnalyticsMutation,
  useGetOrganizationSettingsQuery,
  useGetScorecardTemplatesForSelectQuery,
} from '../../../services';
import {
  AnalyticsFilters,
  ComponentSize,
  TextColor,
  CallMetric,
  TeamPerformanceAnalyticsResponse,
  TeamPerformanceComputeFieldsType,
} from '../../../types';
import { useGetFiltersFromParams } from '../../../hooks';
import { DEFAULT_ANALYTICS_DATE_RANGE } from '../../../constants';
import { areStrArraysEqual, transformToHeatMapData } from '../../../utils';
import { HeatMap } from './HeatMap';

const ERROR_MSG = 'Error updating default metrics';

// Convert an array of strings to a metrics record
const convertStringsToMetricsRecord = (metricsArray: string[]): Record<CallMetric, boolean> => {
  return Object.values(CallMetric).reduce(
    (acc, metric) => {
      acc[metric] = metricsArray.includes(metric);
      return acc;
    },
    {} as Record<CallMetric, boolean>
  );
};

// Convert from a metrics record to an array of strings
const convertMetricsRecordToStrings = (metricsRecord: Record<CallMetric, boolean>): string[] => {
  return Object.entries(metricsRecord)
    .filter(([_, value]) => value === true)
    .map(([key]) => key);
};

const TeamPerformanceTab = () => {
  const filters = useGetFiltersFromParams() as AnalyticsFilters;

  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  // We store the local analytics data and metrics in state to avoid loading while refetching data
  // which happens when user updates the default metrics
  const [analyticsLocalState, setAnalyticsLocalState] = useState<TeamPerformanceAnalyticsResponse | undefined>(
    undefined
  );
  const { analytics: localStateData, defaultAnalyticsMetrics: localStateMetrics } = analyticsLocalState ?? {};

  const { data: orgConfigs, isLoading: isLoadingOrgConfigs } = useGetOrganizationSettingsQuery();

  // We fetch active and archived scorecard templates to allow users to select from both
  const { data: scorecardTemplates = [] } = useGetScorecardTemplatesForSelectQuery({
    status: undefined,
  });

  const [getTeamPerformanceAnalytics, { data: teamPerformanceResponse, isLoading: isLoadingAnalytics }] =
    useGetTeamPerformanceAnalyticsMutation();

  const transformedData = useMemo(() => {
    if (!analyticsLocalState) return [];
    return transformToHeatMapData(analyticsLocalState, scorecardTemplates, orgConfigs);
  }, [analyticsLocalState, orgConfigs, scorecardTemplates]);

  const fetchTeamPerformanceAnalytics = useCallback(
    async (computeFields?: TeamPerformanceComputeFieldsType) => {
      const dateRange = [filters.dateRange ?? DEFAULT_ANALYTICS_DATE_RANGE];
      const applyDefaultMetrics = !computeFields;
      getTeamPerformanceAnalytics({
        dateRange,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        computeFields,
        applyDefaultMetrics,
      });
    },
    [getTeamPerformanceAnalytics, filters]
  );

  // Fetch the analytics data on initial load
  useEffect(() => {
    fetchTeamPerformanceAnalytics();
  }, [fetchTeamPerformanceAnalytics]);

  const defaultMetrics = useMemo(() => {
    if (!localStateMetrics) return [];
    // Filter keys with true values
    const callMetricsStrings = convertMetricsRecordToStrings(localStateMetrics);
    // Include scorecardTemplateIds
    return [...callMetricsStrings, ...(localStateMetrics.scorecardTemplateIds ?? [])];
  }, [localStateMetrics]);

  // Set selected fields to the default metrics on initial load
  useEffect(() => {
    if (isLoadingAnalytics) return;
    setSelectedFields(defaultMetrics);
  }, [isLoadingAnalytics, defaultMetrics]);

  // Keeps local analytics in sync with the analytics data and metrics
  useEffect(() => {
    if (isLoadingAnalytics || !teamPerformanceResponse) return;
    setAnalyticsLocalState(teamPerformanceResponse);
  }, [teamPerformanceResponse, isLoadingAnalytics]);

  const handleUpdateDefaultMetrics = useCallback(async () => {
    try {
      if (!selectedFields.length || areStrArraysEqual(selectedFields, defaultMetrics)) return;

      // Convert selected fields to a metrics record
      const selectedCallMetricsRecords = convertStringsToMetricsRecord(selectedFields);
      const selectedScorecardTemplateIds = selectedFields.filter((field) => !(field in selectedCallMetricsRecords));

      // Update team performance analytics with new metrics
      fetchTeamPerformanceAnalytics({
        ...selectedCallMetricsRecords,
        scorecardTemplateIds: selectedScorecardTemplateIds,
      });
    } catch (error) {
      console.error(ERROR_MSG, error);
    }
  }, [selectedFields, fetchTeamPerformanceAnalytics, defaultMetrics]);

  const isLoading = isLoadingAnalytics || isLoadingOrgConfigs;

  // Don't show the loading state if there is local state data
  // because this means we are updating the default metrics
  if (isLoading && !localStateData) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.SMALL} />
      </div>
    );
  }

  if (!localStateData?.length) {
    return <EmptyState description="No analytics data found for the selected filters" icon={Icon.BAR_CHART} />;
  }

  return (
    <div className="flex flex-col rounded-md border">
      <div className="flex flex-col p-6">
        <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          Performance overview
        </Typography>
        <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
          User metrics across key performance indicators
        </Typography>
      </div>
      <Divider />
      <div className="overflow-x-auto p-8">
        <HeatMap
          data={transformedData}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          handleUpdateDefaultMetrics={handleUpdateDefaultMetrics}
        />
      </div>
    </div>
  );
};

export default TeamPerformanceTab;
