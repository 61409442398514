import { useCallback, useEffect, useState } from 'react';
import {
  TypographySize,
  TypographyWeight,
  Typography,
  Icon,
  ButtonVariant,
  Spinner,
  AlertType,
  Icons,
  TextButton,
} from '../../../../components';
import { useGenerateCallCoachingMutation } from '../../../../services';
import { CallCoaching, CallProcessingStatus, ComponentSize, TextColor } from '../../../../types';
import { useToast } from '../../../../hooks';
import CoachingItem from './CoachingItem';

interface CoachingCardProps {
  callSid: string;
  aiCoachingResult?: CallCoaching | null;
  coachingStatus?: CallProcessingStatus;
}

const CoachingCard = ({ aiCoachingResult, callSid, coachingStatus }: CoachingCardProps) => {
  const { showToast } = useToast();

  const [isGeneratingCoaching, setIsGeneratingCoaching] = useState(false);
  const [generateCallCoaching] = useGenerateCallCoachingMutation();

  const handleGenerateCoaching = useCallback(async () => {
    try {
      setIsGeneratingCoaching(true);
      // Generate coaching will instantly always return success.
      // We get the generated coaching data from the websocket event.
      const response = await generateCallCoaching(callSid);

      // We want to show an error toast only if the coaching failed.
      // We intentionally don't show a toast on success because
      // this api success doesn't mean the coaching data is successfully generated.
      if (response.error) {
        setIsGeneratingCoaching(false);
        showToast({ message: 'Failed to generate coaching data.', type: AlertType.ERROR });
        console.error('Error generating coaching:', response.error);
      }
    } catch (error) {
      setIsGeneratingCoaching(false);
      console.error('Error generating coaching:', error);
    }
  }, [generateCallCoaching, callSid, showToast]);

  const isCoachingSuccess = coachingStatus === CallProcessingStatus.PROCESSED;
  const isCoachingProcessing = coachingStatus === CallProcessingStatus.PROCESSING;
  const isCoachingFailed = coachingStatus === CallProcessingStatus.PROCESSING_FAILED;

  // Stop generating coaching if it failed or succeeded
  useEffect(() => {
    if (isCoachingFailed || isCoachingSuccess) {
      setIsGeneratingCoaching(false);
    }
  }, [isCoachingFailed, isCoachingSuccess]);

  // Show spinner if coaching is in progress and no previous coaching data
  if (isCoachingProcessing && !aiCoachingResult) {
    return (
      <div className="flex h-full items-center justify-center gap-2">
        <Spinner size={ComponentSize.MEDIUM} color={TextColor.SECONDARY} />
        <Typography>Processing in progress</Typography>
      </div>
    );
  }

  // Show error message if coaching failed and no previous coaching data or no previous coaching data
  if ((isCoachingFailed && !aiCoachingResult) || !aiCoachingResult) {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-4">
        <div className="flex items-center gap-2">
          <Icons icon={Icon.TRIANGLE_ALERT} size={ComponentSize.MEDIUM} />
          <Typography>Processing error</Typography>
        </div>
        <TextButton text="Retry" variant={ButtonVariant.OUTLINE} onClick={handleGenerateCoaching} />
      </div>
    );
  }

  // Show coaching data with a button to regenerate coaching data
  // On regenerating, we show previous coaching data and a spinner
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
          Coaching
        </Typography>
        {isGeneratingCoaching && <Spinner size={ComponentSize.X_SMALL} color={TextColor.SECONDARY} />}
        {!isGeneratingCoaching && (
          <Icons
            icon={Icon.FAST_FORWARD}
            size={ComponentSize.X_SMALL}
            color={TextColor.SECONDARY}
            onClick={handleGenerateCoaching}
          />
        )}
      </div>
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        {aiCoachingResult.overallFeedback}
      </Typography>
      <CoachingItem title="Strengths" items={aiCoachingResult.strengths} />
      <CoachingItem title="Areas of improvement" items={aiCoachingResult.areasOfImprovement} />
      <CoachingItem title="Next steps" items={aiCoachingResult.nextSteps} />
    </div>
  );
};

export default CoachingCard;
