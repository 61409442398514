import { ApiSuccess, ApiTagTypes, BaseTeamPayload, Team, TeamData } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createTeam: builder.mutation<ApiSuccess<TeamData>, BaseTeamPayload>({
      query: (payload) => ({
        url: '/teams/create',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [ApiTagTypes.TEAMS],
    }),

    editTeam: builder.mutation<ApiSuccess<TeamData>, Team>({
      query: ({ id, ...payload }) => ({
        url: `/teams/${id}/edit`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: ApiTagTypes.TEAMS, id }, ApiTagTypes.TEAMS, ApiTagTypes.USERS],
    }),
  }),
});

export const { useCreateTeamMutation, useEditTeamMutation } = enhancedApiSlice;
