import { Dispatch, SetStateAction, useCallback } from 'react';
import { getYesNoOptions, RadioGroup, Slider, TextArea, Typography, TypographySize } from '../../../../components';
import {
  ComponentSize,
  QuestionType,
  ScorecardAnswer,
  ScorecardQuestionType,
  ScorecardSectionType,
  TextColor,
} from '../../../../types';

const TEXT_AREA_MAX_ROWS = 3;
const TEXT_AREA_MIN_ROWS = 2;

interface ScorecardContentProps {
  isEditMode: boolean;
  setAnswers: Dispatch<SetStateAction<ScorecardAnswer[] | undefined>>;
  sections?: ScorecardSectionType[];
  answers?: ScorecardAnswer[];
}

const ScorecardContent = ({ isEditMode, sections, answers, setAnswers }: ScorecardContentProps) => {
  const handleAnswerChange = useCallback((questionId: string, value: string) => {
    setAnswers((prevAnswers) => {
      // If there are no previous answers, create a new array with the current answer
      if (!prevAnswers) return [{ questionId, response: value }];

      let answerFound = false;
      // Map through existing answers, updating the matching question or keeping others as is
      const updatedAnswers = prevAnswers.map((answer) => {
        if (answer.questionId === questionId) {
          answerFound = true;
          return { ...answer, response: value }; // Update existing answer
        }
        return answer; // Keep other answers unchanged
      });

      // If no matching question was found, add the new answer to the array
      if (!answerFound) {
        updatedAnswers.push({ questionId, response: value });
      }

      // Return the updated answers array
      return updatedAnswers;
    });
  }, []);

  // Retrieves the answer for a specific question
  const getAnswerForQuestion = (questionId: string) => {
    return answers?.find((answer) => answer.questionId === questionId)?.response || '';
  };

  const renderQuestionInput = (question: ScorecardQuestionType) => {
    const answer = getAnswerForQuestion(question.id);

    switch (question.type) {
      case QuestionType.RANGE:
        return (
          <Slider
            size={ComponentSize.MEDIUM}
            value={Number(answer) || 1}
            disabled={!isEditMode}
            onChange={(value) => handleAnswerChange(question.id, value.toString())}
          />
        );

      case QuestionType.YES_NO:
        return (
          <RadioGroup
            options={getYesNoOptions(true)}
            value={answer}
            isViewMode={!isEditMode}
            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
          />
        );

      case QuestionType.TEXT:
      default:
        return (
          <TextArea
            value={answer}
            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
            placeholder="Type here"
            size={ComponentSize.MEDIUM}
            rows={TEXT_AREA_MIN_ROWS}
            maxRows={TEXT_AREA_MAX_ROWS}
            disabled={!isEditMode}
            fitContent
          />
        );
    }
  };

  return (
    <div className="flex flex-col gap-6">
      {sections?.map((section) => {
        // Filter questions if in view mode to only show answered ones
        const questionsToDisplay = isEditMode
          ? section.questions
          : section.questions.filter((question) => getAnswerForQuestion(question.id));

        // Only show section if it has questions to display
        if (!questionsToDisplay.length) return null;

        return (
          <div key={section.id} className="flex flex-col gap-4">
            <Typography size={TypographySize.H5}>{section.title}</Typography>
            <div className="flex flex-col gap-6 pl-4">
              {questionsToDisplay.map((question) => (
                <div className="flex items-center gap-4" key={question.id}>
                  <div className="flex w-1/2 flex-col gap-1">
                    <Typography>{question.text}</Typography>
                    {/* We explicitly check for number because it can be 0 */}
                    {typeof question.points === 'number' && (
                      <Typography color={TextColor.SECONDARY}>
                        {question.points} point{Math.abs(question.points) === 1 ? '' : 's'}
                      </Typography>
                    )}
                  </div>
                  <div className="w-1/2">{renderQuestionInput(question)}</div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScorecardContent;
