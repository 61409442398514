import { TypographyMaxLines } from '../types';

export const BUTTON_ID = 'BUTTON';

// Mapping of maximum lines of text to the corresponding Tailwind class.
export const MAX_LINES_TO_CLASSNAME: Record<TypographyMaxLines, string> = {
  1: 'line-clamp-1 overflow-hidden text-ellipsis',
  2: 'line-clamp-2 overflow-hidden text-ellipsis',
  3: 'line-clamp-3 overflow-hidden text-ellipsis',
  4: 'line-clamp-4 overflow-hidden text-ellipsis',
  5: 'line-clamp-5 overflow-hidden text-ellipsis',
  6: 'line-clamp-6 overflow-hidden text-ellipsis',
};

// The threshold for enabling search in Select components.
export const SELECT_SEARCH_THRESHOLD = 6;
