import { ApiTagTypes, GongUsersData, GongUsersResponse } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGongUsers: builder.query<GongUsersData, void>({
      query: () => '/gong/users',
      transformResponse: (response: GongUsersResponse) => response.data,
      providesTags: [ApiTagTypes.GONG_USERS],
    }),
  }),
});

export const { useGetGongUsersQuery } = enhancedApiSlice;
