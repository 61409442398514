import { useCallback, useState } from 'react';
import { ButtonColor, ButtonVariant, Icon, IconButton, Icons, TextInput } from '../../components';
import { ComponentSize, TextColor } from '../../types';

interface SearchBarProps {
  onSubmit: (newValue: string) => void;
}

const SearchBar = ({ onSubmit }: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue?.(e.target.value);
  };

  // Clears the search value and immediately submits.
  const onClear = useCallback(() => {
    setSearchValue('');
    onSubmit('');
  }, [onSubmit]);

  // Submits the search value when the user presses enter.
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit(searchValue);
    }
  };

  const renderEndElement = () =>
    searchValue ? (
      <IconButton color={ButtonColor.SECONDARY} icon={Icon.CLOSE} variant={ButtonVariant.GHOST} onClick={onClear} />
    ) : (
      <div className="flex h-8 min-w-8 items-center justify-center">
        <Icons color={TextColor.SECONDARY} icon={Icon.SEARCH} size={ComponentSize.X_SMALL} />
      </div>
    );

  return (
    <TextInput
      placeholder="Search"
      endElement={renderEndElement()}
      size={ComponentSize.MEDIUM}
      value={searchValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};

export default SearchBar;
