import { useEffect } from 'react';
import { ButtonColor, Icon, TextButton } from '../../../../components';
import { MIN_REQUIRED_DISCOVERY_HIDDEN_CONTEXTS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  addHiddenContext,
  deleteHiddenContext,
  randomizeHiddenGems,
  updateHiddenContext,
} from '../../../../redux/reducers';
import { ProspectType } from '../../../../types';
import TabSection from '../TabSection';
import ScenarioListItem from './ScenarioListItem';

const HiddenGems = () => {
  const dispatch = useAppDispatch();
  const {
    type,
    fields: { hiddenContexts },
  } = useAppSelector((state) => state.prospect);

  const isDiscoveryProspect = type === ProspectType.DISCOVERY;
  const minHiddenContexts = isDiscoveryProspect ? MIN_REQUIRED_DISCOVERY_HIDDEN_CONTEXTS : 1;

  // Delete empty hidden contexts on unmount.
  useEffect(() => {
    return () => {
      if (hiddenContexts.length > minHiddenContexts) {
        hiddenContexts.forEach((context, index) => {
          if (!context.value) {
            dispatch(deleteHiddenContext(index));
          }
        });
      }
    };
  }, []);

  return (
    <TabSection
      title="Hidden gems"
      description="Key details about prospect, account, or opportunity"
      onRandomize={isDiscoveryProspect ? () => dispatch(randomizeHiddenGems()) : undefined}
    >
      {hiddenContexts.map((item, index) => (
        <ScenarioListItem
          key={index}
          index={index}
          canDelete={hiddenContexts.length > minHiddenContexts}
          isRequired={index < minHiddenContexts && isDiscoveryProspect}
          value={item.value}
          deleteItem={() => dispatch(deleteHiddenContext(index))}
          updateItem={(value) => dispatch(updateHiddenContext({ index, value }))}
        />
      ))}
      <TextButton
        text="Add gem"
        startIcon={Icon.PLUS}
        color={ButtonColor.SECONDARY}
        onClick={() => {
          dispatch(addHiddenContext());
        }}
      />
    </TabSection>
  );
};

export default HiddenGems;
