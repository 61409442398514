import { ApiTagTypes, Invite, InvitesResponse } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvites: builder.query<Invite[], void>({
      query: () => '/invite',
      transformResponse: (response: InvitesResponse) => response.data.invitations,
      providesTags: [ApiTagTypes.INVITES],
    }),
  }),
});

export const { useGetInvitesQuery } = enhancedApiSlice;
