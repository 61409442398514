import { useCallback, useMemo } from 'react';
import { Select } from '../../../../components';
import { PROSPECT_SLIDER_LIMITS, SUCCESS_RESULT_OPTIONS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  randomizeCallContext,
  setLastTouchpoint,
  setSuccessDifficultyLevel,
  setSuccessResult,
} from '../../../../redux/reducers';
import { ProspectLastTouchpoint, ProspectSuccessResult } from '../../../../types';
import { parseValuesToOptions } from '../../../../utils';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectPageField from '../ProspectPageField';
import ProspectSlider from '../ProspectSlider';
import TabSection from '../TabSection';

const LAST_TOUCHPOINT_OPTIONS = parseValuesToOptions(Object.values(ProspectLastTouchpoint));

const CallContext = () => {
  const dispatch = useAppDispatch();
  const { lastTouchpoint, successResult, successDifficultyLevel } = useAppSelector((state) => state.prospect.fields);

  const successResultOptions = useMemo(() => {
    if (successResult.value === ProspectSuccessResult.QUALIFY_PROSPECT) {
      return [{ label: 'Qualify prospect', value: ProspectSuccessResult.QUALIFY_PROSPECT }, ...SUCCESS_RESULT_OPTIONS];
    } else {
      return SUCCESS_RESULT_OPTIONS;
    }
  }, [successResult.value]);

  const handleLastTouchpointChange = useCallback(
    (value?: string) => {
      const newLastTouchpoint = value as ProspectLastTouchpoint;
      dispatch(setLastTouchpoint(newLastTouchpoint));
    },
    [dispatch]
  );

  const handleSuccessResultChange = useCallback(
    (value?: string) => {
      const newSuccessResult = value as ProspectSuccessResult;
      dispatch(setSuccessResult(newSuccessResult));
    },
    [dispatch]
  );

  const handleDifficultyChange = useCallback(
    (value: number) => {
      dispatch(setSuccessDifficultyLevel(value));
    },
    [dispatch]
  );

  const fieldGroup = useMemo(
    () => [
      {
        label: 'Last touchpoint',
        required: true,
        content: (
          <Select
            selected={lastTouchpoint.value}
            options={LAST_TOUCHPOINT_OPTIONS}
            onChange={handleLastTouchpointChange}
          />
        ),
      },
      {
        label: 'Goal',
        required: true,
        content: (
          <Select selected={successResult.value} options={successResultOptions} onChange={handleSuccessResultChange} />
        ),
      },
    ],
    [
      lastTouchpoint.value,
      successResult.value,
      successResultOptions,
      handleLastTouchpointChange,
      handleSuccessResultChange,
    ]
  );

  return (
    <TabSection title="Call context" onRandomize={() => dispatch(randomizeCallContext())}>
      <ProspectFieldGroup fields={fieldGroup} />
      <ProspectPageField
        label="Difficulty"
        content={
          <ProspectSlider
            hideProgress
            leftLabel="Easy"
            rightLabel="Difficult"
            max={PROSPECT_SLIDER_LIMITS.max}
            min={PROSPECT_SLIDER_LIMITS.min}
            step={PROSPECT_SLIDER_LIMITS.step}
            value={successDifficultyLevel.value}
            onChange={handleDifficultyChange}
          />
        }
      />
    </TabSection>
  );
};

export default CallContext;
