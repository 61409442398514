import { AppOrganization, OrganizationResponse, OrganizationsResponse } from '../../types';
import { transformToAppOrg } from '../../utils';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<AppOrganization, string>({
      query: (orgId) => `/organizations/${orgId}`,
      transformResponse: (response: OrganizationResponse) => transformToAppOrg(response.data.organization),
    }),
    getOrganizations: builder.query<AppOrganization[], void>({
      query: () => ({
        url: '/organizations',
        params: {
          // Prevents user impersonation from being applied to this request.
          // This endpoint is only accessible to super admins and we don't allow super-admins to impersonate super-admins,
          // so we don't need to worry about impersonation here.
          disableImpersonation: true,
        },
      }),
      transformResponse: (response: OrganizationsResponse) => response.data.organizations.map(transformToAppOrg),
    }),
  }),
});

export const { useGetOrganizationQuery, useGetOrganizationsQuery } = enhancedApiSlice;
