import { PracticeProspect } from '../../../../types';
import CallActions from './CallActions';
import UpNext from './UpNext';

interface WebCallEndActionsProps {
  currPersonaId: string;
  isCalling: boolean;
  isConnectionError: boolean;
  isConnectingToMic: boolean;
  isHangingUp: boolean;
  isMuted: boolean;
  exitCall: () => void;
  mute: () => void;
  unmute: () => void;
  stopCall: () => void;
  nextProspect?: PracticeProspect;
}

const WebCallEndActions = ({
  currPersonaId,
  isCalling,
  isConnectionError,
  isConnectingToMic,
  isHangingUp,
  isMuted,
  exitCall,
  mute,
  unmute,
  stopCall,
  nextProspect,
}: WebCallEndActionsProps) => {
  const isIdle = !isCalling && !isConnectionError;

  return (
    <>
      {!isIdle && (
        <CallActions
          endCall={stopCall}
          isConnectingToMic={isConnectingToMic}
          isHangingUp={isHangingUp}
          isMuted={isMuted}
          mute={mute}
          unmute={unmute}
        />
      )}
      {isIdle && <UpNext currPersonaId={currPersonaId} exitCall={exitCall} nextProspect={nextProspect} />}
    </>
  );
};

export default WebCallEndActions;
