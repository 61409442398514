import clsx from 'clsx';
import { useEffect, useMemo, useRef } from 'react';
import { Avatar, Typography, TypographySize, TypographyWeight } from '../../../../components';
import { ComponentSize, TextColor } from '../../../../types';
import { formatSecondsToDuration } from '../../../../utils';
import { useAppSelector } from '../../../../hooks';

type TranscriptRowProps = {
  speakerName: string;
  startTime: number;
  endTime: number;
  transcript: string;
  seekToAudio: () => void;
  speakerPicture?: string;
};

const TranscriptRow = ({
  speakerName,
  startTime,
  endTime,
  transcript,
  seekToAudio,
  speakerPicture,
}: TranscriptRowProps) => {
  const { currentTime } = useAppSelector((state) => state.mediaPlayer);

  const isActiveRow = useMemo(
    () => currentTime >= startTime && currentTime < endTime,
    [currentTime, startTime, endTime]
  );
  const rowRef = useRef<HTMLDivElement>(null);

  // Scroll to the active row to be always visible.
  useEffect(() => {
    if (isActiveRow && rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isActiveRow]);

  return (
    <div className="flex items-start gap-4" ref={rowRef}>
      <Typography color={TextColor.SECONDARY} className="mt-2">
        {formatSecondsToDuration(startTime)}
      </Typography>
      <div
        className={clsx(
          'flex w-full cursor-pointer gap-4 rounded-lg p-2 hover:bg-success',
          isActiveRow ? 'bg-success' : ''
        )}
        onClick={seekToAudio}
      >
        <div className="h-4 flex-shrink-0">
          <Avatar imgSrc={speakerPicture} label={speakerName} size={ComponentSize.X_SMALL} />
        </div>
        <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
          {speakerName}: <span className="font-normal">{transcript}</span>
        </Typography>
      </div>
    </div>
  );
};

export default TranscriptRow;
