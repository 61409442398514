import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector, useShareCall } from '../../hooks';
import { openRequestReviewModal, openUnflagConfirmModal } from '../../redux/reducers';
import { Call, CallProcessingStatus, Permissions, StatusColor, TextColor } from '../../types';
import { conditionalArray } from '../../utils';

/**
 * Custom hook to generate actions for call rows in the data table.
 */
const useCallActions = (call: Call, closeActions: () => void) => {
  const { callSid, isFlaggedToUser, processingStatus } = call;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { onShare } = useShareCall(callSid, undefined, closeActions);

  const user = useAppSelector((state) => state.auth.user);
  const permissions = user?.permissions || [];

  // Check if the user has permission to flag/unflag calls.
  const canFlag = permissions.includes(Permissions.FLAG_CALL);
  const canUnflag = !canFlag && permissions.includes(Permissions.UNFLAG_CALL) && !!isFlaggedToUser;

  // Handles opening the request review modal.
  const onRequestReview = useCallback(() => {
    dispatch(openRequestReviewModal({ call }));
    closeActions();
  }, [call, closeActions, dispatch]);

  // Handles opening the call details page of the selected call
  // by navigating to the Review page with the callSid as a parameter
  // while preserving existing search params.
  const onOpen = useCallback(() => {
    navigate({
      pathname: `${AppRoutes.REVIEW}/${callSid}`,
      search: window.location.search,
    });
    closeActions();
  }, [callSid, closeActions, navigate]);

  // Handles opening the unflag confirmation modal.
  const onUnflagCall = useCallback(() => {
    dispatch(openUnflagConfirmModal(callSid));
    closeActions();
  }, [callSid, closeActions, dispatch]);

  // Disable actions if call hasn't finished processing.
  if (processingStatus !== CallProcessingStatus.PROCESSED) {
    return [];
  }

  return [
    {
      label: 'Review call',
      icon: Icon.SEARCH_CHECK,
      onClick: onOpen,
    },
    ...conditionalArray(canFlag, {
      label: 'Request review',
      icon: Icon.FLAG,
      iconFillColor: TextColor.PRIMARY,
      onClick: onRequestReview,
    }),
    ...conditionalArray(canUnflag, {
      label: 'Unflag call',
      icon: Icon.FLAG,
      iconFillColor: StatusColor.WARNING,
      onClick: onUnflagCall,
    }),
    {
      label: 'Share',
      icon: Icon.SHARE,
      onClick: onShare,
    },
  ];
};

export default useCallActions;
