import { DropdownPlacement, TextColor } from '../../../types';
import { ButtonColor, ButtonVariant, IconButton } from '../Button';
import { Dropdown, DropdownContent, DropdownTrigger } from '../Dropdown';
import { DropdownItem } from '../DropdownItem';
import { Icon, Icons } from '../Icons';
import { Typography } from '../Typography';
import { RowEndAction } from './DataTable.types';

interface DataTableActionsCellProps<TData> {
  data: TData;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  useEndActions: (row: TData, closeActions: () => void) => RowEndAction[];
}

const DataTableActionsCell = <TData,>({ data, isOpen, setIsOpen, useEndActions }: DataTableActionsCellProps<TData>) => {
  const actions = useEndActions(data, () => setIsOpen(false));
  const isDisabled = !actions.length;

  return (
    <Dropdown
      disabled={isDisabled}
      open={!isDisabled && isOpen}
      onOpenChange={setIsOpen}
      placement={DropdownPlacement.BOTTOM_END}
    >
      <DropdownTrigger>
        <IconButton
          active={isOpen}
          color={ButtonColor.SECONDARY}
          disabled={isDisabled}
          icon={Icon.ELLIPSIS_VERTICAL}
          variant={ButtonVariant.OUTLINE}
          onClick={() => setIsOpen(true)}
        />
      </DropdownTrigger>
      <DropdownContent>
        {actions.map(
          ({ destructive, icon, iconColor: iconColorProp, iconFillColor, label, submenu, onClick, onSubmenuClose }) => {
            const textColor = destructive ? TextColor.DESTRUCTIVE : TextColor.PRIMARY;
            const iconColor = iconColorProp || iconFillColor ? iconColorProp : textColor;
            return (
              <DropdownItem
                key={label}
                destructive={destructive}
                icon={<Icons icon={icon} color={iconColor} fillColor={iconFillColor} />}
                label={<Typography color={textColor}>{label}</Typography>}
                {...(onClick ? { onClick } : { submenu, onSubmenuClose })}
              />
            );
          }
        )}
      </DropdownContent>
    </Dropdown>
  );
};

export default DataTableActionsCell;
