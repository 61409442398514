import { useMemo } from 'react';
import { Select, TextArea } from '../../../../components';
import { COOLDOWN_PERIOD_OPTIONS, TIME_LIMIT_OPTIONS } from '../../../../constants';
import { useAppDispatch, useAppSelector, useGetScorecardTemplateOptions } from '../../../../hooks';
import { setCooldownPeriod, setManagerNotes, setScorecardTemplateId, setTimeLimit } from '../../../../redux/reducers';
import { ProspectCategory } from '../../../../types';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const MAX_AI_SCORECARD_SELECT_WIDTH = 340;

const MIN_MANAGER_NOTES_ROWS = 1;
const MAX_MANAGER_NOTES_ROWS = 5;

const ManagerSettings = () => {
  const dispatch = useAppDispatch();
  const {
    category,
    orgId,
    fields: { scorecardTemplateId, cooldownPeriod, timeLimit, managerNotes },
  } = useAppSelector((state) => state.prospect);

  const isQuiz = category === ProspectCategory.QUIZ;

  const { options: scorecardTemplateOptions, isLoading: isLoadingScorecardTemplates } = useGetScorecardTemplateOptions({
    orgId,
    selectedTemplateId: scorecardTemplateId,
  });

  const aiScorecardField = useMemo(
    () => ({
      label: 'AI scorecard',
      content: (
        <Select
          clearable
          loading={isLoadingScorecardTemplates}
          options={scorecardTemplateOptions}
          selected={scorecardTemplateId}
          onChange={(newValue) => dispatch(setScorecardTemplateId(newValue))}
        />
      ),
    }),
    [isLoadingScorecardTemplates, scorecardTemplateId, scorecardTemplateOptions, dispatch]
  );

  const quizFields = useMemo(
    () => [
      aiScorecardField,
      {
        label: 'Cool-down period',
        tooltip: 'Require wait before next attempt.',
        content: (
          <Select
            clearable
            options={COOLDOWN_PERIOD_OPTIONS}
            selected={cooldownPeriod?.toString()}
            onChange={(newValue) => dispatch(setCooldownPeriod(newValue ? parseInt(newValue) : undefined))}
          />
        ),
      },
      {
        label: 'Time limit',
        tooltip: 'Limit the length of the quiz.',
        content: (
          <Select
            clearable
            options={TIME_LIMIT_OPTIONS}
            selected={timeLimit?.toString()}
            onChange={(newValue) => dispatch(setTimeLimit(newValue ? parseInt(newValue) : undefined))}
          />
        ),
      },
    ],
    [aiScorecardField, cooldownPeriod, timeLimit, dispatch]
  );

  return (
    <TabSection title="Settings">
      {!isQuiz && <ProspectPageField width={MAX_AI_SCORECARD_SELECT_WIDTH} {...aiScorecardField} />}
      {isQuiz && <ProspectFieldGroup fields={quizFields} />}
      <ProspectPageField
        label="Manager notes"
        tooltip="Only visible to managers and administrators."
        content={
          <TextArea
            fitContent
            maxRows={MAX_MANAGER_NOTES_ROWS}
            rows={MIN_MANAGER_NOTES_ROWS}
            value={managerNotes}
            onChange={(e) => dispatch(setManagerNotes(e.target.value))}
          />
        }
      />
    </TabSection>
  );
};

export default ManagerSettings;
