import { PaginationState } from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import {
  Breadcrumbs,
  ButtonColor,
  CreateButton,
  EmptyState,
  TextButton,
  Typography,
  TypographySize,
} from '../../components';
import { PAGINATION_PAGE_SIZE } from '../../constants';
import { useAppDispatch, useAppSelector, useGetFiltersFromParams, useTimer } from '../../hooks';
import {
  SimulationsFolders,
  SimulationsView,
  openUploadModal,
  setProspects,
  setShowConfetti,
} from '../../redux/reducers';
import { useGetOrganizationSettingsQuery, useGetPracticeProspectsMutation } from '../../services';
import {
  CustomSortingState,
  ProspectSortingFilters,
  SimulationsFilterKeys,
  SimulationsFilters,
  SortingOrder,
} from '../../types';
import { snakeCaseToLabel } from '../../utils';
import ProspectTable from './ProspectTable';
import SearchBar from './SearchBar';

const CONFETTI_GRAVITY = 0.8;
const CONFETTI_DURATION = 2500;
const CONFETTI_NUMBER_OF_PIECES = 400;

const SimulationsPage = () => {
  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const { repCanCreateSims = false } = orgConfigs || {};

  const dispatch = useAppDispatch();
  const { currFolder, view, showConfetti } = useAppSelector((state) => state.simulations);
  const organization = useAppSelector((state) => state.auth.organization);
  const isInFavorites = currFolder === SimulationsFolders.FAVORITES;
  const isUploadModalEnabled = organization?.isUploadModalEnabled;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });

  const [sorting, setSorting] = useState<CustomSortingState>({
    sortBy: ProspectSortingFilters.UPDATED_AT,
    sortOrder: SortingOrder.DESC,
  });

  const [searchValue, setSearchValue] = useState('');

  const [getPracticeProspects, { data, isLoading }] = useGetPracticeProspectsMutation();
  const totalPages = data?.pagination.totalPages;

  const filters: SimulationsFilters = useGetFiltersFromParams();

  const filtersApplied = Object.keys(filters).length > 0 || !!searchValue;
  const isEmpty = !isLoading && !data?.prospects.length;
  // In folder view, show the table only if the user is in the favorites folder, or if there are any filters applied.
  // In table view, always show the table.
  const showTable =
    !isEmpty && (view === SimulationsView.TABLE || currFolder === SimulationsFolders.FAVORITES || filtersApplied);

  const isConfettiPlaying = showConfetti && !isLoading;

  const { startTimer, stopTimer } = useTimer({
    callback: () => dispatch(setShowConfetti(false)),
    delay: CONFETTI_DURATION,
    disabled: !isConfettiPlaying,
  });

  //  If confetti is being shown, automatically hide it after duration.
  useEffect(() => {
    if (isConfettiPlaying) {
      startTimer();
    }
    return () => {
      stopTimer();
    };
  }, [isConfettiPlaying, startTimer, stopTimer]);

  // Fetch prospects whenever filters change.
  const fetchProspects = useCallback(() => {
    const createdDate = filters.createdDate ? [filters.createdDate] : undefined;
    getPracticeProspects({
      ...Object.entries(filters).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
      [SimulationsFilterKeys.DATE_CREATED]: createdDate,
      [SimulationsFilterKeys.SEARCH]: searchValue,
      pagination,
      sorting,
      isFavorited: isInFavorites,
    });
  }, [filters, isInFavorites, pagination, searchValue, sorting, getPracticeProspects]);

  // Fetch prospects whenever filters change.
  useEffect(() => {
    fetchProspects();
  }, [fetchProspects]);

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination((prev) => ({ ...prev, pageIndex: 1 }));
    }
  }, [filters]);

  // This effect sets the list of prospects for simulations pages.
  // It allows for optimistic updates to the state instead of refetching from the server.
  useEffect(() => {
    if (isLoading) return;
    dispatch(setProspects(data?.prospects || []));
    return () => {
      dispatch(setProspects([]));
    };
  }, [data?.prospects, isLoading, dispatch]);

  return (
    <div className="flex h-full flex-col">
      {isConfettiPlaying && (
        <Confetti
          gravity={CONFETTI_GRAVITY}
          numberOfPieces={CONFETTI_NUMBER_OF_PIECES}
          className="fixed left-0 top-0 !z-10"
        />
      )}
      <div className="flex items-center justify-between">
        <Breadcrumbs items={['Simulations', snakeCaseToLabel(currFolder)]} />
        <div className="flex gap-2">
          <SearchBar onSubmit={setSearchValue} />
          <CreateButton repCanCreateSims={repCanCreateSims} />
        </div>
      </div>
      {isEmpty && (
        <EmptyState
          description={
            filtersApplied
              ? 'No simulations found for the selected filters'
              : isInFavorites
                ? 'No simulations have been added to your favorites yet'
                : 'No simulations created yet'
          }
        />
      )}
      {showTable && (
        <div className="flex flex-col">
          <ProspectTable
            isInFavorites={isInFavorites}
            repCanCreateSims={repCanCreateSims}
            isLoading={isLoading}
            pagination={pagination}
            sorting={sorting}
            totalPages={totalPages}
            refetchProspects={fetchProspects}
            setPagination={setPagination}
            setSorting={setSorting}
          />
          {isUploadModalEnabled && (
            <div className="flex flex-col items-center gap-4 py-4">
              <Typography size={TypographySize.H5}>Want to improve your prospects?</Typography>
              <TextButton
                text="Enhance"
                onClick={() => {
                  dispatch(openUploadModal());
                }}
                color={ButtonColor.SECONDARY}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SimulationsPage;
