import {
  ApiSuccess,
  GenerateGongOAuthStateResponse,
  GongAPIKeyPayload,
  GongCallsData,
  GongCallsPayload,
  GongCallsResponse,
  GongImportCallsPayload,
} from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGongCalls: builder.mutation<GongCallsData, GongCallsPayload>({
      query: ({ cursor, ...filters }) => ({
        url: '/gong/calls',
        method: 'POST',
        body: filters,
        params: { cursor },
      }),
      transformResponse: (response: GongCallsResponse) => response.data,
    }),
    importGongCalls: builder.mutation<ApiSuccess, GongImportCallsPayload>({
      query: (payload) => ({
        url: '/gong/calls/import',
        method: 'POST',
        body: payload,
      }),
    }),
    generateGongOauthState: builder.mutation<string, void>({
      query: () => ({
        url: '/gong/oauth-state',
        method: 'POST',
      }),
      transformResponse: (response: GenerateGongOAuthStateResponse) => response.data.state,
    }),
    submitGongAPIKey: builder.mutation<ApiSuccess, GongAPIKeyPayload>({
      query: (apiKeyData) => ({
        url: '/gong/api-key',
        method: 'POST',
        body: apiKeyData,
      }),
    }),
    disconnectGong: builder.mutation<ApiSuccess, void>({
      query: () => ({
        url: '/gong/disconnect',
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetGongCallsMutation,
  useImportGongCallsMutation,
  useGenerateGongOauthStateMutation,
  useSubmitGongAPIKeyMutation,
  useDisconnectGongMutation,
} = enhancedApiSlice;
