import { Typography } from '../../../../components';
import { PROSPECT_SLIDER_LIMITS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  randomizePersonality,
  setEmpathyLevel,
  setFigurativeThinkingLevel,
  setImprovisationLevel,
  setTalkativenessLevel,
} from '../../../../redux/reducers';
import { Alignment } from '../../../../types';
import ProspectSlider from '../ProspectSlider';
import TabSection from '../TabSection';

const Personality = () => {
  const dispatch = useAppDispatch();
  const {
    fields: { talkativenessLevel, figurativeThinkingLevel, empathyLevel, improvisationLevel },
  } = useAppSelector((state) => state.prospect);

  const fields = [
    {
      leftLabel: 'Talkative and outgoing',
      rightLabel: 'Reserved and private',
      value: talkativenessLevel.value,
      onChange: (value: number) => {
        dispatch(setTalkativenessLevel(value));
      },
    },
    {
      leftLabel: 'Literal thinker',
      rightLabel: 'Figurative thinker',
      value: figurativeThinkingLevel.value,
      onChange: (value: number) => {
        dispatch(setFigurativeThinkingLevel(value));
      },
    },
    {
      leftLabel: 'Reasonable and level-headed',
      rightLabel: 'Warm and empathetic',
      value: empathyLevel.value,
      onChange: (value: number) => {
        dispatch(setEmpathyLevel(value));
      },
    },
    {
      leftLabel: 'Prefers detailed step-by-step instructions',
      rightLabel: 'Likes to improvise and make things up as you go',
      value: improvisationLevel.value,
      onChange: (value: number) => {
        dispatch(setImprovisationLevel(value));
      },
    },
  ];

  const renderLabel = (label: string, alignment: Alignment) => (
    <Typography className="w-56" alignment={alignment}>
      {label}
    </Typography>
  );

  return (
    <TabSection description="Key traits" title="Personality" onRandomize={() => dispatch(randomizePersonality())}>
      <div className="flex flex-col items-center gap-4">
        {fields.map((field, index) => (
          <ProspectSlider
            key={index}
            className="flex-1"
            leftLabel={renderLabel(field.leftLabel, Alignment.RIGHT)}
            rightLabel={renderLabel(field.rightLabel, Alignment.LEFT)}
            hideProgress
            max={PROSPECT_SLIDER_LIMITS.max}
            min={PROSPECT_SLIDER_LIMITS.min}
            step={PROSPECT_SLIDER_LIMITS.step}
            value={field.value}
            onChange={(value) => field.onChange(value)}
          />
        ))}
      </div>
    </TabSection>
  );
};

export default Personality;
