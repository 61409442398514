import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { PaginationType } from '../../../types';
import { Table, TableHead, TableHeader, TableRow } from '../Table';
import { END_ACTIONS_COLUMN_ID } from './DataTable.constants';
import { DataTableProps, ExtendedRowData } from './DataTable.types';
import { getSizeStyles } from './DataTable.utils';
import DataTableActionsCell from './DataTableActionsCell';
import DataTableBody from './DataTableBody';
import PaginationControls from './PaginationControls';

/** Generic DataTable component designed to handle different data types with configurable columns. */
function DataTable<TData>({
  activeRowIndices: activeRowIndicesProp = [],
  columns: columnsProp,
  data,
  contentHeight,
  isLoading,
  paginationControls,
  useEndActions,
}: DataTableProps<TData & ExtendedRowData>) {
  const [openEndActionsIndex, setOpenEndActionsIndex] = useState<number | null>(null);
  const [localPagination, setLocalPagination] = useState(paginationControls);

  const activeRowIndices = useMemo(
    () => [...activeRowIndicesProp, openEndActionsIndex].filter((index) => index !== null) as number[],
    [activeRowIndicesProp, openEndActionsIndex]
  );

  useEffect(() => {
    if (!isLoading && paginationControls) {
      setLocalPagination(paginationControls);
    }
  }, [isLoading, paginationControls]);

  // Add end actions column to the table.
  const columns = useMemo(() => {
    const baseColumns = [...columnsProp];
    if (useEndActions) {
      baseColumns.push({
        accessorKey: END_ACTIONS_COLUMN_ID,
        header: '',
        cell: ({ row }) => (
          <DataTableActionsCell
            data={row.original}
            isOpen={openEndActionsIndex === row.index}
            setIsOpen={(isOpen) => setOpenEndActionsIndex(isOpen ? row.index : null)}
            useEndActions={useEndActions}
          />
        ),
      });
    }
    return baseColumns;
  }, [columnsProp, useEndActions, openEndActionsIndex]);

  // Initialize the table with configuration for columns and data.
  const table = useReactTable({
    data,
    columns,
    manualPagination: true, // Manual "server-side" pagination
    getCoreRowModel: getCoreRowModel(), // Function to generate a row model from provided data.
    onPaginationChange: localPagination?.setPagination,
    state: {
      pagination: localPagination?.pagination,
    },
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
  });

  // If both onNextClick and onPrevClick are provided, then it is cursor-based.
  // @tanstack/react-table doesn't have built-in support for cursor-based pagination.
  // We need to handle it manually in case the pagination is cursor-based.
  const isCursorPagination = !!(paginationControls?.onNextClick && paginationControls?.onPrevClick);
  const paginationType = isCursorPagination ? PaginationType.CURSOR : PaginationType.PAGE;

  // Intionally not ternary condition to not evaluate the expression based on the return value of the function itself.
  const handlePrevClick = () => {
    if (paginationControls?.onPrevClick) {
      return paginationControls.onPrevClick();
    } else {
      return table.previousPage();
    }
  };

  // Intionally not ternary condition to not evaluate the expression based on the return value of the function itself.
  const handleNextClick = () => {
    if (paginationControls?.onNextClick) {
      return paginationControls.onNextClick();
    } else {
      return table.nextPage();
    }
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan} style={getSizeStyles(header.getSize())}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <DataTableBody
          activeRowIndices={activeRowIndices}
          colSpan={columns.length}
          contentHeight={contentHeight}
          isLoading={isLoading}
          rows={table.getRowModel().rows}
          setOpenEndActionsIndex={setOpenEndActionsIndex}
        />
      </Table>
      {!!localPagination && localPagination.totalPages > 1 && (
        <PaginationControls
          onPrevClick={handlePrevClick}
          onNextClick={handleNextClick}
          pagination={localPagination.pagination}
          setPagination={localPagination.setPagination}
          totalPages={localPagination.totalPages}
          paginationType={paginationType}
        />
      )}
    </div>
  );
}

export default DataTable;
