import { TextColor } from '../../../types';
import { Icon, Icons } from '../Icons';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import { BreadcrumbsProps } from './Breadcrumbs.types';

const Breadcrumbs = ({ items = [] }: BreadcrumbsProps) => {
  const renderChevron = () => <Icons icon={Icon.CHEVRON_RIGHT} color={TextColor.SECONDARY} />;

  const renderLabel = ({ label, active }: { label: string; active: boolean }) => (
    <Typography
      size={TypographySize.H3}
      color={active ? TextColor.PRIMARY : TextColor.SECONDARY}
      weight={TypographyWeight.SEMI_BOLD}
    >
      {label}
    </Typography>
  );

  return (
    <div className="flex min-h-12 items-center gap-2">
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        const isActive = isLast && items.length > 1;
        return (
          <div className="flex items-center gap-2" key={item}>
            {renderLabel({ label: item, active: isActive })}
            {!isLast && renderChevron()}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
