import { ApiSuccess } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitLink: builder.mutation<ApiSuccess, string>({
      query: (link) => ({
        url: '/url',
        method: 'POST',
        body: { url: link },
      }),
    }),
  }),
});

export const { useSubmitLinkMutation } = enhancedApiSlice;
