import * as FullStory from '@fullstory/browser';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import AppContent from './AppContent';
import { CustomAuth0Provider } from './context';
import { persistor, store } from './redux/store';
import { DailyProvider } from '@daily-co/daily-react';

/**
 * App component sets up the React and Redux environment.
 * It wraps the app content in necessary providers for state management and authentication.
 */
const App = () => {
  // Initializes FullStory analytics if the organization ID is provided.
  useEffect(() => {
    const orgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
    if (orgId) {
      FullStory.init({ orgId });
    }
  }, []);

  return (
    <React.StrictMode>
      <CustomAuth0Provider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <DailyProvider>
              <AppContent />
            </DailyProvider>
          </PersistGate>
        </Provider>
      </CustomAuth0Provider>
    </React.StrictMode>
  );
};

export default App;
