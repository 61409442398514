import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SimulationDesignerModal, Spinner } from '../../components';
import { useAppDispatch, useAppSelector, useWarnBeforeUnload } from '../../hooks';
import {
  clearProspectForm,
  ProspectFooterFields,
  setMode,
  setProspectFooterFields,
  setProspectForm,
} from '../../redux/reducers';
import { useGetPracticeProspectByIdMutation } from '../../services';
import { PracticeProspectDetails, ProspectPageMode, ProspectPageTabs, Permissions } from '../../types';
import ProspectPageFooter from './ProspectPageFooter';
import ProspectPageHeader from './ProspectPageHeader';
import { AccountTab, ConfigurationTab, LeadTab, PersonalTab, PromptTab, ScenarioTab } from './ProspectPageTabs';

const DEFAULT_ACTIVE_TAB = ProspectPageTabs.PERSONAL;

const ProspectPage = () => {
  const { personaId } = useParams();

  const dispatch = useAppDispatch();
  const { orgId, category, type } = useAppSelector((state) => state.prospect);

  const user = useAppSelector((state) => state.auth.user);
  const canManageProspect = (user?.permissions || []).includes(Permissions.MANAGE_PROSPECT);

  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  // The original prospect data that prefills the form when viewing an existing prospect.
  // This is used to reset the form to its initial state when discarding changes.
  // We omit the footer fields because they are edited on-the-fly.
  const [prospect, setProspect] = useState<Omit<PracticeProspectDetails, ProspectFooterFields>>();
  const [visitedTabs, setVisitedTabs] = useState(new Set([DEFAULT_ACTIVE_TAB]));

  const [getPracticeProspectById, { isLoading: isLoadingProspect }] = useGetPracticeProspectByIdMutation();

  // Warn the user if they try to leave the page without saving.
  useWarnBeforeUnload();

  // Resets the form to the original prospect data, discarding any unsaved changes.
  const resetForm = useCallback(() => {
    if (prospect) dispatch(setProspectForm({ prospect, canManageProspect }));
  }, [dispatch, prospect, canManageProspect]);

  // Fetch prospect data and populate form state when personaId is provided.
  useEffect(() => {
    if (!personaId) return;

    const fetchProspect = async () => {
      const { data: newProspect } = await getPracticeProspectById(personaId);
      if (newProspect) {
        setProspect(newProspect);
        const { isFavorited, notes, tags, ...rest } = newProspect;
        dispatch(setProspectForm({ prospect: rest, canManageProspect }));
        dispatch(setProspectFooterFields({ isFavorited, notes, tags }));
        dispatch(setMode(ProspectPageMode.EDIT));
        // Mark all tabs as visited since we are viewing an existing prospect.
        setVisitedTabs(new Set(Object.values(ProspectPageTabs)));
      }
    };

    fetchProspect();
  }, [dispatch, canManageProspect, personaId, getPracticeProspectById, setProspect]);

  // Resets the prospect form when the page is unmounted.
  useEffect(() => {
    return () => {
      dispatch(clearProspectForm());
    };
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case ProspectPageTabs.PERSONAL:
        return <PersonalTab />;
      case ProspectPageTabs.LEAD:
        return <LeadTab />;
      case ProspectPageTabs.ACCOUNT:
        return <AccountTab />;
      case ProspectPageTabs.SCENARIO:
        return <ScenarioTab />;
      case ProspectPageTabs.CONFIGURATION:
        return <ConfigurationTab />;
      case ProspectPageTabs.PROMPT:
      default:
        return <PromptTab />;
    }
  };

  if (isLoadingProspect) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <ProspectPageHeader
        activeTab={activeTab}
        visitedTabs={visitedTabs}
        setActiveTab={setActiveTab}
        setVisitedTabs={setVisitedTabs}
      />
      <div className="flex flex-grow justify-center overflow-y-auto py-8">
        <div className="h-fit w-full max-w-[700px]">{renderTabContent()}</div>
      </div>
      <ProspectPageFooter
        activeTab={activeTab}
        visitedTabs={visitedTabs}
        resetForm={resetForm}
        setActiveTab={setActiveTab}
        setVisitedTabs={setVisitedTabs}
      />
      {/* Show the prospect designer modal if the designer modal fields are not set,
      meaning the user has just started creating a prospect. */}
      {(!orgId || !category || !type) && <SimulationDesignerModal setVisitedTabs={setVisitedTabs} />}
    </div>
  );
};

export default ProspectPage;
