import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useClientPoint,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { useMemo, useRef, useState } from 'react';
import { DropdownPlacement } from '../../../types';
import { conditionalArray, conditionalObject, convertToPx } from '../../../utils';
import { DropdownOptions } from './Dropdown.types';

export function useDropdown({
  disabled = false,
  fullWidth = false,
  initialOpen = false,
  listNavigation,
  maxHeight,
  maxWidth,
  minWidth = 'fit-content',
  placement = DropdownPlacement.BOTTOM,
  position,
  offset: offsetValue = 0,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  showArrow = false,
  ...options
}: DropdownOptions = {}) {
  const arrowRef = useRef(null);

  // State to manage uncontrolled open state.
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  // Determine the open state (controlled or uncontrolled).
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  // Initialize the floating UI with the provided configuration.
  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      // Add an offset to the floating element.
      // Increase offset when arrow is shown.
      offset(offsetValue),
      // Flip the floating element's placement when it overflows.
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: 8,
      }),
      // Shift the floating element to fit within the viewport.
      shift({ padding: 8 }),
      // Adjust the size of the floating element.
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: convertToPx(minWidth),
            maxWidth: convertToPx(maxWidth),
            ...conditionalObject(fullWidth, { width: `${rects.reference.width}px` }),
            ...conditionalObject(!!maxHeight, {
              maxHeight: convertToPx(maxHeight),
              overflowY: 'auto',
            }),
          });
        },
      }),
      ...conditionalArray(showArrow, arrow({ element: arrowRef })),
    ],
  });

  const context = data.context;

  // Middleware to position the floating element at a provided custom position if it exists.
  const clientPoint = useClientPoint(context, {
    enabled: !!position,
    ...position,
  });

  // Click interaction to open/close the dropdown.
  const click = useClick(context, {
    enabled: !disabled,
    keyboardHandlers: false,
  });

  // Dismiss interaction to close the dropdown on outside clicks and ESC key.
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });

  // Role interaction for ARIA role attributes.
  const role = useRole(context);

  // List navigation interaction.
  const listNav = listNavigation
    ? useListNavigation(context, {
        enabled: open,
        loop: true,
        virtual: true,
        ...listNavigation,
      })
    : null;

  // Combine all interactions.
  const interactions = useInteractions([clientPoint, click, dismiss, role, ...(listNav ? [listNav] : [])]);

  // Memoize the returned values.
  return useMemo(
    () => ({
      ...conditionalObject(showArrow, { arrowRef }),
      open,
      setOpen,
      ...interactions,
      ...data,
      ...options,
      labelId,
      descriptionId,
      disabled,
      setLabelId,
      setDescriptionId,
    }),
    [arrowRef, open, setOpen, interactions, data, options, labelId, descriptionId, disabled]
  );
}
