import { ApiTagTypes, CreateTagPayload, CreateTagResponse } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createTag: builder.mutation<CreateTagResponse, CreateTagPayload>({
      query: ({ ...tagData }) => ({
        url: '/tags/create',
        method: 'POST',
        body: tagData,
      }),
      invalidatesTags: [ApiTagTypes.TAGS],
    }),
  }),
});

export const { useCreateTagMutation } = enhancedApiSlice;
