import { useCallback } from 'react';
import { useSubmitAITwinLinkMutation, useSubmitLinkMutation } from '../services';
import { isValidURL } from '../utils';
import useHandleApiResponse from './useHandleApiResponse';
import { FilePurpose, ProspectCategory, ProspectType } from '../types';

const useSubmitLink = (isAITwin: boolean = false) => {
  const [submitLink, { isLoading: isSubmittingLink }] = useSubmitLinkMutation();
  const [submitAITwinLink, { isLoading: isSubmittingAITwinLink }] = useSubmitAITwinLinkMutation();
  const handleApiResponse = useHandleApiResponse(true);

  const SUBMIT_LINK_ERROR_MSG = isAITwin ? 'Failed to submit AI twin link' : 'Failed to submit link';

  // Handler function to manage link submission
  const handleLinkSubmit = useCallback(
    async (linkInput: string) => {
      if (!linkInput) {
        return { error: 'Please enter a URL' };
      }

      if (!isValidURL(linkInput)) {
        return { error: 'Please enter a valid URL' };
      }

      try {
        const response = isAITwin
          ? await submitAITwinLink({
              urls: [
                {
                  url: linkInput,
                  purpose: FilePurpose.PROSPECT_CREATION,
                  prospectCategory: ProspectCategory.PRACTICE,
                  prospectType: ProspectType.PROSPECTING,
                },
              ],
            })
          : await submitLink(linkInput);
        let result: { error: string } | undefined;
        handleApiResponse({
          response,
          errorMsg: SUBMIT_LINK_ERROR_MSG,
          onError: (e) => {
            result = { error: e.message };
          },
        });
        return result;
      } catch (error) {
        console.error(`${SUBMIT_LINK_ERROR_MSG}: `, error);
        return { error: SUBMIT_LINK_ERROR_MSG };
      }
    },
    [isAITwin, handleApiResponse, submitLink, submitAITwinLink]
  );

  return { submitLink: handleLinkSubmit, isLoading: isSubmittingLink || isSubmittingAITwinLink };
};

export default useSubmitLink;
