import clsx from 'clsx';
import { BASE_COLOR_TO_BG_COLOR, BUTTON_ID, STATUS_COLOR_TO_BG_COLOR, TAG_COLOR_TO_BG_COLOR } from '../../../constants';
import { BaseColor, ComponentSize, StatusColor, TagColor } from '../../../types';
import {
  conditionalObject,
  getTooltipContent,
  getTooltipPlacement,
  isBaseColor,
  isStatusColor,
  isTagColor,
} from '../../../utils';
import { Tooltip } from '../Tooltip';
import { DOT_SIZE } from './Dot.constants';
import { DotProps } from './Dot.types';

const Dot = ({ color, tooltip, onClick, size = ComponentSize.SMALL }: DotProps) => {
  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <Tooltip label={getTooltipContent(tooltip)} placement={getTooltipPlacement(tooltip)}>
      <div
        className={clsx(
          'rounded-full',
          DOT_SIZE[size],
          onClick && 'cursor-pointer',
          isBaseColor(color) && BASE_COLOR_TO_BG_COLOR[color as BaseColor],
          isTagColor(color) && TAG_COLOR_TO_BG_COLOR[color as TagColor],
          isStatusColor(color) && STATUS_COLOR_TO_BG_COLOR[color as StatusColor]
        )}
        id={onClick ? BUTTON_ID : undefined}
        style={{
          ...conditionalObject(!isBaseColor(color) && !isTagColor(color) && !isStatusColor(color), {
            backgroundColor: color,
          }),
        }}
        onClick={handleOnClick}
      />
    </Tooltip>
  );
};

export default Dot;
