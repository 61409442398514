import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useHandleApiResponse, useToast } from '../../../hooks';
import { deleteProspect, updateProspect } from '../../../redux/reducers';
import {
  useArchivePracticeProspectMutation,
  useDeletePracticeProspectMutation,
  useEditPracticeProspectMutation,
  useSubscribeToProspectMutation,
  useUnsubscribeFromProspectMutation,
} from '../../../services';
import { PracticeProspect } from '../../../types';
import { AlertType, ButtonColor, ButtonGroup, ButtonVariant, TextButton } from '../../shared';
import ConfirmModal from '../ConfirmModal';

const ARCHIVE_ERROR_MSG = 'Failed to archive prospect';
const DELETE_ERROR_MSG = 'Failed to delete prospect';
const EDIT_ERROR_MSG = 'Failed to update prospect';
const SAVE_SUBSCRIPTION_ERROR_MSG = 'Failed to save prospect subscription';

interface ManagerNotesActionButtonsProps {
  notesInput: string;
  isSendNotificationEnabled: boolean;
  prospect: PracticeProspect;
  selectedAllowedCallers: string[];
  selectedAllowedTeams: string[];
  usersOptions: { label: string; value: string }[];
  teamsOptions: { label: string; value: string; color?: string }[];
  onClose: () => void;
  refetchProspects: () => void;
  scorecardTemplateIdInput?: string;
  cooldownPeriodInput?: number;
  timeLimitInput?: number;
}

const ManagerNotesActionButtons = ({
  notesInput,
  isSendNotificationEnabled,
  prospect,
  onClose,
  refetchProspects,
  scorecardTemplateIdInput,
  cooldownPeriodInput,
  timeLimitInput,
  selectedAllowedCallers,
  selectedAllowedTeams,
  usersOptions,
  teamsOptions,
}: ManagerNotesActionButtonsProps) => {
  const dispatch = useAppDispatch();

  const [isArchiveConfirmModalOpen, setIsArchiveConfirmModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);

  const [archivePracticeProspect, { isLoading: isArchiving }] = useArchivePracticeProspectMutation();
  const [deletePracticeProspect, { isLoading: isDeleting }] = useDeletePracticeProspectMutation();
  const [editPracticeProspect, { isLoading: isSaving }] = useEditPracticeProspectMutation();

  const [subscribeToProspect, { isLoading: isSubscribing }] = useSubscribeToProspectMutation();
  const [unsubscribeFromProspect, { isLoading: isUnsubscribing }] = useUnsubscribeFromProspectMutation();

  const handleApiResponse = useHandleApiResponse();
  const { showToast } = useToast();

  const {
    firstName,
    lastName,
    isUsed,
    personaId,
    isSubscribed,
    managerNotes,
    scorecardTemplateId,
    timeLimit,
    allowedCallers,
    allowedTeams,
    cooldownPeriod,
  } = prospect;
  const fullName = `${firstName} ${lastName}`;

  const allowedCallersData = useMemo(() => {
    return (
      usersOptions
        .filter((user) => selectedAllowedCallers.includes(user.value))
        .map((user) => ({
          id: user.value,
          email: user.label,
          name: user.label,
        })) || []
    );
  }, [usersOptions, selectedAllowedCallers]);

  const allowedTeamsData = useMemo(() => {
    return (
      teamsOptions
        .filter((team) => selectedAllowedTeams.includes(team.value))
        .map((team) => ({
          id: team.value,
          name: team.label,
          color: team.color,
        })) || []
    );
  }, [teamsOptions, selectedAllowedTeams]);

  const runArchive = useCallback(async () => {
    try {
      const response = await archivePracticeProspect(personaId);
      handleApiResponse({
        response,
        errorMsg: ARCHIVE_ERROR_MSG,
        onSuccess: () => {
          // We refetch prospects after archiving to ensure the prospect is moved to the end of the list.
          // We cannot handle this on the FE because we only have access to the current page of prospects.
          refetchProspects();
          onClose();
        },
      });
    } catch (error) {
      console.error(error);
      showToast({ type: AlertType.ERROR, message: ARCHIVE_ERROR_MSG });
    }
  }, [personaId, archivePracticeProspect, handleApiResponse, onClose, refetchProspects, showToast]);

  const runDelete = useCallback(async () => {
    try {
      const response = await deletePracticeProspect(personaId);
      handleApiResponse({
        response,
        errorMsg: DELETE_ERROR_MSG,
        onSuccess: () => {
          dispatch(deleteProspect(personaId));
          onClose();
        },
      });
    } catch (error) {
      console.error(error);
      showToast({ type: AlertType.ERROR, message: DELETE_ERROR_MSG });
    }
  }, [personaId, deletePracticeProspect, dispatch, handleApiResponse, onClose, showToast]);

  const hasDirtyFields = useMemo(() => {
    const allAllowedCallersIds = allowedCallers?.map((caller) => caller.id);
    const allAllowedTeamsIds = allowedTeams?.map((team) => team.id);

    return (
      notesInput !== managerNotes ||
      scorecardTemplateIdInput !== scorecardTemplateId ||
      timeLimitInput !== timeLimit ||
      cooldownPeriodInput !== cooldownPeriod ||
      selectedAllowedCallers !== allAllowedCallersIds ||
      selectedAllowedTeams !== allAllowedTeamsIds
    );
  }, [
    notesInput,
    managerNotes,
    scorecardTemplateIdInput,
    scorecardTemplateId,
    timeLimitInput,
    timeLimit,
    selectedAllowedCallers,
    allowedCallers,
    selectedAllowedTeams,
    allowedTeams,
    cooldownPeriodInput,
    cooldownPeriod,
  ]);
  const handleSaveProspect = useCallback(async () => {
    // If the prospect has no dirty fields, return
    if (!hasDirtyFields) return;

    try {
      const response = await editPracticeProspect({
        id: personaId,
        managerNotes: notesInput,
        scorecardTemplateId: scorecardTemplateIdInput ?? null,
        cooldownPeriod: cooldownPeriodInput ?? null,
        timeLimit: timeLimitInput ?? null,
        allowedCallers: selectedAllowedCallers,
        allowedTeams: selectedAllowedTeams,
      });
      handleApiResponse({
        response,
        errorMsg: EDIT_ERROR_MSG,
      });
      return response;
    } catch (error) {
      console.error(error);
      showToast({ type: AlertType.ERROR, message: EDIT_ERROR_MSG });
      return { error };
    }
  }, [
    personaId,
    notesInput,
    scorecardTemplateIdInput,
    cooldownPeriodInput,
    timeLimitInput,
    selectedAllowedCallers,
    selectedAllowedTeams,
    hasDirtyFields,
    editPracticeProspect,
    showToast,
    handleApiResponse,
  ]);

  const handleSaveSubscription = useCallback(async () => {
    // If the subscription status is the same, return
    if (isSendNotificationEnabled === isSubscribed) return;

    try {
      let response;
      if (isSendNotificationEnabled) {
        response = await subscribeToProspect({ personaId });
      } else {
        response = await unsubscribeFromProspect({ personaId });
      }
      handleApiResponse({
        response,
        errorMsg: SAVE_SUBSCRIPTION_ERROR_MSG,
      });
      return response;
    } catch (error) {
      console.error(error);
      showToast({ type: AlertType.ERROR, message: SAVE_SUBSCRIPTION_ERROR_MSG });
      return { error };
    }
  }, [
    isSendNotificationEnabled,
    isSubscribed,
    personaId,
    subscribeToProspect,
    unsubscribeFromProspect,
    showToast,
    handleApiResponse,
  ]);

  const onSave = useCallback(async () => {
    // Save the prospect and subscription synchronously
    const [prospectResponse, subscriptionResponse] = await Promise.all([
      handleSaveProspect(),
      handleSaveSubscription(),
    ]);

    // Update the prospect and subscription in prospect store
    const prospectData = prospectResponse && 'data' in prospectResponse ? prospectResponse.data?.data.prospect : null;
    const subscriptionData =
      subscriptionResponse && 'data' in subscriptionResponse ? subscriptionResponse.data?.data : null;

    if (prospectData || subscriptionData) {
      dispatch(
        updateProspect({
          personaId,
          allowedCallers: allowedCallersData,
          allowedTeams: allowedTeamsData,
          cooldownPeriod: cooldownPeriodInput,
          ...prospectData,
          ...(subscriptionData ? { isSubscribed: isSendNotificationEnabled } : {}),
        })
      );
    }

    // Close the modal if the save was successful
    if (!prospectResponse?.error && !subscriptionResponse?.error) {
      onClose();
    }
  }, [
    handleSaveProspect,
    handleSaveSubscription,
    dispatch,
    prospect,
    isSendNotificationEnabled,
    onClose,
    allowedCallersData,
    allowedTeamsData,
    personaId,
    cooldownPeriodInput,
  ]);

  return (
    <div className="flex">
      <TextButton
        text={isUsed ? 'Archive' : 'Delete'}
        color={ButtonColor.DESTRUCTIVE}
        variant={ButtonVariant.OUTLINE}
        onClick={isUsed ? () => setIsArchiveConfirmModalOpen(true) : () => setIsDeleteConfirmModalOpen(true)}
        loading={isArchiving || isDeleting}
      />
      <ButtonGroup
        disabled={isArchiving || isDeleting}
        loading={isSaving || isSubscribing || isUnsubscribing}
        className="ml-auto"
      >
        <TextButton text="Save changes" variant={ButtonVariant.FILLED} color={ButtonColor.PRIMARY} onClick={onSave} />
        <TextButton text="Cancel" onClick={onClose} />
      </ButtonGroup>
      <ConfirmModal
        destructive
        isOpen={isArchiveConfirmModalOpen}
        setIsOpen={setIsArchiveConfirmModalOpen}
        onConfirm={runArchive}
        title={`Archive ${fullName}`}
        confirmText={`Are you sure you want to archive this prospect? You will have the option to unarchive them later.`}
        buttonText="Archive"
        isLoading={isArchiving}
      />
      <ConfirmModal
        destructive
        isOpen={isDeleteConfirmModalOpen}
        setIsOpen={setIsDeleteConfirmModalOpen}
        onConfirm={runDelete}
        title={`Delete ${fullName}`}
        confirmText={`Are you sure you want to delete this prospect? This action cannot be undone.`}
        buttonText="Delete"
        isLoading={isDeleting}
      />
    </div>
  );
};

export default ManagerNotesActionButtons;
