import { ScorecardSectionType } from './scorecard.types';
import { CustomSortingState } from './sorting.types';
import { Tag } from './tags.types';

export enum ScorecardTemplateStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ScorecardTemplateType {
  AI = 'AI',
  MANUAL = 'MANUAL',
}

export type ScorecardTemplatesParams = {
  sorting?: CustomSortingState;
  status?: ScorecardTemplateStatus;
  type?: ScorecardTemplateType;
};

export type ScorecardTemplatesForSelectParams = {
  orgId?: string;
  status?: ScorecardTemplateStatus;
  type?: ScorecardTemplateType;
};

export type BaseScorecardTemplate = {
  id: string;
  name: string;
  type: ScorecardTemplateType;
};

export type ScorecardTemplate = BaseScorecardTemplate & {
  lastEditor: {
    name: string;
    picture: string;
  };
  status: ScorecardTemplateStatus;
  updatedAt: string;
  points?: number;
  type?: ScorecardTemplateType;
  isUsedInScorecards?: boolean;
  sections?: ScorecardSectionType[];
  tags?: Tag[];
};

export type ScorecardTemplatesResponse = {
  data: {
    scorecardTemplates: ScorecardTemplate[];
  };
};

export type ScorecardTemplatesSelectResponse = {
  data: {
    scorecardTemplates: BaseScorecardTemplate[];
  };
};

export type ScorecardTemplateResponse = {
  data: {
    scorecardTemplate: ScorecardTemplate;
  };
};

type BaseScorecardTemplatePayload = {
  name?: string;
  tags?: string[];
  sections?: ScorecardSectionType[];
};

export type CreateScorecardTemplatePayload = BaseScorecardTemplatePayload & {
  type: ScorecardTemplateType;
};

export type UpdateScorecardTemplatePayload = BaseScorecardTemplatePayload & {
  id: string;
};

export interface ScorecardTemplateActionType {
  id: string;
  name: string;
  status: ScorecardTemplateStatus;
  updatedAt: string;
  type: ScorecardTemplateType;
}

export interface ScorecardTemplateActionResponse {
  message: string;
  data: {
    scorecardTemplate: ScorecardTemplateActionType;
  };
}
