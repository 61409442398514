import { v4 as uuidv4 } from 'uuid';
import { QuestionType } from '../types';

/** Get a new scorecard question with a default type according to the scorecard type */
export const getNewScorecardQuestion = (isAiScorecard: boolean) => ({
  text: '',
  id: uuidv4(),
  type: isAiScorecard ? QuestionType.YES_NO : undefined,
  points: isAiScorecard ? 1 : undefined,
});
