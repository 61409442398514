import { Avatar, Badge, Icon, Typography, TypographySize } from '../../../../components';
import { useAppSelector } from '../../../../hooks';
import { ComponentSize, SemanticColor, TextColor } from '../../../../types';

interface ScoredByInfoProps {
  isScoredByAI?: boolean;
  scoredByUser?: {
    id: string;
    name: string;
    picture: string;
  };
}

const ScoredByInfo = ({ isScoredByAI, scoredByUser }: ScoredByInfoProps) => {
  const { name, picture, id } = scoredByUser ?? {};
  const { user } = useAppSelector((state) => state.auth);

  const isScoredByCurrentUser = id === user?.id;
  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex gap-2">
        <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION} noWrap>
          Scored by:
        </Typography>
        {isScoredByAI && (
          <Badge label="AI" color={SemanticColor.AI} startIcon={Icon.WAND} size={ComponentSize.MEDIUM} />
        )}
        {!isScoredByAI && (
          <div className="flex items-center gap-2">
            <Avatar size={ComponentSize.X_SMALL} label={name} imgSrc={picture} />
            <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} noWrap>
              {name} {isScoredByCurrentUser && '(You)'}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoredByInfo;
