import { useGetOrganizationSettingsQuery } from '../../../../../services';
import { ComponentSize } from '../../../../../types';
import { Spinner } from '../../../../shared';
import TabHeader from '../TabHeader';
import Access from './Access';
import Benchmarks from './Benchmarks';
import CustomVocabulary from './CustomVocabulary';
import DefaultAIScorecard from './DefaultAIScorecard';

const OrganizationTab = () => {
  const { data: orgConfigs, isLoading: isLoadingOrgSettings } = useGetOrganizationSettingsQuery();
  const { vocabulary, repCanScoreCalls, repCanCreateSims, defaultScorecardTemplateId } = orgConfigs || {};

  if (isLoadingOrgSettings) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.MEDIUM} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <TabHeader title="Your organization’s configurations" />
      <DefaultAIScorecard defaultScorecardTemplateId={defaultScorecardTemplateId ?? undefined} />
      {!!orgConfigs && <Benchmarks orgConfigs={orgConfigs} />}
      <CustomVocabulary vocabulary={vocabulary ?? []} />
      <Access repCanScoreCalls={repCanScoreCalls ?? false} repCanCreateSims={repCanCreateSims ?? false} />
    </div>
  );
};

export default OrganizationTab;
