import { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogType, Tabs, TabsType } from '../../shared';
import { AITwinTab, CustomSimulationTab } from './CreateTabs';

enum CreateTab {
  CUSTOM_SIMULATION = 'CUSTOM_SIMULATION',
  AI_TWIN = 'AI_TWIN',
  SCORECARD = 'SCORECARD',
}

interface CreateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateModal = ({ isOpen, onClose }: CreateModalProps) => {
  const [activeTab, setActiveTab] = useState(CreateTab.CUSTOM_SIMULATION);

  const tabs = useMemo(
    () => [
      {
        id: CreateTab.CUSTOM_SIMULATION,
        title: 'Custom simulation',
        content: <CustomSimulationTab onClose={onClose} />,
      },
      {
        id: CreateTab.AI_TWIN,
        title: 'AI twin',
        content: <AITwinTab onClose={onClose} />,
      },
    ],
    [onClose]
  );

  // Reset active tab when modal is opened.
  useEffect(() => {
    if (isOpen) {
      setActiveTab(CreateTab.CUSTOM_SIMULATION);
    }
  }, [isOpen]);

  return (
    <Dialog isOpen={isOpen} title="Create simulation" onClose={onClose} type={DialogType.SETTINGS}>
      <Tabs
        activeTab={activeTab}
        tabs={tabs}
        setActiveTab={(newActiveTab) => setActiveTab(newActiveTab as CreateTab)}
        type={TabsType.LIFTED}
      />
    </Dialog>
  );
};

export default CreateModal;
