import { ApiSuccess, ApiTagTypes, InviteUserPayload } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    inviteUser: builder.mutation<ApiSuccess, InviteUserPayload>({
      query: ({ ...inviteData }) => ({
        url: '/invite',
        method: 'POST',
        body: inviteData,
      }),
      invalidatesTags: [ApiTagTypes.INVITES],
    }),
    revokeInvitation: builder.mutation<ApiSuccess, { id: string }>({
      query: ({ id }) => ({
        url: `/invite/${id}/revoke`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.INVITES],
    }),
  }),
});

export const { useInviteUserMutation, useRevokeInvitationMutation } = enhancedApiSlice;
