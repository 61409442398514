import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { apiSlice } from '../../services';
import {
  authReducer,
  connectionReducer,
  gongReducer,
  modalReducer,
  prospectReducer,
  reviewReducer,
  scorecardTemplateReducer,
  mediaPlayerReducer,
  simulationsReducer,
} from '../reducers';
import { PERSIST_CONFIG } from './store.constants';

const rootReducer = combineReducers({
  auth: authReducer,
  connection: connectionReducer,
  gong: gongReducer,
  modal: modalReducer,
  prospect: prospectReducer,
  review: reviewReducer,
  scorecardTemplate: scorecardTemplateReducer,
  mediaPlayer: mediaPlayerReducer,
  simulations: simulationsReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(PERSIST_CONFIG, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

// Enable automated refetching of queries on certain actions, like focus or network reconnection.
setupListeners(store.dispatch);
