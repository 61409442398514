import { ComputedCell, ResponsiveHeatMap } from '@nivo/heatmap';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { truncateText } from '../../../../utils';
import { AnalyticsTab, AnalyticsFilterKeys, HeatMapData, HeatMapDataPoint } from '../../../../types';
import {
  ANALYTICS_ACTIVE_TAB_PARAM,
  HEAT_MAP_LABEL_WIDTH,
  HEAT_MAP_CELL_GAP,
  HEAT_MAP_LABEL_RIGHT_PADDING,
} from '../../../../constants';
import HeatMapTooltip from './HeatMapTooltip';
import HeatMapYLabel from './HeatMapYLabel';
import HeatMapFieldsDropdown from './HeatMapFieldsDropdown';
import { HeatMapProps } from './HeatMap.types';

const CHART_MARGIN = 60;
const CELL_SIZE = 32;
const CELL_PADDING = 0.3;
const CELL_BORDER_WIDTH = 1;
const CELL_BORDER_RADIUS = 2;

const HeatMap = ({ data, selectedFields, setSelectedFields, handleUpdateDefaultMetrics }: HeatMapProps) => {
  const [isFieldsDropdownOpen, setIsFieldsDropdownOpen] = useState(false);
  const [collapsedTeamIds, setCollapsedTeamIds] = useState<string[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const toggleCollapse = useCallback((teamId: string) => {
    setCollapsedTeamIds((prev) => {
      if (prev.includes(teamId)) {
        return prev.filter((id) => id !== teamId);
      } else {
        return [...prev, teamId];
      }
    });
  }, []);

  // Flatten the data to have a single array of data points
  const flattenedData = useMemo(() => {
    return data.reduce<HeatMapData[]>((acc, teamPerformanceData) => {
      acc.push(teamPerformanceData);
      if (teamPerformanceData.rows?.length && !collapsedTeamIds.includes(teamPerformanceData.id)) {
        acc.push(...teamPerformanceData.rows);
      }
      return acc;
    }, []);
  }, [data, collapsedTeamIds]);

  // Calculate the number of unique x values
  const uniqueColumns = useMemo(() => {
    const xValuesSet = new Set<string>();
    flattenedData.forEach((row) => {
      row.data.forEach((point) => {
        xValuesSet.add(point.x);
      });
    });
    return xValuesSet.size;
  }, [flattenedData]);

  const CELL_SIZE_WITH_PADDING = CELL_SIZE + HEAT_MAP_CELL_GAP;

  // Calculate width based on number of columns
  // Number of unique x values + 1 for the dropdown column
  const calculatedWidth =
    (uniqueColumns + 1) * CELL_SIZE_WITH_PADDING + HEAT_MAP_LABEL_RIGHT_PADDING + HEAT_MAP_LABEL_WIDTH;

  // Calculate height based on number of rows
  const calculatedHeight = flattenedData.length * CELL_SIZE_WITH_PADDING + CHART_MARGIN + HEAT_MAP_LABEL_WIDTH;
  // Get the team or user data for the given id
  const getTeamOrUserData = useCallback((id: string) => flattenedData.find((d) => d.id === id), [flattenedData]);

  const handleDataPointClick = useCallback(
    (cell: ComputedCell<HeatMapDataPoint>) => {
      const data = getTeamOrUserData(cell.serieId);
      const { id: rowId, rows } = data || {};
      const isTeam = !!rows?.length;

      // If the row is a team, return
      // Because we don't have teams filter in progress report
      if (isTeam) return;

      // Get the user id from the row id
      const userId = rowId?.split('user-')[1];

      setSearchParams({
        ...Object.fromEntries(searchParams.entries()), // Keep filter params
        [ANALYTICS_ACTIVE_TAB_PARAM]: AnalyticsTab.PROGRESS_REPORT,
        [AnalyticsFilterKeys.USER]: userId,
      } as Record<string, string>);
    },
    [searchParams, setSearchParams, getTeamOrUserData]
  );

  return (
    <div style={{ height: calculatedHeight, width: calculatedWidth, margin: '0 auto' }}>
      <ResponsiveHeatMap
        data={flattenedData}
        onClick={handleDataPointClick}
        margin={{
          top: HEAT_MAP_LABEL_WIDTH + HEAT_MAP_CELL_GAP,
          left: HEAT_MAP_LABEL_WIDTH + HEAT_MAP_CELL_GAP,
          bottom: CHART_MARGIN,
        }}
        axisTop={{
          tickSize: 0,
          tickPadding: 8,
          tickRotation: -90,
          format: (value) => truncateText(value, HEAT_MAP_LABEL_WIDTH),
        }}
        axisLeft={{
          renderTick: (props) => (
            <HeatMapYLabel
              {...props}
              data={getTeamOrUserData(props.value)}
              collapsedTeamIds={collapsedTeamIds}
              onToggleCollapse={toggleCollapse}
            />
          ),
        }}
        axisRight={{
          renderTick: (props) => (
            <HeatMapFieldsDropdown
              {...props}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
              isFieldsDropdownOpen={isFieldsDropdownOpen}
              setIsFieldsDropdownOpen={setIsFieldsDropdownOpen}
              handleUpdateDefaultMetrics={handleUpdateDefaultMetrics}
            />
          ),
        }}
        enableLabels={false}
        forceSquare
        hoverTarget="row"
        xInnerPadding={CELL_PADDING}
        yInnerPadding={CELL_PADDING}
        borderWidth={CELL_BORDER_WIDTH}
        borderRadius={CELL_BORDER_RADIUS}
        emptyColor="transparent"
        tooltip={(props) => <HeatMapTooltip {...props} data={getTeamOrUserData(props.cell.serieId)} />}
        borderColor="oklch(var(--b1))"
        colors={({ data }) => data.color || 'transparent'}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: 'oklch(var(--nc))',
                fontSize: 12,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default HeatMap;
