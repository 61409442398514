import { ApiTagTypes, PromptTemplate, PromptTemplatesParams, PromptTemplatesResponse } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPromptTemplates: builder.query<PromptTemplate[], PromptTemplatesParams>({
      query: (params) => ({
        url: '/prompt-templates',
        params,
      }),
      transformResponse: (response: PromptTemplatesResponse) => response.data.promptTemplates,
      providesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
  }),
});

export const { useGetPromptTemplatesQuery } = enhancedApiSlice;
