import clsx from 'clsx';
import { ComponentSize, TextColor } from '../../../types';
import { SPINNER_SIZE } from './Spinner.constants';
import { SpinnerProps } from './Spinner.types';

const Spinner = ({ className, color = TextColor.PRIMARY, size = ComponentSize.LARGE }: SpinnerProps) => {
  return <div className={clsx('loading loading-spinner', color, SPINNER_SIZE[size], className)} />;
};

export default Spinner;
