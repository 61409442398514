import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import { Permissions, ProspectCategory, ProspectPageTabs, ProspectType } from '../../../types';
import { Dialog } from '../../shared';
import SimulationCategoryStep, { SimulationDesignerOption } from './SimulationCategoryStep';
import SimulationTypeStep from './SimulationTypeStep';

const DESIGNER_MODAL_WIDTH = 485;

enum SimulationDesignerStep {
  CATEGORY = 'CATEGORY', // First step: user picks template or new prospect category.
  TYPE = 'TYPE', // Second step: user picks prospect type (if not template).
}

interface SimulationDesignerModalProps {
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
}

const SimulationDesignerModal = ({ setVisitedTabs }: SimulationDesignerModalProps) => {
  const navigate = useNavigate();

  const { user, organization } = useAppSelector((state) => state.auth);
  const permissions = user?.permissions || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);

  const [currentStep, setCurrentStep] = useState<SimulationDesignerStep>(SimulationDesignerStep.CATEGORY);

  const [newCategory, setNewCategory] = useState<ProspectCategory>();
  const [newType, setNewType] = useState<ProspectType>();

  const [targetOrgId, setTargetOrgId] = useState(isSuperAdmin ? undefined : organization?.id);
  // This state is initialized here so it would persist between steps if the user decides to go back.
  const [selectedDesignerOption, setSelectedDesignerOption] = useState(SimulationDesignerOption.TEMPLATE);

  // Exits designer mode by navigating to the practice page,
  // since the designer modal only opens in the /prospect page.
  const onCancel = useCallback(() => {
    navigate(AppRoutes.SIMULATIONS);
  }, [navigate]);

  return (
    <Dialog isOpen title="Simulation designer" width={DESIGNER_MODAL_WIDTH} onClose={onCancel}>
      {currentStep === SimulationDesignerStep.CATEGORY && (
        <SimulationCategoryStep
          isSuperAdmin={isSuperAdmin}
          newCategory={newCategory}
          targetOrgId={targetOrgId}
          selectedDesignerOption={selectedDesignerOption}
          goToNextStep={() => setCurrentStep(SimulationDesignerStep.TYPE)}
          onCancel={onCancel}
          setNewCategory={setNewCategory}
          setTargetOrgId={setTargetOrgId}
          setSelectedDesignerOption={setSelectedDesignerOption}
          setVisitedTabs={setVisitedTabs}
        />
      )}
      {currentStep === SimulationDesignerStep.TYPE && (
        <SimulationTypeStep
          targetOrgId={targetOrgId}
          newCategory={newCategory}
          newType={newType}
          goToPrevStep={() => setCurrentStep(SimulationDesignerStep.CATEGORY)}
          setNewType={setNewType}
        />
      )}
    </Dialog>
  );
};

export default SimulationDesignerModal;
