import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ButtonColor,
  CreateModal,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  Icon,
  TextButton,
} from '../../../components';
import { AppRoutes } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import { ComponentSize, DropdownPlacement, Permissions } from '../../../types';

const CREATE_DROPDOWN_MIN_WIDTH = 150;

interface CreateButtonProps {
  repCanCreateSims: boolean;
}

const CreateButton = ({ repCanCreateSims }: CreateButtonProps) => {
  const navigate = useNavigate();
  const permissions = useAppSelector((state) => state.auth.user?.permissions) || [];

  const canCreateSims = permissions.includes(Permissions.MANAGE_PROSPECT) || repCanCreateSims;
  const canModifyScorecardTemplates = permissions.includes(Permissions.MODIFY_SCORECARD_TEMPLATE);
  const canAccessCreate = canCreateSims || canModifyScorecardTemplates;

  const [isCreateDropdownOpen, setIsCreateDropdownOpen] = useState(false);
  const [isSimulationModalOpen, setIsSimulationModalOpen] = useState(false);

  return (
    <>
      <Dropdown
        minWidth={CREATE_DROPDOWN_MIN_WIDTH}
        open={isCreateDropdownOpen}
        placement={DropdownPlacement.BOTTOM_END}
        onOpenChange={setIsCreateDropdownOpen}
      >
        <DropdownTrigger asChild>
          <TextButton
            text="Create"
            startIcon={canAccessCreate ? Icon.WAND : Icon.LOCK}
            color={ButtonColor.AI}
            size={ComponentSize.MEDIUM}
            disabled={!canAccessCreate}
            onClick={() => setIsCreateDropdownOpen(true)}
          />
        </DropdownTrigger>
        <DropdownContent>
          {canCreateSims && (
            <DropdownItem
              label="Simulation"
              onClick={() => {
                setIsSimulationModalOpen(true);
                setIsCreateDropdownOpen(false);
              }}
            />
          )}
          {canModifyScorecardTemplates && (
            <DropdownItem
              label="Scorecard"
              onClick={() => {
                navigate(AppRoutes.SCORECARD_TEMPLATES);
              }}
            />
          )}
        </DropdownContent>
      </Dropdown>
      <CreateModal isOpen={isSimulationModalOpen} onClose={() => setIsSimulationModalOpen(false)} />
    </>
  );
};

export default CreateButton;
