import { forwardRef } from 'react';
import { BaseSelect } from '../BaseSelect';
import { SingleSelectProps } from './SingleSelect.types';

/**
 * SingleSelect is a dropdown component that allows users to select a single option.
 * It extends the BaseSelect component and adds specific logic for single-select behavior.
 */
const SingleSelect = forwardRef<HTMLDivElement, SingleSelectProps>(
  ({ clearable, loading, options, searchProps, selected, onChange, ...props }, ref) => {
    const filteredOptions = searchProps
      ? options.filter((option) => option.label.toLowerCase().includes(searchProps.searchValue.toLowerCase()))
      : options;

    const parsedOptions = filteredOptions.map((option) => ({ ...option, active: option.value === selected }));

    const selectedOption = parsedOptions.find((option) => option.value === selected);

    return (
      <BaseSelect
        {...props}
        ref={ref}
        clearable={clearable && !!selected}
        loading={loading}
        options={parsedOptions}
        selected={selected}
        displayValue={loading ? 'Loading...' : selectedOption?.label || ''}
        displayValueColor={selectedOption?.color}
        handleOptionSelection={onChange}
        handleClear={() => onChange(undefined)}
        searchProps={searchProps}
      />
    );
  }
);

SingleSelect.displayName = 'SingleSelect';

export default SingleSelect;
