import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AlertType,
  ButtonColor,
  Icon,
  Select,
  TextButton,
  TextInput,
  Typography,
  TypographySize,
} from '../../../../components';
import { DEFAULT_LANGUAGE, LANGUAGES, VOICE_OPTIONS } from '../../../../constants';
import { TimerMode, useAppDispatch, useAppSelector, useTimer, useToast } from '../../../../hooks';
import { randomizeVoice, setFirstMessage, setLanguage, setPersonaVoiceId } from '../../../../redux/reducers';
import { Language, TextColor } from '../../../../types';
import { conditionalArray, formatSecondsToDuration, snakeCaseToLabel } from '../../../../utils';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const AUDIO_UPDATE_INTERVAL_DELAY = 1000;

const Voice = () => {
  const [audioCurrTime, setAudioCurrTime] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const [isAudioError, setIsAudioError] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [previewAudio, setPreviewAudio] = useState<HTMLAudioElement | undefined>(undefined);

  const { organization } = useAppSelector((state) => state.auth);
  const isMultiLingualEnabled = !!organization?.isMultiLingualEnabled;

  const languageOptions = useMemo(
    () =>
      LANGUAGES.map((language) => ({
        disabled: language.value !== DEFAULT_LANGUAGE.value && !isMultiLingualEnabled,
        label: `${language.flag} ${snakeCaseToLabel(language.value)}`,
        value: language.value,
      })),
    [isMultiLingualEnabled]
  );

  const dispatch = useAppDispatch();
  const {
    fields: { language, personaVoiceId, firstMessage },
  } = useAppSelector((state) => state.prospect);

  const { showToast } = useToast();
  // Manages the interval that updates the preview audio time.
  const { startTimer: startInterval, stopTimer: stopInterval } = useTimer({
    callback: () => setAudioCurrTime((prev) => prev + 1),
    delay: AUDIO_UPDATE_INTERVAL_DELAY,
    mode: TimerMode.INTERVAL,
  });

  const parsedVoiceOptions = useMemo(() => {
    const languageVoiceOptions = VOICE_OPTIONS[language];
    const isUnrecognizedVoice =
      !!personaVoiceId.value && !languageVoiceOptions.find((option) => option.value === personaVoiceId.value);
    return [
      ...conditionalArray(isUnrecognizedVoice, { label: 'Unrecognized voice', value: personaVoiceId.value || '' }),
      ...languageVoiceOptions,
    ];
  }, [personaVoiceId.value, language]);

  const handlePlayPreview = useCallback(async () => {
    if (!previewAudio) return;
    try {
      await new Promise((resolve) => {
        previewAudio.play();
        setIsAudioPlaying(true);
        startInterval();

        previewAudio.onended = () => {
          setIsAudioPlaying(false);
          setAudioCurrTime(0);
          stopInterval();
          resolve(true);
        };
      });
    } catch (error) {
      showToast({ message: 'Failed to play preview audio', type: AlertType.ERROR });
      console.error('Failed to play preview audio', error);
    }
  }, [previewAudio, showToast, startInterval, stopInterval]);

  const voiceFields = useMemo(
    () => [
      {
        fullWidth: true,
        label: 'Language',
        required: true,
        content: (
          <Select
            options={languageOptions}
            selected={language}
            onChange={(value) => value && dispatch(setLanguage(value as Language))}
          />
        ),
      },
      {
        fullWidth: true,
        label: 'Voice',
        required: true,
        content: (
          <Select
            clearable // Clearable to allow randomizing.
            options={parsedVoiceOptions}
            selected={personaVoiceId.value}
            onChange={(value) => dispatch(setPersonaVoiceId(value))}
          />
        ),
        onRandomize: () => dispatch(randomizeVoice()),
      },
      {
        label: 'Preview audio',
        content: (
          <div className="flex items-center gap-2">
            <TextButton
              disabled={!previewAudio || isAudioPlaying}
              color={ButtonColor.SECONDARY}
              startIcon={Icon.PLAY}
              text={`${formatSecondsToDuration(audioCurrTime)} / ${formatSecondsToDuration(audioDuration)}`}
              onClick={handlePlayPreview}
            />
            {isAudioError && (
              <Typography color={TextColor.DESTRUCTIVE} size={TypographySize.CAPTION}>
                No preview exists for this voice.
              </Typography>
            )}
          </div>
        ),
      },
    ],
    [
      audioCurrTime,
      audioDuration,
      isAudioError,
      isAudioPlaying,
      parsedVoiceOptions,
      personaVoiceId.value,
      language,
      previewAudio,
      handlePlayPreview,
    ]
  );

  // Load the preview audio when the voice changes.
  useEffect(() => {
    // Reset the audio state.
    setAudioDuration(0);
    setIsAudioError(false);
    setPreviewAudio(undefined);

    // If the preview is playing, pause it before loading a new one.
    if (previewAudio && isAudioPlaying) {
      previewAudio.pause();
      setIsAudioPlaying(false);
      setAudioCurrTime(0);
      stopInterval();
    }

    // If no new voice is set, return.
    if (!personaVoiceId.value) return;

    // Load the audio from the public bucket.
    const url = `${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/tts-voice-previews/${personaVoiceId.value}.mp3`;
    const audio = new Audio(url);

    // Error event listener to handle unsupported source errors.
    audio.onerror = () => {
      setIsAudioError(true);
    };

    // Set the audio only if metadata is successfully loaded.
    audio.onloadedmetadata = () => {
      setAudioDuration(audio.duration);
      setPreviewAudio(audio);
    };
  }, [personaVoiceId.value, stopInterval]);

  useEffect(() => {
    // Cleanup function to pause the preview audio when the component unmounts.
    return () => {
      if (previewAudio && isAudioPlaying) {
        previewAudio.pause();
      }
    };
  }, [personaVoiceId.value, previewAudio, isAudioPlaying]);

  return (
    <TabSection title="Voice">
      <ProspectFieldGroup fields={voiceFields} />
      <ProspectPageField
        label="First message"
        required
        content={<TextInput value={firstMessage.value} onChange={(e) => dispatch(setFirstMessage(e.target.value))} />}
      />
    </TabSection>
  );
};

export default Voice;
