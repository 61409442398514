import { ColumnDef, PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector, useHandleApiResponse } from '../../../hooks';
import { closeGongCallsModal } from '../../../redux/reducers';
import { useGetGongCallsMutation, useImportGongCallsMutation } from '../../../services';
import {
  ComponentSize,
  DateFormat,
  FilePurpose,
  GongCall,
  ProspectCategory,
  ProspectType,
  TextColor,
  TimeFormat,
} from '../../../types';
import { formatSecondsToDuration, isValidURL } from '../../../utils';
import { DataTable, DatePickerRange, Dialog, Icon, Icons, TextButton, Typography, TypographySize } from '../../shared';
import CallInfoCell from './CallInfoCell';
import GongCallsFilters from './GongCallsFilters';
import GongSuccessModal from './GongSuccessModal';

const GONG_MODAL_WIDTH = 900;
const GONG_CALLS_ITEMS_PER_PAGE = 100;

const ERROR_MSG = 'Failed to create prospects';

const GongCallsModal = () => {
  // Redux
  const dispatch = useAppDispatch();
  const isGongCallsModalOpen = useAppSelector((state) => state.modal.isGongCallsModalOpen);

  // Custom hooks
  const handleApiResponse = useHandleApiResponse(true);

  // State
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<DatePickerRange>();

  const [searchCallId, setSearchCallId] = useState<string | undefined>(undefined);
  const [searchError, setSearchError] = useState<string>('');

  const [selectedCallId, setSelectedCallId] = useState<string | undefined>(undefined);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [cursors, setCursors] = useState<string[]>([]);
  const [currentCursorIndex, setCurrentCursorIndex] = useState(-1);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: GONG_CALLS_ITEMS_PER_PAGE,
  });

  // Mutations
  const [getGongCalls, { data: gongCalls, isLoading: isLoadingGongCalls }] = useGetGongCallsMutation();
  const [importGongCalls, { isLoading: isImportingCalls }] = useImportGongCallsMutation();

  const calls = gongCalls?.calls || [];
  const totalPages = gongCalls?.pagination?.totalPages || 0;

  // Search call ID can be a call ID or a sharable call link
  const extractCallId = useCallback(() => {
    if (!searchCallId) return;

    // If the search call ID is a valid URL, extract the call ID from the URL
    if (isValidURL(searchCallId)) {
      const url = new URL(searchCallId);
      const callId = url.searchParams.get('id');
      // If the call ID is not found, set the search error
      if (!callId) {
        setSearchError('Invalid call link');
        return undefined;
      }
      return callId;
    }
    // If the search call ID is not a valid URL, return the search call ID directly
    return searchCallId;
  }, [searchCallId]);

  // Filters to pass to the getGongCalls mutation
  const filters = useMemo(
    () => ({
      userIds: selectedParticipants.length ? selectedParticipants : undefined,
      startTime: selectedDate,
      callId: extractCallId(),
    }),
    [selectedParticipants, selectedDate, extractCallId]
  );

  // Get the Gong calls
  useEffect(() => {
    if (!isGongCallsModalOpen) return;
    getGongCalls(filters);
  }, [getGongCalls, filters, isGongCallsModalOpen]);

  // Reset state when modal closes
  useEffect(() => {
    if (!isGongCallsModalOpen) {
      setSelectedCallId(undefined);
      setSelectedParticipants([]);
      setSelectedDate(undefined);
    }
  }, [isGongCallsModalOpen]);

  const handleImportCalls = useCallback(async () => {
    try {
      if (!selectedCallId) return;

      const response = await importGongCalls({
        gongCallIds: [selectedCallId],
        purpose: FilePurpose.PROSPECT_CREATION,
        prospectCategory: ProspectCategory.PRACTICE,
        prospectType: ProspectType.PROSPECTING,
      });
      handleApiResponse({
        response,
        errorMsg: ERROR_MSG,
        onSuccess: () => setIsSuccessModalOpen(true),
      });
    } catch (error) {
      console.error(error);
    }
  }, [importGongCalls, handleApiResponse, selectedCallId]);

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setSelectedCallId(undefined);
  };

  const resetPagination = () => {
    // Reset pagination state and fetch first page
    setCursors([]);
    setCurrentCursorIndex(-1);
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      resetPagination();
    }
    // We want this to execute only on filters change
  }, [filters]);

  // Handle pagination controls
  const handleNextPage = useCallback(() => {
    const nextCursor = gongCalls?.pagination?.cursor;
    if (!nextCursor) return;

    // Add new cursor to our cursors array
    // To be able to fetch previous pages
    setCursors((prev) => [...prev, nextCursor]);
    // Update current cursor index
    setCurrentCursorIndex((prev) => prev + 1);
    // Update pagination state
    setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
    // Fetch next page with cursor
    getGongCalls({
      ...filters,
      cursor: nextCursor,
    });
  }, [filters, gongCalls?.pagination?.cursor]);

  const handlePrevPage = useCallback(() => {
    if (currentCursorIndex < 0) {
      resetPagination();
      return;
    }

    // Update pagination state
    setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
    // Update current cursor index
    setCurrentCursorIndex((prev) => prev - 1);
    // Fetch previous page with cursor
    getGongCalls({
      ...filters,
      cursor: cursors[currentCursorIndex - 1],
    });
  }, [currentCursorIndex, cursors, filters]);

  const parsedData = useMemo(
    () =>
      calls.map((call) => ({
        ...call,
        onClick: () => {
          setSelectedCallId((prevSelectedCallId) => (prevSelectedCallId === call.id ? undefined : call.id));
        },
      })),
    [calls]
  );

  const activeCallIndex = useMemo(() => calls.findIndex((call) => call.id === selectedCallId), [calls, selectedCallId]);

  // Define columns for the data table.
  const columns: ColumnDef<GongCall>[] = useMemo(
    () => [
      {
        accessorKey: 'callInfo',
        size: 4 / 10,
        header: 'Call info',
        cell: ({ row }) => <CallInfoCell call={row.original} />,
      },
      {
        accessorKey: 'duration',
        size: 2.5 / 10,
        header: 'Duration',
        cell: ({ row }) => formatSecondsToDuration(row.original.duration),
      },
      {
        accessorKey: 'callDate',
        header: 'Call date',
        size: 2.5 / 10,
        cell: ({ row }) => {
          const date = row.original?.callDate;
          return (
            <div className="flex flex-col gap-1">
              <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION}>
                {dayjs(date).format(TimeFormat.SHORT_TIME)}
              </Typography>
              <Typography size={TypographySize.CAPTION}>{dayjs(date).format(DateFormat.MONTH_DAY)}</Typography>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Dialog
        isOpen={isGongCallsModalOpen}
        onClose={() => dispatch(closeGongCallsModal())}
        title="Import from Gong"
        icon={<Icons icon={Icon.GONG} color="text-transparent" preserveSize />}
        width={GONG_MODAL_WIDTH}
        className="h-full"
      >
        <div className="flex h-full flex-col items-end justify-between gap-4">
          <div className="flex h-[calc(100%-64px)] w-full gap-4">
            <div className="flex-1">
              <GongCallsFilters
                selectedParticipants={selectedParticipants}
                setSelectedParticipants={setSelectedParticipants}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                searchCallId={searchCallId}
                setSearchCallId={setSearchCallId}
                searchError={searchError}
                setSearchError={setSearchError}
              />
            </div>
            <DataTable
              columns={columns}
              data={parsedData}
              isLoading={isLoadingGongCalls}
              activeRowIndices={[activeCallIndex]}
              paginationControls={{
                pagination,
                setPagination,
                totalPages,
                onNextClick: handleNextPage,
                onPrevClick: handlePrevPage,
              }}
            />
          </div>
          <TextButton
            text="Create"
            onClick={handleImportCalls}
            size={ComponentSize.MEDIUM}
            loading={isImportingCalls}
            disabled={!selectedCallId}
          />
        </div>
      </Dialog>
      <GongSuccessModal isOpen={isSuccessModalOpen} onClose={handleCloseSuccessModal} />
    </>
  );
};

export default GongCallsModal;
