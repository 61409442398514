import { ApiSuccess, ApiTagTypes, CreatePromptTemplatePayload, UpdatePromptTemplatePayload } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    archivePromptTemplate: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prompt-templates/${id}/archive`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
    createPromptTemplate: builder.mutation<ApiSuccess, CreatePromptTemplatePayload>({
      query: (payload) => ({
        url: '/prompt-templates',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
    updatePromptTemplate: builder.mutation<ApiSuccess, UpdatePromptTemplatePayload>({
      query: ({ id, ...payload }) => ({
        url: `/prompt-templates/${id}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
    duplicatePromptTemplate: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prompt-templates/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
    deletePromptTemplate: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prompt-templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
  }),
});

export const {
  useArchivePromptTemplateMutation,
  useCreatePromptTemplateMutation,
  useUpdatePromptTemplateMutation,
  useDuplicatePromptTemplateMutation,
  useDeletePromptTemplateMutation,
} = enhancedApiSlice;
