import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { SelectOption } from '../components';
import {
  NEUTRAL_FIRST_NAMES,
  PRONOUNS_TO_FIRST_NAMES,
  PROSPECT_AGE_VARIANCE,
  PROSPECT_AVERAGE_AGE,
  PROSPECT_MAX_AGE,
  PROSPECT_MAX_NUM_OF_DIRECT_REPORTS,
  PROSPECT_MAX_TENURE,
  PROSPECT_MIN_AGE,
  PROSPECT_MIN_NUM_OF_DIRECT_REPORTS,
  PROSPECT_MIN_TENURE,
  PROSPECT_NUM_OF_DIRECT_REPORTS_AVERAGE,
  PROSPECT_NUM_OF_DIRECT_REPORTS_VARIANCE,
  PROSPECT_SLIDER_LIMITS,
  PROSPECT_TENURE_AVERAGE,
  PROSPECT_TENURE_VARIANCE,
} from '../constants';
import { AppDispatch } from '../redux/store';
import {
  BasePracticeProspect,
  PracticeProspect,
  Pronouns,
  ProspectCategory,
  ProspectPageMode,
  Tag,
  TimePeriod,
} from '../types';
import { getRandomElement, getRandomElements } from './object.utils';
import { parseStringToNumber } from './string.utils';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Converts an API prospect response to the app's prospect format.
 * Transforms the nested tags array structure from the API ({ tag: Tag }[])
 * into a flat array of Tag objects (Tag[]) used throughout the app.
 */
export const parseProspect = <T extends { tags: { tag: Tag }[] }>(prospect: T): T & { tags: Tag[] } => ({
  ...prospect,
  tags: prospect.tags.map((tag) => tag.tag),
});

/**
 * Helper function to generate a normally distributed random number using Box-Muller transform
 * and clamps the result between the min and max values.
 */
const generateNormalRandom = (average: number, max: number, min: number, variance: number) => {
  const u1 = Math.random();
  const u2 = Math.random();
  const z = Math.sqrt(-2 * Math.log(u1)) * Math.cos(2 * Math.PI * u2);
  const scaledZ = Math.round(z * variance + average);
  return Math.max(min, Math.min(max, scaledZ));
};

/** Generates a random slider value. */
export const getRandomSliderValue = () => {
  const { max, min, step } = PROSPECT_SLIDER_LIMITS;
  // Range of values.
  const range = max - min;
  // Generate a random value within the range.
  const randomValue = min + Math.random() * range;
  // Round to the nearest step.
  const roundedValue = Math.round(randomValue / step) * step;
  // Determine the number of decimal places in the step.
  const decimalPlaces = step.toString().split('.')[1]?.length || 0;
  // Return the rounded value formatted to the same number of decimal places as the step.
  return parseFloat(roundedValue.toFixed(decimalPlaces));
};

/**
 * Generates a random first name based on the given pronouns.
 * @param pronouns Optional pronouns to filter names by
 * @param excludeName Optional name to exclude from selection
 */
export const getRandomFirstName = (pronouns?: Pronouns, excludeName?: string) => {
  const firstNames = pronouns ? PRONOUNS_TO_FIRST_NAMES[pronouns] : NEUTRAL_FIRST_NAMES;
  const availableNames = excludeName ? firstNames.filter((name) => name !== excludeName) : firstNames;
  return getRandomElement(availableNames);
};

/** Generates a random age between the min and max ages. */
export const getRandomAge = () => {
  return generateNormalRandom(PROSPECT_AVERAGE_AGE, PROSPECT_MAX_AGE, PROSPECT_MIN_AGE, PROSPECT_AGE_VARIANCE);
};

/** Generates a random tenure between the min and max tenures. */
export const getRandomTenure = () => {
  return generateNormalRandom(
    PROSPECT_TENURE_AVERAGE,
    PROSPECT_MAX_TENURE,
    PROSPECT_MIN_TENURE,
    PROSPECT_TENURE_VARIANCE
  );
};

/** Generates a random number of direct reports between the min and max number of direct reports. */
export const getRandomNumOfDirectReports = () => {
  return generateNormalRandom(
    PROSPECT_NUM_OF_DIRECT_REPORTS_AVERAGE,
    PROSPECT_MAX_NUM_OF_DIRECT_REPORTS,
    PROSPECT_MIN_NUM_OF_DIRECT_REPORTS,
    PROSPECT_NUM_OF_DIRECT_REPORTS_VARIANCE
  );
};

/** Determines if an item can be randomized. */
export const canRandomizeItem = <T>(mode: ProspectPageMode, item: { value?: T; isUserModified?: boolean }) => {
  return (mode === ProspectPageMode.CREATE && !item.isUserModified) || item.value === undefined;
};

/** Generates a random set of scenario items. */
export const getRandomScenarioItems = (
  allItems: string[],
  currentItems: { value: string; isUserModified?: boolean }[],
  maxRandomItems?: number
) => {
  // Get the items that the user has modified.
  const userModified = currentItems.filter((p) => p.isUserModified);

  // Calculate how many more items we need to add to reach the maximum number of randomly generated items.
  const numToAdd = (maxRandomItems ?? allItems.length) - userModified.length;

  // Determine the remaining items that we can randomize from.
  const remainingItems = allItems.filter((item) => !userModified.some((i) => i.value === item));

  // Get the random items.
  const randomItems = getRandomElements(remainingItems, numToAdd).map((value) => ({ value }));

  // Replace non-user-modified items with nulls to keep the user-modified items at the same index.
  const parsedItems = currentItems.map((item) => (item.isUserModified ? item : null));

  // Replace nulls with random items.
  const newItems = parsedItems
    .map((item) => item ?? randomItems.shift())
    .filter((item): item is NonNullable<typeof item> => item != null);

  // Return the new items with empty values removed.
  return [...newItems, ...randomItems].filter((item) => item.value);
};

export const parseProspectToOption = (prospect: BasePracticeProspect, includeJobTitle = false): SelectOption => ({
  label: `${prospect.firstName} ${prospect.lastName} | ${prospect.accountName} ${includeJobTitle && prospect.jobTitle ? `| ${prospect.jobTitle}` : ''}`,
  value: prospect.personaId,
});

/** Handles number input change by parsing the input value into a number and dispatching an action with the new value. */
export const handleNumberInputChange =
  (dispatch: AppDispatch, action: ActionCreatorWithOptionalPayload<number | undefined>) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseStringToNumber(e.target.value);
    dispatch(action(newValue));
  };

/** Returns the cooldown end time for a prospect. */
export const getCooldownEndTime = (prospect: Pick<PracticeProspect, 'cooldownPeriod' | 'lastCall'>) => {
  const { lastCall, cooldownPeriod } = prospect;
  const lastCallEndTime = lastCall?.endTime;

  if (!lastCallEndTime || !cooldownPeriod) return;

  const cooldownEndTime = dayjs(lastCallEndTime).add(cooldownPeriod, TimePeriod.MINUTE);
  return cooldownEndTime;
};

/** Determines if a prospect is in cooldown period. */
export const isProspectInCooldownPeriod = (
  prospect: Pick<PracticeProspect, 'cooldownPeriod' | 'lastCall'>
): boolean => {
  const cooldownEndTime = getCooldownEndTime(prospect);
  if (!cooldownEndTime) return false;

  const userTimezone = dayjs.tz.guess();
  const currentTime = dayjs().tz(userTimezone);

  return currentTime.isBefore(cooldownEndTime);
};

/** Determines if a prospect is locked based on the cooldown period and access control. */
export const isProspectLocked = (prospect: PracticeProspect): boolean => {
  // Locked prospects are only in quiz page
  if (prospect.category !== ProspectCategory.QUIZ) return false;
  return isProspectInCooldownPeriod(prospect) || prospect.isLocked;
};
