import { useState } from 'react';
import { ButtonColor, ButtonVariant, Icon, TextButton } from '../../../components';
import { useAppDispatch } from '../../../hooks';
import { startWebCall } from '../../../redux/reducers';
import { PracticeProspect, ProspectCategory, ProspectStatus } from '../../../types';
import { isProspectLocked } from '../../../utils';

interface ProspectCallCellProps {
  prospect: PracticeProspect;
}

const ProspectCallCell = ({ prospect }: ProspectCallCellProps) => {
  const dispatch = useAppDispatch();

  const [isHovered, setIsHovered] = useState(false);

  const isArchived = prospect.status === ProspectStatus.ARCHIVED;

  const isLocked = isProspectLocked(prospect);

  const isQuiz = prospect.category === ProspectCategory.QUIZ;

  const runCall = () => {
    dispatch(startWebCall({ prospect }));
  };

  const getStartIcon = () => {
    if (isArchived) return;
    if (isLocked) return Icon.LOCK;
    return Icon.PHONE;
  };

  const getButtonText = () => {
    if (isArchived) return 'Archived';
    if (isLocked) return 'Locked';
    if (isQuiz) return 'Start';
    return 'Call';
  };

  return (
    <TextButton
      startIcon={getStartIcon()}
      text={getButtonText()}
      color={isHovered ? ButtonColor.PRIMARY : ButtonColor.SECONDARY}
      variant={isHovered ? ButtonVariant.FILLED : ButtonVariant.OUTLINE}
      onClick={runCall}
      onHover={(hover) => setIsHovered(hover)}
      disabled={isArchived || isLocked}
    />
  );
};

export default ProspectCallCell;
