import clsx from 'clsx';
import { ComponentSize, TextColor } from '../../../types';
import { Tooltip } from '../Tooltip';
import { ICON_COLOR_TO_FILL_COLOR, ICON_COLOR_TO_STROKE_COLOR, ICON_MAP, ICON_SIZE } from './Icons.constants';
import { IconsProps } from './Icons.types';

const Icons = ({
  icon,
  className,
  color: strokeColorProp,
  fillColor: fillColorProp,
  size = ComponentSize.SMALL,
  tooltip,
  onClick,
  onHover,
  preserveSize,
}: IconsProps) => {
  const IconComponent = ICON_MAP[icon];
  const iconSize = typeof size === 'number' ? size : ICON_SIZE[size];

  if (!IconComponent) return null;

  const handleOnClick = onClick
    ? (e: React.MouseEvent<SVGElement>) => {
        e.stopPropagation();
        onClick();
      }
    : undefined;

  const getColorClassName = () => {
    if (!strokeColorProp && !fillColorProp) {
      return ICON_COLOR_TO_STROKE_COLOR[TextColor.PRIMARY];
    } else {
      const strokeColor = strokeColorProp ? ICON_COLOR_TO_STROKE_COLOR[strokeColorProp] : undefined;
      const fillColor = fillColorProp ? ICON_COLOR_TO_FILL_COLOR[fillColorProp] : undefined;
      return `${strokeColor ?? strokeColorProp ?? ''} ${fillColor ?? fillColorProp ?? ''}`;
    }
  };

  return (
    <Tooltip label={tooltip}>
      <IconComponent
        className={clsx(getColorClassName(), onClick && 'cursor-pointer', className)}
        style={preserveSize ? undefined : { width: iconSize, height: iconSize }} // Only apply size if not preserving original
        onClick={handleOnClick}
        onMouseEnter={() => onHover?.(true)}
        onMouseLeave={() => onHover?.(false)}
      />
    </Tooltip>
  );
};

export default Icons;
