import { TooltipType } from '../types';

/** Extracts the placement from a tooltip object. */
export const getTooltipPlacement = (tooltip: TooltipType) => {
  return tooltip && typeof tooltip === 'object' && 'placement' in tooltip ? tooltip.placement : undefined;
};

/** Extracts the content from a tooltip object. */
export const getTooltipContent = (tooltip: TooltipType) => {
  return tooltip && typeof tooltip === 'object' && 'content' in tooltip ? tooltip.content : tooltip;
};

/** Truncates text to fit within a given width. */
export const truncateText = (text: string, maxWidth: number): string => {
  // Create a temporary canvas for text measurement
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return text;

  // Gradually remove characters until text + ellipsis fits
  let truncated = text;
  while (truncated.length > 0 && context.measureText(truncated + '...').width > maxWidth) {
    truncated = truncated.slice(0, -1);
  }

  return truncated.length < text.length ? truncated + '...' : text;
};
