import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ComponentSize, TagColor } from '../../../types';
import { conditionalObject, convertToPx } from '../../../utils';
import { Dot } from '../Dot';
import { Icons } from '../Icons';
import { Tooltip } from '../Tooltip';
import { BADGE_ICON_SIZE, BADGE_SIZE } from './Badge.constants';
import { BadgeProps } from './Badge.types';
import { getBadgeColorConfig } from './Badge.utils';

const Badge = ({
  label,
  active,
  color,
  destructive,
  dotColor = TagColor.BLUE,
  showDot,
  startIcon,
  maxWidth,
  outline,
  size = ComponentSize.SMALL,
  endElement,
  onClick,
}: BadgeProps) => {
  const labelRef = useRef<HTMLDivElement>(null);

  const [hovered, setHovered] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const colors = getBadgeColorConfig(color, destructive, outline);
  const bgColor = active || hovered ? colors.hoverBgColor : colors.bgColor;
  const textColor = active || hovered ? colors.hoverTextColor : colors.textColor;

  const handleClick = onClick
    ? (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }
    : undefined;

  useEffect(() => {
    if (labelRef.current) {
      setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
    }
  }, [label, maxWidth]);

  return (
    <Tooltip label={label} disabled={!isOverflowing}>
      <div
        className={clsx(
          'badge flex items-center justify-center gap-2',
          !outline && 'border-none',
          bgColor,
          BADGE_SIZE[size]
        )}
        style={{ maxWidth: convertToPx(maxWidth) }}
        onMouseEnter={onClick ? () => setHovered(true) : undefined}
        onMouseLeave={onClick ? () => setHovered(false) : undefined}
      >
        <div
          className={clsx('flex items-center gap-2', onClick ? ' cursor-pointer' : 'pointer-events-none')}
          onClick={handleClick}
        >
          {showDot && <Dot color={dotColor} size={ComponentSize.X_SMALL} />}
          {startIcon && <Icons icon={startIcon} size={BADGE_ICON_SIZE[size]} color={textColor} />}
          <div
            ref={labelRef}
            className={clsx('whitespace-nowrap', textColor)}
            style={{
              ...conditionalObject(!!maxWidth, {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }),
            }}
          >
            {label}
          </div>
        </div>
        {endElement && endElement}
      </div>
    </Tooltip>
  );
};

export default Badge;
