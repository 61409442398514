import { Dispatch, SetStateAction, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonColor, ButtonGroup, TextButton } from '../../../components';
import { AppRoutes } from '../../../constants';
import { useAppDispatch, useAppSelector, useCheckRequiredProspectFields, useHandleApiResponse } from '../../../hooks';
import { setProspectForm } from '../../../redux/reducers';
import { useEditPracticeProspectMutation } from '../../../services';
import { Permissions, ProspectLastTouchpoint, UpdatedPracticeProspectData } from '../../../types';
import { conditionalObject } from '../../../utils';

const EDIT_PROSPECT_ERROR_MSG = 'Failed to save changes.';

function parseUndefinedValueToNull<T>(value?: T, disabled?: boolean): T | null {
  return disabled || value === undefined ? null : value;
}

interface EditEndActionsProps {
  isSuperAdmin: boolean;
  resetForm: () => void;
  runRegeneratePrompt: () => Promise<void>;
  setIsRegeneratePromptOpen: Dispatch<SetStateAction<boolean>>;
}

const EditEndActions = ({
  isSuperAdmin,
  resetForm,
  runRegeneratePrompt,
  setIsRegeneratePromptOpen,
}: EditEndActionsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { personaId, personaPrompt, fields } = useAppSelector((state) => state.prospect);
  const user = useAppSelector((state) => state.auth.user);
  const canManageProspect = (user?.permissions || []).includes(Permissions.MANAGE_PROSPECT);

  // Checks if all required fields are filled.
  const areRequiredFieldsFilled = useCheckRequiredProspectFields();
  const handleApiResponse = useHandleApiResponse();
  const isSaveDisabled = !areRequiredFieldsFilled();

  const [editPracticeProspect, { isLoading: isEditing }] = useEditPracticeProspectMutation();

  // Handles canceling the edit mode by resetting the edited values.
  const handleCancel = useCallback(() => {
    resetForm();
    navigate(AppRoutes.SIMULATIONS);
  }, [resetForm, navigate]);

  const onSaveSuccess = useCallback(
    (newData: UpdatedPracticeProspectData) => {
      dispatch(setProspectForm({ prospect: newData.prospect, canManageProspect }));
      if (isSuperAdmin) {
        // For super admins, we give them the option to regenerate or not regenerate the prompt.
        setIsRegeneratePromptOpen(true);
      } else {
        // For non-super admins, we regenerate the prompt automatically.
        // We will consolidate this into a single API call in the future.
        runRegeneratePrompt();
        navigate(AppRoutes.SIMULATIONS);
      }
    },
    [isSuperAdmin, canManageProspect, navigate, runRegeneratePrompt, setIsRegeneratePromptOpen]
  );

  const handleSaveChanges = useCallback(async () => {
    if (!personaId) return;
    try {
      const response = await editPracticeProspect({
        id: personaId,
        ...conditionalObject(!!personaPrompt.isUserModified, {
          // Only save the new prompt if it was edited by the user.
          personaPrompt: personaPrompt.value,
        }),

        // Personal
        firstName: fields.firstName.value,
        lastName: fields.lastName.value,
        age: parseUndefinedValueToNull(fields.age.value),
        pronouns: parseUndefinedValueToNull(fields.pronouns.value),
        talkativenessLevel: fields.talkativenessLevel.value,
        figurativeThinkingLevel: fields.figurativeThinkingLevel.value,
        empathyLevel: fields.empathyLevel.value,
        improvisationLevel: fields.improvisationLevel.value,
        personaVoiceId: fields.personaVoiceId.value,
        language: fields.language,
        firstMessage: fields.firstMessage.value,

        // Lead
        jobTitle: fields.jobTitle.value,
        tenure: parseUndefinedValueToNull(fields.tenure.value),
        roleType: fields.roleType.value,
        department: fields.department.value,
        numOfDirectReports: parseUndefinedValueToNull(fields.numOfDirectReports.value),

        // Account
        accountName: fields.accountName.value,
        industry: fields.industry.value,
        hqLocation: fields.hqLocation.value,
        remoteType: parseUndefinedValueToNull(fields.remoteType.value),
        isMultiSite: fields.isMultiSite.value,
        employeesFrom: parseUndefinedValueToNull(fields.employeesFrom.value),
        employeesTo: parseUndefinedValueToNull(fields.employeesTo, fields.employeesFrom.value === undefined),
        annualRevenueFrom: parseUndefinedValueToNull(fields.annualRevenueFrom.value),
        annualRevenueTo: parseUndefinedValueToNull(
          fields.annualRevenueTo,
          fields.annualRevenueFrom.value === undefined
        ),
        fundingRaisedType: parseUndefinedValueToNull(fields.fundingRaisedType.value),

        // Scenario
        priorities: fields.priorities.map((priority) => priority.value).filter((priority) => !!priority),
        isDynamicPriorities: fields.isDynamicPriorities,
        numOfDynamicPriorities: parseUndefinedValueToNull(fields.numOfDynamicPriorities),
        objections: fields.objections.map((objection) => objection.value).filter((objection) => !!objection),
        isDynamicObjections: fields.isDynamicObjections,
        numOfDynamicObjections: parseUndefinedValueToNull(fields.numOfDynamicObjections),
        questions: fields.questions.map((question) => question.value).filter((question) => !!question),
        isDynamicQuestions: fields.isDynamicQuestions,
        numOfDynamicQuestions: parseUndefinedValueToNull(fields.numOfDynamicQuestions),
        lastTouchpoint:
          fields.lastTouchpoint.value === ProspectLastTouchpoint.NONE ? null : fields.lastTouchpoint.value,
        successResult: fields.successResult.value,
        hiddenContexts: fields.hiddenContexts.map((context) => context.value).filter((context) => !!context),
        successDifficultyLevel: fields.successDifficultyLevel.value,

        // Configuration
        isHidden: fields.isHidden,
        ...conditionalObject(canManageProspect, {
          scorecardTemplateId: parseUndefinedValueToNull(fields.scorecardTemplateId),
          cooldownPeriod: parseUndefinedValueToNull(fields.cooldownPeriod),
          timeLimit: parseUndefinedValueToNull(fields.timeLimit),
          managerNotes: parseUndefinedValueToNull(fields.managerNotes),
        }),

        ...conditionalObject(isSuperAdmin, {
          isRestricted: fields.isRestricted,
          breathingRoom: parseUndefinedValueToNull(fields.breathingRoom),
          numOfInitialShortResponses: parseUndefinedValueToNull(fields.numOfInitialShortResponses),
          objectionRate: parseUndefinedValueToNull(fields.objectionRate),
          questionRate: parseUndefinedValueToNull(fields.questionRate),
          numOfRequiredUncoveredPriorities: parseUndefinedValueToNull(fields.numOfRequiredUncoveredPriorities),
          isHangupFeatureEnabled: fields.isHangupFeatureEnabled,
          hangupPrompt: fields.isHangupFeatureEnabled ? fields.hangupPrompt : null,
          buyNewPhoneNumber: fields.buyNewPhoneNumber,
          ttsModelId: fields.ttsModelId,
          sttModel: fields.sttModel,
          sttModelId: fields.sttModelId,
          llmModel: fields.llmModel,
          llmModelId: fields.llmModelId,
          llmTemperature: fields.llmTemperature,
          llmMaxTokens: fields.llmMaxTokens,
          llmFrequencyPenalty: parseUndefinedValueToNull(fields.llmFrequencyPenalty),
          llmPresencePenalty: parseUndefinedValueToNull(fields.llmPresencePenalty),
          promptTemplateId: fields.promptTemplateId,
        }),
      });

      handleApiResponse({
        response,
        errorMsg: EDIT_PROSPECT_ERROR_MSG,
        onSuccess: onSaveSuccess,
      });
    } catch (error) {
      console.error(EDIT_PROSPECT_ERROR_MSG, error);
    }
  }, [
    isSuperAdmin,
    canManageProspect,
    personaId,
    personaPrompt,
    fields,
    editPracticeProspect,
    handleApiResponse,
    onSaveSuccess,
  ]);

  return (
    <ButtonGroup loading={isEditing}>
      <TextButton
        disabled={isSaveDisabled}
        text="Save changes"
        onClick={handleSaveChanges}
        color={ButtonColor.PRIMARY}
        tooltip={isSaveDisabled ? 'Please fill out all required fields before saving.' : undefined}
      />
      <TextButton text="Cancel" onClick={handleCancel} />
    </ButtonGroup>
  );
};

export default EditEndActions;
