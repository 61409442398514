import clsx from 'clsx';
import { Outlet, useLocation } from 'react-router-dom';
import { NavBar, Sidebar } from '../../components';
import { CREATE_ROUTES } from '../../constants';
import { useCallSocketEvents, useIntercom } from '../../hooks';
import Banners from './Banners';
import Modals from './Modals';

function Layout() {
  const location = useLocation();
  const isCreatePage = CREATE_ROUTES.some((route) => location.pathname.includes(route));

  useIntercom();
  // Listens on call events and updates call details accordingly.
  useCallSocketEvents();

  return (
    <div className="flex h-screen flex-col">
      <Banners />
      <NavBar />
      <div className="flex flex-1 overflow-hidden">
        {!isCreatePage && <Sidebar />}
        <div className={clsx('flex-1 overflow-auto bg-base-0', !isCreatePage && 'p-4')}>
          <Outlet />
        </div>
      </div>
      <Modals />
    </div>
  );
}

export default Layout;
