import { ApiSuccess, ApiTagTypes, Team, TeamsData } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<Team[], void>({
      query: () => '/teams',
      transformResponse: (response: ApiSuccess<TeamsData>) => response.data.teams,
      providesTags: [ApiTagTypes.TEAMS],
    }),
  }),
});

export const { useGetTeamsQuery } = enhancedApiSlice;
