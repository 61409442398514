export const getSizeStyles = (size: number) => {
  // If the size is an integer, then it is a number of pixels.
  // If the size is a float, then it is a percentage of the table width.
  // This is because TanStack Table only supports number sizes.
  const isInteger = size % 1 === 0;
  return {
    width: size === 0 ? 'auto' : isInteger ? size : `${size * 100}%`,
    minWidth: isInteger ? size : undefined,
  };
};
