import { useCallback, useMemo } from 'react';
import { Select, TextInput } from '../../../../components';
import { MULTI_SITE_OPTIONS, REMOTE_OPTIONS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setHQLocation, setIsMultiSite, setRemoteType } from '../../../../redux/reducers';
import { RemoteType } from '../../../../types';
import ProspectFieldGroup from '../ProspectFieldGroup';

const LOCATION_SELECT_WIDTH = 200;

const LocationFields = () => {
  const dispatch = useAppDispatch();
  const { hqLocation, remoteType, isMultiSite } = useAppSelector((state) => state.prospect.fields);

  const handleHQLocationChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newHQLocation = e.target.value;
      dispatch(setHQLocation(newHQLocation));
    },
    [dispatch]
  );

  const handleRemoteChange = useCallback(
    (value?: string) => {
      const newRemote = value as RemoteType;
      dispatch(setRemoteType(newRemote));
    },
    [dispatch]
  );

  const handleMultiSiteChange = useCallback(
    (value?: string) => {
      const newIsMultiSite = value === undefined ? undefined : value === 'true';
      dispatch(setIsMultiSite(newIsMultiSite));
    },
    [dispatch]
  );

  const fields = useMemo(
    () => [
      {
        label: 'HQ location',
        content: <TextInput value={hqLocation.value} onChange={handleHQLocationChange} />,
      },
      {
        label: 'Remote?',
        width: LOCATION_SELECT_WIDTH,
        content: (
          <Select clearable options={REMOTE_OPTIONS} selected={remoteType.value} onChange={handleRemoteChange} />
        ),
      },
      {
        label: 'Multi-site?',
        width: LOCATION_SELECT_WIDTH,
        content: (
          <Select
            clearable
            options={MULTI_SITE_OPTIONS}
            selected={isMultiSite.value === undefined ? undefined : isMultiSite.value ? 'true' : 'false'}
            onChange={handleMultiSiteChange}
          />
        ),
      },
    ],
    [hqLocation, isMultiSite, remoteType, handleHQLocationChange, handleMultiSiteChange, handleRemoteChange]
  );

  return <ProspectFieldGroup fields={fields} />;
};

export default LocationFields;
