import { useEffect, useState } from 'react';
import { SCREEN_SIZE_BREAKPOINTS } from '../../constants';
import { useWindowResize } from '../../hooks';
import { ComponentSize } from '../../types';
import { Icon, Icons } from '../shared';
import SidebarContent from './SidebarContent';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const windowWidth = useWindowResize();

  // Automatically collapse the sidebar for window widths less than or equal to the threshold.
  useEffect(() => {
    if (windowWidth > SCREEN_SIZE_BREAKPOINTS.TABLET && isCollapsed) {
      setIsCollapsed(false);
    } else if (windowWidth <= SCREEN_SIZE_BREAKPOINTS.TABLET && !isCollapsed) {
      setIsCollapsed(true);
    }
  }, [windowWidth]);

  return (
    <div className="flex items-start bg-base-0">
      {/* Sidebar content */}
      {!isCollapsed && <SidebarContent />}
      {/* Collapse button */}
      <div
        className="ml-[-1px] mt-4 flex w-4 cursor-pointer justify-center rounded-r-lg border-b border-r border-t border-base-100 py-[5px] hover:bg-base-200"
        onClick={() => setIsCollapsed((prev) => !prev)}
      >
        <Icons icon={isCollapsed ? Icon.CHEVRON_RIGHT : Icon.CHEVRON_LEFT} size={ComponentSize.X_SMALL} />
      </div>
    </div>
  );
};

export default Sidebar;
