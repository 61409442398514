import { useEffect, useState } from 'react';
import { truncateText } from '../../../../utils';
import {
  ButtonColor,
  ButtonVariant,
  Typography,
  TypographySize,
  TypographyWeight,
  Icon,
  IconButton,
} from '../../../../components';
import { ComponentSize, TextColor, HeatMapData } from '../../../../types';
import { HEAT_MAP_CELL_GAP, HEAT_MAP_LABEL_WIDTH, HEAT_MAP_LABEL_RIGHT_PADDING } from '../../../../constants';

interface HeatMapYLabelProps {
  x: number;
  y: number;
  collapsedTeamIds: string[];
  onToggleCollapse: (id: string) => void;
  data?: HeatMapData;
}

const HeatMapYLabel = ({ x, y, data, collapsedTeamIds, onToggleCollapse }: HeatMapYLabelProps) => {
  const [animatedX, setAnimatedX] = useState(x);

  const { rows, name, id } = data ?? {};

  // If the row has users, it's a team row
  const isTeam = !!rows?.length;
  // If the team is collapsed, to
  const isCollapsed = id ? collapsedTeamIds.includes(id) : false;

  const formattedName = name ? truncateText(name, HEAT_MAP_LABEL_WIDTH) : undefined;

  useEffect(() => {
    // To prevent flickering on mount
    setAnimatedX(x - HEAT_MAP_LABEL_RIGHT_PADDING);
  }, [x]);

  if (!id) return null;

  return (
    <foreignObject x={animatedX} y={y - HEAT_MAP_CELL_GAP / 2} width={200} height="100%">
      <div className="flex w-full items-center justify-between px-4">
        <Typography
          weight={isTeam ? TypographyWeight.SEMI_BOLD : undefined}
          color={isTeam ? undefined : TextColor.SECONDARY}
          size={TypographySize.H5}
        >
          {formattedName}
        </Typography>
        {isTeam && (
          <IconButton
            icon={isCollapsed ? Icon.CHEVRON_DOWN : Icon.CHEVRON_UP}
            className="absolute cursor-pointer"
            onClick={() => onToggleCollapse(id)}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
            size={ComponentSize.X_SMALL}
          />
        )}
      </div>
    </foreignObject>
  );
};

export default HeatMapYLabel;
