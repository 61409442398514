import { useEffect } from 'react';

/**
 * Hook to warn the user if they try to leave the page without saving.
 */
const useWarnBeforeUnload = () => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

export default useWarnBeforeUnload;
