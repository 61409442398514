import { DatePickerRange } from '../components';
import { AnalyticsFilterKeys } from './filters.types';

export enum AnalyticsTab {
  ACTIVITY = 'activity',
  PROGRESS_REPORT = 'progress-report',
  TEAM_PERFORMANCE = 'team-performance',
}

export enum AverageByPeriod {
  DAY = 'day',
  WEEK = 'week',
}

export enum CallMetric {
  FILLER_WORDS = 'fillerWords',
  TALK_SPEED = 'talkSpeed',
  TALK_RATIO = 'talkRatio',
  LONGEST_MONOLOGUE = 'longestMonologue',
}

export enum CallMetricAverageKeys {
  AVERAGE_TALK_SPEED = 'averageTalkSpeed',
  AVERAGE_TALK_RATIO = 'averageTalkRatio',
  AVERAGE_FILLER_WORDS = 'averageFillerWords',
  AVERAGE_LONGEST_MONOLOGUE = 'averageLongestMonologue',
}

// TODO: Extend the AnalyticsFilters type when the API accepts the date as a single value.
type BaseAnalyticsPayload = {
  [AnalyticsFilterKeys.DATE_RANGE]: DatePickerRange[];
  [AnalyticsFilterKeys.PROSPECT]?: string[];
  [AnalyticsFilterKeys.TAGS]?: string[];
};

export type CallsActivityPayload = BaseAnalyticsPayload & {
  [AnalyticsFilterKeys.CALLERS]?: string[];
  [AnalyticsFilterKeys.TEAM]?: string[];
};

type BaseProgressReportsPayload = BaseAnalyticsPayload & {
  [AnalyticsFilterKeys.AVERAGE_BY]: AverageByPeriod;
  [AnalyticsFilterKeys.USER]: string;
  timezone: string;
};

export type ProgressReportCallScorePayload = BaseProgressReportsPayload & {
  scorecardTemplateId: string;
};

export type ProgressReportMetricPayload = BaseProgressReportsPayload & {
  metric: CallMetric;
};

export type CallsActivityData = {
  totalCalls: number;
  totalTalkTime: number;
  callers: {
    id: string;
    name: string;
    callCount: number;
    talkTime: number;
  }[];
};

export type ProgressReportCallScoreData = {
  date: string;
  avg_score: number | null;
}[];

export type ProgressReportMetricData = {
  stats: {
    date: string;
    metricValue: number;
    count: number;
  }[];
  overallAverage: number;
};

export type TeamPerformanceComputeFieldsType = {
  [CallMetric.FILLER_WORDS]?: boolean;
  [CallMetric.TALK_SPEED]?: boolean;
  [CallMetric.TALK_RATIO]?: boolean;
  [CallMetric.LONGEST_MONOLOGUE]?: boolean;
  scorecardTemplateIds?: string[];
};

export type TeamPerformanceComputeFieldsResponseType = {
  [CallMetric.FILLER_WORDS]: boolean;
  [CallMetric.TALK_SPEED]: boolean;
  [CallMetric.TALK_RATIO]: boolean;
  [CallMetric.LONGEST_MONOLOGUE]: boolean;
  scorecardTemplateIds: string[];
};

export type TeamPerformanceAnalyticsPayload = {
  timezone: string;
  [AnalyticsFilterKeys.DATE_RANGE]: DatePickerRange[];
  computeFields?: TeamPerformanceComputeFieldsType;
  applyDefaultMetrics?: boolean;
};

type TeamPerformanceRowData = {
  id: string;
  name: string;
};

export type TeamPerformanceAnalyticsData = {
  user: TeamPerformanceRowData;
  teams: TeamPerformanceRowData[];
  [CallMetricAverageKeys.AVERAGE_TALK_SPEED]: number | null;
  [CallMetricAverageKeys.AVERAGE_TALK_RATIO]: number | null;
  [CallMetricAverageKeys.AVERAGE_FILLER_WORDS]: number | null;
  [CallMetricAverageKeys.AVERAGE_LONGEST_MONOLOGUE]: number | null;
  scorecardAveragesByTemplate: Record<string, { templateName: string; average: number }>;
}[];

export type TeamPerformanceAnalyticsResponse = {
  analytics: TeamPerformanceAnalyticsData;
  defaultAnalyticsMetrics: TeamPerformanceComputeFieldsResponseType;
};

/* Heat Map Types */
export type HeatMapDataPoint = {
  x: string;
  key: string;
  formatter: (value: number) => string;
  y?: number;
  unit?: string;
  color?: string;
};

export type HeatMapRowData = {
  id: string;
  name: string;
  data: HeatMapDataPoint[];
};

export type HeatMapData = HeatMapRowData & {
  rows?: HeatMapRowData[];
};
