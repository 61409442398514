import { ColumnDef, Getter, PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  Badge,
  CustomCellContext,
  DataTable,
  FavoriteButton,
  ManagerNotesModal,
  MAX_TAGS_AND_NOTES_HEIGHT,
  SortableHeader,
  TagsAndNotesCell,
  Typography,
  TypographySize,
  UserBadge,
} from '../../../components';
import { PROSPECT_CATEGORY_TO_COLOR } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { deleteProspect, startWebCall, updateLastLoginTime, updateProspect } from '../../../redux/reducers';
import {
  ComponentSize,
  CustomSortingState,
  DateFormat,
  DateUnit,
  PracticeProspect,
  ProspectCategory,
  ProspectSortingFilters,
  ProspectStatus,
  TextColor,
  TimeFormat,
} from '../../../types';
import { getCooldownEndTime, isProspectLocked, snakeCaseToLabel } from '../../../utils';
import ProspectCallCell from './ProspectCallCell';
import useProspectActions from './useProspectActions';

interface ProspectTableProps {
  // Whether the table is in the favorites folder.
  isInFavorites: boolean;
  repCanCreateSims: boolean;
  pagination: PaginationState;
  sorting: CustomSortingState;
  refetchProspects: () => void;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  setSorting: Dispatch<SetStateAction<CustomSortingState>>;
  isLoading?: boolean;
  totalPages?: number;
}

const ProspectTable = ({
  isInFavorites,
  repCanCreateSims,
  pagination,
  sorting,
  refetchProspects,
  setPagination,
  setSorting,
  isLoading,
  totalPages = 0,
}: ProspectTableProps) => {
  const [managerNotesProspect, setManagerNotesProspect] = useState<PracticeProspect>();

  const dispatch = useAppDispatch();
  const lastLoginTime = useAppSelector((state) => state.auth.lastLoginTime);
  const prospects = useAppSelector((state) => state.simulations.prospects);

  const getProspectTooltip = useCallback((prospect: PracticeProspect) => {
    if (!isProspectLocked(prospect)) return undefined;
    // Prospect is locked by manager due to access control
    if (prospect.isLocked) return 'Locked by manager';

    const cooldownEndTime = getCooldownEndTime(prospect);
    if (!cooldownEndTime) return undefined;

    const userTimezone = dayjs.tz.guess();
    const formattedTime = cooldownEndTime.tz(userTimezone).format(TimeFormat.SHORT_TIME);

    // Check if the cooldown end time is on a different day than today
    // If it is, then it's tomorrow as the cooldown period is maximum 24 hours
    const isTomorrow = !dayjs().isSame(cooldownEndTime, DateUnit.DAY);

    return `Try again ${isTomorrow ? 'tomorrow' : ''} after ${formattedTime}`;
  }, []);

  const parsedData = useMemo(
    () =>
      prospects.map((prospect) => ({
        ...prospect,
        tooltip: getProspectTooltip(prospect),
        disabled: prospect.status === ProspectStatus.ARCHIVED || isProspectLocked(prospect),
        onClick: () => dispatch(startWebCall({ prospect })),
      })),
    [dispatch, getProspectTooltip, prospects]
  );

  // Define columns for the data table.
  const columns: ColumnDef<PracticeProspect>[] = useMemo(
    () => [
      {
        accessorKey: 'favorite',
        header: '',
        cell: ({ row, isHovered }: CustomCellContext<PracticeProspect, void>) => {
          const { isFavorited, personaId } = row.original;
          if (!isFavorited && !isHovered) {
            // Hide the star if the prospect is not favorited and not hovered.
            // Render an empty div to maintain column width if no simulation is favorited in the page.
            return <div className="w-4" />;
          } else {
            return (
              <FavoriteButton
                personaId={personaId}
                isFavorited={isFavorited}
                onSuccess={(newIsFavorited) => {
                  if (!newIsFavorited && isInFavorites) {
                    // If we are removing a prospect from favorites from within the favorites page,
                    // remove it from the list of prospects.
                    dispatch(deleteProspect(personaId));
                  } else {
                    // Otherwise, just update the prospect's favorite status.
                    dispatch(updateProspect({ personaId, isFavorited: newIsFavorited }));
                  }
                }}
              />
            );
          }
        },
      },
      {
        accessorKey: 'call',
        header: '',
        cell: ({ row }) => <ProspectCallCell prospect={row.original} />,
      },
      {
        accessorKey: 'prospect',
        header: 'Prospect',
        size: 1 / 2,
        cell: ({ row }) => {
          const { firstName, lastName, jobTitle, accountName } = row.original;
          const title = `${firstName} ${lastName}`;
          const subtitle = `${accountName} | ${jobTitle}`;
          return <UserBadge title={title} subtitle={subtitle} />;
        },
      },
      {
        // Column for any additional notes.
        accessorKey: 'tagsAndNotes',
        header: 'Tags & notes',
        size: 1 / 2,
        cell: ({ row }) => {
          const { personaId, tags, notes } = row.original;
          return <TagsAndNotesCell notes={notes} prospectId={personaId} prospectTags={tags} hideEmptyState />;
        },
      },
      {
        accessorKey: 'category',
        header: 'Category',
        cell: ({ getValue }: { getValue: Getter<ProspectCategory> }) => {
          const category = getValue();
          return (
            <Badge
              color={PROSPECT_CATEGORY_TO_COLOR[category]}
              label={snakeCaseToLabel(category)}
              size={ComponentSize.MEDIUM}
            />
          );
        },
      },
      {
        // Column for displaying the date created,
        // highlighting if it was within the last two days.
        accessorKey: 'updatedAt',
        header: () => (
          <SortableHeader
            title="Last updated"
            sorting={sorting}
            setSorting={setSorting}
            sortingId={ProspectSortingFilters.UPDATED_AT}
          />
        ),
        cell: ({ row }) => {
          const { updatedAt, createdAt } = row.original;
          const label = dayjs(updatedAt).format(DateFormat.MONTH_DAY);

          let isNew = false;
          // If the last login time is set,
          // check if the date is after the last login time.
          if (lastLoginTime) {
            isNew = dayjs(createdAt).isAfter(dayjs(lastLoginTime));
          } else {
            // If the last login time is not set, update it with the current time
            // and return false.
            dispatch(updateLastLoginTime());
          }

          return (
            <div className="flex flex-col gap-1">
              {isNew && (
                <Typography color={TextColor.DESTRUCTIVE} size={TypographySize.CAPTION}>
                  NEW
                </Typography>
              )}
              <Typography size={TypographySize.CAPTION}>{label}</Typography>
            </div>
          );
        },
      },
    ],
    [isInFavorites, lastLoginTime, sorting, dispatch]
  );

  return (
    <div className="flex flex-col gap-8 py-4">
      <DataTable
        columns={columns}
        data={parsedData}
        isLoading={isLoading}
        paginationControls={{ pagination, totalPages, setPagination }}
        contentHeight={MAX_TAGS_AND_NOTES_HEIGHT}
        useEndActions={(prospect, closeActions) =>
          useProspectActions({
            prospect,
            repCanCreateSims,
            closeActions,
            refetchProspects,
            setManagerNotesProspect,
          })
        }
      />
      {managerNotesProspect && (
        <ManagerNotesModal
          prospect={managerNotesProspect}
          onClose={() => setManagerNotesProspect(undefined)}
          refetchProspects={refetchProspects}
        />
      )}
    </div>
  );
};

export default ProspectTable;
