import { Dispatch, SetStateAction } from 'react';
import { Badge, ButtonVariant, Select, TextButton, Typography } from '../../components';
import {
  PROSPECT_CATEGORY_TO_COLOR,
  PROSPECT_TYPE_OPTIONS,
  PROSPECT_TYPE_TO_COLOR,
  PUBLIC_PROSPECT_CATEGORY_OPTIONS,
} from '../../constants';
import { ComponentSize, PromptTemplate, ProspectCategory, ProspectType } from '../../types';
import { snakeCaseToLabel } from '../../utils';

interface PromptTemplateSettingsProps {
  newIsDefault: boolean;
  setNewIsDefault: Dispatch<SetStateAction<boolean>>;
  setSelectedCategory: Dispatch<SetStateAction<ProspectCategory | undefined>>;
  setSelectedType: Dispatch<SetStateAction<ProspectType | undefined>>;
  promptTemplate?: PromptTemplate;
  selectedCategory?: ProspectCategory;
  selectedType?: ProspectType;
}

const PromptTemplateSettings = ({
  newIsDefault,
  setNewIsDefault,
  setSelectedCategory,
  setSelectedType,
  promptTemplate,
  selectedCategory,
  selectedType,
}: PromptTemplateSettingsProps) => {
  const { category: existingCategory, default: existingIsDefault, type: existingType } = promptTemplate || {};

  // The category and type cannot be changed once the prompt template is created,
  // so we render the existing category and type in a badge.
  return (
    <div className="flex flex-col gap-4">
      {/* Simulation category */}
      <div className="flex flex-col gap-2">
        <Typography>Simulation category{!existingCategory && '*'}</Typography>
        {existingCategory && (
          <div className="flex h-8">
            <Badge
              color={PROSPECT_CATEGORY_TO_COLOR[existingCategory]}
              label={snakeCaseToLabel(existingCategory)}
              size={ComponentSize.MEDIUM}
            />
          </div>
        )}
        {!existingCategory && (
          <Select
            options={PUBLIC_PROSPECT_CATEGORY_OPTIONS}
            placeholder="Select category"
            selected={selectedCategory}
            onChange={(value) => setSelectedCategory(value as ProspectCategory)}
          />
        )}
      </div>
      {/* Simulation type */}
      <div className="flex flex-col gap-2">
        <Typography>Simulation type{!existingType && '*'}</Typography>
        {existingType && (
          <div className="flex h-8">
            <Badge
              color={PROSPECT_TYPE_TO_COLOR[existingType]}
              label={snakeCaseToLabel(existingType)}
              size={ComponentSize.MEDIUM}
            />
          </div>
        )}
        {!existingType && (
          <Select
            options={PROSPECT_TYPE_OPTIONS}
            placeholder="Select type"
            selected={selectedType}
            onChange={(value) => setSelectedType(value as ProspectType)}
          />
        )}
      </div>
      {/* Default prompt template */}
      <div className="flex items-center gap-2">
        <TextButton
          disabled={existingIsDefault}
          text={newIsDefault && !existingIsDefault ? 'Remove as default' : 'Set as default'}
          variant={ButtonVariant.OUTLINE}
          onClick={() => setNewIsDefault((prev) => !prev)}
        />
        {existingIsDefault && <Typography>Current default</Typography>}
      </div>
    </div>
  );
};

export default PromptTemplateSettings;
