import { MAX_CALL_DURATION } from '../constants';
import { AppUser, Call, CallerInfo, CallProcessingStatus, CallStatus } from '../types';
import { formatDisplayedPhoneNumber } from './string.utils';

/**
 * Retrieves the caller information for a given call and current user.
 */
export const getCallerInfo = (call: Call, currUser: AppUser): CallerInfo => {
  let formattedPhoneNumber = '';
  if (call.incomingPhoneNumber) {
    // Format the incoming phone number for display.
    formattedPhoneNumber = formatDisplayedPhoneNumber(call.incomingPhoneNumber);
  }

  // If the call has no associated userId or candidateId, meaning the caller is a non-FullyRamped user,
  // return the incoming phone number as the caller's unique ID and name.
  if (!call.userId && !call.candidateId) {
    return { name: formattedPhoneNumber };
  }

  // If the caller's userId matches the current user's ID,
  // return the current user's details.
  if (call.userId === currUser.id) {
    return { name: currUser.name || '', picture: currUser.picture };
  }

  // If the call has an associated user object, return the user's details.
  if (call.user) {
    return { name: call.user.name || '', picture: call.user.picture };
  }

  // If the call has an associated candidate object, return the candidate's details.
  if (call.candidate) {
    return {
      name: `${call.candidate.firstName} ${call.candidate.lastName}`.trim(),
    };
  }

  // If none of the above conditions are met, return the incoming phone number as the caller's unique ID and name.
  return { name: formattedPhoneNumber };
};

export const isCallProcessingFailed = (call: Call) => {
  const { audioPath, processingStatus, callStatus, startTime } = call;

  if (processingStatus === CallProcessingStatus.PROCESSING_FAILED) {
    return true;
  }

  // If the call has no audio path, we can assume it's failed.
  if (!audioPath) {
    return true;
  }

  // If the call is still in 'started' status, it usually means the call is live.
  // However, if the duration has exceeded the maximum allowed time,
  // it indicates that something likely went wrong during processing, so we should treat it as failed.
  if (!processingStatus && callStatus === CallStatus.STARTED) {
    const hasExceededTimeLimit = Date.now() - new Date(startTime).getTime() > MAX_CALL_DURATION;
    if (hasExceededTimeLimit) {
      return true;
    }
  }

  return false;
};

export const isCallProcessingPending = (call: Call) => !call.processingStatus && call.callStatus === CallStatus.ENDED;

export const isCallInProgress = (call: Call) => {
  // If the call is still in 'started' status, it usually means the call is live,
  // but only if the duration has not exceeded the maximum allowed time.
  if (call.callStatus === CallStatus.STARTED) {
    const hasExceededTimeLimit = Date.now() - new Date(call.startTime).getTime() > MAX_CALL_DURATION;
    if (!hasExceededTimeLimit) {
      return true;
    }
  }

  return false;
};
