import { useMemo } from 'react';
import { useGetOrganizationSettingsQuery } from '../../../../services';
import { Benchmarks } from '../../../../types';
import { formatDurationVerbose, getStatIndicatorColor } from '../../../../utils';
import CallStatsCard from './CallStatsCard';

const roundToTwoDecimals = (value: number): number => Math.round(value * 100) / 100;

const calculateTalkRatio = (talkTime: number, callDuration: number): number =>
  callDuration ? roundToTwoDecimals((talkTime / callDuration) * 100) : 0;

const calculateTalkSpeed = (wordsCount: number, talkTime: number): number =>
  talkTime ? Math.round((wordsCount / talkTime) * 60) : 0;

interface CallStatsPanelProps {
  longestMonologueDuration?: number;
  fillerWordsCount?: number;
  wordsCount?: number;
  talkTime?: number;
  callDuration?: number | null;
}

const CallStatsPanel = ({
  longestMonologueDuration = 0,
  fillerWordsCount = 0,
  wordsCount = 0,
  talkTime = 0,
  callDuration = 0,
}: CallStatsPanelProps) => {
  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const { longestMonologue, fillerWordsPerMinute, wordsPerMinute, talkRatio } = orgConfigs || {};

  const calculatedTalkRatio = useMemo(() => calculateTalkRatio(talkTime, callDuration ?? 0), [talkTime, callDuration]);
  const calculatedTalkSpeed = useMemo(() => calculateTalkSpeed(wordsCount, talkTime), [wordsCount, talkTime]);

  const monologueIndicatorColor = getStatIndicatorColor(
    longestMonologueDuration,
    Benchmarks.LONGEST_MONOLOGUE,
    longestMonologue
  );
  const talkRatioIndicatorColor = getStatIndicatorColor(calculatedTalkRatio, Benchmarks.TALK_RATIO, talkRatio);
  const talkSpeedIndicatorColor = getStatIndicatorColor(calculatedTalkSpeed, Benchmarks.TALK_SPEED, wordsPerMinute);
  const fillerWordsIndicatorColor = getStatIndicatorColor(
    fillerWordsCount,
    Benchmarks.FILLER_WORDS,
    fillerWordsPerMinute
  );

  return (
    <div className="flex gap-4">
      {/* Longest time representative spoke without letting the prospect speak. */}
      <CallStatsCard
        title="Longest monologue"
        value={formatDurationVerbose(longestMonologueDuration)}
        tooltip="Longest monologue by representative"
        indicatorColor={monologueIndicatorColor}
      />
      {/* The % of the call the representative was speaking. */}
      <CallStatsCard
        title="Talk ratio"
        value={`${calculatedTalkRatio}%`}
        tooltip="Talk ratio, % representative"
        indicatorColor={talkRatioIndicatorColor}
      />
      {/* How many words per minute the representative spoke */}
      <CallStatsCard
        title="Talk speed"
        value={calculatedTalkSpeed}
        tooltip="Talk speed, words per minute"
        indicatorColor={talkSpeedIndicatorColor}
      />
      {/* How many filler words the representative spoke per minute */}
      <CallStatsCard
        title="Filler words"
        value={fillerWordsCount}
        tooltip="Filler words, filler words per minute"
        indicatorColor={fillerWordsIndicatorColor}
      />
    </div>
  );
};

export default CallStatsPanel;
