import { useAuth0 } from '@auth0/auth0-react';
import { show as openIntercom } from '@intercom/messenger-js-sdk';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import fullyRampedLogo from '../../assets/logo-light.png';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector, useFeatureFlag } from '../../hooks';
import {
  SettingsModalTabs,
  SimulationsView,
  openSettingsModal,
  openUploadModal,
  openZeusModal,
  setView,
} from '../../redux/reducers';
import { LD_FeatureFlags, OrganizationStatus, Permissions, TextColor } from '../../types';
import { SettingsModal, UploadModal, ZeusModal } from '../modals';
import {
  Avatar,
  ButtonColor,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  Icon,
  IconButton,
  Tabs,
  TabsType,
  Typography,
  TypographyWeight,
} from '../shared';

const NavBar = () => {
  // Hooks
  const { logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  // Custom hooks
  const foldersFF = useFeatureFlag(LD_FeatureFlags.RELEASE_FOLDERS);

  // Redux
  const dispatch = useAppDispatch();
  const view = useAppSelector((state) => state.simulations.view);
  const { organization, user, isImpersonating } = useAppSelector((state) => state.auth);
  const { isUploadModalEnabled, isHiringModuleEnabled, status } = organization || {};
  const isTrialOrg = status === OrganizationStatus.TRIAL;

  // Check if the curr user is allowed to invite other users.
  const permissions = user?.permissions || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);
  const canViewHiring = permissions.includes(Permissions.VIEW_CANDIDATE);

  const canOpenZeusModal = isSuperAdmin || isImpersonating;

  // State
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  // Navigation tabs
  const tabs = [
    {
      id: AppRoutes.SIMULATIONS,
      icon: Icon.PHONE,
      title: 'Simulations',
    },
    {
      id: AppRoutes.REVIEW,
      icon: Icon.HISTORY,
      title: 'Review',
    },
    {
      id: AppRoutes.ANALYTICS,
      icon: Icon.BAR_CHART,
      title: 'Analytics',
    },
  ];

  const activeTab = useMemo(() => tabs.find((tab) => location.pathname.includes(tab.id))?.id, [location.pathname]);

  const userCanHire = canViewHiring && isHiringModuleEnabled;

  return (
    <>
      <div className="navbar z-10 flex w-full justify-between border-b border-b-neutral-200 bg-base-0 px-4 py-2 shadow-sm">
        <div className="flex items-center gap-3">
          <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-8 w-8 rounded-md" />
          <Tabs activeTab={activeTab} tabs={tabs} type={TabsType.GHOST} setActiveTab={(id) => navigate(id)} />
        </div>
        <div className="flex items-center gap-3">
          {/* Temporary toggle for folders view until we release it. */}
          {foldersFF && activeTab === AppRoutes.SIMULATIONS && (
            <IconButton
              icon={view === SimulationsView.FOLDERS ? Icon.TABLE_VIEW : Icon.FOLDER_VIEW}
              onClick={() => {
                dispatch(setView(view === SimulationsView.FOLDERS ? SimulationsView.TABLE : SimulationsView.FOLDERS));
              }}
              tooltip={view === SimulationsView.FOLDERS ? 'Show table view' : 'Show folder view'}
              color={ButtonColor.SECONDARY}
            />
          )}
          {userCanHire && (
            <IconButton
              icon={isTrialOrg ? Icon.LOCK : Icon.USER_SEARCH}
              onClick={() => {
                dispatch(openSettingsModal(SettingsModalTabs.HIRING));
              }}
              disabled={isTrialOrg}
              tooltip="Hiring"
              color={ButtonColor.SECONDARY}
            />
          )}
          {isUploadModalEnabled && (
            <IconButton
              icon={Icon.UPLOAD}
              onClick={() => {
                dispatch(openUploadModal());
              }}
              tooltip="Upload file"
              color={ButtonColor.SECONDARY}
            />
          )}
          <IconButton
            icon={Icon.CIRCLE_HELP}
            color={ButtonColor.SECONDARY}
            onClick={openIntercom}
            tooltip={window.Intercom ? 'Chat with support' : undefined}
            disabled={!window.Intercom}
          />
          <Dropdown open={isUserModalOpen} onOpenChange={setIsUserModalOpen}>
            <DropdownTrigger>
              <Avatar label={user?.name} imgSrc={user?.picture} />
            </DropdownTrigger>
            <DropdownContent>
              <DropdownItem
                label={
                  <Typography weight={TypographyWeight.SEMI_BOLD} color={TextColor.PRIMARY}>
                    {organization?.name}
                  </Typography>
                }
              />
              <DropdownItem
                label="Settings"
                icon={Icon.SETTINGS}
                onClick={() => {
                  dispatch(openSettingsModal());
                  setIsUserModalOpen(false);
                }}
              />
              <DropdownItem
                label="Invite user"
                disabled={isTrialOrg}
                icon={isTrialOrg ? Icon.LOCK : Icon.USER_PLUS}
                onClick={() => {
                  dispatch(openSettingsModal(SettingsModalTabs.PEOPLE));
                  setIsUserModalOpen(false);
                }}
              />
              {canOpenZeusModal && (
                <DropdownItem
                  label="Zeus mode"
                  icon={Icon.ZEUS}
                  onClick={() => {
                    dispatch(openZeusModal());
                    setIsUserModalOpen(false);
                  }}
                />
              )}
              <DropdownItem label="Logout" icon={Icon.LOG_OUT} onClick={logout} />
            </DropdownContent>
          </Dropdown>
        </div>
      </div>
      {/* Navbar Modals */}
      <SettingsModal />
      <UploadModal />
      {/* We conditionally render Zeus modal so we don't make unnecessary/invalid API calls. */}
      {canOpenZeusModal && <ZeusModal />}
    </>
  );
};

export default NavBar;
