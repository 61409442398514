import {
  ApiSuccess,
  ApiTagTypes,
  ApplyTagsPayload,
  CreatePracticeProspectPayload,
  EditPracticeProspectPayload,
  PracticeProspectListResponse,
  PracticeProspectListResponseData,
  PracticeProspectsFilters,
  PracticeProspectsParams,
  UpdatedPracticeProspectData,
} from '../../types';
import { parseProspect } from '../../utils';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPracticeProspects: builder.mutation<
      PracticeProspectListResponseData,
      PracticeProspectsParams & PracticeProspectsFilters
    >({
      query: ({ pagination, sorting, ...filters }) => ({
        url: '/prospects',
        method: 'POST',
        params: {
          page: pagination?.pageIndex,
          perPage: pagination?.pageSize,
          ...sorting,
        },
        body: filters,
      }),
      transformResponse: (response: PracticeProspectListResponse) => ({
        ...response.data,
        prospects: response.data.prospects.map(parseProspect),
      }),
    }),
    applyTags: builder.mutation<ApiSuccess, ApplyTagsPayload>({
      query: ({ prospectId, ...tagData }) => ({
        url: `/prospects/${prospectId}/apply-tags`,
        method: 'POST',
        body: tagData,
      }),
      invalidatesTags: (_, __, { prospectId }) => [ApiTagTypes.CALLS, { type: ApiTagTypes.PROSPECTS, id: prospectId }],
    }),
    regeneratePrompt: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, string>({
      query: (prospectId) => ({
        url: `/prospects/${prospectId}/regenerate-prompt`,
        method: 'POST',
      }),
    }),
    createPracticeProspect: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, CreatePracticeProspectPayload>({
      query: ({ orgId, ...prospectData }) => ({
        url: '/prospects/create',
        method: 'POST',
        params: { orgId },
        body: prospectData,
      }),
    }),
    editPracticeProspect: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, EditPracticeProspectPayload>({
      query: ({ id, ...prospectUpdates }) => ({
        url: `/prospects/${id}/edit`,
        method: 'POST',
        body: prospectUpdates,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: ApiTagTypes.PROSPECTS, id }],
    }),
    archivePracticeProspect: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, string>({
      query: (id) => ({
        url: `/prospects/${id}/archive`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.PROSPECTS],
    }),
    deletePracticeProspect: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prospects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagTypes.PROSPECTS],
    }),
    reactivatePracticeProspect: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, string>({
      query: (id) => ({
        url: `/prospects/${id}/reactivate`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.PROSPECTS],
    }),
    favoriteProspect: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prospects/${id}/favorite`,
        method: 'POST',
      }),
    }),
    unfavoriteProspect: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prospects/${id}/unfavorite`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetPracticeProspectsMutation,
  useApplyTagsMutation,
  useRegeneratePromptMutation,
  useCreatePracticeProspectMutation,
  useEditPracticeProspectMutation,
  useArchivePracticeProspectMutation,
  useDeletePracticeProspectMutation,
  useReactivatePracticeProspectMutation,
  useFavoriteProspectMutation,
  useUnfavoriteProspectMutation,
} = enhancedApiSlice;
