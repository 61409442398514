import { useCallback, useMemo } from 'react';
import { COOLDOWN_PERIOD_OPTIONS, TIME_LIMIT_OPTIONS } from '../../../constants';
import { ComponentSize, TagColor } from '../../../types';
import {
  Badge,
  ButtonColor,
  Checkbox,
  findOptionByValue,
  getBadgeColorConfig,
  Icon,
  Icons,
  Select,
  Typography,
  TypographySize,
} from '../../shared';
import ManagerNotesSection from './ManagerNotesSection';

const SELECT_WIDTH = 130;

interface QuizProspectSectionsProps {
  allowedCallers: string[];
  allowedTeams: string[];
  cooldownPeriodInput: number;
  isCooldownPeriodEnabled: boolean;
  isSendNotificationEnabled: boolean;
  isTimeLimitEnabled: boolean;
  teamsOptions: { label: string; value: string; color?: string }[];
  timeLimitInput: number;
  usersOptions: { label: string; value: string }[];
  setAllowedCallers: (allowedCallers: string[]) => void;
  setAllowedTeams: (allowedTeams: string[]) => void;
  setCooldownPeriodInput: (cooldownPeriodInput: number) => void;
  setIsCooldownPeriodEnabled: (isCooldownPeriodEnabled: boolean) => void;
  setIsSendNotificationEnabled: (isSendNotificationEnabled: boolean) => void;
  setIsTimeLimitEnabled: (isTimeLimitEnabled: boolean) => void;
  setTimeLimitInput: (timeLimitInput: number) => void;
}
const QuizProspectSections = ({
  allowedCallers,
  allowedTeams,
  cooldownPeriodInput,
  isCooldownPeriodEnabled,
  isSendNotificationEnabled,
  isTimeLimitEnabled,
  timeLimitInput,
  usersOptions,
  teamsOptions,
  setAllowedCallers,
  setAllowedTeams,
  setCooldownPeriodInput,
  setIsCooldownPeriodEnabled,
  setIsSendNotificationEnabled,
  setIsTimeLimitEnabled,
  setTimeLimitInput,
}: QuizProspectSectionsProps) => {
  // Users and teams options combined
  // We filter out the selected allowed callers and teams from the options
  // And they are shown below the options in badges
  const accessControlOptions = useMemo(
    () => [
      ...usersOptions.filter((user) => !allowedCallers.includes(user.value)),
      ...teamsOptions.filter((team) => !allowedTeams.includes(team.value)),
    ],
    [usersOptions, teamsOptions, allowedCallers, allowedTeams]
  );

  const onAccessControlSelectChange = useCallback(
    (newValue: string) => {
      // Check if the selected value is a team
      const isTeam = teamsOptions.some((team) => team.value === newValue);
      if (isTeam) {
        // If it is, add it to the allowed teams
        setAllowedTeams([...allowedTeams, newValue]);
        return;
      }
      // If it is not, add it to the allowed callers
      setAllowedCallers([...allowedCallers, newValue]);
    },
    [allowedCallers, allowedTeams, teamsOptions]
  );

  const commonIconButtonProps = {
    size: ComponentSize.X_SMALL,
    icon: Icon.CLOSE,
  };

  const renderAccessControlBadges = () => (
    <div className="flex flex-wrap gap-2">
      {allowedCallers.map((caller) => {
        const userOption = findOptionByValue(usersOptions, caller);
        return (
          userOption && (
            <Badge
              key={caller}
              label={userOption.label}
              endElement={
                <Icons
                  {...commonIconButtonProps}
                  color={ButtonColor.SECONDARY}
                  onClick={() => setAllowedCallers(allowedCallers.filter((id) => id !== caller))}
                />
              }
            />
          )
        );
      })}
      {allowedTeams.map((team) => {
        const teamOption = findOptionByValue(teamsOptions, team);
        const badgeColor = teamOption?.color as TagColor;
        const { textColor } = getBadgeColorConfig(badgeColor);
        return (
          teamOption && (
            <Badge
              key={team}
              color={badgeColor}
              label={teamOption.label}
              endElement={
                <Icons
                  {...commonIconButtonProps}
                  color={textColor}
                  onClick={() => setAllowedTeams(allowedTeams.filter((id) => id !== team))}
                />
              }
            />
          )
        );
      })}
    </div>
  );

  return (
    <>
      <ManagerNotesSection title="Notifications" description="Send notifications after completion">
        <div className="flex items-center gap-2">
          <Checkbox
            checked={isSendNotificationEnabled}
            setChecked={setIsSendNotificationEnabled}
            size={ComponentSize.X_SMALL}
          />
          <Typography size={TypographySize.CAPTION}>Send me a notification when completed</Typography>
        </div>
      </ManagerNotesSection>

      <ManagerNotesSection title="Cool-down period" description="Require wait before next attempt">
        <div className="flex items-center gap-2">
          <Checkbox
            checked={isCooldownPeriodEnabled}
            setChecked={() => setIsCooldownPeriodEnabled(!isCooldownPeriodEnabled)}
            size={ComponentSize.X_SMALL}
          />
          <Typography size={TypographySize.CAPTION} noWrap>
            After each attempt, lock the quiz for
          </Typography>
          <Select
            options={COOLDOWN_PERIOD_OPTIONS}
            selected={String(cooldownPeriodInput)}
            width={SELECT_WIDTH}
            onChange={(newValue) => newValue && setCooldownPeriodInput(parseInt(newValue))}
          />
        </div>
      </ManagerNotesSection>
      <ManagerNotesSection title="Access controls" description="Unlock quiz for selected individuals">
        <div className="flex flex-col gap-2">
          <Select
            options={accessControlOptions}
            onChange={(newValue) => newValue && onAccessControlSelectChange(newValue)}
            placeholder="Select representatives or teams"
          />
          {renderAccessControlBadges()}
        </div>
      </ManagerNotesSection>
      <ManagerNotesSection title="Quiz limits" description="Limit the length of the quiz">
        <div className="flex items-center gap-2">
          <Checkbox
            checked={isTimeLimitEnabled}
            setChecked={() => setIsTimeLimitEnabled(!isTimeLimitEnabled)}
            size={ComponentSize.X_SMALL}
          />
          <Typography size={TypographySize.CAPTION} noWrap>
            Limit this quiz to
          </Typography>
          <Select
            options={TIME_LIMIT_OPTIONS}
            selected={String(timeLimitInput)}
            width={SELECT_WIDTH}
            onChange={(newValue) => newValue && setTimeLimitInput(parseInt(newValue))}
          />
        </div>
      </ManagerNotesSection>
    </>
  );
};

export default QuizProspectSections;
