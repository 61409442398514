import { TextColor } from '../../../types';
import { Typography, TypographySize } from '../../shared';

interface ManagerNotesSectionProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

const ManagerNotesSection = ({ title, description, children }: ManagerNotesSectionProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Typography>{title}</Typography>
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
          {description}
        </Typography>
      </div>
      {children}
    </div>
  );
};

export default ManagerNotesSection;
