import { AddCommentPayload, ApiSuccess, ApiTagTypes } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addComment: builder.mutation<ApiSuccess, AddCommentPayload>({
      query: (commentData) => ({
        url: '/comments/create',
        method: 'POST',
        body: commentData,
      }),
      invalidatesTags: [ApiTagTypes.COMMENTS],
    }),
    deleteComment: builder.mutation<ApiSuccess, string>({
      query: (commentId) => ({
        url: `/comments/${commentId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.COMMENTS],
    }),
  }),
});

export const { useAddCommentMutation, useDeleteCommentMutation } = enhancedApiSlice;
