import { useCallback, useEffect, useMemo } from 'react';
import { SelectOption } from '../components';
import { AppRoutes } from '../constants';
import { useGetPracticeProspectsForSelectQuery, useGetTagsQuery } from '../services';
import {
  AnalyticsFilterKeys,
  AnalyticsFilters,
  FilterKeys,
  ReviewFilterKeys,
  ReviewFilters,
  SimulationsFilterKeys,
  SimulationsFilters,
} from '../types';
import { getCurrentAppRoute, parseRequiredSelectOption } from '../utils';
import useGetFiltersFromParams from './useGetFiltersFromParams';
import useUpdateFilterParams from './useUpdateFilterParams';

export type FilterOptionsAndSelections = {
  /** The options for the filter. */
  options: SelectOption[];
  /** Whether the options are loading. */
  loading: boolean;
  /** The selected options for the filter. */
  selected: string[];
};

// Custom hook to manage filter options and selections.
const useFilterOptionsAndSelections = (): Record<string, FilterOptionsAndSelections> => {
  const { data: prospects = [], isLoading: isProspectsLoading } = useGetPracticeProspectsForSelectQuery({});
  const { data: tags = [], isLoading: isTagsLoading } = useGetTagsQuery();

  const currRoute = getCurrentAppRoute();

  // Get current filters from URL parameters.
  const filters = useGetFiltersFromParams();
  const updateFilterParams = useUpdateFilterParams();

  // Create unique account options from prospect data.
  // Transform options to SelectOption format.
  const accountOptions = Array.from(new Set(prospects.map((p) => p.accountName))).map(parseRequiredSelectOption);
  const prospectOptions = prospects.map(({ personaId, firstName, lastName, accountName }) => ({
    value: personaId,
    label: `${firstName} ${lastName} | ${accountName}`,
  }));
  const tagOptions = tags.map(({ id, name, color }) => ({ value: id, label: name, color }));

  const filterValues = useMemo(() => {
    switch (currRoute) {
      case AppRoutes.SIMULATIONS: {
        const simulationsFilters = filters as SimulationsFilters;
        const accountsFilterKey = SimulationsFilterKeys.PROSPECT_ACCOUNT;
        const prospectsFilterKey = SimulationsFilterKeys.PROSPECT;
        const tagsFilterKey = SimulationsFilterKeys.TAGS;
        return {
          accountsFilter: simulationsFilters[accountsFilterKey] || [],
          accountsFilterKey,
          prospectsFilter: simulationsFilters[prospectsFilterKey] || [],
          prospectsFilterKey,
          tagsFilter: simulationsFilters[tagsFilterKey] || [],
          tagsFilterKey,
        };
      }
      case AppRoutes.REVIEW: {
        const reviewFilters = filters as ReviewFilters;
        const prospectsFilterKey = ReviewFilterKeys.PROSPECT;
        const tagsFilterKey = ReviewFilterKeys.TAGS;
        return {
          accountsFilter: [],
          prospectsFilter: reviewFilters[prospectsFilterKey] || [],
          prospectsFilterKey,
          tagsFilter: reviewFilters[tagsFilterKey] || [],
          tagsFilterKey,
        };
      }
      case AppRoutes.ANALYTICS: {
        const analyticsFilters = filters as AnalyticsFilters;
        const prospectsFilterKey = AnalyticsFilterKeys.PROSPECT;
        const tagsFilterKey = AnalyticsFilterKeys.TAGS;
        return {
          accountsFilter: [],
          prospectsFilter: analyticsFilters[prospectsFilterKey] || [],
          prospectsFilterKey,
          tagsFilter: analyticsFilters[tagsFilterKey] || [],
          tagsFilterKey,
        };
      }
      default:
        return {
          accountsFilter: [],
          prospectsFilter: [],
          tagsFilter: [],
        };
    }
  }, [currRoute, filters]);

  // Updates the params if current param values and valid param values are not the same.
  const updateParamsWithValidValues = useCallback(
    (filterKey: FilterKeys, validSelectedValues: string[], isLoading: boolean, filterValue?: string[]) => {
      if (isLoading || !filterValue || filterValue.length === validSelectedValues.length) return;
      updateFilterParams(filterKey, validSelectedValues);
    },
    [updateFilterParams]
  );

  useEffect(() => {
    const { accountsFilter, accountsFilterKey, prospectsFilter, prospectsFilterKey, tagsFilter, tagsFilterKey } =
      filterValues;

    accountsFilterKey &&
      updateParamsWithValidValues(
        accountsFilterKey,
        accountsFilter.filter((account) => accountOptions.find((option) => option.value === account)),
        isProspectsLoading,
        accountsFilter
      );

    prospectsFilterKey &&
      updateParamsWithValidValues(
        prospectsFilterKey,
        prospectsFilter.filter((prospect) => prospectOptions.find((option) => option.value === prospect)),
        isProspectsLoading,
        prospectsFilter
      );

    tagsFilterKey &&
      updateParamsWithValidValues(
        tagsFilterKey,
        tagsFilter.filter((tag) => tagOptions.find((option) => option.value === tag)),
        isTagsLoading,
        tagsFilter
      );
  }, [filterValues, isProspectsLoading, isTagsLoading, updateParamsWithValidValues]);

  // Return an object with filter options, selections, and loading states for each filter type.
  return {
    accounts: {
      options: accountOptions,
      selected: filterValues.accountsFilter,
      loading: isProspectsLoading,
    },
    prospects: {
      options: prospectOptions,
      selected: filterValues.prospectsFilter,
      loading: isProspectsLoading,
    },
    tags: {
      options: tagOptions,
      selected: filterValues.tagsFilter,
      loading: isTagsLoading,
    },
  };
};

export default useFilterOptionsAndSelections;
