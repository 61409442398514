/**
 * Type for the response data item from the generate upload URL mutation.
 */
type UploadFileUrl = {
  presignedUrl: string;
  key: string;
};

/**
 * Type for the response data from the generate upload URL mutation.
 */
export type GenerateUploadUrlResponseData = UploadFileUrl[];

export enum FilePurpose {
  PROSPECT_CREATION = 'PROSPECT_CREATION',
  CALL_CREATION = 'CALL_CREATION',
}

/**
 * Type for the payload data from the generate upload URL mutation.
 */
type FilePayloadType = {
  name: string;
  type: string;
  size: number;
  purpose?: FilePurpose;
};

/**
 * Type for the payload data from the generate upload URL mutation.
 */
export type GenerateUploadUrlPayload = {
  files: FilePayloadType[];
};

/**
 * Type for the status of the file upload.
 */
export enum FileStatus {
  UPLOADING = 'uploading',
  SUCCESS = 'success',
  ERROR = 'error',
}

/**
 * Type for the file upload status.
 */
export type FileDataType = {
  id: string;
  file: File;
  status: FileStatus;
  progress: number;
};
