import { ApiSuccess, ApiTagTypes, ConfirmUserPhoneNumberPayload } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    confirmUserPhoneNumber: builder.mutation<ApiSuccess, ConfirmUserPhoneNumberPayload>({
      query: ({ id, phoneNumber }) => ({
        url: `/phone-verification/${id}/verify`,
        method: 'POST',
        body: { phoneNumber },
      }),
      invalidatesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
  }),
});

export const { useConfirmUserPhoneNumberMutation } = enhancedApiSlice;
