import { useCallback, useState } from 'react';
import { Badge, ButtonColor, ButtonVariant, Icon, IconButton, Spinner } from '../../components';
import { useAppDispatch } from '../../hooks';
import { openMediaPlayer } from '../../redux/reducers';
import { Call, CallProcessingStatus, ComponentSize, StatusColor, TextColor } from '../../types';
import { isCallInProgress, isCallProcessingFailed, isCallProcessingPending } from '../../utils';

interface ReviewListenCellProps {
  call: Call;
}

const ReviewListenCell = ({ call }: ReviewListenCellProps) => {
  const dispatch = useAppDispatch();

  const [isHovered, setIsHovered] = useState(false);

  const runListen = useCallback(() => {
    dispatch(openMediaPlayer({ call, autoPlay: true }));
  }, [call, dispatch]);

  // Disable the listen button if call processing is pending or failed.
  const isDisabled = isCallProcessingPending(call) || isCallProcessingFailed(call);

  // Hide the listen button and show a badge if the call is in progress.
  if (isCallInProgress(call)) {
    return <Badge color={StatusColor.SUCCESS} label="Live" />;
  }

  // Hide the listen button and show a loading spinner if call processing is in progress.
  if (call.processingStatus === CallProcessingStatus.PROCESSING) {
    return <Spinner color={TextColor.SECONDARY} size={ComponentSize.MEDIUM} />;
  }

  return (
    <IconButton
      disabled={isDisabled}
      icon={Icon.PLAY}
      color={isHovered ? ButtonColor.PRIMARY : ButtonColor.SECONDARY}
      variant={isHovered ? ButtonVariant.FILLED : ButtonVariant.OUTLINE}
      onClick={runListen}
      onHover={(hover) => setIsHovered(hover)}
    />
  );
};

export default ReviewListenCell;
