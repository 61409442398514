import dayjs from 'dayjs';
import { DatePickerRange } from '../components';
import { AverageByPeriod, Benchmarks, CallMetric, CallMetricAverageKeys, DateUnit } from '../types';
import { formatSecondsToDuration } from '../utils';

type CallMetricDetails = {
  label: string;
  unit: string;
  benchmarkKey: Benchmarks;
  averageKey: CallMetricAverageKeys;
  formatter: (value: number) => string;
};

/**
 * Default date range for analytics as the current month.
 */
export const DEFAULT_ANALYTICS_DATE_RANGE: DatePickerRange = {
  start: dayjs().startOf(DateUnit.MONTH).toDate(),
  end: dayjs().endOf(DateUnit.MONTH).toDate(),
};

export const DEFAULT_ANALYTICS_AVERAGE_BY = AverageByPeriod.DAY;

export const AVERAGE_BY_OPTIONS = [
  { label: 'Day', value: AverageByPeriod.DAY },
  { label: 'Week', value: AverageByPeriod.WEEK },
];

export const ANALYTICS_ACTIVE_TAB_PARAM = 'analyticsTab';

export const SUCCESS_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE = 5;
export const WARNING_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE = 20;
export const WARNING_FILLER_AND_MONOLOGUE_MARGIN_PERCENTAGE = 20;
export const SCORECARD_SUCCESS_PERCENTAGE = 80;
export const SCORECARD_WARNING_PERCENTAGE = 60;

/**
 * Call metrics with their respective details.
 */
export const CALL_METRICS: Record<CallMetric, CallMetricDetails> = {
  [CallMetric.TALK_SPEED]: {
    label: 'Talk speed',
    unit: 'words per minute',
    benchmarkKey: Benchmarks.TALK_SPEED,
    averageKey: CallMetricAverageKeys.AVERAGE_TALK_SPEED,
    formatter: (value: number) => `${Math.round(value)}`,
  },
  [CallMetric.FILLER_WORDS]: {
    label: 'Filler words',
    unit: 'words per minute',
    benchmarkKey: Benchmarks.FILLER_WORDS,
    averageKey: CallMetricAverageKeys.AVERAGE_FILLER_WORDS,
    formatter: (value: number) => `${Math.round(value)}`,
  },
  [CallMetric.LONGEST_MONOLOGUE]: {
    label: 'Longest monologue',
    unit: 'minutes : seconds',
    benchmarkKey: Benchmarks.LONGEST_MONOLOGUE,
    averageKey: CallMetricAverageKeys.AVERAGE_LONGEST_MONOLOGUE,
    formatter: (value: number) => formatSecondsToDuration(value),
  },
  [CallMetric.TALK_RATIO]: {
    label: 'Talk ratio',
    unit: 'representative speaking',
    benchmarkKey: Benchmarks.TALK_RATIO,
    averageKey: CallMetricAverageKeys.AVERAGE_TALK_RATIO,
    formatter: (value: number) => `${Math.round(value * 100)}%`,
  },
};

export const CALL_METRIC_OPTIONS = Object.entries(CALL_METRICS).map(([key, metricProps]) => ({
  ...metricProps,
  value: key,
}));

export const HEAT_MAP_LABEL_WIDTH = 100;
export const HEAT_MAP_CELL_GAP = 16;
export const HEAT_MAP_LABEL_RIGHT_PADDING = 200;
