import { SKIP_AUTH_HEADER } from '../../constants';
import { ApiSuccess, GenerateUploadUrlPayload, GenerateUploadUrlResponseData } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateUploadUrl: builder.mutation<GenerateUploadUrlResponseData, GenerateUploadUrlPayload>({
      query: ({ files }) => ({
        url: '/file/generate-upload-url',
        method: 'POST',
        body: { files },
      }),
      transformResponse: (response: ApiSuccess<GenerateUploadUrlResponseData>) => response.data,
    }),
    uploadFile: builder.mutation<ApiSuccess, { file: File; uploadUrl: string; signal?: AbortSignal }>({
      query: ({ file, uploadUrl, signal }) => ({
        url: uploadUrl,
        method: 'PUT',
        body: file,
        skipAuth: true,
        signal,
        headers: {
          'Content-Type': file.type,
          // Skip Auth0 authentication for file uploads
          [SKIP_AUTH_HEADER]: 'true',
        },
      }),
    }),
  }),
});

export const { useGenerateUploadUrlMutation, useUploadFileMutation } = enhancedApiSlice;
