import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ButtonColor,
  ButtonVariant,
  ConfirmModal,
  Icon,
  Icons,
  Spinner,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../../components';
import { useAppDispatch, useHandleApiResponse } from '../../../../hooks';
import { updateCall } from '../../../../redux/reducers';
import {
  useCreateScorecardMutation,
  useDeleteScorecardMutation,
  useUpdateScorecardMutation,
} from '../../../../services';
import { ComponentSize, Scorecard, ScorecardAnswer, TextColor } from '../../../../types';
import ScoredByInfo from './ScoredByInfo';

const CREATE_ERROR_MSG = 'Failed to add scorecard.';
const CREATE_SUCCESS_MSG = 'Scorecard added successfully.';

const UPDATE_ERROR_MSG = 'Failed to update scorecard.';
const UPDATE_SUCCESS_MSG = 'Scorecard updated successfully.';

const DELETE_ERROR_MSG = 'Failed to delete scorecard.';
const DELETE_SUCCESS_MSG = 'Scorecard deleted successfully.';

interface ScorecardHeaderProps {
  isAiScoreable: boolean;
  enableEditMode: boolean;
  isScoringProcessing: boolean;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  handleClearAnswers: () => void;
  handleScoreWithAI: () => void;
  selectedScorecardTemplateId?: string;
  scorecardName?: string;
  answers?: ScorecardAnswer[];
  existingScorecard?: Scorecard;
}

const ScorecardHeader = ({
  selectedScorecardTemplateId,
  scorecardName,
  isAiScoreable,
  enableEditMode,
  isScoringProcessing,
  isEditMode,
  setIsEditMode,
  handleClearAnswers,
  handleScoreWithAI,
  answers,
  existingScorecard,
}: ScorecardHeaderProps) => {
  // Mutations
  const [createScorecard, { isLoading: isCreatingScorecard }] = useCreateScorecardMutation();
  const [updateScorecard, { isLoading: isUpdatingScorecard }] = useUpdateScorecardMutation();
  const [deleteScorecard, { isLoading: isDeletingScorecard }] = useDeleteScorecardMutation();

  const handleApiResponse = useHandleApiResponse();
  const { callSid } = useParams();
  const dispatch = useAppDispatch();
  // State
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { id: scorecardId, isScoredByAI, user } = existingScorecard ?? {};

  // If no scorecard Id, means that this scorecard is new/being added for the first time
  const isNewScorecard = !scorecardId;

  const onUpdateCallScorecard = useCallback(
    (pointsPercentage?: number | null) => {
      if (!callSid) return;

      dispatch(
        updateCall({ callSid, scorecard: { ...existingScorecard, pointsPercentage: pointsPercentage ?? null } })
      );
      // Exit edit mode after updating scorecard
      setIsEditMode(false);
    },
    [dispatch, callSid]
  );

  /** Handles the creation of a new scorecard */
  const handleCreateScorecard = useCallback(async () => {
    if (!callSid || !selectedScorecardTemplateId) return;
    try {
      const response = await createScorecard({
        callSid,
        scorecardTemplateId: selectedScorecardTemplateId,
        answers: answers || [],
      });
      handleApiResponse({
        response,
        errorMsg: CREATE_ERROR_MSG,
        successMsg: CREATE_SUCCESS_MSG,
      });
    } catch (error) {
      console.error(`${CREATE_ERROR_MSG}: ${error}`);
    }
  }, [callSid, selectedScorecardTemplateId, answers, createScorecard, handleApiResponse]);

  /** Handles the editing of an existing scorecard */
  const handleEditScorecard = useCallback(async () => {
    if (!scorecardId || !callSid) return;

    if (!isEditMode) {
      setIsEditMode(true);
      return;
    }

    try {
      const response = await updateScorecard({
        id: scorecardId,
        callSid: callSid || '',
        answers: answers || [],
      });
      handleApiResponse({
        response,
        errorMsg: UPDATE_ERROR_MSG,
        successMsg: UPDATE_SUCCESS_MSG,
        onSuccess: (data) => onUpdateCallScorecard(data.scorecard.pointsPercentage),
      });
    } catch (error) {
      console.error(`${UPDATE_ERROR_MSG}: ${error}`);
    }
  }, [scorecardId, callSid, answers, updateScorecard, handleApiResponse, onUpdateCallScorecard, isEditMode]);

  const onDeleteScorecard = useCallback(async () => {
    handleClearAnswers();
    setIsConfirmModalOpen(false);
    dispatch(updateCall({ callSid, scorecard: undefined }));
  }, [handleClearAnswers, callSid, dispatch]);

  /** Handles the deletion of an existing scorecard */
  const handleDeleteOrClearScorecard = useCallback(async () => {
    // If scorecard is new, clear the answers
    if (isNewScorecard) {
      onDeleteScorecard();
      return;
    }

    if (!scorecardId || !callSid) return;

    try {
      const response = await deleteScorecard({ id: scorecardId, callSid });
      handleApiResponse({
        response,
        errorMsg: DELETE_ERROR_MSG,
        successMsg: DELETE_SUCCESS_MSG,
        onSuccess: () => onDeleteScorecard(),
      });
    } catch (error) {
      console.error(`${DELETE_ERROR_MSG}: ${error}`);
    }
  }, [scorecardId, deleteScorecard, handleApiResponse, onDeleteScorecard, isNewScorecard, callSid]);

  const destructiveActionText = isNewScorecard ? 'Clear' : 'Delete';

  const hasNewAnswers = !!answers?.length;

  const destructiveButtonConfirmText = (
    <>
      Are you sure you want to {destructiveActionText.toLowerCase()}&nbsp;
      <span className="font-medium">{scorecardName}</span>? This action cannot be undone.
    </>
  );

  const aiScoringButtonIcon = isScoringProcessing ? (
    <Spinner color={TextColor.SECONDARY} size={ComponentSize.X_SMALL} />
  ) : (
    <Icons icon={Icon.WAND} />
  );

  return (
    <div className="flex justify-between gap-4">
      <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
        {scorecardName}
      </Typography>
      <div className="flex gap-4">
        {scorecardId && <ScoredByInfo isScoredByAI={isScoredByAI} scoredByUser={user} />}
        {enableEditMode && (
          <div className="flex gap-2">
            <TextButton
              text={destructiveActionText}
              onClick={() => setIsConfirmModalOpen(true)}
              variant={ButtonVariant.OUTLINE}
              color={ButtonColor.DESTRUCTIVE}
              size={ComponentSize.X_SMALL}
              disabled={isScoringProcessing}
            />

            {isAiScoreable && isNewScorecard && (
              <TextButton
                text="Score with AI"
                variant={ButtonVariant.OUTLINE}
                onClick={handleScoreWithAI}
                color={ButtonColor.AI}
                size={ComponentSize.X_SMALL}
                startIcon={aiScoringButtonIcon}
                disabled={isScoringProcessing}
              />
            )}

            <TextButton
              text={isNewScorecard || isEditMode ? 'Save' : 'Edit'}
              onClick={isNewScorecard ? handleCreateScorecard : handleEditScorecard}
              size={ComponentSize.X_SMALL}
              loading={isCreatingScorecard || isUpdatingScorecard}
              disabled={!hasNewAnswers || isScoringProcessing}
            />
          </div>
        )}
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          buttonText={destructiveActionText}
          setIsOpen={setIsConfirmModalOpen}
          onConfirm={handleDeleteOrClearScorecard}
          isLoading={isDeletingScorecard}
          title={`${destructiveActionText} scorecard`}
          confirmText={destructiveButtonConfirmText}
          destructive
        />
      </div>
    </div>
  );
};

export default ScorecardHeader;
