import { useCallback, useEffect } from 'react';
import { Icon, Spinner } from './components';
import { SCREEN_SIZE_BREAKPOINTS } from './constants';
import { CustomRouterProvider, ToastProvider } from './context';
import { LaunchDarklyProvider } from './context/LaunchDarklyProvider';
import {
  useAppSelector,
  useSyncAuthState,
  useWindowResize,
  useSocketConnection,
  useRUM,
  useAppDispatch,
} from './hooks';
import { MessagePage } from './pages';
import { useFinishSetupMutation } from './services';
import { setIsOnboarded } from './redux/reducers';

/**
 * AppContent component manages the main content of the application.
 * It checks internet connection, window size, and renders appropriate messages or content.
 * It also sets up providers for feature flags, toast notifications, and routing.
 */
const AppContent = () => {
  // Synchronize Auth0 authentication state with Redux.
  useSyncAuthState();
  useRUM();
  useSocketConnection();

  const windowSize = useWindowResize();
  const isMobile = windowSize <= SCREEN_SIZE_BREAKPOINTS.MOBILE;

  const dispatch = useAppDispatch();
  const { isAuthenticated: reduxIsAuthenticated, isOnboarded } = useAppSelector((state) => state.auth);

  const hasInternetConnection = useAppSelector((state) => state.connection.hasInternetConnection);

  const [finishSetup, { isLoading: isFinishingSetup }] = useFinishSetupMutation();

  // Handles finish setup and onboarding user.
  const handleFinishSetup = useCallback(async () => {
    try {
      const response = await finishSetup();
      if (response.error) return;

      // If finish setup is successful, set the user as onboarded.
      if (response.data) {
        dispatch(setIsOnboarded(true));
      }
    } catch (error) {
      console.error('Failed to finish setup', error);
    }
  }, [finishSetup, dispatch]);

  // Finish setup and onboard user if they are authenticated and not onboarded.
  useEffect(() => {
    if (reduxIsAuthenticated && !isOnboarded) {
      handleFinishSetup();
    }
  }, [isOnboarded, reduxIsAuthenticated, handleFinishSetup]);

  if (isFinishingSetup) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!hasInternetConnection) {
    return (
      <MessagePage
        icon={Icon.WIFI_OFF}
        title="No internet connection"
        message="Please check your connection and refresh this page"
      />
    );
  }

  // If the window is too small, show the "View on desktop" page.
  if (isMobile) {
    return (
      <MessagePage
        icon={Icon.LAPTOP}
        title="Please view on desktop"
        message="For best experience, please open FullyRamped on a larger device."
      />
    );
  }

  return (
    <LaunchDarklyProvider>
      <ToastProvider>
        <CustomRouterProvider />
      </ToastProvider>
    </LaunchDarklyProvider>
  );
};

export default AppContent;
