import { useAppSelector } from '../../../../hooks';
import { Permissions } from '../../../../types';
import CallType from './CallType';
import ConversationTuning from './ConversationTuning';
import EndingTheCall from './EndingTheCall';
import ManagerSettings from './ManagerSettings';
import TechnicalConfiguration from './TechnicalConfiguration';

const ConfigurationTab = () => {
  const user = useAppSelector((state) => state.auth.user);
  const isSuperAdmin = (user?.permissions || []).includes(Permissions.ADMIN_ACCESS);

  return (
    <div className="flex flex-col gap-4">
      <CallType isSuperAdmin={isSuperAdmin} />
      <ManagerSettings />
      {isSuperAdmin && (
        <>
          <ConversationTuning />
          <EndingTheCall />
          <TechnicalConfiguration />
        </>
      )}
    </div>
  );
};

export default ConfigurationTab;
