import { ApiSuccess, ApiTagTypes, CreateCandidatePayload } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCandidate: builder.mutation<ApiSuccess, CreateCandidatePayload>({
      query: (candidateDate) => ({
        url: '/candidates/create',
        method: 'POST',
        body: candidateDate,
      }),
      invalidatesTags: [ApiTagTypes.CANDIDATES],
    }),
    deleteCandidate: builder.mutation<ApiSuccess, string>({
      query: (candidateId) => ({
        url: `/candidates/${candidateId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.CANDIDATES],
    }),
  }),
});

export const { useCreateCandidateMutation, useDeleteCandidateMutation } = enhancedApiSlice;
