import { Dot, Tooltip, Typography, TypographySize, TypographyWeight } from '../../../../components';
import { DropdownPlacement, StatusColor } from '../../../../types';

const STAT_CARD_HEIGHT = 106;

interface CallStatsCardProps {
  title: string;
  value: string | number;
  tooltip: string;
  indicatorColor?: StatusColor;
}

const CallStatsCard = ({ title, value, tooltip, indicatorColor }: CallStatsCardProps) => {
  return (
    <Tooltip label={tooltip} triggerConfig={{ fullWidth: true }} placement={DropdownPlacement.BOTTOM}>
      <div
        className="flex h-full w-full flex-col justify-center gap-1 rounded-lg border border-base-200 p-4"
        style={{ minHeight: STAT_CARD_HEIGHT }}
      >
        <Typography>{title}</Typography>
        <div className="flex items-center gap-2">
          {indicatorColor && <Dot color={indicatorColor} />}
          <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
            {value}
          </Typography>
        </div>
      </div>
    </Tooltip>
  );
};

export default CallStatsCard;
