import { User as Auth0User } from '@auth0/auth0-react';
import { PaginationState } from '@tanstack/react-table';
import { DatePickerRange } from '../components';
import { Candidate } from './candidates.types';
import { ReviewFilterKeys } from './filters.types';
import { PagePagination } from './pagination.types';
import { ApiListPracticeProspect, PracticeProspect, ProspectCategory } from './prospect.types';
import { CustomSortingState } from './sorting.types';
import { Scorecard } from './scorecard.types';

// -- ENUMS --
export enum CallProcessingStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
}

export enum CallSortingFilters {
  CALL_DURATION = 'callDuration',
  CALLER = 'caller',
  PROSPECT = 'prospect',
  START_TIME = 'startTime',
  POINTS_PERCENTAGE = 'pointsPercentage',
}

export enum CallSpeaker {
  AGENT = 'AGENT',
  CUSTOMER = 'CUSTOMER',
}

export enum CallMethod {
  WEB_CALL = 'WEB_CALL',
  TELEPHONY = 'TELEPHONY',
}

type WordBase = {
  word: string;
  start: number;
  end: number;
  confidence: number;
  punctuated_word?: string;
  speaker?: number;
  speaker_confidence?: number;
};

export type Utterance = {
  start: number;
  end: number;
  transcript: string;
  speaker: CallSpeaker;
  channel: number;
  id: string;
  words: WordBase[];
};

// -- TYPES --
export type CallSpeechCategory = {
  id: string;
  callSid: string;
  summary: string;
  speaker: CallSpeaker;
  category: string;
  label: string;
  importanceConfidence: number;
  startTime: number;
  endTime: number;
  createdAt: Date;
  updatedAt: Date;
};

// TODO: Extend the ReviewFilters type when the API accepts the date as a single value.
export interface CallsPayload {
  // Filters
  [ReviewFilterKeys.CATEGORY]?: ProspectCategory[];
  [ReviewFilterKeys.INCOMING_PHONE_NUMBER]?: string[];
  [ReviewFilterKeys.PROSPECT]?: string[];
  [ReviewFilterKeys.CALL_DATE]?: DatePickerRange[];
  [ReviewFilterKeys.TAGS]?: string[];
  [ReviewFilterKeys.USER]?: string[];
  [ReviewFilterKeys.FLAGGED]?: boolean;
  [ReviewFilterKeys.TAGS]?: string[];
  [ReviewFilterKeys.TEAM]?: string[];
  // Controls
  pagination?: PaginationState;
  sorting?: CustomSortingState;
}

export enum CallStatus {
  STARTED = 'started',
  ENDED = 'ended',
}

type CallChapter = {
  id: string;
  callSid: string;
  title: string;
  startTime: number;
  endTime: number;
};

export type CallCoaching = {
  overallFeedback: string;
  strengths: string[];
  areasOfImprovement: string[];
  nextSteps: string[];
};

export type CallWithoutProspect = {
  callSid: string;
  callStatus: CallStatus;
  callType: string; // "inbound" or "outbound"
  incomingPhoneNumber?: string;
  orgId: string;
  personaId: string;
  receivingPhoneNumber: string;
  startTime: Date;
  audioPath?: string;
  callDuration?: number | null; // Duration in seconds
  endedReason?: string | null;
  endTime?: Date;
  transcriptionPath?: string | null;
  user?: Auth0User;
  userId?: string;
  candidate?: Candidate;
  candidateId?: string | null;
  attemptNumber?: number | null;

  // Processing details
  fillerWordsCount?: number;
  longestMonologueDuration?: number;
  longestMonologueStart?: number;
  processingStatus?: CallProcessingStatus;
  coachingStatus?: CallProcessingStatus;
  aiCoachingResult?: CallCoaching | null;
  scoringStatus?: CallProcessingStatus;
  summary?: string;
  talkTime?: number;
  wordsCount?: number;
  isSuccessfulOutcome?: boolean | null;

  callChapters?: CallChapter[];
  callSpeechCategories?: CallSpeechCategory[];

  // Flagging details
  flaggedUserIds?: string[];
  isFlaggedToUser?: boolean;

  // Call score
  // If scorecard is saved from get calls api, it will only have the pointsPercentage.
  // If scorecard is saved from websocket event response,
  // it will have the full scorecard object to populate realtime.
  scorecard?:
    | {
        pointsPercentage: number | null;
      }
    | Scorecard;

  // Call comments
  numberOfComments?: number;
  usersCommented?: {
    id: string;
    name: string;
    picture: string;
  }[];
};

export type Call = CallWithoutProspect & {
  practiceProspect: PracticeProspect;
};

type CallWithApiListPracticeProspect = CallWithoutProspect & {
  practiceProspect: ApiListPracticeProspect;
};

export type CallResponse = {
  data: {
    call: CallWithApiListPracticeProspect;
  };
};

export type CallsResponse = {
  data: {
    calls: CallWithApiListPracticeProspect[];
    pagination: PagePagination;
  };
};

export type FlaggedUser = {
  userId: string;
  notify: boolean;
};

export type FlagCallPayload = {
  callSid: string;
  flaggedUsers: FlaggedUser[];
};
