import { useCallback, useEffect } from 'react';
import { addCall, updateCall } from '../redux/reducers';
import { useAppDispatch, useAppSelector } from '../hooks';
import { CallWithoutProspect, Roles } from '../types';
import { socket, useGetPracticeProspectByIdMutation } from '../services';
import { isHigherRole } from '../utils';

enum CallEvent {
  // Call lifecycle events
  CALL_STARTED = 'call:started',
  CALL_ENDED = 'call:ended',

  // Processing events
  CALL_PROCESSING = 'call:processing',
  CALL_PROCESSED = 'call:processed',
  CALL_PROCESSING_FAILED = 'call:processing:failed',

  // Scoring events
  CALL_SCORING_STARTED = 'call:scoring:started',
  CALL_SCORING_COMPLETED = 'call:scoring:completed',
  CALL_SCORING_FAILED = 'call:scoring:failed',

  // Coaching events
  CALL_COACHING_STARTED = 'call:coaching:started',
  CALL_COACHING_COMPLETED = 'call:coaching:completed',
  CALL_COACHING_FAILED = 'call:coaching:failed',
}

type CallEventResponse = {
  data: {
    call: CallWithoutProspect;
    callerRole: Roles;
  };
};

const useCallSocketEvents = () => {
  const dispatch = useAppDispatch();

  const [getPracticeProspectById] = useGetPracticeProspectByIdMutation();
  const { user } = useAppSelector((state) => state.auth);
  const { role: userRole, id: userId } = user || {};

  const handleCallStarted = useCallback(
    async (response: CallEventResponse) => {
      if (!userRole || !userId) return;

      const { call, callerRole } = response.data;
      const { personaId: prospectId, userId: callerId } = call;

      // TODO: Remove this when backend return only relevant calls to each user. @yomnashaban
      // Sales reps can only see their calls
      // All other roles can see same and lower roles calls
      const canViewCall = isHigherRole(userRole, callerRole, userRole !== Roles.SALES_REP || userId === callerId);

      if (!prospectId || !canViewCall) return;

      const prospect = await getPracticeProspectById(prospectId);
      if (!prospect.data) return;

      dispatch(addCall({ ...call, practiceProspect: prospect.data }));
    },
    [getPracticeProspectById, dispatch, userRole, userId]
  );

  const handleUpdateCall = useCallback(
    (response: CallEventResponse) => dispatch(updateCall(response.data.call)),
    [dispatch]
  );

  useEffect(() => {
    socket.on(CallEvent.CALL_STARTED, handleCallStarted);
    socket.on(CallEvent.CALL_ENDED, handleUpdateCall);

    socket.on(CallEvent.CALL_PROCESSING, handleUpdateCall);
    socket.on(CallEvent.CALL_PROCESSED, handleUpdateCall);
    socket.on(CallEvent.CALL_PROCESSING_FAILED, handleUpdateCall);

    socket.on(CallEvent.CALL_COACHING_STARTED, handleUpdateCall);
    socket.on(CallEvent.CALL_COACHING_COMPLETED, handleUpdateCall);
    socket.on(CallEvent.CALL_COACHING_FAILED, handleUpdateCall);

    socket.on(CallEvent.CALL_SCORING_STARTED, handleUpdateCall);
    socket.on(CallEvent.CALL_SCORING_COMPLETED, handleUpdateCall);
    socket.on(CallEvent.CALL_SCORING_FAILED, handleUpdateCall);

    // Clean up the event listeners when the component unmounts
    return () => {
      socket.off(CallEvent.CALL_STARTED, handleCallStarted);
      socket.off(CallEvent.CALL_ENDED, handleUpdateCall);

      socket.off(CallEvent.CALL_PROCESSING, handleUpdateCall);
      socket.off(CallEvent.CALL_PROCESSED, handleUpdateCall);
      socket.off(CallEvent.CALL_PROCESSING_FAILED, handleUpdateCall);

      socket.off(CallEvent.CALL_COACHING_STARTED, handleUpdateCall);
      socket.off(CallEvent.CALL_COACHING_COMPLETED, handleUpdateCall);
      socket.off(CallEvent.CALL_COACHING_FAILED, handleUpdateCall);

      socket.off(CallEvent.CALL_SCORING_STARTED, handleUpdateCall);
      socket.off(CallEvent.CALL_SCORING_COMPLETED, handleUpdateCall);
      socket.off(CallEvent.CALL_SCORING_FAILED, handleUpdateCall);
    };
  }, [socket, dispatch, handleCallStarted, handleUpdateCall]);
};

export default useCallSocketEvents;
