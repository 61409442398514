import { ApiTagTypes, OrganizationSettings, OrganizationSettingsResponse } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationSettings: builder.query<OrganizationSettings, void>({
      query: () => '/organization-settings',
      transformResponse: (response: OrganizationSettingsResponse) => response.data.settings,
      providesTags: [ApiTagTypes.ORGANIZATION_SETTINGS],
    }),
  }),
});

export const { useGetOrganizationSettingsQuery } = enhancedApiSlice;
