import { TooltipProps } from './Tooltip.types';
import TooltipContent from './TooltipContent';
import TooltipRoot from './TooltipRoot';
import TooltipTrigger from './TooltipTrigger';

/**
 * A wrapper component that simplifies adding tooltips to elements.
 *
 * Combines TooltipRoot, TooltipTrigger and TooltipContent into a single, easy-to-use component
 * to reduce boilerplate code when adding tooltips.
 */
const Tooltip = ({ children, disabled, label, triggerConfig, ...props }: TooltipProps) => {
  return (
    <TooltipRoot {...props}>
      {children && <TooltipTrigger {...triggerConfig}>{children}</TooltipTrigger>}
      {!disabled && <TooltipContent>{label}</TooltipContent>}
    </TooltipRoot>
  );
};

export default Tooltip;
