import { useEffect } from 'react';
import { ButtonColor, Icon, TextButton } from '../../../../components';
import { MIN_REQUIRED_SCENARIO_ITEMS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  addPriority,
  deletePriority,
  randomizePriorities,
  setIsDynamicPriorities,
  setNumOfDynamicPriorities,
  updatePriority,
} from '../../../../redux/reducers';
import TabSection from '../TabSection';
import DynamicItemsSelector from './DynamicItemsSelector';
import ScenarioListItem from './ScenarioListItem';

const Priorities = () => {
  const dispatch = useAppDispatch();
  const {
    fields: { priorities, isDynamicPriorities: isDynamicPrioritiesChecked, numOfDynamicPriorities },
  } = useAppSelector((state) => state.prospect);

  const canDelete = priorities.length > MIN_REQUIRED_SCENARIO_ITEMS;
  const numFilledPriorities = priorities.filter((p) => !!p.value.trim()).length;

  // Delete empty priorities on unmount.
  useEffect(() => {
    return () => {
      if (priorities.length > MIN_REQUIRED_SCENARIO_ITEMS) {
        priorities.forEach((priority, index) => {
          if (!priority.value) {
            dispatch(deletePriority(index));
          }
        });
      }
    };
  }, []);

  return (
    <TabSection title="Priorities" onRandomize={() => dispatch(randomizePriorities())}>
      {priorities.map((priority, index) => (
        <ScenarioListItem
          key={index}
          index={index}
          canDelete={canDelete}
          isRequired={index < MIN_REQUIRED_SCENARIO_ITEMS}
          value={priority.value}
          deleteItem={() => dispatch(deletePriority(index))}
          updateItem={(value) => dispatch(updatePriority({ index, value }))}
        />
      ))}
      <div className="flex items-center gap-4">
        <TextButton
          text="Add priority"
          startIcon={Icon.PLUS}
          color={ButtonColor.SECONDARY}
          onClick={() => {
            dispatch(addPriority());
          }}
        />
        <DynamicItemsSelector
          isDynamicChecked={isDynamicPrioritiesChecked}
          label="priority"
          numOfDynamicItems={numOfDynamicPriorities}
          totalItems={numFilledPriorities}
          setIsDynamicChecked={(newChecked) => dispatch(setIsDynamicPriorities(newChecked))}
          setNumOfDynamicItems={(newNumOfDynamicItems) => dispatch(setNumOfDynamicPriorities(newNumOfDynamicItems))}
        />
      </div>
    </TabSection>
  );
};

export default Priorities;
