import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonColor, Icon, TextArea, TextButton, TextInput } from '../../components';
import { AppRoutes } from '../../constants';
import { PromptTemplate } from '../../types';
import ManagePromptTemplateFooter from './ManagePromptTemplateFooter';
import PromptTemplateLiquidTags from './PromptTemplateLiquidTags';
import PromptTemplateSettings from './PromptTemplateSettings';

interface ManagePromptTemplatePageProps {
  /**
   * The prompt template to edit.
   * If not provided, the user is creating a new prompt template.
   */
  promptTemplate?: PromptTemplate;
}

const ManagePromptTemplatePage = ({ promptTemplate }: ManagePromptTemplatePageProps) => {
  const navigate = useNavigate();

  const [cursorPosition, setCursorPosition] = useState(0);
  const [nameInput, setNameInput] = useState(promptTemplate?.name || '');
  const [newIsDefault, setNewIsDefault] = useState(promptTemplate?.default || false);
  const [promptInput, setPromptInput] = useState(promptTemplate?.prompt || '');
  const [selectedCategory, setSelectedCategory] = useState(promptTemplate?.category);
  const [selectedType, setSelectedType] = useState(promptTemplate?.type);
  const [isInsertingTag, setIsInsertingTag] = useState(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromptInput(e.target.value);
    setCursorPosition(e.target.selectionStart);
  };

  const insertTagAtCursor = useCallback(
    (tag: string) => {
      setIsInsertingTag(true);
      // Insert the tag at the cursor position.
      const newValue = promptInput.slice(0, cursorPosition) + tag + promptInput.slice(cursorPosition);
      setPromptInput(newValue);
      // Set the cursor position to the end of the tag.
      const newCursorPosition = cursorPosition + tag.length;
      setCursorPosition(newCursorPosition);
    },
    [cursorPosition, promptInput]
  );

  // Navigate back to the prompt templates page.
  const handleBack = useCallback(() => {
    navigate(AppRoutes.PROMPT_TEMPLATES);
  }, [navigate]);

  // After inserting a tag, refocus the text area and set the cursor position to the end of the tag.
  useEffect(() => {
    if (textAreaRef.current && isInsertingTag) {
      const textarea = textAreaRef.current;
      const scrollPos = textarea.scrollTop; // Save current scroll position.

      textarea.focus();
      textarea.setSelectionRange(cursorPosition, cursorPosition);
      textarea.scrollTop = scrollPos; // Restore scroll position.

      setIsInsertingTag(false);
    }
  }, [cursorPosition, isInsertingTag]);

  const renderSidebar = (content: React.ReactNode) => (
    <div className="min-w-64 max-w-64 rounded-lg bg-base-100 p-4">{content}</div>
  );

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center gap-4 px-8 pt-4">
        <TextButton onClick={handleBack} text="Back" color={ButtonColor.SECONDARY} startIcon={Icon.CHEVRON_LEFT} />
        <TextInput
          placeholder="Prompt template name*"
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
        />
      </div>
      <div className="flex h-full justify-center overflow-y-auto p-8">
        <div className="flex h-full w-full gap-4">
          {renderSidebar(
            <PromptTemplateSettings
              newIsDefault={newIsDefault}
              promptTemplate={promptTemplate}
              setNewIsDefault={setNewIsDefault}
              setSelectedCategory={setSelectedCategory}
              setSelectedType={setSelectedType}
              selectedCategory={selectedCategory}
              selectedType={selectedType}
            />
          )}
          <TextArea
            fillContainer
            ref={textAreaRef}
            placeholder="Prompt template text*"
            value={promptInput}
            onChange={handleTextAreaChange}
            onSelect={(e) => setCursorPosition(e.currentTarget.selectionStart)}
          />
          {renderSidebar(<PromptTemplateLiquidTags onInsertTag={insertTagAtCursor} />)}
        </div>
      </div>
      <ManagePromptTemplateFooter
        promptTemplate={promptTemplate}
        nameInput={nameInput}
        newIsDefault={newIsDefault}
        promptInput={promptInput}
        selectedCategory={selectedCategory}
        selectedType={selectedType}
        handleBack={handleBack}
      />
    </div>
  );
};

export default ManagePromptTemplatePage;
