import {
  Benchmarks,
  OrganizationSettings,
  StatusColor,
  TeamPerformanceAnalyticsData,
  CallMetric,
  TeamPerformanceAnalyticsResponse,
  BaseScorecardTemplate,
  HeatMapData,
  HeatMapRowData,
  HeatMapDataPoint,
} from '../types';
import {
  SUCCESS_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE,
  WARNING_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE,
  WARNING_FILLER_AND_MONOLOGUE_MARGIN_PERCENTAGE,
  CALL_METRICS,
  STATUS_COLOR_TO_HEX_COLOR,
  SCORECARD_SUCCESS_PERCENTAGE,
  SCORECARD_WARNING_PERCENTAGE,
} from '../constants';
import { kebabCaseToLabel } from './string.utils';
import { conditionalObject } from './object.utils';

/* Get the color of the indicator based on the value and the benchmark set in the org settings. */
export const getStatIndicatorColor = (value: number, type: Benchmarks, benchmark?: number | null) => {
  if (!benchmark) return undefined;

  switch (type) {
    case Benchmarks.LONGEST_MONOLOGUE:
    case Benchmarks.FILLER_WORDS:
      // Value at benchmark or under is successful
      if (value <= benchmark) return StatusColor.SUCCESS_CONTENT;
      // Value up to 20% above benchmark is warning
      if (value >= benchmark * (1 + WARNING_FILLER_AND_MONOLOGUE_MARGIN_PERCENTAGE / 100)) return StatusColor.WARNING;
      // Value more than 20% above benchmark is critical
      return StatusColor.ERROR_CONTENT;

    case Benchmarks.TALK_RATIO:
    case Benchmarks.TALK_SPEED: {
      const deviation = (Math.abs(value - benchmark) / benchmark) * 100;
      // Value up to 5% below or above benchmark is successful
      if (deviation <= SUCCESS_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE) return StatusColor.SUCCESS_CONTENT;
      // Value up to 20% below or above benchmark is warning
      if (deviation <= WARNING_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE) return StatusColor.WARNING;
      // Value more than 20% below or above benchmark is critical
      return StatusColor.ERROR_CONTENT;
    }

    default:
      return undefined;
  }
};

/* Utility function to get hex color from status color */
const getHexColorFromStatus = (statusColor: StatusColor | undefined) => {
  return statusColor ? STATUS_COLOR_TO_HEX_COLOR[statusColor] : undefined;
};

/* Utility function to determine the color based on scorecard average */
const getScorecardIndicatorColor = (average: number): StatusColor => {
  // Value above success threshold is successful
  if (average >= SCORECARD_SUCCESS_PERCENTAGE / 100) {
    return StatusColor.SUCCESS_CONTENT;
  }
  // Value above or equal to warning threshold is warning
  if (average >= SCORECARD_WARNING_PERCENTAGE / 100) {
    return StatusColor.WARNING;
  }
  // Value below warning threshold is critical
  return StatusColor.ERROR_CONTENT;
};

const includeUnit = (metric: CallMetric) => metric === CallMetric.TALK_SPEED || metric === CallMetric.FILLER_WORDS;

/* Get the data points for the heat map chart. */
const getHeatMapRowDataPoints = (
  entry: TeamPerformanceAnalyticsData[number],
  metrics: Partial<Record<CallMetric, boolean> & { scorecardTemplateIds: string[] }>,
  scorecardTemplates: BaseScorecardTemplate[],
  orgConfigs?: OrganizationSettings
): HeatMapDataPoint[] => {
  // Filter the metrics that are enabled in the compute fields
  // Because call metrics values can be null in 2 cases
  // 1. Compute field not selected - column should be filtered out
  // 2. Invalid metric data - in this case we fallback to 0
  const metricsDataPoints = Object.keys(CALL_METRICS)
    .filter((metricKey) => metrics?.[metricKey as CallMetric] === true)
    .map((metricKey) => {
      const metric = metricKey as CallMetric;
      const value = entry[CALL_METRICS[metric].averageKey] ?? 0;
      const adjustedValue = metric === CallMetric.TALK_RATIO ? value * 100 : value;
      return {
        x: CALL_METRICS[metric].label,
        y: value,
        key: metric,
        ...conditionalObject(includeUnit(metric), {
          unit: CALL_METRICS[metric].unit,
        }),
        color: getHexColorFromStatus(
          getStatIndicatorColor(
            adjustedValue,
            CALL_METRICS[metric].benchmarkKey,
            orgConfigs?.[CALL_METRICS[metric].benchmarkKey]
          )
        ),
        formatter: CALL_METRICS[metric].formatter,
      };
    });

  // Add scorecard data points for scorecardTemplateIds
  const scorecardDataPoints = metrics?.scorecardTemplateIds?.map((templateId) => {
    // Find scorecard template details
    const scorecard = scorecardTemplates.find((template) => template.id === templateId);
    // Get the average score for the scorecard
    const average = entry.scorecardAveragesByTemplate[templateId]?.average;
    return {
      x: scorecard?.name ?? templateId,
      y: average,
      key: templateId,
      ...conditionalObject(average !== undefined, {
        color: getHexColorFromStatus(getScorecardIndicatorColor(average)),
      }),
      formatter: (value: number) => `${Math.round(value * 100)}%`,
    };
  });

  return [...metricsDataPoints, ...(scorecardDataPoints ?? [])];
};

/* Transform the backend data to the heat map data. */
export const transformToHeatMapData = (
  backendData: TeamPerformanceAnalyticsResponse,
  scorecardTemplates: BaseScorecardTemplate[],
  orgConfigs?: OrganizationSettings
): HeatMapData[] => {
  // Map of team id to users data
  const teamMap: { [teamId: string]: HeatMapData } = {};
  // Collect users with no team
  const noTeamUsers: HeatMapRowData[] = [];

  const { analytics: analyticsData, defaultAnalyticsMetrics: analyticsMetrics } = backendData;

  analyticsData.forEach((entry) => {
    const userDataPoints = getHeatMapRowDataPoints(entry, analyticsMetrics, scorecardTemplates, orgConfigs);

    if (!entry.teams.length) {
      // Handle users with no team
      const user: HeatMapRowData = {
        id: `user-${entry.user.id}`,
        name: entry.user.name,
        data: userDataPoints,
      };
      noTeamUsers.push(user);
    } else {
      entry.teams.forEach((team) => {
        // Initialize the team in the map if it doesn't exist
        if (!teamMap[team.id]) {
          teamMap[team.id] = {
            id: team.id,
            name: team.name,
            data: [],
            rows: [],
          };
        }

        // Initialize the user in the team if it doesn't exist
        const user: HeatMapRowData = {
          // Same user can be in multiple teams,
          // So we append the team id to the user id for unique identifier
          id: `team-${team.id}-user-${entry.user.id}`,
          name: entry.user.name,
          data: userDataPoints,
        };

        // Add the user to the team map
        teamMap[team.id].rows?.push(user);
      });
    }
  });

  // Calculate averages for each team
  Object.values(teamMap).forEach((team) => {
    const metricAverages = Object.keys(CALL_METRICS)
      .filter((metricKey) => analyticsMetrics?.[metricKey as CallMetric] === true)
      .map((metricKey) => {
        const metric = metricKey as CallMetric;
        // Total of all the users' data points for the metric
        const total =
          team.rows?.reduce((sum, row) => {
            const dataPoint = row.data.find((d) => d.key === metric);
            return sum + (dataPoint?.y ?? 0);
          }, 0) ?? 0;
        // Count of all the users' data points for the metric
        const count = team.rows?.length ?? 0;
        // Average of all the users' data points for the metric
        const average = total / count;
        // Multiply by 100 for Talk Ratio to convert to percentage
        const adjustedAverage = metric === CallMetric.TALK_RATIO ? average * 100 : average;
        return {
          x: CALL_METRICS[metric].label,
          key: metric,
          y: average,
          ...conditionalObject(includeUnit(metric), {
            unit: CALL_METRICS[metric].unit,
          }),
          color: getHexColorFromStatus(
            getStatIndicatorColor(
              adjustedAverage,
              CALL_METRICS[metric].benchmarkKey,
              orgConfigs?.[CALL_METRICS[metric].benchmarkKey]
            )
          ),
          formatter: CALL_METRICS[metric].formatter,
        };
      });

    // Calculate scorecard averages for each team
    const scorecardTeamAverages = (analyticsMetrics?.scorecardTemplateIds || []).map((templateId) => {
      const scores = team.rows?.map((row) => {
        const scorecard = row.data.find((d) => d.key === templateId);
        return scorecard?.y;
      });

      // Filter out undefined scores
      const validScores = scores?.filter((score) => score !== undefined);
      // Count of users with defined scores
      const count = validScores?.length ?? 0;
      // Count of all the users' scorecard valid averages for the template
      const total = validScores?.reduce((sum, score) => (sum ?? 0) + (score ?? 0), 0) ?? 0;
      // Average of all the users' scorecard valid averages for the template
      const average = total / count;
      // Use the template name for the x value
      const templateName = scorecardTemplates.find((template) => template.id === templateId)?.name || templateId;

      return {
        x: templateName,
        key: templateId,
        ...conditionalObject(count > 0, {
          y: average,
          color: getHexColorFromStatus(getScorecardIndicatorColor(average ?? 0)),
        }),
        formatter: (value: number) => `${Math.round(value * 100)}%`,
      };
    });

    team.data = [...metricAverages, ...scorecardTeamAverages];
  });

  const NO_TEAM_ID = 'no-team';

  // Add users that don't have a team to 'no team' team
  if (noTeamUsers.length > 0) {
    teamMap[NO_TEAM_ID] = {
      id: NO_TEAM_ID,
      name: kebabCaseToLabel(NO_TEAM_ID),
      data: [],
      rows: noTeamUsers,
    };
  }

  return Object.values(teamMap);
};
