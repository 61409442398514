import { useMemo } from 'react';
import { Select, TextInput, TextInputType } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  setBreathingRoom,
  setNumOfInitialShortResponses,
  setNumOfRequiredUncoveredPriorities,
  setObjectionRate,
  setQuestionRate,
} from '../../../../redux/reducers';
import { SeverityLevel } from '../../../../types';
import { handleNumberInputChange, parseStringToNumber, snakeCaseToLabel } from '../../../../utils';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const TUNING_SELECT_WIDTH = 340;

const SEVERITY_LEVEL_OPTIONS = Object.values(SeverityLevel).map((level) => ({
  label: snakeCaseToLabel(level),
  value: level,
}));

const ConversationTuning = () => {
  const dispatch = useAppDispatch();
  const {
    priorities,
    breathingRoom,
    numOfInitialShortResponses,
    objectionRate,
    questionRate,
    numOfRequiredUncoveredPriorities: numOfPriorities,
  } = useAppSelector((state) => state.prospect.fields);

  // +1 because we want to include the option to select 0 uncovered priorities.
  const numOfPrioritiesOptions = Array.from({ length: priorities.length + 1 }, (_, index) => ({
    label: index.toString(),
    value: index.toString(),
  }));

  // All fields are clearable bec they are not required.
  const firstRowFields = useMemo(
    () => [
      {
        label: 'Breathing room (# of seconds)',
        content: (
          <TextInput
            type={TextInputType.NUMBER}
            value={breathingRoom?.toString()}
            onChange={handleNumberInputChange(dispatch, setBreathingRoom)}
          />
        ),
      },
      {
        label: '# of initial short responses',
        content: (
          <TextInput
            type={TextInputType.NUMBER}
            value={numOfInitialShortResponses?.toString()}
            onChange={handleNumberInputChange(dispatch, setNumOfInitialShortResponses)}
          />
        ),
      },
    ],
    [breathingRoom, numOfInitialShortResponses]
  );

  const secondRowFields = useMemo(
    () => [
      {
        label: 'Objection rate',
        content: (
          <Select
            clearable
            options={SEVERITY_LEVEL_OPTIONS}
            selected={objectionRate}
            onChange={(newValue) => dispatch(setObjectionRate(newValue as SeverityLevel))}
          />
        ),
      },
      {
        label: 'Question rate',
        content: (
          <Select
            clearable
            options={SEVERITY_LEVEL_OPTIONS}
            selected={questionRate}
            onChange={(newValue) => dispatch(setQuestionRate(newValue as SeverityLevel))}
          />
        ),
      },
    ],
    [objectionRate, questionRate]
  );

  return (
    <TabSection title="Conversation tuning">
      <ProspectFieldGroup fields={firstRowFields} />
      <ProspectFieldGroup fields={secondRowFields} />
      <ProspectPageField
        label="Required uncovered priorities"
        width={TUNING_SELECT_WIDTH}
        content={
          <Select
            clearable
            options={numOfPrioritiesOptions}
            selected={numOfPriorities?.toString()}
            onChange={(newValue) => {
              const newNumOfPriorities = parseStringToNumber(newValue);
              dispatch(setNumOfRequiredUncoveredPriorities(newNumOfPriorities));
            }}
          />
        }
      />
    </TabSection>
  );
};

export default ConversationTuning;
