import { Typography, TypographySize, TypographyWeight } from '../../components';
import { PROMPT_TEMPLATE_LIQUID_TAGS } from '../../constants';

interface PromptTemplateLiquidTagsProps {
  onInsertTag: (tag: string) => void;
}

const PromptTemplateLiquidTags = ({ onInsertTag }: PromptTemplateLiquidTagsProps) => {
  return (
    <div className="flex flex-wrap gap-2 overflow-hidden">
      {PROMPT_TEMPLATE_LIQUID_TAGS.map((tag) => {
        const tagLabel = `{{${tag}}}`;
        return (
          <div key={tag} className="flex items-center">
            <Typography
              color="text-primary"
              mono
              size={TypographySize.CAPTION}
              className="cursor-pointer rounded-lg border border-gray-900 px-2 py-1 hover:bg-base-100"
              weight={TypographyWeight.BOLD}
              onClick={() => onInsertTag(tagLabel)}
            >
              {tagLabel}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default PromptTemplateLiquidTags;
