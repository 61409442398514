import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClientPoint,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import * as React from 'react';
import { DropdownPlacement } from '../../../types';
import { TooltipOptions } from './Tooltip.types';

const TOOLTIP_DEFAULT_DELAY = 300;

/** Custom hook to manage the tooltip state and behavior. */
export function useTooltip({
  initialOpen = false,
  placement = DropdownPlacement.TOP,
  position,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
  // State for uncontrolled open state.
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  // Determine if the tooltip is open based on controlled or uncontrolled state.
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  // Setup floating UI with middleware for offset, flip, and shift.
  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate, // Auto-update the position of the tooltip.
    middleware: [
      // Offset the tooltip by 5px.
      offset(5),
      flip({
        // Allow flipping across the cross axis if placement includes '-'.
        crossAxis: placement.includes('-'),
        // Fallback direction for axis side.
        fallbackAxisSideDirection: 'start',
        // Padding for flip.
        padding: 5,
      }),
      // Shift the tooltip if it overflows the viewport.
      shift({ padding: 5 }),
    ],
  });

  const context = data.context;

  // Middleware to position the floating element at a provided custom position if it exists.
  const clientPoint = useClientPoint(context, {
    enabled: !!position,
    ...position,
  });

  const hover = useHover(context, {
    delay: { open: TOOLTIP_DEFAULT_DELAY, close: 0 },
    move: false,
    enabled: controlledOpen == null, // Enable hover only if not controlled.
  });

  const focus = useFocus(context, {
    enabled: controlledOpen == null, // Enable focus only if not controlled.
  });

  const dismiss = useDismiss(context);

  const role = useRole(context, { role: 'tooltip' });

  // Combine all interactions.
  const interactions = useInteractions([clientPoint, hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  );
}
