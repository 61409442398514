import { useCallback, useRef } from 'react';

const usePlayCallAudio = () => {
  const errorAudioRef = useRef(
    new Audio(`${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/twilio-audio/twilio-error.mp3`)
  );

  const hangUpAudioRef = useRef(
    new Audio(`${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/twilio-audio/end-call-audio.wav`)
  );

  const ringingSoundRef = useRef(
    new Audio(`${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/twilio-audio/outbound-call-ringing.wav`)
  );

  // Tracks if an audio is currently playing.
  // Used to prevent overlapping audio playback.
  // Better than using the audio.paused property since it can be unreliable.
  const isPlayingRef = useRef({ error: false, hangUp: false, ringing: false });

  // Reduce hangup and ringing volume to 50%.
  hangUpAudioRef.current.volume = 0.5;
  ringingSoundRef.current.volume = 0.5;

  // Handles playing the hang-up audio.
  const playHangUpAudio = useCallback(async () => {
    // If any audio is currently playing, do not play the hang-up audio.
    if (isPlayingRef.current.error || isPlayingRef.current.hangUp || isPlayingRef.current.ringing) return;

    // Otherwise, play the hang-up audio.
    const audio = hangUpAudioRef.current;
    await new Promise<void>((resolve) => {
      audio.play();
      isPlayingRef.current.hangUp = true;

      // Play audio for 2 seconds.
      const timeoutId = setTimeout(() => {
        audio.pause();
        audio.currentTime = 0;
        isPlayingRef.current.hangUp = false;
        resolve();
      }, 2000);

      // Clear the timeout if audio ends naturally.
      audio.onended = () => {
        isPlayingRef.current.hangUp = false;
        clearTimeout(timeoutId);
        resolve();
      };
    });
  }, []);

  const pauseHangUpAudio = useCallback(() => {
    // If the hang-up audio is not currently playing, do nothing.
    if (!isPlayingRef.current.hangUp) return;

    // Otherwise, pause and reset the hang-up audio.
    hangUpAudioRef.current.pause();
    hangUpAudioRef.current.currentTime = 0;
    isPlayingRef.current.hangUp = false;
  }, []);

  // Handles playing the error audio.
  const playErrorAudio = useCallback(
    async (setIsHangingUp: () => void) => {
      // If the error audio is currently playing, do nothing.
      if (isPlayingRef.current.error) return;

      // Otherwise, play the error audio.
      const audio = errorAudioRef.current;
      await new Promise<void>((resolve) => {
        audio.play();
        isPlayingRef.current.error = true;
        audio.onended = async () => {
          setIsHangingUp();
          isPlayingRef.current.error = false;
          // Play the hang-up audio after the error audio ends.
          await playHangUpAudio();
          resolve();
        };
      });
    },
    [playHangUpAudio]
  );

  // Handles pausing the error audio.
  const pauseErrorAudio = useCallback(() => {
    // If the error audio is not currently playing, do nothing.
    if (!isPlayingRef.current.error) return;

    // Otherwise, pause and reset the error audio.
    errorAudioRef.current.pause();
    errorAudioRef.current.currentTime = 0;
    isPlayingRef.current.error = false;
  }, []);

  // Handles playing the ringing audio.
  const playRingingAudio = useCallback(() => {
    // If the ringing audio is currently playing, do nothing.
    if (isPlayingRef.current.ringing) return;

    // Otherwise, play the ringing audio.
    ringingSoundRef.current.loop = true;
    ringingSoundRef.current.play();
    isPlayingRef.current.ringing = true;
  }, []);

  // Handles pausing and resetting the ringing audio.
  const pauseRingingAudio = useCallback(() => {
    // If the ringing audio is not currently playing, do nothing.
    if (!isPlayingRef.current.ringing) return;

    // Otherwise, pause and reset the ringing audio.
    ringingSoundRef.current.pause();
    ringingSoundRef.current.currentTime = 0;
    isPlayingRef.current.ringing = false;
  }, []);

  return {
    playErrorAudio,
    pauseErrorAudio,
    playHangUpAudio,
    pauseHangUpAudio,
    playRingingAudio,
    pauseRingingAudio,
  };
};

export default usePlayCallAudio;
