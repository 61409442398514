import { SemanticColor, StatusColor, TagColor, TextColor } from '../../../types';
import { BadgeColor } from './Badge.types';

export const getBadgeColorConfig = (color?: BadgeColor, destructive?: boolean, outline?: boolean) => {
  if (outline) {
    return {
      bgColor: 'badge-outline',
      textColor: TextColor.PRIMARY,
      hoverBgColor: 'badge-neutral',
      hoverTextColor: TextColor.WHITE,
    };
  }

  if (destructive) {
    return {
      bgColor: 'badge-error',
      textColor: TextColor.DESTRUCTIVE,
      hoverBgColor: 'bg-error-content',
      hoverTextColor: TextColor.WHITE,
    };
  }

  switch (color) {
    case SemanticColor.AI:
      return {
        bgColor: 'bg-ai/10',
        textColor: TextColor.AI,
        hoverBgColor: 'bg-ai',
        hoverTextColor: TextColor.WHITE,
      };
    case StatusColor.SUCCESS:
      return {
        bgColor: 'badge-success',
        textColor: 'text-success-content',
        hoverBgColor: 'bg-success-content',
        hoverTextColor: TextColor.WHITE,
      };
    case TagColor.RED:
      return {
        bgColor: 'bg-tags-red/10',
        textColor: 'text-tags-red',
        hoverBgColor: 'bg-tags-red',
        hoverTextColor: TextColor.WHITE,
      };
    case TagColor.PINK:
      return {
        bgColor: 'bg-tags-pink/10',
        textColor: 'text-tags-pink',
        hoverBgColor: 'bg-tags-pink',
        hoverTextColor: TextColor.WHITE,
      };
    case TagColor.PURPLE:
      return {
        bgColor: 'bg-tags-purple/10',
        textColor: 'text-tags-purple',
        hoverBgColor: 'bg-tags-purple',
        hoverTextColor: TextColor.WHITE,
      };
    case TagColor.INDIGO:
      return {
        bgColor: 'bg-tags-indigo/10',
        textColor: 'text-tags-indigo',
        hoverBgColor: 'bg-tags-indigo',
        hoverTextColor: TextColor.WHITE,
      };
    case TagColor.BLUE:
      return {
        bgColor: 'bg-tags-blue/10',
        textColor: 'text-tags-blue',
        hoverBgColor: 'bg-tags-blue',
        hoverTextColor: TextColor.WHITE,
      };
    case TagColor.TEAL:
      return {
        bgColor: 'bg-tags-teal/10',
        textColor: 'text-tags-teal',
        hoverBgColor: 'bg-tags-teal',
        hoverTextColor: TextColor.WHITE,
      };
    case TagColor.LIME:
      return {
        bgColor: 'bg-tags-lime/10',
        textColor: 'text-tags-lime',
        hoverBgColor: 'bg-tags-lime',
        hoverTextColor: TextColor.WHITE,
      };
    case TagColor.YELLOW:
      return {
        bgColor: 'bg-tags-yellow/10',
        textColor: 'text-tags-yellow',
        hoverBgColor: 'bg-tags-yellow',
        hoverTextColor: TextColor.WHITE,
      };
    case TagColor.ORANGE:
      return {
        bgColor: 'bg-tags-orange/10',
        textColor: 'text-tags-orange',
        hoverBgColor: 'bg-tags-orange',
        hoverTextColor: TextColor.WHITE,
      };
    default:
      return {
        bgColor: 'badge-ghost',
        textColor: TextColor.PRIMARY,
        hoverBgColor: 'bg-base-300',
        hoverTextColor: TextColor.PRIMARY,
      };
  }
};
