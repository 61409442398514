import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from '../../components';
import { useAppSelector } from '../../hooks';
import { AccessDeniedPage } from '../../pages';

interface ProtectedRouteProps {
  children: React.JSX.Element;
}

/**
 * A higher-order component that ensures a route can only be accessed by authenticated users.
 * It leverages both local Redux state and Auth0 state to manage authentication flow.
 * - Initiates a login redirect if unauthenticated.
 * - Displays a spinner only during initial route access when user authentication state is being confirmed.
 * - Renders the specified component post-authentication.
 * - Displays an "Access Denied" page if there's an authentication error.
 */
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();

  const { error, isLoading: auth0IsLoading, loginWithRedirect } = useAuth0();
  const { isAuthenticated: reduxIsAuthenticated, isLoading: reduxIsLoading } = useAppSelector((state) => state.auth);

  useEffect(() => {
    // If already authenticated or loading, no need to proceed with redirect logic.
    if (reduxIsAuthenticated || reduxIsLoading) return;

    if (error) {
      console.error(error);
    } else if (!auth0IsLoading) {
      const params = new URLSearchParams(location.search);
      const invitation = params.get('invitation') || undefined;
      // Redirect only when the user is not authenticated and no error is present.
      if (invitation) {
        // if invitation is present, go to invitation flow
        loginWithRedirect({ authorizationParams: { invitation } });
      } else {
        loginWithRedirect();
      }
    }
  }, [reduxIsAuthenticated, error, loginWithRedirect, auth0IsLoading, reduxIsLoading]);

  if (error) {
    return <AccessDeniedPage />;
  }

  // We use reduxIsAuthenticated rather than Auth0's isAuthenticated to avoid showing a spinner
  // during internal application loading processes, which offers a smoother user experience
  // and reduces visual clutter.
  // Show spinner while the authentication status is being confirmed initially.
  if (!reduxIsAuthenticated) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return children;
};

export default ProtectedRoute;
