import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { SIDEBAR_WIDTH } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import { useGetGongUsersQuery } from '../../../services';
import { GongAuthMethod, GongUser, TextColor } from '../../../types';
import {
  ButtonColor,
  DatePickerRange,
  Icon,
  Icons,
  SidebarDateFilter,
  SidebarSelectFilter,
  TextButton,
  TextInput,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../shared';

interface GongCallsFiltersProps {
  selectedParticipants: string[];
  setSelectedParticipants: (participants: string[]) => void;
  setSelectedDate: Dispatch<SetStateAction<DatePickerRange | undefined>>;
  setSearchCallId: Dispatch<SetStateAction<string | undefined>>;
  setSearchError: Dispatch<SetStateAction<string>>;
  selectedDate?: DatePickerRange;
  searchCallId?: string;
  searchError?: string;
}

const getDisplayName = (user: GongUser) => {
  // Check if user has a name (first or last)
  const hasName = user.firstName || user.lastName;

  if (hasName) {
    const firstName = user.firstName || '';
    const lastName = user.lastName || '';
    return `${firstName} ${lastName}`.trim();
  }

  // Fallback to email or phone
  return user.emailAddress || user.phoneNumber;
};

const GongCallsFilters = ({
  selectedParticipants,
  setSelectedParticipants,
  selectedDate,
  setSelectedDate,
  setSearchError,
  searchCallId,
  setSearchCallId,
  searchError,
}: GongCallsFiltersProps) => {
  const connectionMethod = useAppSelector((state) => state.auth.organization?.gongAuth?.authMethod);
  const isApiKeyConnection = connectionMethod === GongAuthMethod.API_KEY;

  const { data: gongUsers, isLoading: isLoadingGongUsers } = useGetGongUsersQuery(undefined, {
    skip: !isApiKeyConnection,
  });

  // Memoized options for the participants filter list
  const participantOptions = useMemo(() => {
    if (!gongUsers) return [];

    return gongUsers?.users?.map((user) => ({
      value: user.id,
      label: getDisplayName(user),
    }));
  }, [gongUsers]);

  // Handles clearing the filters
  const handleClearFilters = () => {
    setSelectedParticipants([]);
    setSelectedDate(undefined);
    setSearchCallId(undefined);
    setSearchError('');
  };

  const handleChangeSearchCallId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchError('');
    setSearchCallId(event.target.value);
  }, []);

  const hasFilters = selectedParticipants.length > 0 || selectedDate || searchCallId;

  return (
    <div
      className="flex h-full w-full flex-col gap-4 overflow-hidden rounded-md border p-6"
      style={{ width: SIDEBAR_WIDTH }}
    >
      <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD} className="sticky top-0">
        Filters
      </Typography>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <Typography>Search by call ID</Typography>
          <TextInput
            placeholder="Call ID or link"
            value={searchCallId}
            error={searchError}
            endElement={<Icons icon={Icon.SEARCH} className="pr-1" color={TextColor.SECONDARY} />}
            onChange={handleChangeSearchCallId}
          />
        </div>
        <div className="flex flex-col gap-6">
          {isApiKeyConnection && (
            <SidebarSelectFilter
              placeholder="Type to filter"
              title="Call participants"
              options={participantOptions}
              onChange={(selected) => setSelectedParticipants(selected || [])}
              loading={isLoadingGongUsers}
              selected={selectedParticipants}
              clearable
            />
          )}

          <SidebarDateFilter title="Call date" onChange={setSelectedDate} selected={selectedDate} />
          {hasFilters && (
            <TextButton text="Clear filters" onClick={handleClearFilters} fullWidth color={ButtonColor.SECONDARY} />
          )}
        </div>
      </div>
    </div>
  );
};

export default GongCallsFilters;
