import { useMemo, useState } from 'react';
import { DEFAULT_COOLDOWN_PERIOD, DEFAULT_TIME_LIMIT } from '../../../constants';
import { useGetScorecardTemplateOptions } from '../../../hooks';
import { useGetTeamsQuery, useGetUsersForSelectQuery } from '../../../services';
import { PracticeProspect, ProspectCategory, RoleFilterType, Roles } from '../../../types';
import { Dialog, Select, TextArea } from '../../shared';
import ManagerNotesActionButtons from './ManagerNotesActionButtons';
import ManagerNotesSection from './ManagerNotesSection';
import QuizProspectSections from './QuizProspectSections';

const MANAGER_NOTES_ROWS = 6;

interface ManagerNotesModalProps {
  prospect: PracticeProspect;
  onClose: () => void;
  refetchProspects: () => void;
}

const ManagerNotesModal = ({ prospect, onClose, refetchProspects }: ManagerNotesModalProps) => {
  const isQuiz = prospect.category === ProspectCategory.QUIZ;

  const [notesInput, setNotesInput] = useState(prospect.managerNotes || '');
  const [scorecardTemplateIdInput, setScorecardTemplateIdInput] = useState(prospect.scorecardTemplateId);

  const [isSendNotificationEnabled, setIsSendNotificationEnabled] = useState(prospect.isSubscribed);

  const [isCooldownPeriodEnabled, setIsCooldownPeriodEnabled] = useState(!!prospect.cooldownPeriod);
  const [cooldownPeriodInput, setCooldownPeriodInput] = useState(prospect.cooldownPeriod || DEFAULT_COOLDOWN_PERIOD);

  const [isTimeLimitEnabled, setIsTimeLimitEnabled] = useState(!!prospect.timeLimit);
  const [timeLimitInput, setTimeLimitInput] = useState(prospect.timeLimit || DEFAULT_TIME_LIMIT);

  const [allowedTeams, setAllowedTeams] = useState(prospect.allowedTeams?.map((team) => team.id) || []);
  const [allowedCallers, setAllowedCallers] = useState(prospect.allowedCallers?.map((caller) => caller.id) || []);

  const { options: scorecardTemplateOptions, isLoading: isLoadingScorecardTemplates } = useGetScorecardTemplateOptions({
    orgId: prospect.orgId,
    selectedTemplateId: scorecardTemplateIdInput,
  });

  const { data: orgUsers } = useGetUsersForSelectQuery(
    {
      roleFilterType: RoleFilterType.SPECIFY_ROLES,
      roles: [Roles.SALES_REP],
    },
    {
      skip: !isQuiz,
    }
  );

  const { data: orgTeams } = useGetTeamsQuery(undefined, {
    skip: !isQuiz,
  });

  const usersOptions = useMemo(
    () =>
      orgUsers?.users?.map((user) => ({
        label: user.name ?? user.email ?? '',
        value: user.id,
      })) || [],
    [orgUsers]
  );

  const teamsOptions = useMemo(
    () =>
      orgTeams?.map((team) => ({
        label: team.name,
        value: team.id,
        color: team.color,
      })) || [],
    [orgTeams]
  );

  return (
    <Dialog isOpen onClose={onClose} title={`${prospect.firstName} ${prospect.lastName}`}>
      <div className="flex flex-col gap-5">
        <ManagerNotesSection title="AI scorecard" description="Automatically scores this call">
          <Select
            loading={isLoadingScorecardTemplates}
            placeholder="Select scorecard"
            options={scorecardTemplateOptions}
            selected={scorecardTemplateIdInput}
            onChange={(newValue) => setScorecardTemplateIdInput(newValue)}
            clearable
          />
        </ManagerNotesSection>
        {isQuiz && (
          <QuizProspectSections
            isSendNotificationEnabled={isSendNotificationEnabled}
            allowedCallers={allowedCallers}
            allowedTeams={allowedTeams}
            isTimeLimitEnabled={isTimeLimitEnabled}
            timeLimitInput={timeLimitInput}
            isCooldownPeriodEnabled={isCooldownPeriodEnabled}
            cooldownPeriodInput={cooldownPeriodInput}
            usersOptions={usersOptions}
            teamsOptions={teamsOptions}
            setIsSendNotificationEnabled={setIsSendNotificationEnabled}
            setAllowedCallers={setAllowedCallers}
            setAllowedTeams={setAllowedTeams}
            setIsTimeLimitEnabled={setIsTimeLimitEnabled}
            setTimeLimitInput={setTimeLimitInput}
            setIsCooldownPeriodEnabled={setIsCooldownPeriodEnabled}
            setCooldownPeriodInput={setCooldownPeriodInput}
          />
        )}
        <ManagerNotesSection title="Manager notes" description="Only visible to managers and administrators">
          <TextArea
            value={notesInput}
            rows={MANAGER_NOTES_ROWS}
            onChange={(e) => setNotesInput(e.target.value)}
            placeholder={`Example:\n\u0020\u0020• Use for Q2 onboarding class\n\u0020\u0020• Tests product knowledge\n\u0020\u0020• Tests competitor objections`}
          />
        </ManagerNotesSection>
        <ManagerNotesActionButtons
          notesInput={notesInput}
          isSendNotificationEnabled={isSendNotificationEnabled}
          prospect={prospect}
          onClose={onClose}
          refetchProspects={refetchProspects}
          selectedAllowedCallers={allowedCallers}
          selectedAllowedTeams={allowedTeams}
          scorecardTemplateIdInput={scorecardTemplateIdInput}
          // Only update cooldown period and time limit if their checkboxes are enabled
          cooldownPeriodInput={isCooldownPeriodEnabled ? cooldownPeriodInput : undefined}
          timeLimitInput={isTimeLimitEnabled ? timeLimitInput : undefined}
          usersOptions={usersOptions}
          teamsOptions={teamsOptions}
        />
      </div>
    </Dialog>
  );
};

export default ManagerNotesModal;
