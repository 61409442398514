import { TypographySize, TypographyWeight, Typography } from '../../../../components';
import ResultsRow from './ResultsRow';

interface ChaptersCardProps {
  chapters: {
    title: string;
    startTime: number;
  }[];
  seekAudio: (startTime: number) => void;
}

const ChaptersCard = ({ chapters, seekAudio }: ChaptersCardProps) => {
  return (
    <div className="flex flex-col gap-4">
      <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
        Chapters
      </Typography>
      {chapters.map((chapter) => (
        <ResultsRow
          key={chapter.startTime}
          seekToAudio={() => seekAudio(chapter.startTime)}
          title={chapter.title}
          startTime={chapter.startTime}
        />
      ))}
    </div>
  );
};

export default ChaptersCard;
