import { useEffect, useMemo, useState } from 'react';
import { SELECT_SEARCH_THRESHOLD } from '../../../constants';
import { useGetOrganizationsQuery } from '../../../services';
import { parseOrgToOption } from '../../../utils';
import { Select, SelectProps } from '../Select';

type OrgSelectorProps = Omit<SelectProps, 'options' | 'selected' | 'inline'> & {
  selectFirstAsDefault?: boolean;
  selectedOrgId?: string;
};

const OrgSelector = ({ onChange, selectFirstAsDefault, selectedOrgId, ...props }: OrgSelectorProps) => {
  const [searchValue, setSearchValue] = useState('');

  const { data: orgs = [], isLoading: isOrgsLoading } = useGetOrganizationsQuery();

  const orgOptions = useMemo(() => orgs.map(parseOrgToOption), [orgs]);
  const isOrgSearchEnabled = orgOptions.length > SELECT_SEARCH_THRESHOLD;
  const orgSearchProps = isOrgSearchEnabled ? { searchValue, setSearchValue } : undefined;

  // If selectFirstAsDefault is true and no org is selected, select the first org as the default.
  useEffect(() => {
    if (selectFirstAsDefault && orgs.length && !selectedOrgId) {
      onChange(orgs[0].id);
    }
  }, [orgs]);

  return (
    <Select
      options={orgOptions}
      placeholder="Select organization"
      searchProps={orgSearchProps}
      selected={selectedOrgId}
      onChange={onChange}
      loading={isOrgsLoading}
      {...props}
    />
  );
};

export default OrgSelector;
