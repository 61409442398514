import { ApiSuccess, InitiateCallPayload, InitiateCallResponseData } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    initiateCall: builder.mutation<InitiateCallResponseData, InitiateCallPayload>({
      query: ({ to }) => ({
        url: '/twilio/initiate-call',
        method: 'POST',
        body: { to },
      }),
      transformResponse: (response: ApiSuccess<InitiateCallResponseData>) => response.data,
    }),
  }),
});

export const { useInitiateCallMutation } = enhancedApiSlice;
