import { useCallback, useRef } from 'react';

export enum TimerMode {
  INTERVAL = 'INTERVAL',
  TIMEOUT = 'TIMEOUT',
}

interface UseTimerProps {
  callback: () => void;
  delay: number;
  disabled?: boolean;
  mode?: TimerMode;
  onStop?: () => void;
}

const useTimer = ({ callback, delay, disabled, mode = TimerMode.TIMEOUT, onStop }: UseTimerProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = useCallback(() => {
    if (disabled) return;

    if (mode === TimerMode.INTERVAL) {
      timeoutRef.current = setInterval(callback, delay);
    } else {
      timeoutRef.current = setTimeout(callback, delay);
    }
  }, [callback, delay, disabled, mode]);

  const stopTimer = useCallback(() => {
    if (!timeoutRef.current || disabled) return;

    if (mode === TimerMode.INTERVAL) {
      clearInterval(timeoutRef.current);
    } else {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = null;
    onStop?.();
  }, [disabled, mode, onStop]);

  return { startTimer, stopTimer };
};

export default useTimer;
