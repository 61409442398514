import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { BUTTON_ID } from '../../../constants';
import { useTimer } from '../../../hooks';
import { MousePosition } from '../../../types';
import { conditionalObject } from '../../../utils';
import { Tooltip } from '../Tooltip';

const TOOLTIP_DELAY = 300;

// Interface for TableRow props extending HTML attributes for a table row.
interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  active?: boolean;
  disabled?: boolean;
  height?: number;
  tooltip?: string;
  onHover?: (hover: boolean) => void;
  onRightClick?: (e: React.MouseEvent) => void;
}

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ active, className, disabled, height, tooltip, onClick, onHover, onRightClick, ...props }, ref) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [mousePosition, setMousePosition] = useState<MousePosition | undefined>(undefined);

    const { startTimer, stopTimer } = useTimer({
      callback: () => setIsTooltipOpen(true),
      delay: TOOLTIP_DELAY,
      disabled: !tooltip,
      onStop: () => setIsTooltipOpen(false),
    });

    const onMouseEnter = useCallback(() => {
      onHover?.(true);
    }, [onHover]);

    const onMouseLeave = useCallback(() => {
      onHover?.(false);
      stopTimer();
    }, [onHover, stopTimer]);

    const updateMousePos = useCallback(
      (e: React.MouseEvent<HTMLTableRowElement>) => {
        // If the mouse is over a button, do not show the tooltip.
        const element = e.target as HTMLElement;
        if (element.closest(`#${BUTTON_ID}`)) {
          stopTimer();
          return;
        }

        const { clientX: newX, clientY: newY } = e;
        const { x: oldX, y: oldY } = mousePosition ?? {};

        // Exit if the mouse has not moved and the tooltip is open.
        if (newX === oldX && newY === oldY && isTooltipOpen) return;

        setMousePosition({ x: newX, y: newY });
        // Reset the timer if the mouse moves, ensuring the tooltip only opens after the mouse has come to a rest.
        stopTimer();
        startTimer();
      },
      [isTooltipOpen, mousePosition, startTimer, stopTimer]
    );

    const onContextMenu = useCallback(
      (e: React.MouseEvent<Element, MouseEvent>) => {
        if (onRightClick) {
          // Prevent the default context menu from appearing.
          e.preventDefault();
          e.stopPropagation();
          onRightClick(e);
        }
      },
      [onRightClick]
    );

    // Handlers that are not disabled if the row is disabled.
    const tooltipHandlers = {
      ...conditionalObject(!active, {
        onMouseEnter,
        onMouseLeave,
        ...conditionalObject(!!tooltip, {
          onMouseMove: updateMousePos,
          onMouseOver: updateMousePos,
        }), // We only need to detect mouse position if a tooltip is passed.
      }),
    };

    const eventHandlers = {
      onClick,
      ...tooltipHandlers,
    };

    // Reset the tooltip if the row becomes active, so it doesn't hang around.
    useEffect(() => {
      if (active) stopTimer();
    }, [active]);

    // Reset the tooltip when the user scrolls, so it doesn't hang around.
    useEffect(() => {
      // We only need to detect scroll if the row is hover-able.
      if (!onHover && !tooltip) return;

      window.addEventListener('scroll', stopTimer, { capture: true });
      return () => {
        window.removeEventListener('scroll', stopTimer, { capture: true });
      };
    }, [onHover, tooltip]);

    return (
      <>
        <Tooltip label={tooltip} position={mousePosition} open={isTooltipOpen} onOpenChange={setIsTooltipOpen} />
        <tr
          ref={ref}
          className={clsx(active && 'active !bg-base-100', !disabled && onClick && 'hover cursor-pointer', className)}
          style={{ height }}
          onContextMenu={onContextMenu}
          {...(disabled ? tooltipHandlers : eventHandlers)}
          {...props}
        />
      </>
    );
  }
);

TableRow.displayName = 'TableRow';

export default TableRow;
