import { useMemo } from 'react';
import { Call } from '../../../../types';
import CallStatsPanel from './CallStatsPanel';
import TabEmptyState from '../TabEmptyState';
import ChaptersCard from './ChaptersCard';
import CoachingCard from './CoachingCard';

interface ResultsTabProps {
  call: Call;
  seekAudio: (startTime: number) => void;
}

const ResultsTab = ({ call, seekAudio }: ResultsTabProps) => {
  const { aiCoachingResult, callChapters, callSpeechCategories, callSid, coachingStatus } = call;

  // Memoized list of chapters from the call data.
  const chapters = useMemo(() => {
    // Check if callChapters exist and are not empty
    if (callChapters && callChapters.length > 0) {
      return callChapters.map((chapter) => ({
        title: chapter.title,
        startTime: chapter.startTime,
      }));
    }

    // If callChapters are not available or empty, fall back to callSpeechCategories for backward compatibility
    return (callSpeechCategories || []).map((category) => ({
      title: category.summary,
      startTime: category.startTime,
    }));
  }, [callChapters, callSpeechCategories]);

  // If no chapters or coaching result, show empty state
  if (!chapters.length && !coachingStatus) {
    return <TabEmptyState text="No summary available" />;
  }

  return (
    <div className="flex h-full flex-col gap-4">
      <CallStatsPanel {...call} />
      <div className="display-scrollbar-sm h-full overflow-auto rounded-lg border border-base-200 p-4">
        {!!coachingStatus && (
          <CoachingCard callSid={callSid} aiCoachingResult={aiCoachingResult} coachingStatus={coachingStatus} />
        )}
        {/* If no coaching status, it means that the call was done before we added the coaching feature */}
        {!coachingStatus && <ChaptersCard chapters={chapters} seekAudio={seekAudio} />}
      </div>
    </div>
  );
};

export default ResultsTab;
