import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setProspectFooterFields, setProspectForm } from '../../../redux/reducers';
import { useGetPracticeProspectByIdMutation } from '../../../services';
import { ComponentSize, ProspectCategory, ProspectPageTabs, Permissions, Roles } from '../../../types';
import {
  ButtonColor,
  ButtonGroup,
  DesignerModalCard,
  OrgSelector,
  TextButton,
  Typography,
  TypographySize,
} from '../../shared';
import SimulationCategorySelector from './SimulationCategorySelector';
import SimulationTemplateSelector from './SimulationTemplateSelector';

export enum SimulationDesignerOption {
  TEMPLATE = 'TEMPLATE',
  CUSTOM = 'CUSTOM',
}

interface SimulationCategoryStepProps {
  isSuperAdmin: boolean;
  selectedDesignerOption: SimulationDesignerOption;
  goToNextStep: () => void;
  onCancel: () => void;
  setTargetOrgId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedDesignerOption: Dispatch<SetStateAction<SimulationDesignerOption>>;
  setNewCategory: Dispatch<SetStateAction<ProspectCategory | undefined>>;
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
  newCategory?: ProspectCategory;
  targetOrgId?: string;
}

const SimulationCategoryStep = ({
  isSuperAdmin,
  selectedDesignerOption,
  goToNextStep,
  onCancel,
  setNewCategory,
  setTargetOrgId,
  setSelectedDesignerOption,
  setVisitedTabs,
  targetOrgId,
  newCategory,
}: SimulationCategoryStepProps) => {
  const dispatch = useAppDispatch();
  const { organization, user } = useAppSelector((state) => state.auth);
  const canManageProspect = (user?.permissions || []).includes(Permissions.MANAGE_PROSPECT);
  const isSalesRep = user?.role === Roles.SALES_REP;

  const isHiringModuleEnabled = organization?.isHiringModuleEnabled || false;

  const [templateOrgId, setTemplateOrgId] = useState<string>();
  const [templateProspectId, setTemplateProspectId] = useState<string>();

  const [getPracticeProspectById, { isLoading: isProspectLoading }] = useGetPracticeProspectByIdMutation();

  // Disable the Next button if the user hasn't selected the required fields.
  const disabled =
    !targetOrgId ||
    (selectedDesignerOption === SimulationDesignerOption.CUSTOM && !newCategory) ||
    (selectedDesignerOption === SimulationDesignerOption.TEMPLATE && !templateProspectId);

  // Filters prospect categories based on user/org permissions:
  const filterProspectCategories = useCallback(
    (category: ProspectCategory) => {
      // If the user is a sales rep, only show practice type.
      if (isSalesRep) return category === ProspectCategory.PRACTICE;
      // Super-admins can see all public categories.
      if (isSuperAdmin) return true;
      // Only orgs with the hiring module enabled can see Interview prospects.
      if (category === ProspectCategory.INTERVIEW) return isHiringModuleEnabled;
      // All other categories are public.
      return true;
    },
    [isSuperAdmin, isSalesRep, isHiringModuleEnabled]
  );

  const options = useMemo(
    () => [
      {
        id: SimulationDesignerOption.TEMPLATE,
        isSelected: selectedDesignerOption === SimulationDesignerOption.TEMPLATE,
        title: 'Based on existing simulation',
        description: 'Which simulation do you want to duplicate?',
        children: (
          <SimulationTemplateSelector
            isSuperAdmin={isSuperAdmin}
            templateOrgId={templateOrgId}
            templateProspectId={templateProspectId}
            filterProspectCategories={filterProspectCategories}
            setTemplateOrgId={setTemplateOrgId}
            setTemplateProspectId={setTemplateProspectId}
            onChange={() => setSelectedDesignerOption(SimulationDesignerOption.TEMPLATE)}
          />
        ),
        onSelect: () => setSelectedDesignerOption(SimulationDesignerOption.TEMPLATE),
      },
      {
        id: SimulationDesignerOption.CUSTOM,
        isSelected: selectedDesignerOption === SimulationDesignerOption.CUSTOM,
        title: 'Create new simulation',
        description: 'How will this simulation be used?',
        children: (
          <SimulationCategorySelector
            newCategory={newCategory}
            filterProspectCategories={filterProspectCategories}
            onChange={() => setSelectedDesignerOption(SimulationDesignerOption.CUSTOM)}
            setNewCategory={setNewCategory}
          />
        ),
        onSelect: () => setSelectedDesignerOption(SimulationDesignerOption.CUSTOM),
      },
    ],
    [isSuperAdmin, templateOrgId, templateProspectId, newCategory, selectedDesignerOption, filterProspectCategories]
  );

  // Sets the org id and prospect type in the redux store.
  // This automatically closes the designer modal since it only opens if orgId or prospectType are not set.
  const handleNext = useCallback(async () => {
    if (selectedDesignerOption === SimulationDesignerOption.CUSTOM) {
      goToNextStep();
    } else if (templateProspectId) {
      // If the user has selected a template prospect, fetch the prospect data and set the form state.
      const { data: prospect } = await getPracticeProspectById(templateProspectId);
      if (prospect && !!targetOrgId) {
        const { notes, tags, ...rest } = prospect;
        dispatch(setProspectForm({ prospect: { ...rest, orgId: targetOrgId }, canManageProspect, isTemplate: true }));
        dispatch(setProspectFooterFields({ isFavorited: false, notes, tags }));
        // Mark all tabs as visited except for the "Prompt" tab since we are creating a new prospect, but with preset data.
        setVisitedTabs(new Set(Object.values(ProspectPageTabs).slice(0, -1)));
      }
    }
  }, [
    dispatch,
    targetOrgId,
    selectedDesignerOption,
    templateProspectId,
    getPracticeProspectById,
    goToNextStep,
    setVisitedTabs,
    canManageProspect,
  ]);

  return (
    <div className="flex flex-col gap-4">
      {options.map((option) => (
        <DesignerModalCard key={option.id} {...option} />
      ))}
      {isSuperAdmin && (
        <div className="flex flex-col gap-4">
          <Typography size={TypographySize.H5}>What organization is this simulation for?</Typography>
          <OrgSelector selectFirstAsDefault selectedOrgId={targetOrgId} onChange={setTargetOrgId} />
        </div>
      )}
      <ButtonGroup size={ComponentSize.MEDIUM} loading={isProspectLoading}>
        <TextButton onClick={handleNext} text="Next" color={ButtonColor.PRIMARY} disabled={disabled} />
        <TextButton onClick={onCancel} text="Cancel" />
      </ButtonGroup>
    </div>
  );
};

export default SimulationCategoryStep;
