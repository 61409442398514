import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Typography, TypographySize, TextInput, TextInputType, AlertType, Icons, Icon } from '../../../../shared';
import { Benchmarks as BenchmarksType, OrganizationSettings } from '../../../../../types';
import { formatSecondsToDuration, formatMinutesToDuration } from '../../../../../utils';
import { useUpdateOrganizationSettingsMutation } from '../../../../../services';
import { useHandleApiResponse, useToast } from '../../../../../hooks';
import OrgTabSectionTitle from './OrgTabSectionTitle';

const INPUT_FIELD_WIDTH = 100;
const ERROR_MSG = 'Failed to update organization settings';

interface BenchmarksProps {
  orgConfigs: OrganizationSettings;
}

const Benchmarks = ({ orgConfigs }: BenchmarksProps) => {
  // API Mutation
  const [updateOrganizationSettings] = useUpdateOrganizationSettingsMutation();

  // Custom Hooks
  const handleApiResponse = useHandleApiResponse(true);
  const { showToast } = useToast();

  // Benchmarks State
  const [fillerWords, setFillerWords] = useState<string | undefined>(orgConfigs.fillerWordsPerMinute?.toString());
  const [talkSpeed, setTalkSpeed] = useState<string | undefined>(orgConfigs.wordsPerMinute?.toString());
  const [talkRatio, setTalkRatio] = useState<string | undefined>(orgConfigs.talkRatio?.toString());
  const [longestMonologue, setLongestMonologue] = useState<string | undefined>(
    orgConfigs.longestMonologue ? formatSecondsToDuration(orgConfigs.longestMonologue) : undefined
  );
  // Error State
  const [error, setError] = useState<keyof OrganizationSettings | undefined>(undefined);

  // On Error Handler
  const onError = useCallback(
    (key: keyof OrganizationSettings, errorMsg?: string) => {
      // We hide toasts in the handleApiResponse hook because we don't want to show a toast on success
      // We pass show toast on error instead
      showToast({ message: errorMsg || ERROR_MSG, type: AlertType.ERROR });
      setError(key);
    },
    [showToast]
  );

  // Update the organization settings with the new benchmarks
  // We allow null values to reset fields if the user clears the input
  const handleUpdateOrgBenchmarks = useCallback(
    async (key: keyof OrganizationSettings, value: number | null) => {
      try {
        // All org benchmarks are required
        if (!value) return onError(key, 'Please enter a valid value');

        // If the value is the same as the current value, we don't need to update
        if (value === orgConfigs[key] || error === key) return;

        const response = await updateOrganizationSettings({ [key]: value });

        handleApiResponse({
          response,
          errorMsg: ERROR_MSG,
          onError: () => onError(key),
        });
      } catch (error) {
        console.error(ERROR_MSG, error);
        onError(key);
      }
    },
    [updateOrganizationSettings, handleApiResponse, onError]
  );

  // Update the longest monologue field format
  const formatLongestMonologue = useCallback(() => {
    // Handle empty input
    if (!longestMonologue?.trim()) {
      setLongestMonologue('');
      return null;
    }

    let minutes: number;

    // If it's just a number, treat it as minutes
    if (!longestMonologue.includes(':')) {
      minutes = Number(longestMonologue);
    } else {
      // Handle mm:ss format
      const [mins, secs] = longestMonologue.split(':');
      minutes = Number(mins) + (Number(secs) || 0) / 60;
    }

    // Format the display value and update state
    const formatted = formatMinutesToDuration(minutes);
    setLongestMonologue(formatted);

    // Convert to seconds for API
    return minutes * 60;
  }, [longestMonologue]);

  // Handles the different fields updates
  const handleFieldUpdate = useCallback(
    (field: BenchmarksType, value?: string) => {
      // If the field is longest monologue, we need to adjust format first
      if (field === BenchmarksType.LONGEST_MONOLOGUE) {
        const seconds = formatLongestMonologue();
        handleUpdateOrgBenchmarks(field, seconds);
        return;
      }
      // Update the rest of the fields
      // We allow null values to reset fields if the user clears the input
      // All of the passed fields are numbers so we can safely convert to number
      handleUpdateOrgBenchmarks(field, value ? Number(value) : null);
    },
    [handleUpdateOrgBenchmarks, formatLongestMonologue]
  );

  // Handle the key down event
  const handleKeyDown = useCallback(
    (field: BenchmarksType, value?: string) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') return handleFieldUpdate(field, value);
    },
    [handleFieldUpdate]
  );

  // Handle the talk ratio change
  const handleTalkRatioChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Talk ratio must be between 0 and 100 as it's a percentage
    if (Number(value) <= 100) {
      setTalkRatio(value);
      setError(undefined);
    }
  }, []);

  // Handle the longest monologue change
  const handleLongestMonologueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow either just numbers, or exactly 2 digits before and after a single colon (mm:ss format)
    if (/^[0-9]{0,2}(:?[0-9]{0,2})?$/.test(value)) {
      setLongestMonologue(value);
      setError(undefined);
    }
  }, []);

  // Handle the filler words change
  const handleFillerWordsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFillerWords(value);
    setError(undefined);
  }, []);

  // Handle the talk speed change
  const handleTalkSpeedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTalkSpeed(value);
    setError(undefined);
  }, []);

  const benchmarkFields = useMemo(
    () => [
      {
        key: BenchmarksType.FILLER_WORDS,
        label: 'Filler words, words per minute',
        value: fillerWords,
        onChange: handleFillerWordsChange,
        placeholder: 'e.g. 10',
        type: TextInputType.NUMBER,
      },
      {
        key: BenchmarksType.TALK_SPEED,
        label: 'Talk speed, words per minute',
        value: talkSpeed,
        onChange: handleTalkSpeedChange,
        placeholder: 'e.g. 200',
        type: TextInputType.NUMBER,
      },
      {
        key: BenchmarksType.TALK_RATIO,
        label: 'Talk ratio, % representative speaking',
        value: talkRatio,
        onChange: handleTalkRatioChange,
        placeholder: 'e.g. 45%',
        type: TextInputType.NUMBER,
        endElement: <Typography size={TypographySize.H5}>%</Typography>,
      },
      {
        key: BenchmarksType.LONGEST_MONOLOGUE,
        label: 'Longest monologue, minutes',
        value: longestMonologue,
        onChange: handleLongestMonologueChange,
        placeholder: 'e.g. 1:30',
      },
    ],
    [
      fillerWords,
      talkSpeed,
      talkRatio,
      longestMonologue,
      handleTalkRatioChange,
      handleFillerWordsChange,
      handleTalkSpeedChange,
      handleLongestMonologueChange,
    ]
  );

  return (
    <div className="flex flex-col gap-2">
      <OrgTabSectionTitle
        title="Benchmarks"
        icon={<Icons icon={Icon.INFO} tooltip="Goal benchmarks are used as success metrics for prospect calls." />}
      />
      {benchmarkFields.map(({ key, value, ...field }, index) => (
        <div key={index} className="flex justify-between">
          <Typography size={TypographySize.H5}>{field.label}</Typography>
          <TextInput
            value={value}
            width={INPUT_FIELD_WIDTH}
            error={error === key}
            onBlur={() => handleFieldUpdate(key, value)}
            onKeyDown={handleKeyDown(key, value)}
            {...field}
          />
        </div>
      ))}
    </div>
  );
};

export default Benchmarks;
