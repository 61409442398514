import { useEffect } from 'react';
import { ButtonColor, Icon, TextButton } from '../../../../components';
import { MIN_REQUIRED_SCENARIO_ITEMS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  addObjection,
  deleteObjection,
  randomizeObjections,
  setIsDynamicObjections,
  setNumOfDynamicObjections,
  updateObjection,
} from '../../../../redux/reducers';
import TabSection from '../TabSection';
import DynamicItemsSelector from './DynamicItemsSelector';
import ScenarioListItem from './ScenarioListItem';

const Objections = () => {
  const dispatch = useAppDispatch();
  const {
    fields: { objections, isDynamicObjections: isDynamicObjectionsChecked, numOfDynamicObjections },
  } = useAppSelector((state) => state.prospect);

  const canDelete = objections.length > MIN_REQUIRED_SCENARIO_ITEMS;
  const numFilledObjections = objections.filter((o) => !!o.value.trim()).length;

  // Delete empty objections on unmount.
  useEffect(() => {
    return () => {
      if (objections.length > MIN_REQUIRED_SCENARIO_ITEMS) {
        objections.forEach((objection, index) => {
          if (!objection.value) {
            dispatch(deleteObjection(index));
          }
        });
      }
    };
  }, []);

  return (
    <TabSection title="Objections" onRandomize={() => dispatch(randomizeObjections())}>
      {objections.map((objection, index) => (
        <ScenarioListItem
          key={index}
          index={index}
          canDelete={canDelete}
          isRequired={index < MIN_REQUIRED_SCENARIO_ITEMS}
          value={objection.value}
          deleteItem={() => dispatch(deleteObjection(index))}
          updateItem={(value) => dispatch(updateObjection({ index, value }))}
        />
      ))}
      <div className="flex items-center gap-4">
        <TextButton
          text="Add objection"
          startIcon={Icon.PLUS}
          color={ButtonColor.SECONDARY}
          onClick={() => {
            dispatch(addObjection());
          }}
        />
        <DynamicItemsSelector
          isDynamicChecked={isDynamicObjectionsChecked}
          label="objection"
          numOfDynamicItems={numOfDynamicObjections}
          totalItems={numFilledObjections}
          setIsDynamicChecked={(newChecked) => dispatch(setIsDynamicObjections(newChecked))}
          setNumOfDynamicItems={(newNumOfDynamicItems) => dispatch(setNumOfDynamicObjections(newNumOfDynamicItems))}
        />
      </div>
    </TabSection>
  );
};

export default Objections;
