import { ApiSuccess, ApiTagTypes, UpdateOrganizationSettingsPayload } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateOrganizationSettings: builder.mutation<ApiSuccess, UpdateOrganizationSettingsPayload>({
      query: (settings) => ({
        url: '/organization-settings',
        method: 'POST',
        body: settings,
      }),
      invalidatesTags: [ApiTagTypes.ORGANIZATION_SETTINGS],
    }),
  }),
});

export const { useUpdateOrganizationSettingsMutation } = enhancedApiSlice;
