import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Icon, ManageScorecardTemplate } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppSelector, useFeatureFlag } from '../../hooks';
import {
  AnalyticsPage,
  GongCallbackPage,
  LayoutPage,
  ManagePromptTemplatePage,
  MessagePage,
  NotFoundPage,
  PromptTemplatesPage,
  ProspectPage,
  ReviewPage,
  ScorecardTemplatesPage,
  SimulationsPage,
} from '../../pages';
import { LD_FeatureFlags, OrganizationStatus, Permissions } from '../../types';
import { conditionalArray } from '../../utils';
import { useGetOrganizationSettingsQuery } from '../../services';
import ProtectedRoute from './ProtectedRoute';

/** Provides router context to the application. */
const CustomRouterProvider: React.FC = () => {
  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const { repCanCreateSims = false } = orgConfigs || {};

  const isUnderConstruction = useFeatureFlag(LD_FeatureFlags.UNDER_CONSTRUCTION);
  const { user, organization } = useAppSelector((state) => state.auth);
  const permissions = user?.permissions || [];

  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);
  const canCreateSims = permissions.includes(Permissions.MANAGE_PROSPECT) || repCanCreateSims;
  const canModifyScorecardTemplates = permissions.includes(Permissions.MODIFY_SCORECARD_TEMPLATE);

  const isSelfServiceEnabled = !!organization?.isSelfServiceEnabled;
  const isTrialOrg = organization?.status === OrganizationStatus.TRIAL;

  const canManageScorecards = canModifyScorecardTemplates && !isTrialOrg;

  // Router configuration using pre-defined route constants.
  const router = createBrowserRouter([
    {
      // Protected route that renders the main layout if the user is authenticated.
      path: AppRoutes.HOME,
      element: (
        <ProtectedRoute>
          <LayoutPage />
        </ProtectedRoute>
      ),
      children: [
        {
          // Redirect root path to Simulations page.
          path: AppRoutes.HOME,
          element: <Navigate to={AppRoutes.SIMULATIONS} replace />,
        },
        {
          // Route for the Simulations page.
          path: AppRoutes.SIMULATIONS,
          element: <SimulationsPage />,
        },
        {
          // Since we combined the /practice and /quiz routes into a single /simulations route,
          // we need this route to ensure existing bookmarks and links continue working.
          path: '/practice/*',
          element: <Navigate to={`${AppRoutes.SIMULATIONS}${window.location.search}`} replace />,
        },
        {
          // Since we combined the /practice and /quiz routes into a single /simulations route,
          // we need this route to ensure existing bookmarks and links continue working.
          path: '/quiz/*',
          element: <Navigate to={`${AppRoutes.SIMULATIONS}${window.location.search}`} replace />,
        },
        ...conditionalArray(canCreateSims && isSelfServiceEnabled, {
          // Route for creating or editing a prospect.
          // Only admins and managers can access this route.
          path: `${AppRoutes.PROSPECT}/:personaId?`,
          element: <ProspectPage />,
        }),
        ...conditionalArray(isSuperAdmin, {
          // Route for viewing, creating and editing prompt templates.
          // Only super admins can access this route.
          path: AppRoutes.PROMPT_TEMPLATES,
          element: <PromptTemplatesPage />,
        }),
        ...conditionalArray(isSuperAdmin, {
          // Route for creating a new prompt template.
          // Only super admins can access this route.
          path: AppRoutes.PROMPT_TEMPLATES_CREATE,
          element: <ManagePromptTemplatePage />,
        }),
        ...conditionalArray(isSuperAdmin, {
          // Route for editing a specific prompt template by ID.
          // Only super admins can access this route.
          path: `${AppRoutes.PROMPT_TEMPLATES}/:promptTemplateId`,
          element: <PromptTemplatesPage />,
        }),
        {
          // Since we renamed the /call-history route to /review,
          // we need this route to ensure existing bookmarks and links continue working.
          path: '/call-history/*',
          element: <Navigate to={`${AppRoutes.REVIEW}${window.location.search}`} replace />,
        },
        {
          // Route for the Review page, with optional callSid parameter
          path: `${AppRoutes.REVIEW}/:callSid?`,
          element: <ReviewPage />,
        },
        {
          // Route for the Analytics page.
          path: AppRoutes.ANALYTICS,
          element: <AnalyticsPage />,
        },
        // Route for the Scorecard Templates page.
        ...conditionalArray(canManageScorecards, {
          path: `${AppRoutes.SCORECARD_TEMPLATES}/:scorecardTemplateId?`,
          element: <ScorecardTemplatesPage />,
        }),
        ...conditionalArray(canManageScorecards, {
          path: AppRoutes.SCORECARD_TEMPLATES_CREATE,
          element: <ManageScorecardTemplate />,
        }),
      ],
    },
    {
      // Route for handling 404 not found errors.
      path: AppRoutes.NOT_FOUND,
      element: <NotFoundPage />,
    },
    {
      path: AppRoutes.GONG_CALLBACK,
      element: (
        <ProtectedRoute>
          <GongCallbackPage />
        </ProtectedRoute>
      ),
    },
  ]);

  if (isUnderConstruction) {
    return (
      <MessagePage
        icon={Icon.TRAFFIC_CONE}
        title="Routine maintenance"
        message="Building new features just for you! Please try again in a few hours."
      />
    );
  }

  return <RouterProvider router={router} />;
};

export default CustomRouterProvider;
