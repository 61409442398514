export enum LD_ContextKinds {
  USER = 'user',
}

export enum LD_ContextRoles {
  SUPER_ADMIN = 'super-admin',
  SYSTEM_USER = 'system-user',
}

export enum LD_FeatureFlags {
  UNDER_CONSTRUCTION = 'under-construction',
  RELEASE_DAILY_FOR_WEB_CALLS = 'release-daily-for-web-calls',
  RELEASE_FOLDERS = 'release-folders',
  RELEASE_GONG = 'release-gong',
  RELEASE_ORG_SETTINGS = 'release-org-settings',
  RELEASE_ANALYTICS_V3 = 'release-analytics-v-3',
  RELEASE_TEAMS = 'release-teams',
  RELEASE_REAL_CALLS = 'release-real-calls',
}
