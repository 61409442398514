import { GongCallsModal, RequestReviewModal, UnflagConfirmModal, WebCallModal } from '../../components';
import { useAppSelector } from '../../hooks';

const Modals = () => {
  const currWebCall = useAppSelector((state) => state.simulations.currWebCall);
  const { requestReviewModal, unflagConfirmModal } = useAppSelector((state) => state.modal);
  return (
    <>
      {currWebCall && <WebCallModal currWebCall={currWebCall} />}
      <GongCallsModal />
      {requestReviewModal && <RequestReviewModal {...requestReviewModal} />}
      {unflagConfirmModal && <UnflagConfirmModal {...unflagConfirmModal} />}
    </>
  );
};

export default Modals;
