import { useMemo } from 'react';
import { PUBLIC_PROSPECT_CATEGORY_OPTIONS_WITH_COLORS } from '../../constants';
import {
  FilterOptionsAndSelections,
  useAppSelector,
  useGetFiltersFromParams,
  useUpdateFilterParams,
} from '../../hooks';
import {
  Permissions,
  ProspectCategory,
  SimulationsFilterKeys,
  SimulationsFilters as SimulationsFiltersType,
} from '../../types';
import { SidebarDateFilter, SidebarSelectFilter } from '../shared';

interface SimulationsFiltersProps {
  accountOptionsAndSelections: FilterOptionsAndSelections;
  prospectOptionsAndSelections: FilterOptionsAndSelections;
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const SimulationsFilters = ({
  accountOptionsAndSelections,
  prospectOptionsAndSelections,
  tagOptionsAndSelections,
}: SimulationsFiltersProps) => {
  const { user } = useAppSelector((state) => state.auth);
  const permissions = user?.permissions || [];
  const canViewHiring = permissions.includes(Permissions.VIEW_CANDIDATE);

  const filters: SimulationsFiltersType = useGetFiltersFromParams();
  const updateFilterParams = useUpdateFilterParams();

  const prospectTypeOptions = useMemo(() => {
    // Filter out candidate options if user doesn't have hiring permissions.
    return PUBLIC_PROSPECT_CATEGORY_OPTIONS_WITH_COLORS.filter(
      (option) => canViewHiring || option.value !== ProspectCategory.INTERVIEW
    );
  }, [canViewHiring]);

  return (
    <>
      <SidebarSelectFilter
        title="Prospect"
        placeholder="Select prospect"
        onChange={(newValue) => updateFilterParams(SimulationsFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      <SidebarSelectFilter
        title="Prospect account"
        placeholder="Select prospect account"
        onChange={(newValue) => updateFilterParams(SimulationsFilterKeys.PROSPECT_ACCOUNT, newValue)}
        {...accountOptionsAndSelections}
      />
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(SimulationsFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
      <SidebarSelectFilter
        title="Category"
        placeholder="Select category"
        selected={filters.categories}
        onChange={(newValue) => updateFilterParams(SimulationsFilterKeys.CATEGORY, newValue)}
        options={prospectTypeOptions}
      />
      <SidebarDateFilter
        title="Date created"
        onChange={(newValue) => updateFilterParams(SimulationsFilterKeys.DATE_CREATED, newValue)}
        selected={filters.createdDate}
      />
    </>
  );
};

export default SimulationsFilters;
