import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useWebCall } from '../../../hooks';
import { closeWebCall, updateProspect, WebCallData } from '../../../redux/reducers';
import { ComponentSize } from '../../../types';
import { ManageMicAccess, Spinner } from '../../shared';
import WebCallModalContent from './WebCallModalContent';

interface WebCallModalProps {
  currWebCall: WebCallData;
}

const WebCallModal = ({ currWebCall }: WebCallModalProps) => {
  // Initially true to show the loading spinner when the modal opens until the call starts.
  const [isLoading, setIsLoading] = useState(true);
  const [isMicAccessGranted, setIsMicAccessGranted] = useState(false);
  const [isRedialing, setIsRedialing] = useState(false);

  const dispatch = useAppDispatch();

  const closeModal = useCallback(() => {
    dispatch(closeWebCall());
  }, [dispatch]);

  const { isCalling, isConnected, isConnectingToMic, isConnectionError, startCall, stopCall, ...webCallProps } =
    useWebCall(closeModal);

  const { personaId, isUsed } = currWebCall.currProspect;

  const runStartCall = useCallback(
    (newIsRedialing: boolean) => {
      if (isMicAccessGranted) {
        setIsRedialing(newIsRedialing);
        startCall(personaId);
      }
    },
    // We purposely don't include the startCall function in the dependency array because it will cause a loop.
    [isMicAccessGranted, personaId]
  );

  // Start a new call whenever the prospect changes.
  useEffect(() => {
    runStartCall(false);
  }, [runStartCall]);

  useEffect(() => {
    if (isCalling && !isRedialing) {
      setIsLoading(isConnectingToMic);
    }
  }, [isCalling, isConnectingToMic, isRedialing]);

  useEffect(() => {
    if (isConnected && !isUsed) {
      dispatch(updateProspect({ personaId, isUsed: true }));
    }
  }, [isConnected, isUsed, personaId, dispatch]);

  return (
    <>
      <ManageMicAccess onCancel={closeModal} onDenied={stopCall} setIsMicAccessGranted={setIsMicAccessGranted} />
      {isMicAccessGranted && (
        <div className="fixed inset-0 z-50 flex h-full items-end">
          {/* Overlay - Only allow clicking the overlay to close the modal if the call is not in progress. */}
          <div
            className="absolute inset-0 bg-neutral bg-opacity-40"
            onClick={isCalling || isConnectionError ? undefined : closeModal}
          />
          <div className="relative z-50 w-full border-t border-gray-200 bg-base-0 p-8">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <Spinner size={ComponentSize.MEDIUM} />
              </div>
            )}
            <div className={clsx(isLoading && 'invisible')}>
              <WebCallModalContent
                currWebCall={currWebCall}
                isCalling={isCalling}
                isConnected={isConnected}
                isConnectingToMic={isConnectingToMic}
                isConnectionError={isConnectionError}
                closeModal={closeModal}
                redial={() => runStartCall(true)}
                stopCall={stopCall}
                {...webCallProps}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WebCallModal;
