import clsx from 'clsx';
import React from 'react';
import { Icon, Icons, Typography, TypographySize } from '../../../components';
import { TextColor } from '../../../types';

type BaseProspectPageFieldProps = {
  content: React.ReactElement;
  label: string;
  required?: boolean;
  tooltip?: string;
  onRandomize?: () => void;
};

export type ProspectPageFieldProps =
  | (BaseProspectPageFieldProps & { fullWidth?: boolean; width?: never })
  | (BaseProspectPageFieldProps & { fullWidth?: never; width?: number });

const ProspectPageField = ({
  content,
  label,
  required,
  tooltip,
  fullWidth,
  width,
  onRandomize,
}: ProspectPageFieldProps) => {
  return (
    <div className={clsx('flex flex-col gap-2', fullWidth ? 'flex-1' : 'flex-0', width && 'w-fit')}>
      <div className="flex items-center gap-2">
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} className="select-none">
          {label}
          {required && '*'}
        </Typography>
        {tooltip && <Icons icon={Icon.INFO} color={TextColor.SECONDARY} tooltip={tooltip} />}
        {onRandomize && <Icons icon={Icon.WAND} color={TextColor.AI} onClick={onRandomize} tooltip="Fill for me" />}
      </div>
      <div className="flex min-h-8 items-center" style={{ width }}>
        {content}
      </div>
    </div>
  );
};

export default ProspectPageField;
