import { io } from 'socket.io-client';
import { ChannelSubscription } from '../types';

const SOCKET_URL = process.env.REACT_APP_API_ROOT_URL as string;

export const socket = io(SOCKET_URL, {
  autoConnect: false,
});

export const connectSocket = (token: string) => {
  socket.auth = { token };
  socket.connect();
};

export const subscribeToChannel = (channelName: string) => {
  const subscriptionData: ChannelSubscription = {
    channelName,
  };
  socket.emit('subscribe', subscriptionData);
};

export const disconnectSocket = () => {
  socket.disconnect();
};
