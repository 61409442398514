import { ApiTagTypes, Call, CallResponse, Scorecard, ScorecardResponse } from '../../types';
import { parseProspect } from '../../utils';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCall: builder.query<Call, string>({
      query: (callSid) => ({
        url: `/calls/${callSid}`,
        method: 'GET',
      }),
      transformResponse: (response: CallResponse) => ({
        ...response.data.call,
        practiceProspect: parseProspect(response.data.call.practiceProspect),
      }),
      providesTags: (_, __, callSid) => [{ type: ApiTagTypes.CALLS, id: callSid }],
    }),
    getCallScorecard: builder.query<Scorecard, string>({
      query: (callSid) => `/calls/${callSid}/scorecard`,
      transformResponse: (response: ScorecardResponse) => response.data.scorecard,
      providesTags: (_, __, callSid) => [{ type: ApiTagTypes.SCORECARDS, id: callSid }],
    }),
  }),
});

export const { useGetCallQuery, useGetCallScorecardQuery } = enhancedApiSlice;
