import { useEffect, useMemo } from 'react';
import { Select } from '../../../../components';
import { pluralize } from '../../../../utils';
import ProspectCheckbox from '../ProspectCheckbox';

const DEFAULT_NUM_OF_DYNAMIC_ITEMS = 1;
const NUM_OF_DYNAMIC_ITEMS_SELECT_WIDTH = 80;

interface DynamicItemsSelectorProps {
  isDynamicChecked: boolean;
  label: string;
  totalItems: number;
  setIsDynamicChecked: (newChecked: boolean) => void;
  setNumOfDynamicItems: (newNumOfDynamicItems: number) => void;
  numOfDynamicItems?: number;
}

const DynamicItemsSelector = ({
  isDynamicChecked,
  label,
  totalItems,
  setIsDynamicChecked,
  setNumOfDynamicItems,
  numOfDynamicItems,
}: DynamicItemsSelectorProps) => {
  // The number of dynamic items ranges from 1 up to one less than the total number of items.
  const options = useMemo(
    () =>
      Array.from({ length: totalItems - 1 }, (_, index) => {
        const value = (index + 1).toString(); // + 1 because we start counting from 1.
        return { label: value, value };
      }),
    [totalItems]
  );

  // Set default number of dynamic items when dynamic is checked.
  useEffect(() => {
    if (isDynamicChecked && numOfDynamicItems === undefined) {
      setNumOfDynamicItems(DEFAULT_NUM_OF_DYNAMIC_ITEMS);
    }
  }, [isDynamicChecked, numOfDynamicItems]);

  useEffect(() => {
    if (totalItems <= 1) {
      // The user cannot choose the total number of items to be dynamic; it has to be total - 1.
      // So if there is one or less items, we disable the dynamic feature.
      setIsDynamicChecked(false);
    } else if (isDynamicChecked && numOfDynamicItems && totalItems === numOfDynamicItems) {
      // If the total number of items equals the number of dynamic items after the user deletes/clears an item,
      // set dynamic items to total - 1.
      setNumOfDynamicItems(totalItems - 1);
    }
  }, [isDynamicChecked, numOfDynamicItems, totalItems]);

  // The user cannot choose the total number of items to be dynamic; it has to be total - 1.
  // So if there is one or less items, we hide the dynamic feature.
  if (totalItems <= 1) {
    return null;
  }

  return (
    <div className="flex w-full items-center justify-between">
      <ProspectCheckbox checked={isDynamicChecked} label="Dynamic" setChecked={setIsDynamicChecked} />
      {isDynamicChecked && (
        <div className="flex items-center gap-2">
          Choose
          <Select
            selected={numOfDynamicItems?.toString()}
            options={options}
            width={NUM_OF_DYNAMIC_ITEMS_SELECT_WIDTH}
            onChange={(value) => setNumOfDynamicItems(Number(value))}
          />
          {numOfDynamicItems === 1 ? label : pluralize(label)}
        </div>
      )}
    </div>
  );
};

export default DynamicItemsSelector;
