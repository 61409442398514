import { useAppDispatch } from '../../../hooks';
import { updateProspect, WebCallData } from '../../../redux/reducers';
import { Orientation } from '../../../types';
import { Divider, MAX_TAGS_AND_NOTES_HEIGHT, ProspectDetails, TagsAndNotesCell } from '../../shared';
import WebCallEndActions from './WebCallEndActions';
import WebCallStatus from './WebCallStatus';

interface WebCallModalContentProps {
  callSid: string;
  currWebCall: WebCallData;
  isCalling: boolean;
  isConnected: boolean;
  isConnectionError: boolean;
  isConnectingToMic: boolean;
  isHangingUp: boolean;
  isMuted: boolean;
  closeModal: () => void;
  redial: () => void;
  mute: () => void;
  unmute: () => void;
  stopCall: () => void;
  startTime?: number;
}

const WebCallModalContent = ({
  callSid,
  currWebCall,
  isCalling,
  isConnected,
  isConnectionError,
  isConnectingToMic,
  isHangingUp,
  isMuted,
  closeModal,
  redial,
  mute,
  unmute,
  stopCall,
  startTime,
}: WebCallModalContentProps) => {
  const dispatch = useAppDispatch();

  const { currProspect, nextProspect } = currWebCall;
  const { personaId, accountName, firstName, lastName, isFavorited, jobTitle, tags, notes } = currProspect;

  return (
    <div className="flex items-center gap-8" style={{ height: MAX_TAGS_AND_NOTES_HEIGHT }}>
      <div className="flex-1">
        <ProspectDetails
          personaId={personaId}
          accountName={accountName}
          firstName={firstName}
          lastName={lastName}
          jobTitle={jobTitle}
          isFavorited={isFavorited}
          onFavoriteSuccess={(newIsFavorited) => dispatch(updateProspect({ personaId, isFavorited: newIsFavorited }))}
        />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex-[1.5]">
        <TagsAndNotesCell prospectId={personaId} prospectTags={tags} notes={notes} />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex-1">
        <WebCallStatus
          callSid={callSid}
          isCalling={isCalling}
          isConnected={isConnected}
          isConnectionError={isConnectionError}
          isConnectingToMic={isConnectingToMic}
          isHangingUp={isHangingUp}
          startTime={startTime}
          handleRedial={redial}
        />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex-[1.25]">
        <WebCallEndActions
          currPersonaId={personaId}
          isCalling={isCalling}
          isConnectionError={isConnectionError}
          isConnectingToMic={isConnectingToMic}
          isHangingUp={isHangingUp}
          isMuted={isMuted}
          exitCall={closeModal}
          mute={mute}
          unmute={unmute}
          stopCall={stopCall}
          nextProspect={nextProspect}
        />
      </div>
    </div>
  );
};

export default WebCallModalContent;
