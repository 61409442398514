import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_SETTINGS_TAB, SETTINGS_ACTIVE_TAB_PARAM, SETTINGS_OPEN_PARAM } from '../../../constants';
import { useAppDispatch, useAppSelector, useFeatureFlag } from '../../../hooks';
import {
  SettingsModalTabs,
  closeSettingsModal,
  openSettingsModal,
  setSettingsActiveTab,
} from '../../../redux/reducers';
import { LD_FeatureFlags, OrganizationStatus, Orientation, Permissions } from '../../../types';
import { conditionalArray } from '../../../utils';
import { Dialog, DialogType, Icon, Tab, Tabs } from '../../shared';
import { AccountTab, HiringTab, IntegrationsTab, OrganizationTab, PeopleTab } from './tabs';

const SettingsModal = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { user, organization } = useAppSelector((state) => state.auth);
  const { isOpen, activeTab } = useAppSelector((state) => state.modal.settings);

  const gongFF = useFeatureFlag(LD_FeatureFlags.RELEASE_GONG);
  const orgSettingsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_ORG_SETTINGS);

  const permissions = user?.permissions || [];
  const { isHiringModuleEnabled, status } = organization || {};

  // Permissions
  const canViewHiring = permissions.includes(Permissions.VIEW_CANDIDATE);
  const canViewOrgSettings = permissions.includes(Permissions.MANAGE_ORG_SETTINGS);
  const canViewGong = permissions.includes(Permissions.VIEW_GONG);

  const isTrialOrg = status === OrganizationStatus.TRIAL;
  const allFlagsLoaded = gongFF !== undefined && orgSettingsFF !== undefined;
  const isHiringDisabled = !isHiringModuleEnabled || isTrialOrg;

  const tabs: Tab[] = useMemo(
    () => [
      {
        id: SettingsModalTabs.ACCOUNT,
        title: 'Account',
        content: <AccountTab />,
      },
      {
        id: SettingsModalTabs.PEOPLE,
        title: 'People',
        content: <PeopleTab />,
      },
      ...conditionalArray(canViewOrgSettings && orgSettingsFF, {
        id: SettingsModalTabs.ORGANIZATION,
        title: 'Organization',
        content: <OrganizationTab />,
      }),
      ...conditionalArray(canViewHiring, {
        id: SettingsModalTabs.HIRING,
        title: 'Hiring',
        content: <HiringTab />,
        disabled: isHiringDisabled,
        icon: isHiringDisabled ? Icon.LOCK : undefined,
      }),
      ...conditionalArray(canViewGong && gongFF, {
        id: SettingsModalTabs.INTEGRATIONS,
        title: 'Integrations',
        content: <IntegrationsTab />,
        disabled: isTrialOrg,
        icon: isTrialOrg ? Icon.LOCK : undefined,
      }),
    ],
    [isHiringModuleEnabled, canViewHiring, gongFF, isTrialOrg, canViewGong, canViewOrgSettings, orgSettingsFF]
  );

  // Effect to open modal based on URL query params on mount or when location.search changes
  useEffect(() => {
    if (!allFlagsLoaded) return;

    const queryParams = new URLSearchParams(location.search);

    // If the SETTINGS_OPEN_PARAM isn't present, do not proceed.
    if (!queryParams.has(SETTINGS_OPEN_PARAM)) {
      // Ensure the modal is closed if the param is not present.
      if (isOpen) dispatch(closeSettingsModal());
      return;
    }

    const openSettings = queryParams.get(SETTINGS_OPEN_PARAM);

    // If SETTINGS_OPEN_PARAM is not 'true', ensure the modal is closed and remove the param.
    if (openSettings !== 'true') {
      if (isOpen) dispatch(closeSettingsModal());
      return;
    }

    // If the SETTINGS_ACTIVE_TAB_PARAM isn't present, open to the default tab.
    if (!queryParams.has(SETTINGS_ACTIVE_TAB_PARAM)) {
      dispatch(openSettingsModal(DEFAULT_SETTINGS_TAB));
      return;
    }

    // Get the tab param and check if it is valid.
    const tabParam = queryParams.get(SETTINGS_ACTIVE_TAB_PARAM);
    const tabId = tabParam as SettingsModalTabs;
    const isValidTabId = Object.values(SettingsModalTabs).includes(tabId);

    let targetTabId = DEFAULT_SETTINGS_TAB;

    // If the tab param is a valid tab, consider setting it as the target tab.
    if (isValidTabId) {
      const tab = tabs.find((tab) => tab.id === tabId);
      const canOpen = !!tab && !tab.disabled;
      if (canOpen) {
        targetTabId = tabId;
      }
    }

    dispatch(openSettingsModal(targetTabId));
  }, [dispatch, location.search, tabs, allFlagsLoaded]);

  // Effect to update URL query params when modal state changes.
  useEffect(() => {
    if (!allFlagsLoaded) return;

    // We use window.location.search because it is a global state that will not trigger re-renders.
    const newParams = new URLSearchParams(window.location.search);

    if (isOpen) {
      // When modal opens, set SETTINGS_OPEN_PARAM to true and SETTINGS_ACTIVE_TAB_PARAM to activeTab.
      newParams.set(SETTINGS_OPEN_PARAM, 'true');
      newParams.set(SETTINGS_ACTIVE_TAB_PARAM, activeTab);
    } else {
      // When modal closes, clear SETTINGS_OPEN_PARAM and SETTINGS_ACTIVE_TAB_PARAM.
      newParams.delete(SETTINGS_OPEN_PARAM);
      newParams.delete(SETTINGS_ACTIVE_TAB_PARAM);
    }

    navigate({ pathname: location.pathname, search: newParams.toString() }, { replace: true });
    // We intentionally do not include URL or param objects in the dependency array
    // to avoid re-triggering this effect when the URL or query parameters change. This prevents
    // unnecessary updates and potential clearing of filter parameters.
  }, [activeTab, isOpen, allFlagsLoaded]);

  return (
    <Dialog
      title="Settings"
      isOpen={isOpen}
      onClose={() => dispatch(closeSettingsModal())}
      type={DialogType.SETTINGS}
      className="h-5/6 max-h-[700px]"
    >
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={(newActiveTab) => dispatch(setSettingsActiveTab(newActiveTab as SettingsModalTabs))}
        orientation={Orientation.VERTICAL}
      />
    </Dialog>
  );
};

export default SettingsModal;
