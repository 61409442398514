import { ApiSuccess, ProspectSubscriptionPayload } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSliceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    subscribeToProspect: builder.mutation<ApiSuccess, ProspectSubscriptionPayload>({
      query: (payload) => ({
        url: '/prospect-subscriptions',
        method: 'POST',
        body: payload,
      }),
    }),
    unsubscribeFromProspect: builder.mutation<ApiSuccess, ProspectSubscriptionPayload>({
      query: (payload) => ({
        url: '/prospect-subscriptions',
        method: 'DELETE',
        body: payload,
      }),
    }),
  }),
});

export const { useSubscribeToProspectMutation, useUnsubscribeFromProspectMutation } = enhancedApiSliceSlice;
