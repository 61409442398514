import { ApiSuccess, SubmitAITwinLinkPayload } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitAITwinLink: builder.mutation<ApiSuccess, SubmitAITwinLinkPayload>({
      query: (payload) => ({
        url: '/transcription-pipelines/process-url',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useSubmitAITwinLinkMutation } = enhancedApiSlice;
