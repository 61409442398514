import { useEffect } from 'react';
import { ButtonColor, Icon, TextButton } from '../../../../components';
import { MIN_REQUIRED_SCENARIO_ITEMS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  addQuestion,
  deleteQuestion,
  randomizeQuestions,
  setIsDynamicQuestions,
  setNumOfDynamicQuestions,
  updateQuestion,
} from '../../../../redux/reducers';
import TabSection from '../TabSection';
import DynamicItemsSelector from './DynamicItemsSelector';
import ScenarioListItem from './ScenarioListItem';

const Questions = () => {
  const dispatch = useAppDispatch();
  const {
    fields: { questions, isDynamicQuestions: isDynamicQuestionsChecked, numOfDynamicQuestions },
  } = useAppSelector((state) => state.prospect);

  const canDelete = questions.length > MIN_REQUIRED_SCENARIO_ITEMS;
  const numFilledQuestions = questions.filter((q) => !!q.value.trim()).length;

  // Delete empty questions on unmount.
  useEffect(() => {
    return () => {
      if (questions.length > MIN_REQUIRED_SCENARIO_ITEMS) {
        questions.forEach((question, index) => {
          if (!question.value) {
            dispatch(deleteQuestion(index));
          }
        });
      }
    };
  }, []);

  return (
    <TabSection title="Questions" onRandomize={() => dispatch(randomizeQuestions())}>
      {questions.map((question, index) => (
        <ScenarioListItem
          key={index}
          index={index}
          canDelete={canDelete}
          isRequired={index < MIN_REQUIRED_SCENARIO_ITEMS}
          value={question.value}
          deleteItem={() => dispatch(deleteQuestion(index))}
          updateItem={(value) => dispatch(updateQuestion({ index, value }))}
        />
      ))}
      <div className="flex items-center gap-4">
        <TextButton
          text="Add question"
          startIcon={Icon.PLUS}
          color={ButtonColor.SECONDARY}
          onClick={() => {
            dispatch(addQuestion());
          }}
        />
        <DynamicItemsSelector
          isDynamicChecked={isDynamicQuestionsChecked}
          label="question"
          numOfDynamicItems={numOfDynamicQuestions}
          totalItems={numFilledQuestions}
          setIsDynamicChecked={(newChecked) => dispatch(setIsDynamicQuestions(newChecked))}
          setNumOfDynamicItems={(newNumOfDynamicItems) => dispatch(setNumOfDynamicQuestions(newNumOfDynamicItems))}
        />
      </div>
    </TabSection>
  );
};

export default Questions;
