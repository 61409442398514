import { ApiTagTypes, Tag, TagsResponse } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query<Tag[], void>({
      query: () => '/tags',
      transformResponse: (response: TagsResponse) => response.data.tags,
      providesTags: [ApiTagTypes.TAGS],
    }),
  }),
});

export const { useGetTagsQuery } = enhancedApiSlice;
