import { useMemo, useState } from 'react';
import {
  ANALYTICS_ACTIVE_TAB_PARAM,
  AppRoutes,
  DEFAULT_ANALYTICS_AVERAGE_BY,
  DEFAULT_ANALYTICS_DATE_RANGE,
} from '../../constants';
import { useGetFiltersFromParams } from '../../hooks';
import { AnalyticsFilterKeys, AnalyticsFilters as AnalyticsFiltersType, AnalyticsTab } from '../../types';
import { getCurrentAppRoute } from '../../utils';
import { ButtonColor, TextButton } from '../shared';
import ClearFiltersDialog from './ClearFiltersDialog';

const SidebarFooterActions = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const currRoute = getCurrentAppRoute();

  const searchParams = new URLSearchParams(location.search);
  const analyticsActiveTab = searchParams.get(ANALYTICS_ACTIVE_TAB_PARAM);

  const filters = useGetFiltersFromParams();
  const hasFilters = Object.keys(filters).length > 0;

  const canClearFilters = useMemo(() => {
    // If we are on the Analytics page, check if the filters only contain the default date range.
    // If so, we do not allow the user to clear it since this is the minimum required filter.
    if (currRoute === AppRoutes.ANALYTICS) {
      const analyticsFilters = filters as AnalyticsFiltersType;
      const dateRangeFilter = analyticsFilters[AnalyticsFilterKeys.DATE_RANGE];
      const averageByFilter = analyticsFilters[AnalyticsFilterKeys.AVERAGE_BY];

      const isProgressReportTab = analyticsActiveTab === AnalyticsTab.PROGRESS_REPORT;

      // Check if the average by and date range filters are the default values.
      const isDefaultAverageByFilter = averageByFilter?.[0] === DEFAULT_ANALYTICS_AVERAGE_BY;
      const isDefaultDateRangeFilter =
        dateRangeFilter &&
        dateRangeFilter.start.getTime() === DEFAULT_ANALYTICS_DATE_RANGE.start.getTime() &&
        dateRangeFilter.end.getTime() === DEFAULT_ANALYTICS_DATE_RANGE.end.getTime();

      // Check if the filters only contain the default filters.
      const isOnlyDefaultDateRange = isProgressReportTab
        ? Object.keys(analyticsFilters).length === 2 && isDefaultDateRangeFilter && isDefaultAverageByFilter
        : Object.keys(analyticsFilters).length === 1 && isDefaultDateRangeFilter;

      return hasFilters && !isOnlyDefaultDateRange;
    }

    return hasFilters;
  }, [analyticsActiveTab, currRoute, filters, hasFilters]);

  if (!canClearFilters) return null;

  return (
    <>
      <TextButton
        fullWidth
        color={ButtonColor.SECONDARY}
        text="Clear filters"
        onClick={() => setIsConfirmModalOpen(true)}
      />
      <ClearFiltersDialog isOpen={isConfirmModalOpen} setIsOpen={setIsConfirmModalOpen} />
    </>
  );
};

export default SidebarFooterActions;
