import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { findOptionByValue } from '../../components';
import {
  ANNUAL_REVENUE_RANGES,
  CITIES,
  COMPANY_NAMES,
  COMPANY_SIZE_RANGES,
  DEFAULT_HANGUP_PROMPT,
  DEFAULT_LANGUAGE,
  DEFAULT_LLM_MAX_TOKENS,
  DEFAULT_LLM_MODEL,
  DEFAULT_LLM_MODEL_ID,
  DEFAULT_LLM_PENALTY,
  DEFAULT_LLM_TEMPERATURE,
  DEFAULT_MONOLINGUAL_STT_MODEL_ID,
  DEFAULT_MONOLINGUAL_TTS_MODEL_ID,
  DEFAULT_MULTILINGUAL_STT_MODEL_ID,
  DEFAULT_MULTILINGUAL_TTS_MODEL_ID,
  DEFAULT_SUCCESS_RESULT,
  DEPARTMENTS,
  DISCOVERY_HIDDEN_CONTEXTS,
  FIRST_MESSAGE,
  INDUSTRIES,
  JOB_TITLES,
  LAST_NAMES,
  MAX_RANDOM_SCENARIO_ITEMS,
  MIN_REQUIRED_DISCOVERY_HIDDEN_CONTEXTS,
  MIN_REQUIRED_SCENARIO_ITEMS,
  MULTILINGUAL_STT_MODEL_ID_OPTIONS,
  MULTILINGUAL_TTS_MODEL_OPTIONS,
  OBJECTIONS,
  PRIORITIES,
  PROSPECT_SLIDER_LIMITS,
  QUESTIONS,
  STT_MODEL,
  SUCCESS_RESULT_OPTIONS,
  VOICE_OPTIONS,
} from '../../constants';
import {
  FundingRaised,
  Language,
  LLMProviderID,
  PracticeProspectDetails,
  Pronouns,
  ProspectCategory,
  ProspectLastTouchpoint,
  ProspectPageMode,
  ProspectRoleType,
  ProspectSuccessResult,
  ProspectType,
  RangeType,
  RemoteType,
  SeverityLevel,
  Tag,
} from '../../types';
import {
  canRandomizeItem,
  getRandomAge,
  getRandomElement,
  getRandomFirstName,
  getRandomNumOfDirectReports,
  getRandomScenarioItems,
  getRandomSliderValue,
  getRandomTenure,
  isValueFilled,
} from '../../utils';

export type ProspectFooterFields = 'isFavorited' | 'notes' | 'tags';

const parseNullToUndefined = <T>(value: T | null): T | undefined => (value === null ? undefined : value);

interface ProspectState {
  mode: ProspectPageMode;
  orgId: string;
  associatedPhoneNumber?: string;
  category?: ProspectCategory;
  type?: ProspectType;
  personaId?: string;
  personaPrompt: { value?: string; isUserModified?: boolean };
  isPromptEdited: boolean;
  tags: Tag[];
  isFavorited?: boolean;
  notes?: string;
  fields: {
    // -- PERSONAL --
    // Demographics
    firstName: { value?: string; isUserModified?: boolean };
    lastName: { value?: string; isUserModified?: boolean };
    age: { value?: number; isUserModified?: boolean };
    pronouns: { value?: Pronouns; isUserModified?: boolean };

    // Personality
    talkativenessLevel: { value: number; isUserModified?: boolean };
    figurativeThinkingLevel: { value: number; isUserModified?: boolean };
    empathyLevel: { value: number; isUserModified?: boolean };
    improvisationLevel: { value: number; isUserModified?: boolean };

    // Voice
    language: Language;
    personaVoiceId: { value?: string; isUserModified?: boolean };
    firstMessage: { value?: string; isUserModified?: boolean };

    // -- LEAD --
    jobTitle: { value?: string; isUserModified?: boolean };
    tenure: { value?: number; isUserModified?: boolean };
    roleType: { value?: ProspectRoleType; isUserModified?: boolean };
    department: { value?: string; isUserModified?: boolean };
    numOfDirectReports: { value?: number; isUserModified?: boolean };

    // -- ACCOUNT --
    accountName: { value?: string; isUserModified?: boolean };
    industry: { value?: string; isUserModified?: boolean };
    hqLocation: { value?: string; isUserModified?: boolean };
    remoteType: { value?: RemoteType; isUserModified?: boolean };
    isMultiSite: { value?: boolean; isUserModified?: boolean };
    employeesFrom: { value?: number; isUserModified?: boolean };
    employeesTo?: number; // Upper bound of employee range, shares isUserModified state with employeesFrom.
    annualRevenueFrom: { value?: number; isUserModified?: boolean };
    annualRevenueTo?: number; // Upper bound of revenue range, shares isUserModified state with annualRevenueFrom.
    fundingRaisedType: { value?: FundingRaised; isUserModified?: boolean };

    // -- SCENARIO --
    priorities: { value: string; isUserModified?: boolean }[];
    isDynamicPriorities: boolean;
    numOfDynamicPriorities?: number;
    objections: { value: string; isUserModified?: boolean }[];
    isDynamicObjections: boolean;
    numOfDynamicObjections?: number;
    questions: { value: string; isUserModified?: boolean }[];
    isDynamicQuestions: boolean;
    numOfDynamicQuestions?: number;

    // Call context
    lastTouchpoint: { value: ProspectLastTouchpoint; isUserModified?: boolean };
    successResult: { value?: ProspectSuccessResult; isUserModified?: boolean };
    hiddenContexts: { value: string; isUserModified?: boolean }[];
    successDifficultyLevel: { value: number; isUserModified?: boolean };

    // -- CONFIGURATION --
    // Call type
    isHidden: boolean;
    isRestricted: boolean;

    // Manager details
    scorecardTemplateId?: string;
    cooldownPeriod?: number;
    timeLimit?: number;
    managerNotes?: string;

    // Conversation tuning
    breathingRoom?: number;
    numOfInitialShortResponses?: number;
    objectionRate?: SeverityLevel;
    questionRate?: SeverityLevel;
    numOfRequiredUncoveredPriorities?: number;

    // Ending the call
    isHangupFeatureEnabled: boolean;
    hangupPrompt?: string;

    // Technical configuration
    buyNewPhoneNumber?: boolean;
    ttsModelId: string;
    sttModel: string;
    sttModelId: string;
    llmModel: LLMProviderID;
    llmModelId?: string;
    llmTemperature: number;
    llmMaxTokens: number;
    llmFrequencyPenalty?: number;
    llmPresencePenalty?: number;
    promptTemplateId?: string;
  };
}

const INITIAL_PROSPECT_STATE: ProspectState = {
  mode: ProspectPageMode.CREATE,
  orgId: '',
  personaPrompt: {},
  isPromptEdited: false,
  tags: [],
  fields: {
    // Demographics
    firstName: {},
    lastName: {},
    age: {},
    pronouns: {},

    // Personality
    talkativenessLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    figurativeThinkingLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    empathyLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    improvisationLevel: { value: PROSPECT_SLIDER_LIMITS.default },

    // Voice
    language: DEFAULT_LANGUAGE.value,
    personaVoiceId: {},
    firstMessage: {},

    jobTitle: {},
    tenure: {},
    roleType: {},
    department: {},
    numOfDirectReports: {},

    accountName: {},
    industry: {},
    hqLocation: {},
    remoteType: {},
    isMultiSite: {},
    employeesFrom: {},
    annualRevenueFrom: {},
    fundingRaisedType: {},

    priorities: Array.from({ length: MIN_REQUIRED_SCENARIO_ITEMS }, () => ({ value: '' })),
    isDynamicPriorities: false,
    objections: Array.from({ length: MIN_REQUIRED_SCENARIO_ITEMS }, () => ({ value: '' })),
    isDynamicObjections: false,
    questions: Array.from({ length: MIN_REQUIRED_SCENARIO_ITEMS }, () => ({ value: '' })),
    isDynamicQuestions: false,
    lastTouchpoint: { value: ProspectLastTouchpoint.NONE },
    successResult: { value: DEFAULT_SUCCESS_RESULT },
    hiddenContexts: [],
    successDifficultyLevel: { value: PROSPECT_SLIDER_LIMITS.default },

    isHidden: false,
    isRestricted: true,
    isHangupFeatureEnabled: true,
    hangupPrompt: DEFAULT_HANGUP_PROMPT,
    ttsModelId: DEFAULT_MONOLINGUAL_TTS_MODEL_ID,
    sttModel: STT_MODEL,
    sttModelId: DEFAULT_MONOLINGUAL_STT_MODEL_ID,
    llmModel: DEFAULT_LLM_MODEL,
    llmModelId: DEFAULT_LLM_MODEL_ID,
    llmTemperature: DEFAULT_LLM_TEMPERATURE,
    llmMaxTokens: DEFAULT_LLM_MAX_TOKENS,
    llmFrequencyPenalty: DEFAULT_LLM_MODEL === LLMProviderID.GPT ? DEFAULT_LLM_PENALTY : undefined,
    llmPresencePenalty: DEFAULT_LLM_MODEL === LLMProviderID.GPT ? DEFAULT_LLM_PENALTY : undefined,
  },
};

// Create a slice for managing the prospect form state.
const prospectReducer = createSlice({
  name: 'prospect',
  initialState: INITIAL_PROSPECT_STATE,
  reducers: {
    // Resets the prospect form to its initial state.
    clearProspectForm: () => INITIAL_PROSPECT_STATE,
    // Sets the prospect form to the given prospect.
    // We handle the main form and the footer fields separately because the footer fields can be edited on-the-fly,
    setProspectForm: (
      state,
      action: PayloadAction<{
        prospect: Omit<PracticeProspectDetails, ProspectFooterFields>;
        canManageProspect: boolean;
        isTemplate?: boolean;
      }>
    ) => {
      const { prospect, isTemplate, canManageProspect } = action.payload;

      if (isTemplate) {
        // When duplicating a prospect as a template, we skip setting unique fields like personaId and associatedPhoneNumber,
        // since we're creating a new persona.
        // We also keep the template prospect's last name but randomize the first name.
        const randomFirstName = getRandomFirstName(prospect.pronouns, prospect.firstName);
        state.fields.firstName.value = randomFirstName;
      } else {
        state.personaId = prospect.personaId;
        state.fields.firstName.value = parseNullToUndefined(prospect.firstName);

        state.associatedPhoneNumber = prospect.associatedPhoneNumber;
        state.fields.buyNewPhoneNumber = !!prospect.associatedPhoneNumber;
      }

      state.orgId = prospect.orgId;
      state.category = prospect.category;
      state.type = prospect.type;
      state.personaPrompt.value = prospect.personaPrompt;
      state.isPromptEdited = prospect.isPromptEdited;

      const fields = state.fields;

      // -- PERSONAL --
      // Demographics
      fields.lastName.value = parseNullToUndefined(prospect.lastName);
      fields.age.value = parseNullToUndefined(prospect.age);
      fields.pronouns.value = parseNullToUndefined(prospect.pronouns);

      // Personality
      fields.talkativenessLevel.value = prospect.talkativenessLevel;
      fields.figurativeThinkingLevel.value = prospect.figurativeThinkingLevel;
      fields.empathyLevel.value = prospect.empathyLevel;
      fields.improvisationLevel.value = prospect.improvisationLevel;

      // Voice
      fields.personaVoiceId.value = parseNullToUndefined(prospect.personaVoiceId);
      fields.language = prospect.language;
      fields.firstMessage.value = parseNullToUndefined(prospect.firstMessage);

      // -- LEAD --
      fields.jobTitle.value = parseNullToUndefined(prospect.jobTitle);
      fields.tenure.value = parseNullToUndefined(prospect.tenure);
      fields.roleType.value = parseNullToUndefined(prospect.roleType);
      fields.department.value = parseNullToUndefined(prospect.department);
      fields.numOfDirectReports.value = parseNullToUndefined(prospect.numOfDirectReports);

      // -- ACCOUNT --
      fields.accountName.value = parseNullToUndefined(prospect.accountName);
      fields.industry.value = parseNullToUndefined(prospect.industry);
      fields.hqLocation.value = parseNullToUndefined(prospect.hqLocation);
      fields.remoteType.value = parseNullToUndefined(prospect.remoteType);
      fields.isMultiSite.value = parseNullToUndefined(prospect.isMultiSite);
      fields.employeesFrom.value = parseNullToUndefined(prospect.employeesFrom);
      fields.employeesTo = parseNullToUndefined(prospect.employeesTo);
      fields.annualRevenueFrom.value = parseNullToUndefined(prospect.annualRevenueFrom);
      fields.annualRevenueTo = parseNullToUndefined(prospect.annualRevenueTo);
      fields.fundingRaisedType.value = parseNullToUndefined(prospect.fundingRaisedType);

      // -- SCENARIO --
      fields.priorities = [
        ...prospect.priorities.map((priority) => ({ value: priority })),
        ...Array(Math.max(0, MIN_REQUIRED_SCENARIO_ITEMS - prospect.priorities.length)).fill({ value: '' }),
      ];
      fields.isDynamicPriorities = prospect.isDynamicPriorities;
      fields.numOfDynamicPriorities = parseNullToUndefined(prospect.numOfDynamicPriorities);

      fields.objections = [
        ...prospect.objections.map((objection) => ({ value: objection })),
        ...Array(Math.max(0, MIN_REQUIRED_SCENARIO_ITEMS - prospect.objections.length)).fill({ value: '' }),
      ];
      fields.isDynamicObjections = prospect.isDynamicObjections;
      fields.numOfDynamicObjections = parseNullToUndefined(prospect.numOfDynamicObjections);

      fields.questions = [
        ...prospect.questions.map((question) => ({ value: question })),
        ...Array(Math.max(0, MIN_REQUIRED_SCENARIO_ITEMS - prospect.questions.length)).fill({ value: '' }),
      ];
      fields.isDynamicQuestions = prospect.isDynamicQuestions;
      fields.numOfDynamicQuestions = parseNullToUndefined(prospect.numOfDynamicQuestions);

      // Call context
      fields.lastTouchpoint.value = parseNullToUndefined(prospect.lastTouchpoint) ?? ProspectLastTouchpoint.NONE;
      // QUALITY_PROSPECT is deprecated, so we set the default success result if the prospect is being created from a template.
      fields.successResult.value =
        isTemplate && prospect.successResult === ProspectSuccessResult.QUALIFY_PROSPECT
          ? DEFAULT_SUCCESS_RESULT
          : parseNullToUndefined(prospect.successResult);

      const minHiddenContexts = prospect.type === ProspectType.DISCOVERY ? MIN_REQUIRED_DISCOVERY_HIDDEN_CONTEXTS : 1;
      const remainingHiddenContexts = minHiddenContexts - prospect.hiddenContexts.length;
      fields.hiddenContexts = [
        ...prospect.hiddenContexts.map((context) => ({ value: context })),
        ...Array(Math.max(0, remainingHiddenContexts)).fill({ value: '' }),
      ];

      fields.successDifficultyLevel.value =
        parseNullToUndefined(prospect.successDifficultyLevel) ?? PROSPECT_SLIDER_LIMITS.min;

      // -- CONFIGURATION --
      // Call type
      fields.isHidden = prospect.isHidden;
      fields.isRestricted = prospect.isRestricted;

      // Manager details
      // Check if the user has access to manage the prospect.
      if (canManageProspect) {
        fields.managerNotes = parseNullToUndefined(prospect.managerNotes);
        fields.scorecardTemplateId = parseNullToUndefined(prospect.scorecardTemplateId);
        fields.cooldownPeriod = parseNullToUndefined(prospect.cooldownPeriod);
        fields.timeLimit = parseNullToUndefined(prospect.timeLimit);
      }

      // Conversation tuning
      fields.breathingRoom = parseNullToUndefined(prospect.breathingRoom);
      fields.numOfInitialShortResponses = parseNullToUndefined(prospect.numOfInitialShortResponses);
      fields.objectionRate = parseNullToUndefined(prospect.objectionRate);
      fields.questionRate = parseNullToUndefined(prospect.questionRate);
      fields.numOfRequiredUncoveredPriorities = parseNullToUndefined(prospect.numOfRequiredUncoveredPriorities);

      // Ending of the call
      fields.isHangupFeatureEnabled = !!parseNullToUndefined(prospect.isHangupFeatureEnabled);
      fields.hangupPrompt = parseNullToUndefined(prospect.hangupPrompt);

      // Technical configuration
      fields.ttsModelId = prospect.ttsModelId;
      fields.sttModelId = prospect.sttModelId;
      fields.llmModel = prospect.llmModel as LLMProviderID;
      fields.llmModelId = prospect.llmModelId;
      fields.llmTemperature = prospect.llmTemperature;
      fields.llmMaxTokens = prospect.llmMaxTokens;
      fields.llmFrequencyPenalty = parseNullToUndefined(prospect.llmFrequencyPenalty);
      fields.llmPresencePenalty = parseNullToUndefined(prospect.llmPresencePenalty);
      fields.promptTemplateId = parseNullToUndefined(prospect.promptTemplateId);
    },
    setProspectFooterFields: (state, action: PayloadAction<Pick<PracticeProspectDetails, ProspectFooterFields>>) => {
      const { isFavorited, notes, tags } = action.payload;
      state.isFavorited = isFavorited;
      state.notes = parseNullToUndefined(notes);
      state.tags = tags;
    },
    // -- General -- //
    setMode: (state, action: PayloadAction<ProspectPageMode>) => {
      state.mode = action.payload;
    },
    setOrgId: (state, action: PayloadAction<string>) => {
      state.orgId = action.payload;
    },
    // -- Personal -- //
    setFirstName: (state, action: PayloadAction<string>) => {
      state.fields.firstName.value = action.payload;
      state.fields.firstName.isUserModified = isValueFilled(action.payload);
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.fields.lastName.value = action.payload;
      state.fields.lastName.isUserModified = isValueFilled(action.payload);
    },
    setAge: (state, action: PayloadAction<number | undefined>) => {
      state.fields.age.value = action.payload;
      state.fields.age.isUserModified = isValueFilled(action.payload);
    },
    setPronouns: (state, action: PayloadAction<Pronouns | undefined>) => {
      state.fields.pronouns.value = action.payload;
      state.fields.pronouns.isUserModified = isValueFilled(action.payload);
    },
    setTalkativenessLevel: (state, action: PayloadAction<number>) => {
      state.fields.talkativenessLevel.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.talkativenessLevel.isUserModified = true;
    },
    setFigurativeThinkingLevel: (state, action: PayloadAction<number>) => {
      state.fields.figurativeThinkingLevel.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.figurativeThinkingLevel.isUserModified = true;
    },
    setEmpathyLevel: (state, action: PayloadAction<number>) => {
      state.fields.empathyLevel.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.empathyLevel.isUserModified = true;
    },
    setImprovisationLevel: (state, action: PayloadAction<number>) => {
      state.fields.improvisationLevel.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.improvisationLevel.isUserModified = true;
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.fields.language = action.payload;
      // Reset the voice if the language is changed.
      state.fields.personaVoiceId.value = undefined;

      const isMultilingual = action.payload !== DEFAULT_LANGUAGE.value;

      if (!isMultilingual) return;

      const selectedTtsModel = findOptionByValue(MULTILINGUAL_TTS_MODEL_OPTIONS, state.fields.ttsModelId);
      const selectedSttModel = findOptionByValue(MULTILINGUAL_STT_MODEL_ID_OPTIONS, state.fields.sttModelId);

      // If the selected TTS model is invalid for multilingual, reset the value to the default.
      if (!selectedTtsModel) {
        state.fields.ttsModelId = DEFAULT_MULTILINGUAL_TTS_MODEL_ID;
      }

      // If the selected STT model is invalid for multilingual, reset the value to the default.
      if (!selectedSttModel) {
        state.fields.sttModelId = DEFAULT_MULTILINGUAL_STT_MODEL_ID;
      }
    },
    setPersonaVoiceId: (state, action: PayloadAction<string | undefined>) => {
      state.fields.personaVoiceId.value = action.payload;
      state.fields.personaVoiceId.isUserModified = isValueFilled(action.payload);
    },
    setFirstMessage: (state, action: PayloadAction<string>) => {
      state.fields.firstMessage.value = action.payload;
      state.fields.firstMessage.isUserModified = isValueFilled(action.payload);
    },
    // Randomizes demographics.
    // Only randomizes fields that the user hasn't previously modified themselves.
    randomizeDemographics: (state) => {
      const fields = state.fields;

      let newPronouns: Pronouns | undefined;
      if (canRandomizeItem(state.mode, fields.pronouns)) {
        newPronouns = getRandomElement(Object.values(Pronouns));
        state.fields.pronouns.value = newPronouns;
      }

      if (canRandomizeItem(state.mode, fields.firstName)) {
        const currentPronouns = newPronouns ?? state.fields.pronouns?.value;
        const randomFirstName = getRandomFirstName(currentPronouns);
        state.fields.firstName.value = randomFirstName;
      }

      if (canRandomizeItem(state.mode, fields.lastName)) {
        const randomLastName = getRandomElement(LAST_NAMES);
        state.fields.lastName.value = randomLastName;
      }

      if (canRandomizeItem(state.mode, fields.age)) {
        const randomAge = getRandomAge();
        state.fields.age.value = randomAge;
      }
    },
    // Randomizes personality traits.
    // Only randomizes fields that the user hasn't previously modified themselves.
    randomizePersonality: (state) => {
      const fields = state.fields;

      if (canRandomizeItem(state.mode, fields.talkativenessLevel)) {
        const randomValue = getRandomSliderValue();
        state.fields.talkativenessLevel.value = randomValue;
      }

      if (canRandomizeItem(state.mode, fields.figurativeThinkingLevel)) {
        const randomValue = getRandomSliderValue();
        state.fields.figurativeThinkingLevel.value = randomValue;
      }

      if (canRandomizeItem(state.mode, fields.empathyLevel)) {
        const randomValue = getRandomSliderValue();
        state.fields.empathyLevel.value = randomValue;
      }

      if (canRandomizeItem(state.mode, fields.improvisationLevel)) {
        const randomValue = getRandomSliderValue();
        state.fields.improvisationLevel.value = randomValue;
      }
    },
    // Randomizes voice.
    // Only randomizes fields that the user hasn't previously modified themselves.
    randomizeVoice: (state) => {
      if (canRandomizeItem(state.mode, state.fields.personaVoiceId)) {
        const randomVoiceId = getRandomElement(VOICE_OPTIONS[state.fields.language]).value;
        state.fields.personaVoiceId.value = randomVoiceId;
      }
    },
    // -- Lead -- //
    setJobTitle: (state, action: PayloadAction<string>) => {
      state.fields.jobTitle.value = action.payload;
      state.fields.jobTitle.isUserModified = !!action.payload;
    },
    setTenure: (state, action: PayloadAction<number | undefined>) => {
      state.fields.tenure.value = action.payload;
      // We check for undefined because 0 is a valid value.
      state.fields.tenure.isUserModified = action.payload !== undefined;
    },
    setRoleType: (state, action: PayloadAction<ProspectRoleType | undefined>) => {
      state.fields.roleType.value = action.payload;
      state.fields.roleType.isUserModified = !!action.payload;
    },
    setDepartment: (state, action: PayloadAction<string>) => {
      state.fields.department.value = action.payload;
      state.fields.department.isUserModified = !!action.payload;
    },
    setNumOfDirectReports: (state, action: PayloadAction<number | undefined>) => {
      state.fields.numOfDirectReports.value = action.payload;
      // We check for undefined because 0 is a valid value.
      state.fields.numOfDirectReports.isUserModified = action.payload !== undefined;
    },
    randomizeLead: (state) => {
      const fields = state.fields;

      if (canRandomizeItem(state.mode, fields.jobTitle)) {
        const randomJobTitle = getRandomElement(JOB_TITLES);
        state.fields.jobTitle.value = randomJobTitle;
      }

      if (canRandomizeItem(state.mode, fields.tenure)) {
        const randomTenure = getRandomTenure();
        state.fields.tenure.value = randomTenure;
      }

      if (canRandomizeItem(state.mode, fields.roleType)) {
        const randomRoleType = getRandomElement(Object.values(ProspectRoleType));
        state.fields.roleType.value = randomRoleType;
      }

      if (canRandomizeItem(state.mode, fields.department)) {
        const randomDepartment = getRandomElement(DEPARTMENTS);
        state.fields.department.value = randomDepartment;
      }

      if (canRandomizeItem(state.mode, fields.numOfDirectReports)) {
        const randomNumOfDirectReports = getRandomNumOfDirectReports();
        state.fields.numOfDirectReports.value = randomNumOfDirectReports;
      }
    },
    // -- Account -- //
    setAccountName: (state, action: PayloadAction<string>) => {
      state.fields.accountName.value = action.payload;
      state.fields.accountName.isUserModified = !!action.payload;
    },
    setIndustry: (state, action: PayloadAction<string>) => {
      state.fields.industry.value = action.payload;
      state.fields.industry.isUserModified = !!action.payload;
    },
    setHQLocation: (state, action: PayloadAction<string>) => {
      state.fields.hqLocation.value = action.payload;
      state.fields.hqLocation.isUserModified = !!action.payload;
    },
    setRemoteType: (state, action: PayloadAction<RemoteType | undefined>) => {
      state.fields.remoteType.value = action.payload;
      state.fields.remoteType.isUserModified = !!action.payload;
    },
    setIsMultiSite: (state, action: PayloadAction<boolean | undefined>) => {
      state.fields.isMultiSite.value = action.payload;
      // We check for undefined because false is a valid value.
      state.fields.isMultiSite.isUserModified = action.payload !== undefined;
    },
    setNumOfEmployees: (state, action: PayloadAction<RangeType | undefined>) => {
      const [employeesFrom, employeesTo] = action.payload ?? [];
      if (employeesFrom === undefined) {
        // If employeesFrom is undefined, we clear the range.
        state.fields.employeesFrom.value = undefined;
        state.fields.employeesTo = undefined;
        state.fields.employeesFrom.isUserModified = false;
      } else {
        // Otherwise, we set the range.
        state.fields.employeesFrom.value = employeesFrom;
        state.fields.employeesTo = employeesTo;
        state.fields.employeesFrom.isUserModified = true;
      }
    },
    setAnnualRevenue: (state, action: PayloadAction<RangeType | undefined>) => {
      const [revenueFrom, revenueTo] = action.payload ?? [];
      if (revenueFrom === undefined) {
        // If revenueFrom is undefined, we clear the range.
        state.fields.annualRevenueFrom.value = undefined;
        state.fields.annualRevenueTo = undefined;
        state.fields.annualRevenueFrom.isUserModified = false;
      } else {
        // Otherwise, we set the range.
        state.fields.annualRevenueFrom.value = revenueFrom;
        state.fields.annualRevenueTo = revenueTo;
        state.fields.annualRevenueFrom.isUserModified = true;
      }
    },
    setFundingRaisedType: (state, action: PayloadAction<FundingRaised | undefined>) => {
      state.fields.fundingRaisedType.value = action.payload;
      state.fields.fundingRaisedType.isUserModified = !!action.payload;
    },
    randomizeAccount: (state) => {
      const fields = state.fields;

      if (canRandomizeItem(state.mode, fields.accountName)) {
        const randomCompanyName = getRandomElement(COMPANY_NAMES);
        state.fields.accountName.value = randomCompanyName;
      }

      if (canRandomizeItem(state.mode, fields.industry)) {
        const randomIndustry = getRandomElement(INDUSTRIES);
        state.fields.industry.value = randomIndustry;
      }

      if (canRandomizeItem(state.mode, fields.hqLocation)) {
        const randomHQLocation = getRandomElement(CITIES);
        state.fields.hqLocation.value = randomHQLocation;
      }

      if (canRandomizeItem(state.mode, fields.remoteType)) {
        const randomRemoteType = getRandomElement(Object.values(RemoteType));
        state.fields.remoteType.value = randomRemoteType;
      }

      if (canRandomizeItem(state.mode, fields.isMultiSite)) {
        const randomIsMultiSite = getRandomElement([true, false]);
        state.fields.isMultiSite.value = randomIsMultiSite;
      }

      if (canRandomizeItem(state.mode, fields.employeesFrom)) {
        const [randomEmployeesFrom, randomEmployeesTo] = getRandomElement(COMPANY_SIZE_RANGES).value;
        state.fields.employeesFrom.value = randomEmployeesFrom;
        state.fields.employeesTo = randomEmployeesTo;
      }

      if (canRandomizeItem(state.mode, fields.annualRevenueFrom)) {
        const [randomRevenueFrom, randomRevenueTo] = getRandomElement(ANNUAL_REVENUE_RANGES).value;
        state.fields.annualRevenueFrom.value = randomRevenueFrom;
        state.fields.annualRevenueTo = randomRevenueTo;
      }

      if (canRandomizeItem(state.mode, fields.fundingRaisedType)) {
        const randomFundingRaisedType = getRandomElement(Object.values(FundingRaised));
        state.fields.fundingRaisedType.value = randomFundingRaisedType;
      }
    },
    // -- Scenario -- //
    // Adds an empty item.
    addPriority: (state) => {
      state.fields.priorities.push({ value: '' });
    },
    addObjection: (state) => {
      state.fields.objections.push({ value: '' });
    },
    addQuestion: (state) => {
      state.fields.questions.push({ value: '' });
    },
    addHiddenContext: (state) => {
      state.fields.hiddenContexts.push({ value: '' });
    },
    // Deletes an item at the given index.
    deletePriority: (state, action: PayloadAction<number>) => {
      state.fields.priorities = state.fields.priorities.filter((_, i) => i !== action.payload);
    },
    deleteObjection: (state, action: PayloadAction<number>) => {
      state.fields.objections = state.fields.objections.filter((_, i) => i !== action.payload);
    },
    deleteQuestion: (state, action: PayloadAction<number>) => {
      state.fields.questions = state.fields.questions.filter((_, i) => i !== action.payload);
    },
    deleteHiddenContext: (state, action: PayloadAction<number>) => {
      state.fields.hiddenContexts = state.fields.hiddenContexts.filter((_, i) => i !== action.payload);
    },
    // Updates an item at the given index.
    updatePriority: (state, action: PayloadAction<{ index: number; value: string }>) => {
      const { index, value } = action.payload;
      if (state.fields.priorities[index]) {
        state.fields.priorities[index].value = value;
        state.fields.priorities[index].isUserModified = !!value;
      }
    },
    updateObjection: (state, action: PayloadAction<{ index: number; value: string }>) => {
      const { index, value } = action.payload;
      if (state.fields.objections[index]) {
        state.fields.objections[index].value = value;
        state.fields.objections[index].isUserModified = !!value;
      }
    },
    updateQuestion: (state, action: PayloadAction<{ index: number; value: string }>) => {
      const { index, value } = action.payload;
      if (state.fields.questions[index]) {
        state.fields.questions[index].value = value;
        state.fields.questions[index].isUserModified = !!value;
      }
    },
    updateHiddenContext: (state, action: PayloadAction<{ index: number; value: string }>) => {
      const { index, value } = action.payload;
      if (state.fields.hiddenContexts[index]) {
        state.fields.hiddenContexts[index].value = value;
        state.fields.hiddenContexts[index].isUserModified = !!value;
      }
    },
    setIsDynamicPriorities: (state, action: PayloadAction<boolean>) => {
      const newIsDynamicPriorities = action.payload;
      state.fields.isDynamicPriorities = newIsDynamicPriorities;
      // If dynamic priorities is disabled, we reset the number of dynamic priorities.
      if (!newIsDynamicPriorities) {
        state.fields.numOfDynamicPriorities = undefined;
      }
    },
    setNumOfDynamicPriorities: (state, action: PayloadAction<number>) => {
      state.fields.numOfDynamicPriorities = action.payload;
    },
    setIsDynamicObjections: (state, action: PayloadAction<boolean>) => {
      const newIsDynamicObjections = action.payload;
      state.fields.isDynamicObjections = newIsDynamicObjections;
      // If dynamic objections is disabled, we reset the number of dynamic objections.
      if (!newIsDynamicObjections) {
        state.fields.numOfDynamicObjections = undefined;
      }
    },
    setNumOfDynamicObjections: (state, action: PayloadAction<number>) => {
      state.fields.numOfDynamicObjections = action.payload;
    },
    setIsDynamicQuestions: (state, action: PayloadAction<boolean>) => {
      const newIsDynamicQuestions = action.payload;
      state.fields.isDynamicQuestions = newIsDynamicQuestions;
      // If dynamic questions is disabled, we reset the number of dynamic questions.
      if (!newIsDynamicQuestions) {
        state.fields.numOfDynamicQuestions = undefined;
      }
    },
    setNumOfDynamicQuestions: (state, action: PayloadAction<number>) => {
      state.fields.numOfDynamicQuestions = action.payload;
    },
    setLastTouchpoint: (state, action: PayloadAction<ProspectLastTouchpoint>) => {
      state.fields.lastTouchpoint.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.lastTouchpoint.isUserModified = true;
    },
    setSuccessResult: (state, action: PayloadAction<ProspectSuccessResult>) => {
      state.fields.successResult.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.successResult.isUserModified = true;
    },
    setSuccessDifficultyLevel: (state, action: PayloadAction<number>) => {
      state.fields.successDifficultyLevel.value = action.payload;
      // We check for undefined because 0 is a valid value.
      state.fields.successDifficultyLevel.isUserModified = action.payload !== undefined;
    },
    randomizePriorities: (state) => {
      state.fields.priorities = getRandomScenarioItems(PRIORITIES, state.fields.priorities, MAX_RANDOM_SCENARIO_ITEMS);
    },
    randomizeObjections: (state) => {
      state.fields.objections = getRandomScenarioItems(OBJECTIONS, state.fields.objections, MAX_RANDOM_SCENARIO_ITEMS);
    },
    randomizeQuestions: (state) => {
      state.fields.questions = getRandomScenarioItems(QUESTIONS, state.fields.questions, MAX_RANDOM_SCENARIO_ITEMS);
    },
    randomizeCallContext: (state) => {
      const fields = state.fields;

      if (canRandomizeItem(state.mode, fields.lastTouchpoint)) {
        const randomLastTouchpoint = getRandomElement(Object.values(ProspectLastTouchpoint));
        state.fields.lastTouchpoint.value = randomLastTouchpoint;
      }

      if (canRandomizeItem(state.mode, fields.successResult)) {
        const randomSuccessResult = getRandomElement(SUCCESS_RESULT_OPTIONS).value;
        state.fields.successResult.value = randomSuccessResult;
      }

      if (canRandomizeItem(state.mode, fields.successDifficultyLevel)) {
        const randomSuccessDifficulty = getRandomSliderValue();
        state.fields.successDifficultyLevel.value = randomSuccessDifficulty;
      }
    },
    randomizeHiddenGems: (state) => {
      // Only discovery prospects have pre-defined hidden gems.
      if (state.type === ProspectType.DISCOVERY) {
        state.fields.hiddenContexts = getRandomScenarioItems(DISCOVERY_HIDDEN_CONTEXTS, state.fields.hiddenContexts);
      }
    },
    // -- Configuration -- //
    setType: (state, action: PayloadAction<ProspectType>) => {
      const newType = action.payload;
      state.type = newType;

      // Set the default first message based on the type.
      state.fields.firstMessage.value = FIRST_MESSAGE[newType];

      // Set the minimum number of hidden context fields based on the type.
      const minHiddenContexts = newType === ProspectType.DISCOVERY ? MIN_REQUIRED_DISCOVERY_HIDDEN_CONTEXTS : 1;
      state.fields.hiddenContexts = Array.from({ length: minHiddenContexts }, () => ({ value: '' }));
    },
    setCategory: (state, action: PayloadAction<ProspectCategory>) => {
      state.category = action.payload;
    },
    setIsHidden: (state, action: PayloadAction<boolean>) => {
      state.fields.isHidden = action.payload;
    },
    setIsRestricted: (state, action: PayloadAction<boolean>) => {
      state.fields.isRestricted = action.payload;
    },
    setScorecardTemplateId: (state, action: PayloadAction<string | undefined>) => {
      state.fields.scorecardTemplateId = action.payload;
    },
    setCooldownPeriod: (state, action: PayloadAction<number | undefined>) => {
      state.fields.cooldownPeriod = action.payload;
    },
    setTimeLimit: (state, action: PayloadAction<number | undefined>) => {
      state.fields.timeLimit = action.payload;
    },
    setManagerNotes: (state, action: PayloadAction<string>) => {
      state.fields.managerNotes = action.payload;
    },
    setBreathingRoom: (state, action: PayloadAction<number | undefined>) => {
      state.fields.breathingRoom = action.payload;
    },
    setNumOfInitialShortResponses: (state, action: PayloadAction<number | undefined>) => {
      state.fields.numOfInitialShortResponses = action.payload;
    },
    setObjectionRate: (state, action: PayloadAction<SeverityLevel | undefined>) => {
      state.fields.objectionRate = action.payload;
    },
    setQuestionRate: (state, action: PayloadAction<SeverityLevel | undefined>) => {
      state.fields.questionRate = action.payload;
    },
    setNumOfRequiredUncoveredPriorities: (state, action: PayloadAction<number | undefined>) => {
      state.fields.numOfRequiredUncoveredPriorities = action.payload;
    },
    setIsHangupFeatureEnabled: (state, action: PayloadAction<boolean>) => {
      state.fields.isHangupFeatureEnabled = action.payload;
    },
    setHangupPrompt: (state, action: PayloadAction<string | undefined>) => {
      state.fields.hangupPrompt = action.payload;
    },
    setBuyNewPhoneNumber: (state, action: PayloadAction<boolean>) => {
      state.fields.buyNewPhoneNumber = action.payload;
    },
    setTtsModelId: (state, action: PayloadAction<string>) => {
      state.fields.ttsModelId = action.payload;
    },
    setSttModelId: (state, action: PayloadAction<string>) => {
      state.fields.sttModelId = action.payload;
    },
    setLlmModel: (state, action: PayloadAction<LLMProviderID>) => {
      const newLlmModel = action.payload;
      state.fields.llmModel = newLlmModel;
      // Reset LLM ID, frequency and presence penalties on changing the provider.
      state.fields.llmModelId = undefined;
      state.fields.llmFrequencyPenalty = newLlmModel === LLMProviderID.GPT ? DEFAULT_LLM_PENALTY : undefined;
      state.fields.llmPresencePenalty = newLlmModel === LLMProviderID.GPT ? DEFAULT_LLM_PENALTY : undefined;
    },
    setLlmModelId: (state, action: PayloadAction<string | undefined>) => {
      state.fields.llmModelId = action.payload;
    },
    setLlmTemperature: (state, action: PayloadAction<number>) => {
      state.fields.llmTemperature = action.payload;
    },
    setLlmMaxTokens: (state, action: PayloadAction<number>) => {
      state.fields.llmMaxTokens = action.payload;
    },
    setLlmFrequencyPenalty: (state, action: PayloadAction<number | undefined>) => {
      state.fields.llmFrequencyPenalty = action.payload;
    },
    setLlmPresencePenalty: (state, action: PayloadAction<number | undefined>) => {
      state.fields.llmPresencePenalty = action.payload;
    },
    setPromptTemplateId: (state, action: PayloadAction<string>) => {
      state.fields.promptTemplateId = action.payload;
    },
    setPersonaPrompt: (state, action: PayloadAction<{ value?: string; isUserModified?: boolean }>) => {
      state.personaPrompt.value = action.payload.value;
      state.personaPrompt.isUserModified = !!action.payload.isUserModified;
    },
    setIsPromptEdited: (state, action: PayloadAction<boolean>) => {
      state.isPromptEdited = action.payload;
    },
    updateFooterFields: (state, action: PayloadAction<{ isFavorited?: boolean; notes?: string; tags?: Tag[] }>) => {
      const { isFavorited, notes, tags } = action.payload;
      if (isFavorited !== undefined) state.isFavorited = isFavorited;
      if (notes !== undefined) state.notes = notes;
      if (tags !== undefined) state.tags = tags;
    },
  },
});
export const {
  clearProspectForm,
  setProspectForm,
  setProspectFooterFields,
  setMode,
  setOrgId,
  setFirstName,
  setLastName,
  setAge,
  setPronouns,
  setTalkativenessLevel,
  setFigurativeThinkingLevel,
  setEmpathyLevel,
  setImprovisationLevel,
  setLanguage,
  setPersonaVoiceId,
  randomizeDemographics,
  randomizePersonality,
  randomizeVoice,
  setJobTitle,
  setTenure,
  setRoleType,
  setDepartment,
  setNumOfDirectReports,
  randomizeLead,
  setAccountName,
  setIndustry,
  setHQLocation,
  setRemoteType,
  setIsMultiSite,
  setNumOfEmployees,
  setAnnualRevenue,
  setFundingRaisedType,
  randomizeAccount,
  addPriority,
  addObjection,
  addQuestion,
  addHiddenContext,
  deletePriority,
  deleteObjection,
  deleteQuestion,
  deleteHiddenContext,
  updatePriority,
  updateObjection,
  updateQuestion,
  updateHiddenContext,
  setIsDynamicPriorities,
  setNumOfDynamicPriorities,
  setIsDynamicObjections,
  setNumOfDynamicObjections,
  setIsDynamicQuestions,
  setNumOfDynamicQuestions,
  setLastTouchpoint,
  setSuccessResult,
  setSuccessDifficultyLevel,
  randomizePriorities,
  randomizeObjections,
  randomizeQuestions,
  randomizeCallContext,
  randomizeHiddenGems,
  setType,
  setCategory,
  setIsHidden,
  setIsRestricted,
  setScorecardTemplateId,
  setCooldownPeriod,
  setTimeLimit,
  setManagerNotes,
  setFirstMessage,
  setBreathingRoom,
  setNumOfInitialShortResponses,
  setObjectionRate,
  setQuestionRate,
  setNumOfRequiredUncoveredPriorities,
  setIsHangupFeatureEnabled,
  setHangupPrompt,
  setBuyNewPhoneNumber,
  setTtsModelId,
  setSttModelId,
  setLlmModel,
  setLlmModelId,
  setLlmTemperature,
  setLlmMaxTokens,
  setLlmFrequencyPenalty,
  setLlmPresencePenalty,
  setPromptTemplateId,
  setPersonaPrompt,
  setIsPromptEdited,
  updateFooterFields,
} = prospectReducer.actions;

export default prospectReducer.reducer;
