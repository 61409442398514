import clsx from 'clsx';
import React, { FocusEvent, KeyboardEvent, forwardRef, useCallback, useEffect, useRef } from 'react';
import { ComponentSize } from '../../../../types';
import { Icon, IconComponent, Icons } from '../../Icons';
import '../Input.css';
import { getInputClasses } from '../Input.utils';
import InputError from '../InputError';
import { TEXT_AREA_DEFAULT_ROWS, TEXT_AREA_SIZE } from './TextArea.constants';
import { TextAreaProps } from './TextArea.types';

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      disabled,
      error,
      endIcon,
      placeholder,
      rows = TEXT_AREA_DEFAULT_ROWS,
      maxRows,
      fillContainer = false,
      fitContent = false,
      width,
      size = ComponentSize.SMALL,
      value = '',
      onBlur,
      onChange,
      onKeyDown,
      onSelect,
    },
    ref
  ) => {
    const internalRef = useRef<HTMLTextAreaElement | null>(null);

    const setRefs = useCallback((element: HTMLTextAreaElement | null) => {
      // Set internal ref used for auto-sizing
      internalRef.current = element;

      // Set external ref
      if (typeof ref === 'function') ref(element);
      else if (ref) ref.current = element;
    }, []);

    const handleOnBlur = useCallback(
      (event: FocusEvent<HTMLTextAreaElement>) => {
        if (onBlur) {
          event.stopPropagation();
          onBlur(event);
        }
      },
      [onBlur]
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (onKeyDown) {
          event.stopPropagation();
          onKeyDown(event);
        }
      },
      [onKeyDown]
    );

    // Auto-size the text area based on the value and max rows,
    // making sure the parent container is not scrolled when the textarea is resized.
    useEffect(() => {
      if (!fitContent) return;

      const textarea = internalRef.current;
      if (!textarea) return;

      // Save the current scroll position of the parent container.
      const parent = textarea.closest('.flex-grow');
      const parentScrollPosition = parent ? parent.scrollTop : 0;

      // Reset the height of the textarea before calculating the new height.
      textarea.style.height = 'auto';

      // Calculate line height based on current font size
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
      const maxHeight = maxRows ? lineHeight * maxRows : Infinity;

      // Get the scroll height of the textarea.
      const scrollHeight = textarea.scrollHeight;

      // Apply height constraints to the textarea.
      if (scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.height = `${scrollHeight}px`;
        textarea.style.overflowY = 'hidden';
      }

      // After applying the new height, restore the parent scroll position.
      if (parent) {
        // Delay restoring the scroll position to ensure the layout update takes effect
        setTimeout(() => {
          parent.scrollTop = parentScrollPosition;
        }, 0);
      }
    }, [value, fitContent, maxRows]);

    const renderIcon = (icon: Icon | IconComponent) => {
      return typeof icon === 'string' ? <Icons icon={icon} /> : React.cloneElement(icon);
    };

    return (
      <div className={clsx(!width && 'w-full', className)} style={{ width }}>
        <label
          className={clsx(
            'textarea textarea-bordered relative',
            TEXT_AREA_SIZE[size],
            disabled && 'textarea-disabled',
            fillContainer && 'h-full',
            getInputClasses(disabled, error)
          )}
        >
          <textarea
            ref={setRefs}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            className={clsx(
              'display-scrollbar-sm flex w-full resize-none whitespace-break-spaces outline-none',
              fillContainer && 'h-full'
            )}
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
            readOnly={!onChange || disabled}
            rows={rows}
            onSelect={onSelect}
          />
          {endIcon && <div className="absolute bottom-1 right-1">{renderIcon(endIcon)}</div>}
        </label>
        {typeof error === 'string' && <InputError message={error} />}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';
export default TextArea;
