import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PracticeProspect, ProspectStatus } from '../../types';
import { alphanumericCompare, conditionalObject, isProspectInCooldownPeriod, isProspectLocked } from '../../utils';

export enum SimulationsFolders {
  LIBRARY = 'MY_LIBRARY',
  FAVORITES = 'MY_FAVORITES',
}

export enum SimulationsView {
  TABLE = 'TABLE',
  FOLDERS = 'FOLDERS',
}

const getNextProspect = (prospects: PracticeProspect[], currProspect: PracticeProspect) => {
  const activeProspects = prospects.filter((prospect) => prospect.status !== ProspectStatus.ARCHIVED);

  const currIndex = activeProspects.findIndex((prospect) => prospect.personaId === currProspect.personaId);
  if (currIndex === -1) {
    return undefined;
  }

  // If the current prospect is the last prospect, wrap around to the first prospect.
  const nextIndex = currIndex + 1 < activeProspects.length ? currIndex + 1 : 0;
  let nextProspect: PracticeProspect | undefined = activeProspects[nextIndex];

  // If the next prospect is the same as the current prospect, set nextProspect to undefined.
  if (nextProspect.personaId === currProspect.personaId) {
    nextProspect = undefined;
  }

  return nextProspect;
};

export type WebCallData = {
  currProspect: PracticeProspect;
  nextProspect?: PracticeProspect;
};

interface SimulationsState {
  currFolder: SimulationsFolders;
  showConfetti: boolean;
  /**
   * List of prospects in the order they can be called.
   * After ending a call, the user can call the next prospect in this sequence.
   */
  prospects: PracticeProspect[];
  /**
   * Information about the current call.
   * Undefined when no call is in progress.
   */
  currWebCall?: WebCallData;
  /**
   * Temporary state to store the view to display the prospects in until we release the folders view.
   * Used to toggle between table and folder views.
   */
  view: SimulationsView;
}

const INITIAL_SIMULATIONS_STATE: SimulationsState = {
  currFolder: SimulationsFolders.LIBRARY,
  showConfetti: false,
  prospects: [],
  view: SimulationsView.TABLE,
};

// Create a slice for managing the practice state.
const simulationsReducer = createSlice({
  name: 'simulations',
  initialState: INITIAL_SIMULATIONS_STATE,
  reducers: {
    setShowConfetti: (state, action: PayloadAction<boolean>) => {
      state.showConfetti = action.payload;
    },
    setCurrFolder: (state, action: PayloadAction<SimulationsFolders>) => {
      state.currFolder = action.payload;
    },
    setProspects: (state, action: PayloadAction<PracticeProspect[]>) => {
      const newProspects = action.payload;

      // Prospects do not come from the backend sorted by locked status,
      // so we need to manually sort them here.
      // Locked prospects are displayed before archived prospects, so we just need to insert them before the first
      // archived prospect, and filtering will not change their updatedAt order.
      const lockedProspects = newProspects.filter((p) => isProspectLocked(p));
      const archivedProspects = newProspects.filter((p) => p.status === ProspectStatus.ARCHIVED);
      const activeProspects = newProspects.filter((p) => !isProspectLocked(p) && p.status === ProspectStatus.ACTIVE);

      // Create a new array with active prospects first, then locked, then archived.
      state.prospects = [...activeProspects, ...lockedProspects, ...archivedProspects];
    },
    deleteProspect: (state, action: PayloadAction<string>) => {
      const personaId = action.payload;
      state.prospects = state.prospects.filter((p) => p.personaId !== personaId);
    },
    // Update the prospect with the given personaId.
    updateProspect: (state, action: PayloadAction<Partial<PracticeProspect> & { personaId: string }>) => {
      const { personaId, ...updatedFields } = action.payload;

      const oldIndex = state.prospects.findIndex((p) => p.personaId === personaId);
      const oldProspect = state.prospects[oldIndex];

      const updateProspect = (p: PracticeProspect) => ({
        ...p,
        ...updatedFields,
        ...conditionalObject(!!updatedFields.tags, {
          // Sort the tags by name in alphanumeric order, similar to how the tags are sorted on the server.
          tags: (updatedFields.tags || []).sort((a, b) => alphanumericCompare(a.name, b.name)),
        }),
      });

      const newProspect = updateProspect(oldProspect);

      // If a call has just concluded with this prospect, or the cooldown period has been updated,
      // check if the prospect should be locked.
      // Locked prospects are displayed after archived prospects.
      const isUpdatingEndTime = !!updatedFields.lastCall?.endTime && !!newProspect.cooldownPeriod;
      const isUpdatingCooldownPeriod = 'cooldownPeriod' in updatedFields && !!newProspect.lastCall?.endTime;
      const isInCooldownPeriod = isProspectInCooldownPeriod(newProspect);
      if ((isUpdatingEndTime || isUpdatingCooldownPeriod) && isInCooldownPeriod) {
        // If the prospect is being locked, move it before the first locked prospect.
        if (oldIndex === -1) return;
        // Remove the prospect from the list.
        state.prospects.splice(oldIndex, 1);
        // Get the index of the first prospect that matches the given status.
        const firstIndex = state.prospects.findIndex((p) => isProspectLocked(p));
        // Insert the prospect before the first prospect that matches the given status.
        // If there is no prospect that matches the given status, insert the prospect at the end of the list.
        state.prospects.splice(firstIndex === -1 ? state.prospects.length : firstIndex, 0, newProspect);
      } else if (isUpdatingCooldownPeriod && !isInCooldownPeriod) {
        // If the prospect is being unlocked (ie. by removing the cool-down period), move it to the top of the prospects list.
        if (oldIndex === -1) return;
        // Remove the prospect from the list.
        state.prospects.splice(oldIndex, 1);
        // Insert the prospect at the top of the list.
        state.prospects.unshift(newProspect);
      }

      state.prospects = state.prospects.map((p) => (p.personaId === personaId ? newProspect : p));

      // Update web call state if the prospect being updated is the current or next prospect.
      if (state.currWebCall?.currProspect.personaId === personaId) {
        state.currWebCall.currProspect = updateProspect(state.currWebCall.currProspect);
      } else if (state.currWebCall?.nextProspect?.personaId === personaId) {
        state.currWebCall.nextProspect = updateProspect(state.currWebCall.nextProspect);
      }
    },
    // Start a web call with the given prospect by setting the currWebCall state
    // which triggers opening the WebCallModal and starting the call.
    startWebCall: (state, action: PayloadAction<{ prospect: PracticeProspect; hideUpNext?: boolean }>) => {
      const { prospect: currProspect, hideUpNext } = action.payload;
      const nextProspect = hideUpNext ? undefined : getNextProspect(state.prospects, currProspect);

      state.currWebCall = {
        currProspect,
        nextProspect,
      };
    },
    // Start the next web call by setting the currWebCall state with the next prospect.
    startNextWebCall: (state) => {
      if (!state.currWebCall || !state.currWebCall.nextProspect) return;

      const currProspect = state.currWebCall.nextProspect;
      const nextProspect = getNextProspect(state.prospects, currProspect);

      state.currWebCall = {
        currProspect,
        nextProspect,
      };
    },
    closeWebCall: (state) => {
      state.currWebCall = undefined;
    },
    setView: (state, action: PayloadAction<SimulationsView>) => {
      state.view = action.payload;
    },
  },
});

export const {
  setShowConfetti,
  setCurrFolder,
  setProspects,
  deleteProspect,
  updateProspect,
  startWebCall,
  startNextWebCall,
  closeWebCall,
  setView,
} = simulationsReducer.actions;
export default simulationsReducer.reducer;
