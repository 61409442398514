import { CallSpeaker } from '../types';

/** Mapping of call speaker types to their respective labels. */
export const CALL_SPEAKER_TO_LABEL: Record<CallSpeaker, string> = {
  [CallSpeaker.AGENT]: 'Representative',
  [CallSpeaker.CUSTOMER]: 'Prospect',
};

/** The maximum duration of a call in milliseconds. */
export const MAX_CALL_DURATION = 70 * 60 * 1000; // 1 hour and 10 minutes
