import {
  ApiSuccess,
  ApiTagTypes,
  Call,
  CallsPayload,
  CallsResponse,
  FlagCallPayload,
  PagePagination,
} from '../../types';
import { getUrlWithPaginationAndSorting, parseProspect } from '../../utils';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCalls: builder.mutation<{ calls: Call[]; pagination: PagePagination }, CallsPayload>({
      query: ({ pagination, sorting, ...filters }) => ({
        url: getUrlWithPaginationAndSorting('/calls', pagination, sorting),
        method: 'POST',
        body: filters,
      }),
      transformResponse: (response: CallsResponse) => ({
        calls: response.data.calls.map((call) => ({
          ...call,
          practiceProspect: parseProspect(call.practiceProspect),
        })),
        pagination: response.data.pagination,
      }),
    }),
    flagCall: builder.mutation<ApiSuccess, FlagCallPayload>({
      query: ({ callSid, flaggedUsers }) => ({
        url: `/calls/${callSid}/flag`,
        method: 'POST',
        body: { flaggedUsers },
      }),
      invalidatesTags: (_, __, { callSid }) => [{ type: ApiTagTypes.CALLS, id: callSid }],
    }),
    unflagCall: builder.mutation<ApiSuccess, string>({
      query: (callSid) => ({
        url: `/calls/${callSid}/flag`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, callSid) => [{ type: ApiTagTypes.CALLS, id: callSid }],
    }),
    generateCallCoaching: builder.mutation<ApiSuccess, string>({
      query: (callSid) => ({
        url: `/calls/${callSid}/ai-coaching`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetCallsMutation, useFlagCallMutation, useUnflagCallMutation, useGenerateCallCoachingMutation } =
  enhancedApiSlice;
