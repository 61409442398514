import clsx from 'clsx';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { SimulationsFolders as SimulationsFolderTypes, setCurrFolder } from '../../redux/reducers';
import { StatusColor } from '../../types';
import { snakeCaseToLabel } from '../../utils';
import { Divider, Icon, Icons, Typography, TypographySize, TypographyWeight } from '../shared';

const SIMULATIONS_FOLDERS = [
  {
    id: SimulationsFolderTypes.LIBRARY,
    label: snakeCaseToLabel(SimulationsFolderTypes.LIBRARY),
    icon: Icon.LIBRARY,
  },
  {
    id: SimulationsFolderTypes.FAVORITES,
    label: snakeCaseToLabel(SimulationsFolderTypes.FAVORITES),
    icon: Icon.STAR,
    iconColor: StatusColor.WARNING,
    iconFillColor: StatusColor.WARNING,
  },
];

const SimulationsFolders = () => {
  const dispatch = useAppDispatch();
  const currFolder = useAppSelector((state) => state.simulations.currFolder);

  // Reset to default folder on mount.
  useEffect(() => {
    dispatch(setCurrFolder(SimulationsFolderTypes.LIBRARY));
  }, [dispatch]);

  return (
    <>
      <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
        Simulations
      </Typography>
      <div className="flex flex-col gap-2">
        {SIMULATIONS_FOLDERS.map(({ icon, id, label, iconColor, iconFillColor }) => {
          const isActive = currFolder === id;
          return (
            <div
              key={id}
              className={clsx(
                'flex h-8 cursor-pointer select-none items-center gap-2 rounded-lg px-8',
                isActive ? 'bg-base-200' : 'hover:bg-base-100'
              )}
              onClick={() => dispatch(setCurrFolder(id))}
            >
              <Icons icon={icon} fillColor={iconFillColor} color={iconColor} />
              <Typography>{label}</Typography>
            </div>
          );
        })}
      </div>
      <Divider />
    </>
  );
};

export default SimulationsFolders;
