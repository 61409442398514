import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ButtonColor,
  ButtonVariant,
  Divider,
  getSelectOptions,
  Icon,
  Icons,
  Select,
  Spinner,
  TextButton,
  Typography,
} from '../../../../components';
import { AppRoutes } from '../../../../constants';
import { useAppSelector } from '../../../../hooks';
import { useGetScorecardTemplateQuery, useGetScorecardTemplatesForSelectQuery } from '../../../../services';
import {
  BaseScorecardTemplate,
  ComponentSize,
  OrganizationStatus,
  ScorecardTemplate,
  TextColor,
} from '../../../../types';

const NEW_SCORECARD_CONTAINER_WIDTH = 250;

interface ScorecardEmptyStateProps {
  canScoreCalls: boolean;
  isScoringFailed: boolean;
  setScorecardTemplate: (scorecard?: ScorecardTemplate) => void;
  setIsEditMode: (isEditMode: boolean) => void;
  handleScoreWithAI: () => void;
  scorecardTemplate?: ScorecardTemplate;
}

const ScorecardEmptyState = ({
  canScoreCalls,
  isScoringFailed,
  scorecardTemplate,
  setScorecardTemplate,
  setIsEditMode,
  handleScoreWithAI,
}: ScorecardEmptyStateProps) => {
  const navigate = useNavigate();
  const { organization } = useAppSelector((state) => state.auth);

  const [selectedScorecardTemplateOption, setSelectedScorecardTemplateOption] = useState<
    BaseScorecardTemplate | undefined
  >(scorecardTemplate);

  const isTrialOrg = organization?.status === OrganizationStatus.TRIAL;

  // Fetch all active scorecard templates
  const { data: scorecardTemplates, isLoading: isScorecardTemplatesLoading } = useGetScorecardTemplatesForSelectQuery(
    {
      type: undefined,
    },
    {
      skip: !canScoreCalls,
    }
  );

  // Fetch selected template details
  const { data: selectedTemplateDetails, isLoading: isSelectedTemplateLoading } = useGetScorecardTemplateQuery(
    selectedScorecardTemplateOption?.id ?? '',
    {
      skip: !selectedScorecardTemplateOption,
    }
  );

  // Update scorecardTemplate when selected template details are fetched
  useEffect(() => {
    if (selectedTemplateDetails) {
      setScorecardTemplate(selectedTemplateDetails);
    }
  }, [selectedTemplateDetails]);

  const scorecardOptions = scorecardTemplates ? getSelectOptions(scorecardTemplates) : [];

  // Handle scorecard selection change
  const handleScorecardChange = useCallback(
    (selectedValue: string) => {
      const newSelectedScorecard = scorecardTemplates?.find((scorecard) => scorecard.id === selectedValue);
      setSelectedScorecardTemplateOption(newSelectedScorecard);
      setIsEditMode(true);
    },
    [scorecardTemplates]
  );

  if (isSelectedTemplateLoading || isScorecardTemplatesLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.SMALL} />
      </div>
    );
  }

  // Failure state
  if (isScoringFailed) {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-4">
        <div className="flex items-center gap-2">
          <Icons icon={Icon.TRIANGLE_ALERT} size={ComponentSize.MEDIUM} />
          <Typography>Scoring error</Typography>
        </div>
        <div className="flex gap-2">
          <Select
            options={scorecardOptions ?? []}
            placeholder="Select scorecard"
            selected={selectedScorecardTemplateOption?.id}
            onChange={(selectedValue) => selectedValue && handleScorecardChange(selectedValue)}
          />
          <TextButton text="Retry" variant={ButtonVariant.OUTLINE} onClick={handleScoreWithAI} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="m-auto flex h-full w-full flex-col items-center justify-center gap-8"
      style={{ width: NEW_SCORECARD_CONTAINER_WIDTH }}
    >
      <div className="flex items-center gap-2">
        <Icons icon={Icon.LIST_CHECKS} size={ComponentSize.LARGE} color={TextColor.LINK} />
        <Typography>No scorecard selected</Typography>
      </div>
      {/* If the user can score calls, show the scorecard selection and create new button */}
      {canScoreCalls && (
        <div className="flex w-full flex-col gap-4">
          {/* If there are scorecard templates, show the select */}
          {scorecardTemplates?.length && (
            <>
              <Select
                options={scorecardOptions ?? []}
                placeholder="Select scorecard"
                onChange={(selectedValue) => selectedValue && handleScorecardChange(selectedValue)}
              />
              <Divider text="OR" />
            </>
          )}
          <TextButton
            text="Create new"
            onClick={() => navigate(AppRoutes.SCORECARD_TEMPLATES)}
            color={ButtonColor.SECONDARY}
            disabled={isTrialOrg}
            fullWidth
          />
        </div>
      )}
    </div>
  );
};

export default ScorecardEmptyState;
