import { ColumnDef, Getter, PaginationState, Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  AvatarGroup,
  Badge,
  Breadcrumbs,
  ButtonColor,
  ButtonVariant,
  CreateButton,
  CustomCellContext,
  DataTable,
  Icon,
  IconButton,
  Icons,
  MediaPlayer,
  SortableHeader,
  Typography,
  TypographySize,
  UserBadge,
} from '../../components';
import { AppRoutes, PAGINATION_PAGE_SIZE, PROSPECT_CATEGORY_TO_COLOR } from '../../constants';
import { useAppDispatch, useAppSelector, useFeatureFlag, useGetFiltersFromParams } from '../../hooks';
import { closeMediaPlayer, setCalls } from '../../redux/reducers';
import { useGetCallsMutation, useGetOrganizationSettingsQuery } from '../../services';
import {
  Call,
  CallProcessingStatus,
  CallSortingFilters,
  ComponentSize,
  CustomSortingState,
  DateFormat,
  LD_FeatureFlags,
  PracticeProspect,
  ReviewFilterKeys,
  ReviewFilters,
  Roles,
  SortingOrder,
  TextColor,
  TimeFormat,
} from '../../types';
import {
  conditionalArray,
  formatSecondsToDuration,
  getCallerInfo,
  isCallInProgress,
  isCallProcessingPending,
  snakeCaseToLabel,
} from '../../utils';
import CallDetailsDrawer from './CallDetailsDrawer';
import FlaggedIndicator from './FlaggedIndicator';
import ReviewListenCell from './ReviewListenCell';
import useCallActions from './useCallActions';

const COMMENTS_AVATAR_SIZE = 20;

const ReviewPage = () => {
  const navigate = useNavigate();
  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const { repCanCreateSims = false } = orgConfigs || {};

  // State to track the active row.
  const [activeRowIndex, setActiveRowIndex] = useState<number | undefined>(undefined);
  // State for pagination settings.
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });
  // State for sorting settings.
  const [sorting, setSorting] = useState<CustomSortingState>({
    sortBy: CallSortingFilters.START_TIME,
    sortOrder: SortingOrder.DESC,
  });

  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { calls, currentCallDetails } = useAppSelector((state) => state.review);
  const isSalesRep = useMemo(() => user?.role === Roles.SALES_REP, [user?.role]);

  // Mutations
  // Fetch calls with loading state management.
  const [getCalls, { data, isLoading }] = useGetCallsMutation();
  const totalPages = data?.pagination.totalPages || 0;

  // Ref to the media player element.
  const mediaPlayerRef = useRef<HTMLDivElement | null>(null);

  // Custom hooks
  const teamsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_TEAMS);

  const filters = useGetFiltersFromParams() as ReviewFilters;

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination((prev) => ({ ...prev, pageIndex: 1 }));
    }
  }, [filters, setPagination]);

  const fetchCalls = useCallback(() => {
    getCalls({
      ...Object.entries(filters)
        .filter(([key]) => teamsFF || key !== ReviewFilterKeys.TEAM)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
      startTime: filters.startTime ? [filters.startTime] : undefined,
      pagination,
      sorting,
    });
  }, [filters, pagination, sorting, teamsFF, getCalls]);

  // Fetch calls whenever filters or pagination settings change.
  useEffect(() => {
    fetchCalls();
  }, [fetchCalls]);

  // This effect sets the list of calls for the Review Page in state.
  // It allows for optimistic updates to the state instead of refetching from the server.
  useEffect(() => {
    if (isLoading) return;
    dispatch(setCalls(data?.calls || []));
    return () => {
      dispatch(setCalls([]));
    };
  }, [data?.calls, isLoading, dispatch, setCalls]);

  // Set the active row index to the call that is currently being played.
  useEffect(() => {
    if (currentCallDetails) {
      const index = calls.findIndex((call) => call.callSid === currentCallDetails.call.callSid);
      if (index === -1) {
        // If the call is not in the list, reset the active row index.
        // Happens when the user filters the calls and the call currently being played is no longer in the list.
        setActiveRowIndex(undefined);
      } else if (index !== activeRowIndex) {
        // If the call is in the list and the active row index changed,
        // update the active row index.
        setActiveRowIndex(index);
      }
    } else if (activeRowIndex !== undefined) {
      // Reset the active row index when the media player closes.
      setActiveRowIndex(undefined);
    }

    // We purposely don't include activeRowIndex in the dependency array
    // because we only want to run this effect when the callSid or filters change.
  }, [currentCallDetails, calls, filters, setActiveRowIndex]);

  // Reset the call details when the user navigates away from the Review page.
  useEffect(() => {
    return () => {
      dispatch(closeMediaPlayer());
    };
  }, [dispatch]);

  if (!user) return null;

  const parsedData = useMemo(
    () =>
      calls.map((call) => {
        const { callSid, processingStatus } = call;
        const isProcessed = processingStatus === CallProcessingStatus.PROCESSED;
        const isProcessing = processingStatus === CallProcessingStatus.PROCESSING;

        const getCallTooltip = () => {
          if (isProcessed) return 'Review call';
          if (isProcessing) return 'Processing...';
          if (isCallProcessingPending(call)) return "Processing hasn't started";
          if (isCallInProgress(call)) return 'Call in progress';
          return 'Processing error';
        };

        return {
          ...call,
          tooltip: getCallTooltip(),
          onClick: isProcessed // Only allow click if call has finished processing.
            ? () => {
                // Clicking on the call will navigate to the call details page.
                navigate({
                  pathname: `${AppRoutes.REVIEW}/${callSid}`,
                  search: window.location.search,
                });
              }
            : undefined,
        };
      }),
    [calls, navigate]
  );

  // Conditionally show the comments column if there are any rows with comments.
  const showCommentsColumn = calls.some((call) => !!call.numberOfComments);

  const renderSortableHeader = useCallback(
    (sortingId: CallSortingFilters, title: string) => (
      <SortableHeader title={title} sorting={sorting} setSorting={setSorting} sortingId={sortingId} />
    ),
    [sorting, setSorting]
  );

  // Define columns for the data table.
  const columns: ColumnDef<Call>[] = useMemo(
    () => [
      {
        accessorKey: 'isFlagged',
        header: '',
        cell: ({ row, isActive, isHovered }: CustomCellContext<Call, void>) => {
          const { isFlaggedToUser, flaggedUserIds } = row.original;
          const isFlagged = !!isFlaggedToUser || !!flaggedUserIds?.length;
          return isFlagged ? (
            <FlaggedIndicator call={row.original} isActive={!!isActive || !!isHovered} />
          ) : (
            // Render an empty div with a width of 8px to maintain column width if no call is flagged.
            <div className="w-2" />
          );
        },
      },
      {
        accessorKey: 'listen',
        header: '',
        cell: ({ row }: { row: Row<Call> }) => (
          <div className="flex w-10 justify-center">
            <ReviewListenCell call={row.original} />
          </div>
        ),
      },
      {
        accessorKey: 'callDuration',
        header: () => renderSortableHeader(CallSortingFilters.CALL_DURATION, 'Duration'),
        cell: ({ row }) => {
          // Hide duration if call is live.
          if (isCallInProgress(row.original)) {
            return null;
          }

          const duration = row.original.callDuration || 0;
          return formatSecondsToDuration(duration);
        },
      },
      // Conditionally include the "Caller" column if the user is not a sales representative.
      // This is because a sales rep can only see their own Review.
      ...conditionalArray(!isSalesRep, {
        accessorKey: 'user',
        header: 'User',
        size: 1 / 2,
        cell: ({ row }: { row: Row<Call> }) => {
          const call = row.original;
          const caller = getCallerInfo(call, user);
          if (!caller) return 'N/A';

          const { name, picture } = caller;
          return <UserBadge title={name} imgSrc={picture} hideAvatarIfNoImgSrc />;
        },
      }),
      {
        accessorKey: 'practiceProspect',
        header: 'Prospect',
        size: 1 / 2,
        cell: ({ getValue }: { getValue: Getter<PracticeProspect> }) => {
          const prospect = getValue();
          const { firstName, lastName, jobTitle, accountName } = prospect;
          const title = `${firstName} ${lastName}`;
          const subtitle = `${accountName} | ${jobTitle}`;
          return <UserBadge title={title} subtitle={subtitle} />;
        },
      },
      ...conditionalArray(showCommentsColumn, {
        accessorKey: 'numberOfComments',
        header: () => null,
        cell: ({ row }: { row: Row<Call> }) => {
          const { numberOfComments, processingStatus, usersCommented = [] } = row.original;
          const isCallProcessed = processingStatus === CallProcessingStatus.PROCESSED;

          // Hide comments if call hasn't finished processing.
          if (!isCallProcessed || !numberOfComments) {
            return null;
          }

          return (
            <div className="flex items-center gap-1">
              <Icons icon={Icon.COMMENT} />
              <Typography size={TypographySize.CAPTION}>{numberOfComments}</Typography>
              <AvatarGroup className="!-space-x-2.5" size={COMMENTS_AVATAR_SIZE}>
                {usersCommented.map((user) => (
                  <Avatar label={user.name} key={user.id} imgSrc={user.picture} />
                ))}
              </AvatarGroup>
            </div>
          );
        },
      }),
      {
        accessorKey: 'scorecard',
        header: () => renderSortableHeader(CallSortingFilters.POINTS_PERCENTAGE, 'Score'),
        cell: ({ row }) => {
          const { processingStatus, scorecard } = row.original;
          const isCallProcessed = processingStatus === CallProcessingStatus.PROCESSED;
          const pointsPercentage = scorecard?.pointsPercentage;

          // Hide score if call hasn't finished processing.
          if (!isCallProcessed || typeof pointsPercentage !== 'number') {
            return null;
          }

          return `${Math.round(pointsPercentage * 100)}%`;
        },
      },
      {
        accessorKey: 'category',
        header: 'Category',
        cell: ({ row }: { row: Row<Call> }) => {
          const category = row.original.practiceProspect.category;
          return (
            <Badge
              color={PROSPECT_CATEGORY_TO_COLOR[category]}
              label={snakeCaseToLabel(category)}
              size={ComponentSize.MEDIUM}
            />
          );
        },
      },
      {
        accessorKey: 'startTime',
        header: () => renderSortableHeader(CallSortingFilters.START_TIME, 'Call date'),
        cell: ({ getValue }: { getValue: Getter<Date> }) => {
          const dateStr = getValue();
          return (
            <div className="flex flex-col gap-1">
              <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION}>
                {dayjs(dateStr).format(TimeFormat.SHORT_TIME)}
              </Typography>
              <Typography size={TypographySize.CAPTION}>{dayjs(dateStr).format(DateFormat.MONTH_DAY)}</Typography>
            </div>
          );
        },
      },
    ],
    [isSalesRep, user, renderSortableHeader, showCommentsColumn]
  );

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex w-full justify-between gap-2">
          <div className="flex items-center gap-2">
            <Breadcrumbs items={['Review']} />
            <IconButton
              icon={Icon.FAST_FORWARD}
              variant={ButtonVariant.GHOST}
              color={ButtonColor.SECONDARY}
              onClick={fetchCalls}
              tooltip="Refresh"
              size={ComponentSize.X_SMALL}
            />
          </div>
          <CreateButton repCanCreateSims={repCanCreateSims} />
        </div>
        <DataTable
          activeRowIndices={activeRowIndex === undefined ? undefined : [activeRowIndex]}
          columns={columns}
          data={parsedData}
          isLoading={isLoading}
          paginationControls={{ pagination, totalPages, setPagination }}
          useEndActions={(call, closeActions) => useCallActions(call, closeActions)}
        />
      </div>

      {/* Call Details Drawer */}
      <CallDetailsDrawer mediaPlayerRef={mediaPlayerRef} />
      {/* MediaPlayer Drawer */}
      <MediaPlayer ref={mediaPlayerRef} />
    </>
  );
};

export default ReviewPage;
