export type AccessSettingsPayload = {
  repCanScoreCalls?: boolean;
  repCanCreateSims?: boolean;
};

export type UpdateOrganizationSettingsPayload = AccessSettingsPayload & {
  fillerWordsPerMinute?: number;
  wordsPerMinute?: number;
  talkRatio?: number;
  longestMonologue?: number;
  vocabulary?: string[];
  defaultScorecardTemplateId?: string;
};

export type OrganizationSettings = {
  fillerWordsPerMinute: number;
  wordsPerMinute: number;
  talkRatio: number;
  longestMonologue: number;
  vocabulary: string[];
  repCanScoreCalls: boolean;
  repCanCreateSims: boolean;
  defaultScorecardTemplateId: string | null;
};

export type OrganizationSettingsResponse = {
  message: string;
  data: {
    settings: OrganizationSettings;
  };
};

export enum Benchmarks {
  FILLER_WORDS = 'fillerWordsPerMinute',
  TALK_SPEED = 'wordsPerMinute',
  TALK_RATIO = 'talkRatio',
  LONGEST_MONOLOGUE = 'longestMonologue',
}
