import { TypographySize, TypographyWeight, Typography } from '../../../../components';
import { TextColor } from '../../../../types';

interface CoachingItemProps {
  title: string;
  items: string[];
}

const CoachingItem = ({ title, items }: CoachingItemProps) => (
  <div className="flex flex-col gap-1">
    <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD} color={TextColor.SECONDARY}>
      {title}:
    </Typography>
    <div className="flex flex-col">
      {items.map((item, index) => (
        <Typography key={index} size={TypographySize.H5} color={TextColor.SECONDARY}>
          • {item}
        </Typography>
      ))}
    </div>
  </div>
);

export default CoachingItem;
