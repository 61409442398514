import { snakeCaseToLabel } from './string.utils';

/**
 * Conditionally inserts key-value pairs into an array.
 */
export const conditionalArray = <T>(condition: boolean, ...elements: T[]): T[] => (condition ? elements : []);

/**
 * Conditionally inserts key-value pairs into an object.
 */
export const conditionalObject = <T>(condition: boolean, elements: T): Partial<T> => {
  return condition ? { ...elements } : {};
};

/** Generates a random element from an array. */
export const getRandomElement = <T>(array: T[]): T => {
  return array[Math.floor(Math.random() * array.length)];
};

/** Generates a random subset of an array. */
export const getRandomElements = <T>(array: T[], length: number) =>
  array.sort(() => Math.random() - 0.5).slice(0, length);

/** Checks if the value is filled regardless of its type. */
export const isValueFilled = <T>(value: T) => {
  if (typeof value === 'string') {
    return !!value.trim();
  } else {
    return value !== undefined;
  }
};

/** Parses an array of values to an array of Select options. */
export const parseValuesToOptions = (array: string[]) =>
  array.map((value) => ({ label: snakeCaseToLabel(value), value }));

/** Checks if two string arrays are equal */
export const areStrArraysEqual = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item));
};
