import { ApiTagTypes, Candidate, CandidatesResponse } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCandidates: builder.query<Candidate[], void>({
      query: () => '/candidates',
      transformResponse: (response: CandidatesResponse) => response.data.candidates,
      providesTags: [ApiTagTypes.CANDIDATES],
    }),
  }),
});

export const { useGetCandidatesQuery } = enhancedApiSlice;
