import { Dispatch, SetStateAction, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, ButtonColor, Icon, Tabs, TabsType, TextButton, Typography } from '../../components';
import { useAppSelector, useCheckRequiredProspectFields } from '../../hooks';
import { ComponentSize, Permissions, ProspectPageTabs } from '../../types';
import { camelCaseToLabel } from '../../utils';

interface ProspectPageHeaderProps {
  activeTab: ProspectPageTabs;
  visitedTabs: Set<ProspectPageTabs>;
  setActiveTab: Dispatch<SetStateAction<ProspectPageTabs>>;
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
}

const ProspectPageHeader = ({ activeTab, visitedTabs, setActiveTab, setVisitedTabs }: ProspectPageHeaderProps) => {
  const navigate = useNavigate();

  const { isPromptEdited } = useAppSelector((state) => state.prospect);

  const user = useAppSelector((state) => state.auth.user);
  const permissions = user?.permissions || [];

  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);
  const canManageProspect = permissions.includes(Permissions.MANAGE_PROSPECT);

  const areRequiredFieldsFilled = useCheckRequiredProspectFields();

  // Maps each tab to an object containing its id, title, and disabled state.
  // Hide the Prompt tab from non-super-admins.
  const tabs = useMemo(
    () =>
      Object.values(ProspectPageTabs)
        .filter((tab) => {
          // Hide the Prompt tab from non-super-admins.
          if (!isSuperAdmin && tab === ProspectPageTabs.PROMPT) {
            return false;
          }
          // Hide the Config tab from sales reps.
          if (!canManageProspect && tab === ProspectPageTabs.CONFIGURATION) {
            return false;
          }
          return true;
        })
        .map((tab, index) => {
          // Show an asterisk if the tab has unfilled fields.
          const showAsterisk = !areRequiredFieldsFilled(tab, true);

          // The tab is disabled if it has not been visited before and all required fields in preceding tabs have not been filled out.
          const prevTab = index > 0 ? Object.values(ProspectPageTabs)[index - 1] : null;
          const isDisabled =
            !visitedTabs.has(tab) &&
            (tab === ProspectPageTabs.PROMPT || (!!prevTab && !areRequiredFieldsFilled(prevTab)));
          return {
            id: tab,
            title: (
              <Typography>
                <span className="font-mono">{'\u00A0'}</span>
                {camelCaseToLabel(tab)}
                <span className="font-mono">{showAsterisk ? '*' : '\u00A0'}</span>
              </Typography>
            ),
            disabled: isDisabled,
          };
        }),
    [isSuperAdmin, canManageProspect, visitedTabs, areRequiredFieldsFilled]
  );

  // Navigates back to the previous page.
  // Takes into consideration if the previous page was not the Practice page (ie. Review page).
  const handleBack = () => {
    navigate(-1);
  };

  // Changes the active tab and updates the visited tabs.
  const handleChangeTab = (newActiveTab: string) => {
    setActiveTab(newActiveTab as ProspectPageTabs);
    setVisitedTabs((prev) => new Set(prev).add(newActiveTab as ProspectPageTabs));
  };

  return (
    <div className="flex w-full flex-none items-center justify-between gap-4 px-8 pt-4">
      <div className="flex items-center gap-4">
        <TextButton onClick={handleBack} text="Back" color={ButtonColor.SECONDARY} startIcon={Icon.CHEVRON_LEFT} />
        <Tabs activeTab={activeTab} setActiveTab={handleChangeTab} type={TabsType.BORDERED} tabs={tabs} />
      </div>
      {isPromptEdited && <Badge label="Prompt edited" size={ComponentSize.LARGE} destructive />}
    </div>
  );
};

export default ProspectPageHeader;
