import { useNavigate } from 'react-router-dom';
import {
  AxisConfig,
  Benchmark,
  ButtonColor,
  Divider,
  EmptyState,
  Icon,
  LineChart,
  LineChartDataPoint,
  Select,
  SelectOption,
  Spinner,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../components';
import { AppRoutes } from '../../../constants';
import { ComponentSize, TextColor } from '../../../types';

interface ProgressReportCardProps {
  data: LineChartDataPoint[];
  selectOptions: SelectOption[];
  title: string;
  xAxis: AxisConfig;
  yAxis: AxisConfig;
  onSelectOption: (selected?: string) => void;
  benchmark?: Benchmark;
  value?: string;
  valueUnit?: string;
  isLoading?: boolean;
  selectedOption?: string;
}

export const ProgressReportCard = ({
  data,
  selectOptions,
  title,
  xAxis,
  yAxis,
  benchmark,
  onSelectOption,
  value,
  valueUnit,
  selectedOption,
  isLoading,
}: ProgressReportCardProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col rounded-md border">
      <div className="flex flex-row justify-between p-8">
        <div className="flex w-full flex-col gap-1">
          <Typography size={TypographySize.H5}>{title}</Typography>
          <div className="flex flex-col gap-1">
            <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H1}>
              {value}
            </Typography>
            <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
              {value && valueUnit}
            </Typography>
          </div>
        </div>
        <Select
          options={selectOptions}
          selected={selectedOption}
          size={ComponentSize.MEDIUM}
          onChange={onSelectOption}
        />
      </div>
      <Divider />
      <div className="p-8">
        {/* If there are data points, show the line chart */}
        {!!data.length && <LineChart data={data} xAxis={xAxis} yAxis={yAxis} benchmark={benchmark} />}

        {/* If there are no data points, show an empty state */}
        {!data.length && (
          <div className="flex h-80 items-center justify-center">
            {isLoading && <Spinner size={ComponentSize.SMALL} />}
            {!isLoading && (
              <>
                {/* If there are no select options, show the empty state with create button */}
                {!selectOptions.length && (
                  <EmptyState
                    description="Create AI scorecards to start seeing insights"
                    icon={Icon.BAR_CHART}
                    ctaButtonProps={{
                      color: ButtonColor.PRIMARY,
                      text: 'Create',
                      onClick: () => navigate(AppRoutes.SCORECARD_TEMPLATES),
                    }}
                  />
                )}
                {/* If there are select options, show the empty state with no calls message */}
                {!!selectOptions.length && (
                  <EmptyState description="No calls found for the selected filters" icon={Icon.BAR_CHART} />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
