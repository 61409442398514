import {
  ApiTagTypes,
  BaseScorecardTemplate,
  ScorecardTemplate,
  ScorecardTemplateResponse,
  ScorecardTemplatesForSelectParams,
  ScorecardTemplatesParams,
  ScorecardTemplatesResponse,
  ScorecardTemplatesSelectResponse,
  ScorecardTemplateStatus,
  ScorecardTemplateType,
} from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getScorecardTemplates: builder.query<ScorecardTemplate[], ScorecardTemplatesParams>({
      query: ({ sorting, status }) => ({
        url: '/scorecard-templates',
        params: { ...sorting, status },
      }),
      transformResponse: (response: ScorecardTemplatesResponse) => response.data.scorecardTemplates,
      providesTags: [ApiTagTypes.SCORECARD_TEMPLATES],
    }),
    getScorecardTemplatesForSelect: builder.query<BaseScorecardTemplate[], ScorecardTemplatesForSelectParams>({
      query: (params) => ({
        url: '/scorecard-templates/select',
        params: { status: ScorecardTemplateStatus.ACTIVE, type: ScorecardTemplateType.AI, ...params },
      }),
      transformResponse: (response: ScorecardTemplatesSelectResponse) => response.data.scorecardTemplates,
      providesTags: [ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT],
    }),
    getScorecardTemplate: builder.query<ScorecardTemplate, string>({
      query: (id) => `/scorecard-templates/${id}`,
      transformResponse: (response: ScorecardTemplateResponse) => response.data.scorecardTemplate,
      providesTags: (_, __, id) => [{ type: ApiTagTypes.SCORECARD_TEMPLATES, id }],
    }),
  }),
});

export const { useGetScorecardTemplatesQuery, useGetScorecardTemplatesForSelectQuery, useGetScorecardTemplateQuery } =
  enhancedApiSlice;
