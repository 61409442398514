import { useCallback, useEffect, useState } from 'react';
import { Badge, ButtonColor, Divider, Icon, IconButton, Tabs, Typography } from '../../../components';
import { PROSPECT_CATEGORY_TO_COLOR } from '../../../constants';
import { useAppDispatch } from '../../../hooks';
import { updatePlaybackDetails } from '../../../redux/reducers';
import { useGetCallScorecardQuery } from '../../../services';
import {
  Call,
  ComponentSize,
  Orientation,
  ProspectSuccessResult,
  Scorecard,
  ScorecardTemplateType,
  TextColor,
} from '../../../types';
import { capitalizeFirstLetter, snakeCaseToLabel } from '../../../utils';
import CallInfoCard from './CallInfoCard';
import { CallScorecard } from './CallScorecard';
import ResultsTab from './ResultsTab';
import TranscriptTab from './TranscriptTab';

const SUCCESS_RESULT_LABELS: Record<ProspectSuccessResult, { success: string; failure: string }> = {
  [ProspectSuccessResult.BOOKED_A_MEETING]: {
    success: 'Meeting booked',
    failure: 'Meeting not booked',
  },
  [ProspectSuccessResult.QUALIFY_PROSPECT]: {
    success: 'Prospect qualified',
    failure: 'Prospect not qualified',
  },
  [ProspectSuccessResult.GET_REFERRAL]: {
    success: 'Referral obtained',
    failure: 'Referral not obtained',
  },
};

// Enum for tab identifiers
enum CallDetailsTabs {
  RESULTS = 'RESULTS',
  TRANSCRIPT = 'TRANSCRIPT',
}

const CALL_DETAILS_TABS = Object.values(CallDetailsTabs).map((tab) => ({ id: tab, title: capitalizeFirstLetter(tab) }));
const DEFAULT_ACTIVE_TAB = CallDetailsTabs.RESULTS;

interface CallDetailsDrawerContentProps {
  call: Call;
  isOpen: boolean;
  onClose: () => void;
}

const CallDetailsDrawerContent = ({ call, isOpen, onClose }: CallDetailsDrawerContentProps) => {
  const dispatch = useAppDispatch();

  // State to manage the active tab.
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);

  const {
    attemptNumber,
    isSuccessfulOutcome,
    practiceProspect,
    callSid,
    scoringStatus,
    scorecard: scorecardRedux,
  } = call;
  const { successResult } = practiceProspect;

  // Fetch the scorecard for the current call
  const { data: existingScorecardApi, isLoading: isScorecardLoading } = useGetCallScorecardQuery(callSid);

  // Updated scorecard template
  const existingScorecard = { ...existingScorecardApi, ...(scorecardRedux as Scorecard) };

  const { points = 0, pointsPercentage = 0, scorecardTemplate } = existingScorecard;
  const { points: totalPoints, type: scorecardTemplateType } = scorecardTemplate || {};
  const isAIScorecard = scorecardTemplateType === ScorecardTemplateType.AI;

  // Handles seeking the current call to a specific time.
  const seekAudio = useCallback(
    (startTime: number) => {
      // Enable auto-playing the audio to provide a seamless experience for the user,
      // enabling them to immediately hear the context of the selected transcript time.
      dispatch(updatePlaybackDetails({ startTime, autoPlay: true }));
    },
    [dispatch]
  );

  // Reset the drawer state when the drawer is closed.
  useEffect(() => {
    if (!isOpen) {
      setActiveTab(DEFAULT_ACTIVE_TAB);
    }
  }, [isOpen]);

  // Don't show the outcome card if the outcome is null or undefined.
  const showOutcomeCard = typeof isSuccessfulOutcome === 'boolean' && successResult;
  const showScoreCard = !isScorecardLoading && isAIScorecard;

  const commonProps = {
    call,
    seekAudio,
  };

  return (
    <div className="flex h-full">
      <div className="flex h-full w-1/2 flex-col gap-4 p-4">
        <div className="flex items-center gap-4">
          <IconButton icon={Icon.CLOSE} onClick={onClose} tooltip="Close" color={ButtonColor.SECONDARY} />
          <Badge
            color={PROSPECT_CATEGORY_TO_COLOR[call.practiceProspect.category]}
            label={snakeCaseToLabel(call.practiceProspect.category)}
            size={ComponentSize.MEDIUM}
          />
          {attemptNumber && (
            <Typography color={TextColor.SECONDARY} noWrap>
              Attempt #{attemptNumber}
            </Typography>
          )}
        </div>
        <div className="flex w-full gap-4">
          {showOutcomeCard && (
            <CallInfoCard
              title="Outcome"
              value={isSuccessfulOutcome ? 'Success' : 'Fail'}
              description={
                isSuccessfulOutcome
                  ? SUCCESS_RESULT_LABELS[successResult].success
                  : SUCCESS_RESULT_LABELS[successResult].failure
              }
              icon={isSuccessfulOutcome ? Icon.CIRCLE_CHECK : Icon.CIRCLE_X}
              iconColor={isSuccessfulOutcome ? TextColor.SUCCESS : TextColor.DESTRUCTIVE}
            />
          )}
          {showScoreCard && (
            <CallInfoCard
              title="Score"
              value={`${Math.round(pointsPercentage * 100)}%`}
              description={`${points} / ${totalPoints} points`}
            />
          )}
        </div>
        <CallScorecard
          isScorecardLoading={isScorecardLoading}
          existingScorecard={existingScorecard}
          scoringStatus={scoringStatus}
        />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex h-full w-1/2 flex-col">
        <div className="flex gap-4 p-4 pb-0">
          <Tabs
            tabs={CALL_DETAILS_TABS}
            tabsListClassName="h-8"
            activeTab={activeTab}
            setActiveTab={(newActiveTab) => setActiveTab(newActiveTab as CallDetailsTabs)}
          />
        </div>
        <div className="flex-grow overflow-auto p-4">
          {activeTab === CallDetailsTabs.RESULTS && <ResultsTab {...commonProps} />}
          {activeTab === CallDetailsTabs.TRANSCRIPT && <TranscriptTab {...commonProps} />}
        </div>
      </div>
    </div>
  );
};

export default CallDetailsDrawerContent;
