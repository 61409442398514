import { Checkbox } from '../../../components';
import { ComponentSize } from '../../../types';

interface ProspectCheckboxProps {
  checked: boolean;
  label: string;
  setChecked: (newChecked: boolean) => void;
  disabled?: boolean;
}

const ProspectCheckbox = ({ checked, setChecked, disabled, label }: ProspectCheckboxProps) => {
  return (
    <div className="flex items-center gap-2">
      <Checkbox checked={checked} disabled={disabled} size={ComponentSize.X_SMALL} setChecked={setChecked} />
      {label}
    </div>
  );
};

export default ProspectCheckbox;
