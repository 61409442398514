import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MediaPlayerState {
  currentTime: number;
}

const INITIAL_MEDIA_PLAYER_STATE: MediaPlayerState = {
  currentTime: 0,
};

const mediaPlayerReducer = createSlice({
  name: 'mediaPlayer',
  initialState: INITIAL_MEDIA_PLAYER_STATE,
  reducers: {
    setCurrentTime: (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload;
    },
  },
});

export const { setCurrentTime } = mediaPlayerReducer.actions;
export default mediaPlayerReducer.reducer;
