import { useCallback, useEffect, useRef, useState } from 'react';
import { AlertType } from '../components';
import {
  BUFFER_DURATION_THRESHOLD,
  MAX_PACKET_DURATION_THRESHOLD,
  NO_OUTBOUND_PACKET_THRESHOLD,
  OUTBOUND_PACKET_SAMPLE_RATE,
  PACKET_INTERVAL_MS,
} from '../constants';
import { TwilioMediaMessage } from '../types';
import { base64ToUint8Array, generateCallSid, generateStreamSid, playBufferedAudio } from '../utils';
import useAppSelector from './useAppSelector';
import useAudioInput from './useAudioInput';
import usePlayCallAudio from './usePlayCallAudio';
import useToast from './useToast';
import useWebSocket from './useWebSocket';

const useWebCall = (onAudioInputError: () => void) => {
  // State variables
  // The callSid to be created for our call.
  // TODO: Generate callSid on the server side after we implement Authentication.
  const [callSid, setCallSid] = useState(generateCallSid());
  // Websocket connection status.
  const [isConnected, setIsConnected] = useState(false);
  // Whether the user started the call.
  const [isCalling, setIsCalling] = useState(false);
  // WebSocket connection error status.
  const [isConnectionError, setIsConnectionError] = useState(false);
  // Whether the call is ending.
  const [isHangingUp, setIsHangingUp] = useState(false);
  // Start time of the call in seconds.
  const [startTime, setStartTime] = useState<number>();

  // Refs
  const streamSidRef = useRef(generateStreamSid()); // The streamSid to be created for our call
  const audioPlaybackTimeRef = useRef<number>(0); // Ref to keep track of and set the current playback time
  const muLawBufferRef = useRef<Uint8Array[]>([]); // buffer to play the audio in chunks bigger than 20ms to be processed better with higher quality
  const accumulatedDurationRef = useRef(0); // Accumulated buffer duration in milliseconds
  const lastOutboundTimestampRef = useRef(0); // used to buffer the outbound packets exactly 20ms apart
  const startTimeRef = useRef(0); // used to be detected inside onmessage
  // Reference to track if the first outbound audio has been received.
  const hasReceivedFirstOutboundAudioRef = useRef(false);
  const personaIdRef = useRef<string>();

  // Refs to monitor the call status and avoid unintentional action triggers.
  const isCallingRef = useRef(false);
  const isHangingUpRef = useRef(false);
  const isConnectedRef = useRef(false);

  const userId = useAppSelector((state) => state.auth.user?.id);

  const { showToast } = useToast();
  const { playErrorAudio, pauseErrorAudio } = usePlayCallAudio();

  // Initialize useAudioInput hook
  const {
    isConnectedToMic,
    isConnectingToMic,
    startAudioInput,
    resetAudioInput,
    playbackAudioContextRef,
    isMuted,
    mute,
    unmute,
  } = useAudioInput(startTimeRef);

  const updateIsConnected = useCallback((newIsConnected: boolean) => {
    setIsConnected(newIsConnected);
    isConnectedRef.current = newIsConnected;
  }, []);

  // Wrapper so it would be easier to pass it to hooks and components
  const playBufferedAudioWrapper = useCallback(() => {
    if (muLawBufferRef.current.length > 0) {
      playBufferedAudio(muLawBufferRef, accumulatedDurationRef, playbackAudioContextRef, audioPlaybackTimeRef);
    }
  }, []);

  // Stops the audio playback and closes the AudioContext.
  const stopAudioPlayback = useCallback(() => {
    if (playbackAudioContextRef.current) {
      try {
        playbackAudioContextRef.current.close();
        playbackAudioContextRef.current = null;
      } catch (error) {
        console.error('Error stopping audio playback:', error);
      }
    }
  }, []);

  const updateIsHangingUp = useCallback((newIsHangingUp: boolean) => {
    setIsHangingUp(newIsHangingUp);
    isHangingUpRef.current = newIsHangingUp;
  }, []);

  const updateIsCalling = useCallback((newIsCalling: boolean) => {
    setIsCalling(newIsCalling);
    isCallingRef.current = newIsCalling;
  }, []);

  // This function is triggered whenever there is outbound audio received to the connect websocket
  const handleConnectMessage = useCallback(
    (message: TwilioMediaMessage) => {
      if (message.event === 'media' && message.media && message.media.track === 'outbound' && message.media.payload) {
        // Check if this is the first outbound audio
        if (!hasReceivedFirstOutboundAudioRef.current) {
          hasReceivedFirstOutboundAudioRef.current = true;
          // Mute the mic
          mute();

          // Unmute after 2 seconds
          setTimeout(() => {
            unmute();
          }, 2000);
        }

        const base64Data = message.media.payload;

        // Decode base64 to Uint8Array
        const muLawData = base64ToUint8Array(base64Data);

        // Add the Uint8Array to the buffer
        muLawBufferRef.current.push(muLawData);

        // Each chunk represents 20ms of audio
        const duration = (muLawData.length / OUTBOUND_PACKET_SAMPLE_RATE) * 1000; // Calculate duration in milliseconds
        accumulatedDurationRef.current += duration;

        // Check if we've reached or exceeded the buffer duration threshold or if the duration of the packet itself is larger
        if (duration >= MAX_PACKET_DURATION_THRESHOLD || accumulatedDurationRef.current >= BUFFER_DURATION_THRESHOLD) {
          playBufferedAudioWrapper();
        }
      } else if (message.event === 'stop' && muLawBufferRef.current.length > 0) {
        // Handle any remaining data in the buffer when the stream stops
        playBufferedAudioWrapper();
      }
    },
    [playBufferedAudioWrapper]
  );

  // This function is triggered whenever there is outbound audio received to the CONNECT websocket also
  const handleStartMessage = useCallback((message: TwilioMediaMessage, startTime: number) => {
    if (message.event === 'media' && message.media && message.media.payload && message.media.track === 'outbound') {
      const timestamp = startTime === 0 ? 0 : Date.now() - startTime;
      message.media.timestamp = timestamp.toString();

      // Adjust timestamp if packets arrive too close together
      const lastOutboundTimestamp = lastOutboundTimestampRef.current;
      if (lastOutboundTimestamp && timestamp - lastOutboundTimestamp <= NO_OUTBOUND_PACKET_THRESHOLD) {
        message.media.timestamp = (lastOutboundTimestamp + PACKET_INTERVAL_MS).toString();
      }

      lastOutboundTimestampRef.current = parseInt(message.media.timestamp);

      // Send adjusted message back to the WebSocket server
      sendStartMessage(message);
    }
  }, []);

  // Function to reset call references
  const resetCallRefs = useCallback(() => {
    resetAudioInput();
    streamSidRef.current = generateStreamSid();
    audioPlaybackTimeRef.current = 0;
    muLawBufferRef.current = [];
    accumulatedDurationRef.current = 0;
    lastOutboundTimestampRef.current = 0;
    personaIdRef.current = undefined;

    setStartTime(undefined);
    startTimeRef.current = 0;

    updateIsConnected(false);
    updateIsCalling(false);

    updateIsHangingUp(false);
  }, [updateIsCalling, updateIsHangingUp, resetAudioInput]);

  // Set the start time of the call.
  const initializeStartTime = useCallback(() => {
    setStartTime(new Date().getTime());
    startTimeRef.current = Date.now();
  }, [setStartTime]);

  // Initialize useWebSocket hook
  const { handleConnect, handleDisconnect, sendConnectMessage, sendStartMessage } = useWebSocket({
    personaIdRef,
    isHangingUpRef,
    resetCallRefs,
    onConnectMessage: handleConnectMessage,
    onStartMessage: handleStartMessage,
    updateIsConnected,
    isConnectedRef,
    streamSidRef,
    playBufferedAudio: playBufferedAudioWrapper,
    startTimeRef,
    initializeStartTime,
    setIsHangingUp: () => updateIsHangingUp(true),
  });

  // Function to stop the call.
  const stopCall = useCallback(async () => {
    // Reset the error state.
    // Handles the case where the user hangs up when the error audio is playing.
    pauseErrorAudio();
    setIsConnectionError(false);

    // Stop the audio playback in case it's still playing.
    // Handles the case where the user hangs up before the received audio finishes playing.
    stopAudioPlayback();

    // Create and send a message to stop the call on the server.
    const stopMessage = { event: 'stop', streamSid: streamSidRef.current, stop: {} };
    sendConnectMessage(stopMessage);
    sendStartMessage(stopMessage);
    await handleDisconnect(true);
    resetCallRefs();
  }, [handleDisconnect, resetCallRefs, sendConnectMessage, sendStartMessage, stopAudioPlayback]);

  // Handles mic connection errors.
  const handleAudioInputError = useCallback(() => {
    updateIsCalling(false);
    onAudioInputError();
    showToast({
      delay: 5000,
      title: 'Problem connecting to mic',
      message: 'There was a problem connecting to your microphone. Please try again.',
      type: AlertType.ERROR,
    });
  }, [onAudioInputError, showToast, updateIsCalling]);

  // Handles WebSocket connection errors by playing the error audio
  // and simulating a brief connection before stopping the call.
  const handleWebSocketError = useCallback(async () => {
    setIsConnectionError(true);
    try {
      // Play error audio and wait for it to finish.
      await playErrorAudio(() => updateIsHangingUp(true));
    } catch (error) {
      console.error('Error playing error audio: ', error);
    } finally {
      setIsConnectionError(false);
    }
  }, [playErrorAudio, updateIsHangingUp]);

  // Function to start the call.
  const startCall = useCallback(
    async (personaId: string) => {
      if (!userId || isCallingRef.current) return;

      // Start capturing audio and sending as 'inbound' over both WebSockets.
      updateIsCalling(true);

      personaIdRef.current = personaId;
      // Attempt to start audio input.
      try {
        await startAudioInput([sendStartMessage, sendConnectMessage], streamSidRef);
      } catch (e) {
        handleAudioInputError();
        // Exit early if audio input fails.
        return;
      }

      // Attempt to connect to the WebSocket.
      try {
        // Generate a new callSid for the call.
        const newCallSid = generateCallSid();
        setCallSid(newCallSid);
        await handleConnect(newCallSid, personaId, userId);
      } catch (e) {
        await handleWebSocketError();
      }
    },
    [
      userId,
      handleAudioInputError,
      handleConnect,
      handleWebSocketError,
      startAudioInput,
      sendConnectMessage,
      sendStartMessage,
      updateIsCalling,
    ]
  );

  // Make sure to end the call when the user unloads the page.
  useEffect(() => {
    window.addEventListener('beforeunload', stopCall);
    return () => {
      window.removeEventListener('beforeunload', stopCall);
    };
  }, [stopCall]);

  // Cleanup on unmount.
  useEffect(() => {
    return () => {
      if (isCalling || isConnected) {
        stopCall();
      }
    };
  }, []);

  return {
    callSid,
    startTime,
    isConnectedToMic,
    isConnectingToMic,
    isConnected,
    isCalling,
    isConnectionError,
    isHangingUp,
    startCall,
    stopCall,
    isMuted,
    mute,
    unmute,
  };
};

export default useWebCall;
