import { SerializedError } from '@reduxjs/toolkit';
import { OrgMember, Roles } from './../types';

export interface CustomFetchBaseQueryError {
  data: { message: string };
  status: number | string;
}

export type ApiPostError = CustomFetchBaseQueryError | SerializedError;

export type ApiSuccess<T = void> = { message: string; data: T };

export enum ApiTagTypes {
  CALLS = 'Calls',
  CANDIDATES = 'Candidates',
  COMMENTS = 'Comments',
  GONG_USERS = 'GongUsers',
  HOTLINE_PROSPECT = 'HotlineProspect',
  INVITES = 'Invites',
  PROMPT_TEMPLATES = 'PromptTemplates',
  PHONE_NUMBERS = 'PhoneNumbers',
  PROSPECTS = 'Prospects',
  PROSPECTS_FOR_SELECT = 'ProspectsForSelect',
  TAGS = 'Tags',
  USERS = 'Users',
  USERS_FOR_SELECT = 'UsersForSelect',
  SCORECARD_TEMPLATES = 'ScorecardTemplates',
  SCORECARD_TEMPLATES_FOR_SELECT = 'ScorecardTemplatesForSelect',
  SCORECARDS = 'Scorecards',
  ORGANIZATION_SETTINGS = 'OrganizationSettings',
  TEAMS = 'Teams',
}

export type Invite = {
  id: string;
  email: string;
  role: Roles;
  invitationURL: string;
  expiresAt: Date;
  createdAt: Date;
  updatedAt: Date;
};

export interface InvitesResponse {
  data: {
    invitations: Invite[];
  };
}

/** Defines the structure of a response from the server containing a list of users. */
export interface OrgUsersResponse {
  data: {
    users: OrgMember[];
  };
}

/** Defines the structure of a response from the server containing a list of phone numbers. */
export interface PhoneNumbersResponse {
  data: {
    phoneNumbers: string[];
  };
}

export type InitiateCallResponseData = {
  callSid: string;
  verificationCode: string;
};
