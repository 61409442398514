import {
  ButtonColor,
  Icon,
  Icons,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../components';
import { TextColor } from '../../../types';

interface TabSectionProps {
  children: React.ReactNode;
  title: string;
  description?: string;
  isRegenerating?: boolean;
  onRandomize?: () => void;
  onRegenerate?: () => void;
}

const TabSection = ({ children, description, title, isRegenerating, onRandomize, onRegenerate }: TabSectionProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between gap-1">
          <div className="flex items-center gap-1">
            <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD} className="select-none">
              {title}
            </Typography>
            {onRandomize && <Icons icon={Icon.WAND} color={TextColor.AI} onClick={onRandomize} tooltip="Fill for me" />}
          </div>
          {onRegenerate && (
            <TextButton
              startIcon={Icon.FAST_FORWARD}
              color={ButtonColor.SECONDARY}
              loading={isRegenerating}
              onClick={onRegenerate}
              text="Regenerate"
            />
          )}
        </div>
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} className="select-none">
          {description}
        </Typography>
      </div>
      {children}
    </div>
  );
};

export default TabSection;
