import { SKIP_AUTH_HEADER } from '../../constants';
import { ApiSuccess, SendAccessRequestEmailPayload } from '../../types';
import { apiSlice } from '../api.services';

const enhancedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendAccessRequestEmail: builder.mutation<ApiSuccess, SendAccessRequestEmailPayload>({
      query: ({ email, role, icp }) => ({
        url: '/emails/send-access-request-email',
        method: 'POST',
        body: {
          email,
          role,
          icp,
        },
        headers: {
          [SKIP_AUTH_HEADER]: 'true',
        },
      }),
    }),
  }),
});

export const { useSendAccessRequestEmailMutation } = enhancedApiSlice;
